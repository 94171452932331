import { ProductSummary } from '@/models/ProductSummary';

export class ProductGroup {
    name: string;
    products: ProductSummary[];
}

export class ProductGroupSearch {
    bqCode: string;
    examType: string;
    levelId: string;
    professionId: string;
}
