


























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Option, QuestionDetailInfo } from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    changedReplies() {
        this.$emit('changedReplies');
    }
    optionsLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    getOptionActiveClassCurrent(
        option: Option,
        question: QuestionDetailInfo
    ) {
        if (
            this.question.stuAnswer == this.question.corectAnswer &&
            option.id == this.question.corectAnswer
        )
            return 'greenClass';
        else {
            if (
                this.question.stuAnswer != this.question.rightAnswer &&
                this.question.stuAnswer == option.id
            ) {
                return 'redClass';
            }
        }
        if (option.id == this.question.corectAnswer) return 'greenClass';
        return 'defaultClass';
    }
    getOptionActiveClass(option: Option, question: QuestionDetailInfo) {
        if (question.stuAnswer == option.id) return 'activeClass';

        return 'defaultClass';
    }
}
