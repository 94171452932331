








































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestionDetailInfo, Option } from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
import { WrongTopicViewType } from '@/domain/getters';
import Storage from '@/infrastructure/Storage';
@Context('question', 'isEnable')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() isEnable: boolean;
    optionsLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

    changedReplies() {
        this.question.stuAnswer = (this.question
            .result as unknown) as string;
        this.$emit('changedReplies');
    }
    replaceHtmlTag(content) {
        if (!content) {
            return '';
        }
        return content;
    }
    getOptionActiveClass(option: Option, question: QuestionDetailInfo) {
        const result = (this.question.result as unknown) as Array<string>;
        if (Storage.Instance.get<number>(WrongTopicViewType) != 1) {
            if (result.indexOf(option.id) >= 0) {
                return 'greenClass';
            }
        }
        return 'defaultClass';
    }
}
