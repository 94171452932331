






































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { Rule } from '@/infrastructure/rules';
import { Bindingsuccess, UserLogin } from '@/domain/views';
import Repositories from '@/infrastructure/repository';

@RouteName(Bindingsuccess)
@Component({
    components: {
        navbar,
    },
    data() {
        return {
            radio: '1',
        };
    },
})
export default class InvoiceSuccessCom extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    @Inject(Rules) rules: Rule;
    LoginRe() {
        this.navigator.redirect(UserLogin);
    }
}
