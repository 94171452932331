
















































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { StatusMap } from '@/models/ExamPractice';
import { Notification } from 'vuetify-extension';
@Component
@Context('courseId','index')
@EnableProp()
export default class CourseChapterComponent extends Vue {
    @Prop() readonly courseId!: string;
    @Prop() readonly index!: string;
    @Prop() readonly lists!: any;
    @Inject(Repository) readonly repository!: Repositories;
    Enum = {
        statusMap: StatusMap
    }
    percentage: number = 0;
    downloadedSize:any = 0;
    totalSize:any = 0;
    dtask:any = null;
    isPlayOrStop:number = 0; // 0 等待下载 1 开始下载 2 暂停下载
    // 下载
    async confirmtest(){
        // alert('进入下载');
        // 文件地址
        let sd_path;
        let serverurl = this.lists.videoUrl;
        this.dtask = window.plus.downloader.createDownload(serverurl, {
            method: "GET",
            retry: 0
        });
        // 开始下载
        this.isPlayOrStop = 1;
        //监听下载进度事件
        let that=this;
        that.dtask.addEventListener("statechanged", function (task, status) {
            if (!that.dtask) {
              return;
            }
            switch (task.state) {
                case 1:
                // 开始
                break;
                case 2:
                // 已连接到服务器
                break;
                case 3:
                    // 已接收到数据
                    // eslint-disable-next-line no-case-declarations
                    let nowData = Math.floor(task.downloadedSize * 100 / task.totalSize);
                    that.downloadedSize = Number(task.downloadedSize/1048567).toFixed(2);
                    that.totalSize = Number(task.totalSize/1048567).toFixed(2);
                    that.percentage = Math.floor(that.downloadedSize*100/that.totalSize);
                    if (nowData % 10 === 0) {
                      if (nowData === 100) {
                        let filename= that.dtask.filename.substring(that.dtask.filename.lastIndexOf("/") + 1, that.dtask.filename.length);
                        window.plus.nativeUI.toast("正在准备环境，请稍后！");
                        window.plus.io.resolveLocalFileSystemURL(that.dtask.filename, function (entry) {
                            sd_path = window.plus.io.convertLocalFileSystemURL("_downloads/"+filename);
                        });
                      }
                    }
                break;
                case 4:
                    // alert('wanle');
                    that.dowEnd(); 
                    window.plus.nativeUI.toast("下载完成");
                break;
                case 5:
                    // alert('555暂停了');
                break;
            }
        }, false);
        //设置跨域
        that.dtask.setRequestHeader('Access-Control-Allow-Origin', '*');
        that.dtask.start();//开始任务
    }
    // 通知父组件，下载完成
    dowEnd() {
        this.$emit('statusChange', '3',this.lists.id,this.index,true)
    }
    // 开始下载
    start() {
        if(!this.dtask) {
            // alert('新下载')
            this.confirmtest();
        }else {
            // alert('继续下载')
            this.resume();
        }
    }
    // 暂停下载任务
    pause() {
        if(this.dtask) {
            // alert('暂停');
            this.isPlayOrStop = 2;
            this.dtask.pause();
        }
    }
    // 恢复暂停的下载任务
    resume() {
        if(this.dtask) {
            // alert('下载');
            this.isPlayOrStop = 1;
            this.dtask.resume();
        }
    }
    // 取消下载任务
    abort() {
        if(this.dtask) {
            this.isPlayOrStop = 0;
            this.dtask.abort();
        }
    }
    async playOrStop() {
        let that = this;
        if (!that.dtask || this.isPlayOrStop === 0) {
            this.isPlayOrStop = 1;
            await that.$emit('playOrStart',this.index);
        }else {
            // 0 等待下载 1 开始下载 2 暂停下载
            if(that.isPlayOrStop === 2) {
                // 下载中
                // alert('下载任务暂停中，开始');
                await that.$emit('playOrStop',this.index);
            }else {
                // alert('下载任务进行中，暂停')
                await that.pause();
                await that.$emit('playing',this.index);
            }
        }
    }
    // 调父组件lists
    getList() {
        this.$emit('getList',true,this.isPlayOrStop == 1?this.index:0)
    }
    // 删除
    @Loading(LoadingMsg)
    async del(ids: string) {
        if(this.dtask) this.dtask.abort(); // 移除下载任务
        const data = await this.repository.courseAppraise.VideoDowListDelete(
        {ids:ids}
        );
        if(data.success == true) {
            Notification.success('删除成功');
            // 0 等待下载 1 开始下载 2 暂停下载
            if(this.isPlayOrStop == 1) {
                await this.$emit('getList',true,this.index)
            }else {
                await this.$emit('getList',false)
            }

            // await this.getList();
        }
    }
    // 页面销毁
    // async destroyed() {
    //     if(this.dtask) {
    //         this.dtask.abort(); // 移除下载任务
    //     }
    // }
    

}
