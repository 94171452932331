import {
    ChangeExam,
    SetUserData,
    ClearUserAuthData,
    UpdateUserVersion, UpdateWrongTopicReport,
} from '@/domain/mutations';
import { SelectionExam } from '@/domain/selection';
import Storage from '@/infrastructure/Storage';
import { SelectedExam, Token, UserVersion } from '@/domain/getters';
import { UserLoginResponse } from '@/models/UserLoginResponse';
import { UserState } from '@/store/modules/user/index';
import { WrongTopicReport } from '@/models/WrongTopicReport';

export const mutations = {
    [ChangeExam](state: UserState, exam: SelectionExam) {
        state.exam = exam;
        Storage.Instance.set(SelectedExam, exam);
    },
    [SetUserData](state: UserState, data: UserLoginResponse) {
        state.token = data.token;
        Storage.Instance.set(Token, data.token);
    },
    [ClearUserAuthData](state: UserState) {
        state.token = '';
        Storage.Instance.clear(Token);
    },
    [UpdateUserVersion](state: UserState, version: string) {
        state.userVersion = version;
        Storage.Instance.set(UserVersion, version);
    },
    [UpdateWrongTopicReport](state: UserState, data: WrongTopicReport) {
        state.wrongTopicReport = data;
    },
};
