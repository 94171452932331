export class WxUserInfo {
    openid: string;
    nickname: string;
    headimgurl: string;
    unionid: string;
    sex: number;
    city: string;
    province: string;
    country: string;
}
