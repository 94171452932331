var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"theme-bg--color"},[_c('div',{staticClass:"mx-4 pt-3"},[(
                _vm.question.subjectType === this.Enum.questionType.LunShu ||
                    _vm.question.subjectType ===
                        this.Enum.questionType.Comprehensive
            )?_c('div',[(this.paperId != '-1')?_c('v-img',{attrs:{"width":"2.4533rem","height":"0.8rem","src":require("@/assets/questionBank/my.png")}},[_c('div',{staticClass:"body-2 white--text text-center my-answer-img"},[_vm._v(" 我的答案 ")])]):_vm._e()],1):(this.paperId != '-1')?_c('div',[(
                    this.GetReplyStatus(_vm.question) ==
                        this.ReplyEnum.ReplyType.Correct
                )?_c('v-img',{attrs:{"width":"2.4533rem","height":"0.8rem","src":require("@/assets/questionBank/correct.png")}},[_c('div',{staticClass:"body-2 white--text text-center answer-img"},[_vm._v(" 回答正确 ")])]):_vm._e(),(
                    this.GetReplyStatus(_vm.question) ==
                        this.ReplyEnum.ReplyType.PartCorrect
                )?_c('v-img',{attrs:{"width":"2.4533rem","height":"0.8rem","src":require("@/assets/questionBank/selected.png")}},[_c('div',{staticClass:"body-2 white--text text-center answer-img"},[_vm._v(" 少选 ")])]):_vm._e(),(
                    this.GetReplyStatus(_vm.question) ==
                        this.ReplyEnum.ReplyType.Error
                )?_c('v-img',{attrs:{"width":"2.4533rem","height":"0.8rem","src":require("@/assets/questionBank/error.png")}},[_c('div',{staticClass:"body-2 white--text text-center answer-img"},[_vm._v(" 回答错误 ")])]):_vm._e()],1):_vm._e(),_c('v-card',{staticClass:"mb-2",attrs:{"flat":""}},[(
                    _vm.question.subjectType ===
                        this.Enum.questionType.Comprehensive ||
                        _vm.question.subjectType ===
                            this.Enum.questionType.LunShu
                )?_c('v-row',{attrs:{"no-gutters":""}},[(this.paperId != '-1')?_c('v-col',{staticClass:"pa-4"},[(this.translateAnswer(_vm.question))?_c('div',{staticClass:"body-3 primary--text"},[_c('pre',[_vm._v(_vm._s(this.translateAnswer(_vm.question)))])]):_c('p',{staticClass:"analysis-none title text-center"},[_vm._v(" 未做 ")])]):_vm._e()],1):_c('v-row',{staticClass:"text-grey text-center body-2 py-3",attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" 正确答案 "),_c('p',{staticClass:"green--text title",domProps:{"innerHTML":_vm._s(_vm.question.rightAnswer)}})]),(this.paperId != '-1')?_c('v-col',[_vm._v(" 我的答案 "),(this.translateAnswer(_vm.question))?_c('p',{staticClass:"red--text title"},[_vm._v(" "+_vm._s(this.translateAnswer(_vm.question))+" ")]):_c('p',{staticClass:"analysis-none title"},[_vm._v(" 未做 ")])]):_vm._e()],1)],1),_c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('v-icon',{staticClass:"iconfont mb-1 mr-1",attrs:{"color":"#61646C","size":"16"}},[_vm._v(" hk-analysis ")]),_c('p',{staticClass:"body-2 d-inline-block"},[_vm._v(" 参考解析： ")]),_c('p',{staticClass:"text-grey pt-2 scrollable"},[_c('span',{staticClass:"body-3",domProps:{"innerHTML":_vm._s(_vm.question.questionTextAnalysis)}}),(
                    _vm.question.subjectType ===
                        this.Enum.questionType.Comprehensive ||
                        _vm.question.subjectType ===
                            this.Enum.questionType.LunShu
                )?_c('span',{staticClass:"body-3",domProps:{"innerHTML":_vm._s(_vm.question.rightAnswer)}}):_vm._e()])],1),(_vm.question.questionVedioAnalysis)?_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"mb-2",staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"15px","height":"10px"},attrs:{"src":require("../../assets/images/shipjiexi@2x.png")}}),_c('p',{staticClass:"body-2 d-inline-block pl-1"},[_vm._v(" 视频解析： ")])]),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"http://zhuofancbs.oss-cn-shenzhen.aliyuncs.com/1eea17db-8639-409c-89e5-7d24a81c11bd.jpg"},on:{"click":function($event){return _vm.preview(_vm.question.questionVedioAnalysis)}}})]):_vm._e(),_c('v-divider'),(this.paperId != '-1')?_c('div',{staticClass:"text-center py-4"},[_c('v-btn',{staticClass:"color-gradient--blue white--text subtitle-0 rounded-pill",attrs:{"depressed":"","width":"7.1733rem","height":"1.1733rem"},on:{"click":_vm.ViewErrorQuestion}},[_vm._v(" 本题纠错 ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }