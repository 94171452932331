



















import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { QuestionsCollectAdvancedList } from '@/domain/views';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import NavigationBar from '@/components/Review/questionsCollect/NavigationBar.vue';
import questionsCollectList from '@/components/Review/questionsCollect/List.vue';
@RouteName(QuestionsCollectAdvancedList)
@Component({
    components: { NavigationBar, questionsCollectList },
})
export default class QuestionsCollectAdvancedListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
}
