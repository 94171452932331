











import { Vue, Component, Provide } from 'vue-property-decorator';
import {
    Loader,
    Navigator,
    Notifier,
    Repository,
    Rules,
} from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Notification, Loader as LoaderExtension, Loading } from 'vuetify-extension';
import SimpleNavigation from '@/infrastructure/navigation';
import { RememberVersion } from '@/domain/actions';
import { AppGuide } from '@/domain/views';
import VeeRules from '@/infrastructure/rules';


@Component
export default class AppPageComponent extends Vue {
    @Provide(Repository) repository = new Repositories();
    @Provide(Notifier) notifier = Notification;
    @Provide(Loader) loader = new LoaderExtension();
    @Provide(Navigator) navigator = SimpleNavigation.Instance;
    @Provide(Rules) rules = new VeeRules();

    mounted() {
        this.checkUserVersion();
    }
 async   checkUserVersion() {
        if (this.$store.getters.usedLatestVersion) {
            return;
        }
        await this.$store.dispatch(RememberVersion);
        this.navigator.redirect(AppGuide);
    }
}
