




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestionDetailInfo } from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
@Context('question', 'isEnable')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() isEnable: boolean;
    changedReplies() {
        this.$emit('changedReplies');
    }
}
