import { Page } from '@/infrastructure/navigation/page';
import SimpleNavigation from '@/infrastructure/navigation';
import router from '@/router/index';
import {
    Course,
    MyInfo,
    QuestionBank,
    ReviewList,
    PracticeList,
    PracticeChapterList,
    PracticeExamList,
    ReviewAnswerRecordList,
    QuestionsCollectList,
    ReviewWrongTopicList,
    ReviewAnswerRecordReport,
    ReviewAnswerRecordSubjectiveItemReport,
    PracticeExamReport,
    PracticeExamSubjectiveItemReport,
    PersonalInfo,
    Products, Bindingsuccess,
} from '@/domain/views';

//返回健路由哈希对象
export class BackRoutePage {
    page: Page;
    callBack: any;
    params: any[];
    isCallBack: boolean;
}

//物理返回键，返回路由配置
export default class BackRouteConfig {
    //返回页面哈希表，关键字为当前页面，值为指定返回页面,如果为null表示不进行返回操作，如果没有指定，则由系统自己决定
    static backMap = new Map();
    //物理按钮返回状态，如果物理返回，标注为1
    static backState = 0;

    //初始化返回路由哈希表
    static Init() {
        this.backMap.set(MyInfo, null);
        this.backMap.set(Course, null);
        this.backMap.set(QuestionBank, null);
        this.backMap.set(Bindingsuccess, null);  //屏蔽返回
        //题库模块-练习
        this.backMap.set(PracticeList, this.getPage(QuestionBank));
        this.backMap.set(PracticeChapterList, this.getPage(PracticeList));
        this.backMap.set(PracticeExamList, this.getPage(PracticeList));
        //题库模块-复习
        this.backMap.set(ReviewList, this.getPage(QuestionBank));
        this.backMap.set(ReviewAnswerRecordList, this.getPage(ReviewList));
        this.backMap.set(ReviewWrongTopicList, this.getPage(ReviewList));
        this.backMap.set(QuestionsCollectList, this.getPage(ReviewList));
        //复习-答题记录
        this.backMap.set(
            ReviewAnswerRecordReport,
            this.getPage(ReviewAnswerRecordList)
        );
        this.backMap.set(
            ReviewAnswerRecordSubjectiveItemReport,
            this.getPage(ReviewAnswerRecordList)
        );
        //章节练习/强化练习/试卷
        this.backMap.set(PracticeExamReport, this.getPage(PracticeExamList));
        this.backMap.set(
            PracticeExamSubjectiveItemReport,
            this.getPage(PracticeExamList)
        );

        //我的
        this.backMap.set(PersonalInfo, this.getPage(MyInfo));

        //课程
        this.backMap.set(Products, this.getPage(Course));
    }

    //获取页面路由对象
    private static getPage(name: string, context?: any, query?: any) {
        const bpg = new BackRoutePage();
        bpg.page = new Page(name);
        bpg.page.context = context;
        bpg.page.query = query;

        bpg.callBack = null;
        bpg.isCallBack = false;

        return bpg;
    }

    //添加当前页面的物理按钮，返回路由
    static addBackPage(name: string, context?: any, query?: any) {
        this.backMap.set(
            router.currentRoute.name + '_mt',
            this.getPage(name, context, query)
        );

        this.backState = 0;
    }

    //添加自定义路由事件，返回键调用事件,callback函数名，params自定函数的参数
    static addBackEvent(callback: any, ...params: any[]) {
        const bpg = new BackRoutePage();
        bpg.page = null;
        //自定义路由函数
        bpg.callBack = callback;
        bpg.params = params;
        bpg.isCallBack = true;

        //添加路由
        this.backMap.set(router.currentRoute.name + '_mt', bpg);

        this.backState = 0;
    }

    //返回跳转页面
    static BackRedirect() {
        //当前页面
        const currentRoute = router.currentRoute;
        //手动路由key
        const mtPageName = currentRoute.name + '_mt';

        if (
            this.backMap.has(currentRoute.name) ||
            this.backMap.has(mtPageName)
        ) {
            //手动添加的路由优先
            let backPage = this.backMap.get(mtPageName);
            if (backPage == null) {
                //如果没有手动，则取初始化的路由设定
                backPage = this.backMap.get(currentRoute.name);
            } else {
                //非回调事件，则移除手动添加返回路由
                if (backPage.isCallBack == false) {
                    this.backMap.delete(mtPageName);
                }
            }

            if (backPage != null) {
                if (backPage.isCallBack) {
                    this.backState = 1;
                    //执行自定义函数
                    backPage.callBack(...backPage.params);
                } else {
                    const rtPage = backPage.page;
                    if (rtPage != null && rtPage.name != undefined) {
                        this.backState = 1;
                        //路由跳转
                        SimpleNavigation.Instance.redirect(
                            rtPage.name,
                            rtPage.context,
                            rtPage.query
                        );
                    }
                }
            }
        } else {
            //如果不包含，则条用系统原有的返回函数
            this.backState = 1;
            SimpleNavigation.Instance.back();
        }
    }
}
