











































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    Modifypassword,
    PersonalInfo,
    newpassword,
    verification,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import { UpdatePwdInfo } from '@/models/UserCourseResponse';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Dialog } from 'vant';

@RouteName(Modifypassword)
@Component({
    components: {
        navbar,
    },
})
export default class LoginCompleteInfoPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    show = false;
    pwdInputType = 'password';
    userPwd = '';
    input = new UpdatePwdInfo();
    isShowPwd = true;
    mounted() {
        //大左滑
        BackRouteConfig.addBackEvent(this.ToMyInfo);
    }
    deletePassword() {
        this.userPwd = '';
    }
    get IsDisable() {
        if(this.userPwd!='')
            return false;
        else
            return true;
    }
    //显示隐藏密码
    ShowPassword() {
        if (this.isShowPwd) {
            this.isShowPwd = false;
            this.pwdInputType = 'input';
        } else {
            this.isShowPwd = true;
            this.pwdInputType = 'password';
        }
    }
    async JudgePwd() {
        if (this.userPwd == null || this.userPwd == '') {
            this.notifier.warn('请输入原密码');
            return;
        }
        if (this.$store.getters.isLogin) {
            this.input.LoginOldPwd = this.userPwd;
            const data = await this.repository.user.JudgePwd(this.input);
            if (data.success == false) {
                Dialog.confirm({
                    title: '提示',
                    message: '原密码输入有误',
                    confirmButtonText: '重新输入',
                    cancelButtonText: '使用验证码输入',
                    confirmButtonColor: '#3f7efd',
                })
                    .then(() => {
                        return;
                    })
                    .catch(() => {
                        this.navigator.redirect(verification, '', {
                            OpType: '2',
                        });
                    });
                return;
            } else this.navigator.redirect(newpassword);
        }
    }
    ToMyInfo() {
        this.navigator.redirect(PersonalInfo);
    }
}
