import SimpleNavigation from '@/infrastructure/navigation';

export function getRoute(name: string, query?: any, context?: any): any {
    const route = {
        name: name,
        query: query,
        params: context,
    };
    const routeIsExisted = SimpleNavigation.Instance.existed(name, query);
    if (!routeIsExisted) {
        return '/404';
    }
    return route;
}
