export class VideoQuery {
    Id!: string;
}

export class VideoPlayInfo {
    playURL: string;
    duration: number;
    size: number;
    definition: string;
    height: number;
    width: number;
}

export class VideoResult {
    playAuth!: string;
    title!: string;
    videoId!: string;
    coverURL!: string;
    duration!: number;
    status!: string;
    width: number;
    height: number;
    infos: VideoPlayInfo[];
}

export enum VideoRedirect {
    FromCourseDetail = 0,
    FromMyPackage = 1,
    FromMyCourse = 2,
    fromMyInfo,
}
