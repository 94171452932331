<template>
    <div>
        <div
            v-if="this.newplayAuth !== ''"
            class="prism-player"
            :id="playerId"
            :style="playStyle"
        ></div>
        <div v-else>
            <v-img height="5.6266rem" src="~@/assets/images/novideo.png"></v-img>
        </div>
    </div>
</template>
<script type="text/ecmascript">
    import RateComponent from '@/components/videoPlayer/plugin/RateComponent'
        export default {
          name: 'Aliplayer',
          props: {
            playStyle: {
              type: String,
              default: '',
            },
            aliplayerSdkPath: {
              type: String,
              default: 'https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js',
            },
            autoplay: {
              type: Boolean,
              default: false,
            },
            isLive: {
              type: Boolean,
              default: false,
            },
            playsinline: {
              // H5是否内置播放，有的Android浏览器不起作用。
              type: Boolean,
              default: false,
            },
            width: {
              type: String,
              default: '100%',
            },
            height: {
              type: String,
              default: '5.6266rem',
            },
            controlBarVisibility: {
              // 控制面板的实现，默认为‘hover’， 可选的值为：‘click’、‘hover’、‘always’。
              type: String,
              default: 'hover',
            },
            useH5Prism: {
              type: Boolean,
              default: true,
            },
            useFlashPrism: {
              type: Boolean,
              default: false,
            },
            vid: {
              type: String,
              default: '',
            },
            playauth: {
              type: String,
              default: '',
            },
            source: {
              type: String,
              default: '',
            },
            cover: {
              type: String,
              default: '',
            },
            format: {
              type: String,
              default: 'mp4',
            },
            skinLayout: {
              type: Array,
              default: function() {
                return [];
              },
            },
            x5_video_position: {
              type: String,
              default: 'top',
            },
            x5_type: {
              type: String,
              default: 'h5',
            },
            x5_fullscreen: {
              type: Boolean,
              default: false,
            },
            x5_orientation: {
              type: Number,
              default: 2,
            },
            autoPlayDelay: {
              type: Number,
              default: 0,
            },
            autoPlayDelayDisplayText: {
              type: String,
            },
          },
          data() {
            return {
              videoResult:Object,
              playerId:
                  'aliplayer_' +
                  Math.random()
                      .toString(36)
                      .substr(2),
              scriptTagStatus: 0,
              isReload: false,
              instance: null,
                newplayAuth:'',
              progressInt:0
            };
          },
          created() {
            if (window.Aliplayer !== undefined) {
              // 如果全局对象存在，说明编辑器代码已经初始化完成，直接加载编辑器
              this.scriptTagStatus = 2;
              this.initAliplayer();
            } else {
              // 如果全局对象不存在，说明编辑器代码还没有加载完成，需要加载编辑器代码
              this.insertScriptTag();
            }
          },
          mounted() {
            if (window.Aliplayer !== undefined) {
              // 如果全局对象存在，说明编辑器代码已经初始化完成，直接加载编辑器
              this.scriptTagStatus = 2;
              this.initAliplayer();
            } else {
              // 如果全局对象不存在，说明编辑器代码还没有加载完成，需要加载编辑器代码
              this.insertScriptTag();
            }
          },
            destroyed() {
              this.instance?.dispose();
            },
            methods: {
            insertScriptTag() {
              let playerScriptTag = document.getElementById(
                  'playerScriptTag'
              );
              // 如果这个tag不存在，则生成相关代码tag以加载代码
              if (playerScriptTag === null) {
                playerScriptTag = document.createElement('script');
                playerScriptTag.type = 'text/javascript';
                playerScriptTag.src = this.aliplayerSdkPath;
                playerScriptTag.id = 'playerScriptTag';
                const s = document.getElementsByTagName('head')[0];
                s.appendChild(playerScriptTag);
              }
              if (playerScriptTag.loaded) {
                this.scriptTagStatus++;
              } else {
                playerScriptTag.addEventListener('load', () => {
                  this.scriptTagStatus++;
                  playerScriptTag.loaded = true;
                  this.initAliplayer();
                });
              }
              this.initAliplayer();
            },
            initload(videoId,playAuth,progress,){
                this.newplayAuth=playAuth;
              this.initAliplayer(videoId,playAuth,progress);
            },
            initAliplayer(videoId,playAuth,progress) {

              // scriptTagStatus 为 2 的时候，说明两个必需引入的 js 文件都已经被引入，且加载完成
              if (
                  videoId!=undefined&&playAuth!=undefined&& this.scriptTagStatus === 2 &&
                  (this.instance === null || this.reloadPlayer)
              ) {
                //将之前的播放实列销毁 by yxx
                if(playAuth!=null&&playAuth!='') {
                  this.instance && this.instance.dispose();
                  this.$nextTick(() => {
                    this.instance = window.Aliplayer({
                      id: this.playerId,
                      autoplay: this.autoplay,
                      isLive: this.isLive,
                      playsinline: this.playsinline,
                      format: this.format,
                      width: this.width,
                      height: this.height,
                      controlBarVisibility: this.controlBarVisibility,
                      useH5Prism: this.useH5Prism,
                      useFlashPrism: this.useFlashPrism,
                      vid: videoId,
                      playauth: playAuth,
                      source: this.source,
                      cover: this.cover,
                      x5_video_position: this.x5_video_position,
                      x5_type: this.x5_type,
                      x5_fullscreen: this.x5_fullscreen,
                      x5_orientation: this.x5_orientation,
                      autoPlayDelay: this.autoPlayDelay,
                      autoPlayDelayDisplayText:
                      this.autoPlayDelayDisplayText,
                      definition:'FD,LD',
                      defaultDefinition:'LD',
                        components: [{
                            name: 'RateComponent',
                            type: RateComponent
                        }],
                        skinLayout: [
                            {
                                "name": "bigPlayButton",
                                "align": "cc",
                            },
                            {
                                "name": "H5Loading",
                                "align": "cc"
                            },
                            {
                                "name": "errorDisplay",
                                "align": "tlabs",
                                "x": 0,
                                "y": 0
                            },
                            {
                                "name": "infoDisplay"
                            },
                            {
                                "name": "tooltip",
                                "align": "blabs",
                                "x": 0,
                                "y": 56
                            },
                            {
                                "name": "thumbnail"
                            },
                            {
                                "name": "controlBar",
                                "align": "blabs",
                                "x": 0,
                                "y": 0,
                                "children": [
                                    {
                                        "name": "progress",
                                        "align": "blabs",
                                        "x": 0,
                                        "y": 44
                                    },
                                    {
                                        "name": "playButton",
                                        "align": "tl",
                                        "x": 15,
                                        "y": 12
                                    },
                                    {
                                        "name": "timeDisplay",
                                        "align": "tl",
                                        "x": 10,
                                        "y": 7
                                    },
                                    {
                                        "name": "fullScreenButton",
                                        "align": "tr",
                                        "x": 10,
                                        "y": 12
                                    }
                                ]
                            }
                        ]
                    });

                    // 绑定事件，当 AliPlayer 初始化完成后，将 编辑器实例通过自定义的 ready 事件交出去
                    this.instance.on('ready', () => {
                      this.$emit('ready', this.instance);
                    });

                    this.instance.on('canplaythrough', function (e) {
                      if (progress > 0) {
                        this.instance.seek(progress);
                        progress = 0;
                      }
                    });

                    this.instance.on('play', () => {
                        if (progress > 0) {
                          this.instance.seek(progress);
                          progress = 0;
                        } else {
                          this.$emit('AddVideoProgress',Math.ceil(this.instance.getCurrentTime()),videoId);
                        }
                      this.$emit('play', this.instance);
                    });
                    this.instance.on('pause', () => {
                      this.$emit('pause', this.instance);
                    });
                    this.instance.on('ended', () => {
                      this.$emit('ended', this.instance);
                      this.$emit('AddVideoProgress',Math.ceil(this.instance.getCurrentTime()),videoId);
                    });
                    this.instance.on('liveStreamStop', () => {
                      this.$emit('liveStreamStop', this.instance);
                    });
                    this.instance.on('m3u8Retry', () => {
                      this.$emit('m3u8Retry', this.instance);
                    });
                    this.instance.on('hideBar', () => {
                      this.$emit('hideBar', this.instance);
                    });
                    this.instance.on('waiting', () => {
                      this.$emit('waiting', this.instance);
                    });
                    this.instance.on('snapshoted', () => {
                      this.$emit('snapshoted', this.instance);
                    });

                    this.instance.on('timeupdate', () => {
                      this.progressInt++;
                      if(this.isInteger(this.progressInt/478)==true){
                        this.$emit('AddVideoProgress',Math.ceil(this.instance.getCurrentTime()),videoId);
                      }
                      this.$emit('timeupdate', this.instance);
                    });
                    this.instance.on('requestFullScreen', () => {
                      this.$emit('requestFullScreen', this.instance);
                    });
                    this.instance.on('cancelFullScreen', () => {
                      this.$emit('cancelFullScreen', this.instance);
                    });
                    this.instance.on('error', () => {
                      this.$emit('error', this.instance);
                    });
                    this.instance.on('startSeek', () => {
                      this.$emit('startSeek', this.instance);
                    });
                    this.instance.on('completeSeek', () => {
                      this.$emit('completeSeek', this.instance);
                    });
                  });
                }
              }
            },
              play: function() {
                this.instance.play();
              },

            replay: function() {
              this.instance.replay();
            },

            seek: function(time) {
              this.instance.seek(time);
            },
            getCurrentTime: function() {
              return this.instance.getCurrentTime();
            },

            getDuration: function() {
              return this.instance.getDuration();
            },

            getVolume: function() {
              return this.instance.getVolume();
            },

            setVolume: function(vol) {
              this.instance.setVolume(vol);
            },

            loadByUrl: function(url, time) {
              this.instance.loadByUrl(url, time);
            },

            setSpeed: function(speed) {
              this.instance.setSpeed(speed);
            },

            setPlayerSize: function(w, h) {
              this.instance.setPlayerSize(w, h);
            },

            reloaduserPlayInfoAndVidRequestMts: function(vid, playauth) {
              this.instance.reloaduserPlayInfoAndVidRequestMts(vid, playauth);
            },
            reloadPlayer: function() {
              this.isReload = true;
              this.initAliplayer();
              this.isReload = false;
            },
               isInteger:function(obj) {
      return Math.floor(obj) === obj
    }




          },
        };
</script>

<style lang="postcss" scoped>
    @import 'https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css';
</style>
