import videojs from 'video.js';

const Plugin = videojs.getPlugin('plugin');

export default class FullscreenPlugin extends Plugin {
    static NAME = 'FullscreenPlugin';
    static VERSION = '1.0';
    private player: any;
    constructor(player: any, options: any) {
        super(player, options);
        this.player = player;
        this.setup();
    }

    get api() {
        return window.plus.screen;
    }

    private setup() {
        if (!(videojs.browser.IS_ANDROID || videojs.browser.IS_IOS)) return;

        this.player.on('fullscreenchange', () => {
            this.onFullscreenChanged();
        });
    }

    onFullscreenChanged() {
        const isFullscreen = this.player.isFullscreen();
        if (isFullscreen) {
            this.api.lockOrientation('landscape-primary');
        } else {
            this.api.lockOrientation('portrait-primary');
        }
    }
}
