import { HttpResponseHandler } from '.';
import { AxiosResponse } from 'axios';
import { Loader, Notification } from 'vuetify-extension';
import HttpResponse, {
    ResponseStatus,
} from '@/infrastructure/http/HttpResponse';
import store from '@/store';
import { Logout } from '@/domain/actions';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Dialog } from 'vant';
import {UserLogin } from '@/domain/views';
export default class AxiosHttpResponseHandler implements HttpResponseHandler {


    getResult<T = any>(response: AxiosResponse): T {
        return response.data.data as T;
    }

    isSuccess(response: AxiosResponse | void): boolean {
        if (!response) return false;
        return response.data.code === ResponseStatus.success;
    }

    async process(
        response: AxiosResponse | void,
        ignoreBusinessException: boolean,
        reject: (error: any) => void
    ): Promise<void> {
        Loader.CloseAll();

        if (!response) return;
        const data = response.data as HttpResponse;
        if (!data) return;
        if (ignoreBusinessException) {
            reject(data.message);
        }

        switch (data.code) {
            case ResponseStatus.noAuthorize:
                Notification.error('请登录后再进行操作.');
                break;
            case ResponseStatus.authorizeExpired:
                await store.dispatch(Logout);
                Dialog.alert({
                    title: '提示',
                    message: "您的账号已在其他设备登录",
                    confirmButtonText: '确定',
                    confirmButtonColor: '#3f7efd',
                }).then(r => {
                    window.location.href="http://app.haokao123.com/#/login";
                });
                break;
            case ResponseStatus.noPermission:
                Notification.error('暂无权限.');
                break;
            default:
                if (BackRouteConfig.backState === 1) {
                    //如果出现错题提示，不显示，同时标注为0
                    BackRouteConfig.backState = 0;
                } else {
                    if(data==null)
                        Notification.error('暂无数据');
                }
                console.log(JSON.stringify(data));
                break;
        }
    }
}
