/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-02-25 16:58:48
 * @LastEditTime: 2022-04-28 17:17:25
 * @FilePath: \HaoKao\haokao.app.ui\src\models\ReviewRecord.ts
 */
import { AnswerQuestionOutInfo } from '@/models/ExamPractice';

export class PaperReviewRecord {
    id: string;
    paperId: string;
    title: string;
    date: string;
    score: number;
    containLunShu: boolean;
}

export class ChapterReviewRecord {
    id: string;
    title: string;
    date: string;
    knowLedgePointId: string;
}

export class WrongTopicRecord {
    subjectId: string;
    chapterQuestionsId: string;
    knowledgePointName: string;
    answerCount: number;
    wrongType: number;
}

export class WrongTopicInfoReturn {
     index: number;
     answer: number;
     notAnswer: number;
     bili: number;
     errorAnsert: string;
    errorNumber: number;
     sucess: string;
     knowledgePointName: string = '';
     errorQuestionIds:Array<string> = [];
     partSucessQuestionIds:Array<string> = [];
     sucessQuestionIds:Array<string> = [];
}

export class QuestionsCollect {
    subType: string;
    subjectName: string;
    sumCount: string;
    collectiontype:string;
    
}
