




















































































































































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
    ChapterPracticeInfo,
    SubjectRedisInfo,
    ReplyParam,
} from '@/models/ChapterPractice';
import { SubjectInfo } from '@/models/ExamPractice';
import {
    PracticeChapterQuestionReply,
    PracticeChapterList,
    PracticeList,
    ReviewAnswerRecordChapterReport,
} from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import PageNotFound from '@/components/PageNotFound.vue';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestionInfos,
    ChapterQuestions,
    ChapterReplies,
    ChapterTrueQuestionIds,
    NoRepliedXChapterQuestionIds,
    QuestionId,
    Questions,
    subjectInfo,
    chaptertaskType,
    sourcetype,
    ReplyParams,
} from '@/domain/getters';
import { Dialog } from 'vant';

@Component({
    components: {
        PageNotFound,
        navbar,
    },
})
@RouteName(PracticeChapterList)
export default class ChapterList extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    Subjects = new Array<SubjectInfo>();
    SubjectsCount = 0;
    chapters = new Array<ChapterPracticeInfo>();
    tab = 0;
    answerShow = false;
    resultShow = false;
    isCompleted = false;
    DataCount = 0;

    get Type() {
        return this.$route.query.Type as string;
    }

    @Loading(LoadingMsg)
    async GetQuestionInfoBySubjectInfo(subjectId: string) {
        this.isCompleted = false;
        this.DataCount = 0;

        const data = await this.repository.chapterPractice.GetQuestionInfoBySubjectNew(
            this.Type,
            subjectId
        );
        this.chapters = data;

        this.isCompleted = true;
        for (const chapter of this.chapters) {
            this.DataCount += chapter.chapters.filter(
                x => x.topicCount > 0
            ).length;
        }


    }
    changeSubject(Id: string) {
        this.chapters = new Array<ChapterPracticeInfo>();
        this.GetQuestionInfoBySubjectInfo(Id);
    }

    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Number((((num / total) * 10000) / 100).toFixed(1));
    }
    async GetSubjects() {
        const data = await this.repository.exampractice.GetSubjects(
            this.$store.getters.exam.profession?.id,
            '0'
        );

        this.Subjects = data;
        this.SubjectsCount = data.length;
    }
    async mounted() {
        await this.GetSubjects();
        const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(subjectInfo);
        if (subjectinfo == null) {
            await this.GetQuestionInfoBySubjectInfo(this.Subjects[0].id);
        } else {
            await this.GetQuestionInfoBySubjectInfo(subjectinfo.subjectId);
            this.tab = subjectinfo.tab;
        }
    }
    async redirectQuestionReply(
        chapterId: string,
        topicCount: number,
        answerCount: number,
        chidChapterQuestionId: string,
        subjectId: string
    ) {
        Storage.Instance.clear(ReplyParams);
        const data = await this.repository.exampractice.IsShopping({
            subjectid: subjectId,
            relationType: this.Type,
        });
        if (data.result == true) {
            //点击切换将subjectid和tab本地做缓存,避免来回跳转形成俄罗斯套娃
            const subjectinfo = new SubjectRedisInfo();
            subjectinfo.subjectId = subjectId;
            subjectinfo.tab = this.tab;
            Storage.Instance.set(subjectInfo, subjectinfo);

            this.clearStroage();
            if (answerCount == 0) {
                this.redirectReply(
                    chapterId,
                    topicCount,
                    chidChapterQuestionId,

                    0
                );
            } else {
                const param = new ReplyParam();
                param.chapterId = chapterId;
                param.topicCount = topicCount;
                param.chidChapterQuestionId = chidChapterQuestionId;
                Storage.Instance.set(ReplyParams, param);
                if (answerCount < topicCount) {
                    this.answerShow = true;
                } else this.resultShow = true;
            }
        } else {
            this.notifier.success('没有权限！');
            return;
        }
    }
    resultConfirm() {
        const Param = Storage.Instance.get<ReplyParam>(ReplyParams);
        this.redirectReport(Param.chidChapterQuestionId, Param.chapterId);
    }
    resultCancle() {
        const Param = Storage.Instance.get<ReplyParam>(ReplyParams);
        this.redirectReply(
            Param.chapterId,
            Param.topicCount,
            Param.chidChapterQuestionId,

            0
        );
    }

    ReplyConfirm() {
        const Param = Storage.Instance.get<ReplyParam>(ReplyParams);
        this.redirectReply(
            Param.chapterId,
            Param.topicCount,
            Param.chidChapterQuestionId,

            1
        );
    }
    ReplyCancle() {
        const Param = Storage.Instance.get<ReplyParam>(ReplyParams);
        this.redirectReply(
            Param.chapterId,
            Param.topicCount,
            Param.chidChapterQuestionId,

            0
        );
    }

    redirectReply(
        chapterId: string,
        topicCount: number,
        chidChapterQuestionId: string,

        isReStart: number
    ) {
        this.navigator.redirect(
            PracticeChapterQuestionReply,
            { topicCount: topicCount },
            {
                chidChapterQuestionId: chidChapterQuestionId,
                knowledgePointId: chapterId,
                relationType: this.Type,
                isRestart: isReStart,
            }
        );
    }

    redirectReport(chapterQuestionsId: string, knowledgePointId: string) {
        Storage.Instance.set(sourcetype, 1);
        this.navigator.redirect(ReviewAnswerRecordChapterReport, '', {
            sourcetype: 1,
            chapterQuestionsId: chapterQuestionsId,
            relationType: this.Type,
            knowledgePointId: knowledgePointId,
        });
    }
    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(ChapterQuestionInfos);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        Storage.Instance.clear(ChapterReplies);
        Storage.Instance.clear(ChapterQuestions);
        Storage.Instance.clear(Questions);
        Storage.Instance.clear(chaptertaskType);
        Storage.Instance.clear(sourcetype);
    }

    practiceList() {
        this.navigator.redirect(PracticeList);
    }
}
