



















import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { ReviewList, ReviewWrongTopicAdvancedList } from '@/domain/views';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import wrongTopicList from '@/components/Review/wrongTopic/List.vue';
import NavigationBar from '@/components/Review/wrongTopic/NavigationBar.vue';
@RouteName(ReviewWrongTopicAdvancedList)
@Component({
    components: { wrongTopicList, NavigationBar },
})
export default class ReviewWrongTopicAdvancedListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    reviewList() {
        this.navigator.redirect(ReviewList);
    }
    practice = [
        { practiceList: '待消灭错题' },
        { practiceList: '已消灭错题' },
    ];
}
