























































































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
    ExaminationPaperInfo,
    PaperQuestionInfo,
    QuestionDetailInfo,
    QuestionType,
} from '@/models/ExamPractice';
import { RouteName } from 'ea-router';
import {
    PracticeExamQuestionView,
    PracticeExamReport,
    PracticeExamSubjectiveItemReport,
    PracticeExamTopicList,
    ReviewAnswerRecordReport,
    ReviewAnswerRecordSubjectiveItemReport,
} from '@/domain/views';

import QuestionReplyComponent from '@/components/questionviews/QuestionView.vue';
import Analysis from '@/components/questionviews/Analysis.vue';
import { Navigation } from '@/infrastructure/navigation';
import Storage from '@/infrastructure/Storage';
import {
    NoRepliedQuestionIds,
    ExaminationPaperInfos,
    PaperQuestions,
    QuestionId,
    TrueQuestionIds,
    taskType,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
        navbar,
    },
})
@RouteName(PracticeExamQuestionView)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;

    PaperQuestions = new Array<PaperQuestionInfo>();
    ExaminationPaper = new ExaminationPaperInfo();
    Questions = new Array<QuestionDetailInfo>();
    IsShowAnswer = true;
    IsRepeat = true;
    IsShowCollectioned = false;
    question = new QuestionDetailInfo();
    currentQuestionIndex = 1;
    collectShow = false;
    Enum = {
        questionType: QuestionType,
    };
    TotalCount = 0;
    Count2 = 0;
    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get paperId() {
        return this.$route.query.paperId as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }
    get taskType() {
        return Storage.Instance.get<number>(taskType);
    }

    get paperQuestionsRedis() {
        return Storage.Instance.get<Array<PaperQuestionInfo>>(
            PaperQuestions
        );
    }
    get ExaminationPaperRedis() {
        return Storage.Instance.get<ExaminationPaperInfo>(
            ExaminationPaperInfos
        );
    }
    get falseQuestionIds() {
        return Storage.Instance.get<Array<string>>(NoRepliedQuestionIds);
    }
    get trueQuestionIds() {
        return Storage.Instance.get<Array<string>>(TrueQuestionIds);
    }

    get IsLunshu() {
        return (
            this.Questions.filter(
                x => x.subjectType == this.Enum.questionType.LunShu
            ).length >= this.Questions.length
        );
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);

        if (
            this.paperQuestionsRedis != null &&
            this.ExaminationPaperRedis != null
        ) {
            await this.HandInLocalData();
            this.ExaminationPaper = this.ExaminationPaperRedis;
        }
        await this.GetAllQuestions();
        this.InitTotalCountOrCurrentIndex();

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }
    InitTotalCountOrCurrentIndex() {
        if (
            this.falseQuestionIds != undefined &&
            this.falseQuestionIds.length > 0
        ) {
            this.TotalCount = this.falseQuestionIds.length;

            if (this.questionId != undefined)
                this.currentQuestionIndex = this.question.rowsIndex;
            else this.currentQuestionIndex = 1;
        } else {
            this.TotalCount = this.Questions.length;
            this.currentQuestionIndex = this.question.rowsIndex;
        }
    }
    async HandInLocalData() {
        this.PaperQuestions = this.paperQuestionsRedis;
        if (this.questionId != undefined && this.questionId != null) {
            this.GetCurrentQuestion(this.questionId);
        } else {
            if (
                this.falseQuestionIds != undefined &&
                this.falseQuestionIds.length > 0
            ) {
                const questionId = this.falseQuestionIds[0];
                this.GetCurrentQuestion(questionId);
            } else {
                this.question = this.PaperQuestions[0].question[0];
            }
            await this.HandlerBtnController();
        }
    }

    GetCurrentQuestion(questionId: string) {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if (x.id == questionId) {
                    this.GetComprehensiveType(p, x);
                    this.question = x;
                    this.HandlerBtnController();
                }
            });
        });
    }
    async GetAllQuestions() {
        let index = 0;
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                this.Count2++;
                if (this.trueQuestionIds != null) {
                    if (this.trueQuestionIds.indexOf(x.id) == -1) {
                        index++;
                        x.rowsIndex = index;
                        this.GetComprehensiveType(p, x);
                        this.Questions.push(x);
                    }
                } else {
                    this.GetComprehensiveType(p, x);
                    this.Questions.push(x);
                }
            });
        });
    }
    GetComprehensiveType(p: PaperQuestionInfo, x: QuestionDetailInfo) {
        if (p.questionType == this.Enum.questionType.Comprehensive) {
            x.subjectTypeName = '案例分析题';
        }
    }

    async preQuestion() {
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async nextQuestion() {
        if (this.currentQuestionIndex >= this.TotalCount) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async HandlerBtnController() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowAnswer = true;
            this.IsShowCollectioned = true;
        } else {
            this.IsRepeat = true;
            this.IsShowAnswer = true;
            this.IsShowCollectioned = false;
        }
    }
    async HandlerChangeQuestion() {
        if (
            this.falseQuestionIds != undefined &&
            this.falseQuestionIds.length > 0
        ) {
            this.question = this.Questions.filter(
                x =>
                    x.id ==
                    this.falseQuestionIds[this.currentQuestionIndex - 1]
            )[0];
        } else {
            this.question = this.Questions.filter(
                x => x.rowsIndex == this.currentQuestionIndex
            )[0];
        }
        Storage.Instance.set(QuestionId, this.question.id);
    }
    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        } else {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        }
    }

    viewQuestionItems() {
        //console.log(this.question.id);
        Storage.Instance.set(QuestionId, this.question.id);

        this.navigator.redirect(PracticeExamTopicList, '', {
            IsShowHand: false,
            count: this.Count2,
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }

    redirectList() {
        if (this.taskType != null && this.taskType == 1) {
            if (this.IsLunshu) {
                this.navigator.redirect(
                    ReviewAnswerRecordSubjectiveItemReport,
                    '',
                    {
                        paperId: this.paperId,
                        groupId: this.groupId,
                        paperRecordsId: this.paperRecordsId,
                    }
                );
            } else {
                this.navigator.redirect(ReviewAnswerRecordReport, '', {
                    paperId: this.paperId,
                    groupId: this.groupId,
                    paperRecordsId: this.paperRecordsId,
                });
            }
        } else {
            if (this.IsLunshu) {
                this.navigator.redirect(
                    PracticeExamSubjectiveItemReport,
                    '',
                    {
                        count: this.Count2,
                        paperId: this.paperId,
                        groupId: this.groupId,
                        paperRecordsId: this.paperRecordsId,
                    }
                );
            } else {
                this.navigator.redirect(PracticeExamReport, '', {
                    count: this.Count2,
                    paperId: this.paperId,
                    groupId: this.groupId,
                    paperRecordsId: this.paperRecordsId,
                });
            }
        }
    }
}
