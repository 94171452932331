














































































































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import { Exam, ExamLevel, Profession } from '@/models/Exam';
import { Context, EnableProp, RouteName } from 'ea-router';
import {
    ChooseProfession,
    Course,
    PersonalInfo,
    PracticeList,
    QuestionBank,
    ReviewList,
} from '@/domain/views';
import { SelectExam } from '@/domain/actions';
import { SelectionExam } from '@/domain/selection';

@RouteName(ChooseProfession)
@Context('IsQuestion', 'IsQuestionBank')
@EnableProp()
@Component
export default class ChooseProfessionPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Prop() IsQuestion: boolean;
    @Prop() IsQuestionBank: boolean;
    @Prop() IsReview: boolean;
    tab: any = null;
    exams: Exam[] = [];
    examText = true;
    selectedExam: SelectionExam = new SelectionExam();

    get isSelected() {
        return 'id' in this.selectedExam;
    }
    get infoProfession() {
        return this.$route.query.infoProfession;
    }

    get isQuestionBank() {
        return this.$route.query.isQuestionBank;
    }

    get isReview() {
        return this.$route.query.isReview;
    }
    get isQuestion() {
        return this.$route.query.isQuestion;
    }

    async mounted() {
        if (this.$store.getters.exam) {
            this.selectedExam = { ...this.$store.getters.exam };
        }
        await this.getExam();
    }

    async examName(index) {
        if (index == '0') {
            this.examText = true;
        } else {
            this.examText = false;
        }
    }

    async getExam() {
        this.exams = await this.repository.exam.List();
    }

    async selectExamWithoutProfession(exam: Exam, level: ExamLevel) {
        await this.select(exam, level);
    }

    select(exam: Exam, level: ExamLevel, profession?: Profession) {
        this.selectedExam = {
            id: exam.id,
            name: exam.name,
            type: exam.type,
            level: level,
            profession: profession,
        };
    }

    isActive(exam: Exam, level: ExamLevel, profession?: Profession): boolean {
        if (this.selectedExam.id !== exam.id) return false;
        if (this.selectedExam.level.id !== level.id) return false;
        if (profession && this.selectedExam.profession?.id !== profession?.id)
            return false;

        return true;
    }

    async selectFinish() {
        await this.$store.dispatch(SelectExam, this.selectedExam);
        if (this.isQuestion) this.navigator.redirect(PracticeList);
        else if (this.isQuestionBank) this.navigator.redirect(QuestionBank);
        else if (this.isReview) this.navigator.redirect(ReviewList);
        else if (this.infoProfession) this.navigator.redirect(PersonalInfo);
        else this.navigator.redirect(Course);
    }
}
