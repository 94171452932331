



























































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    ReviewList,
    ReviewAnswerRecordList,
    ReviewAnswerRecordSubjectiveItemReport,
    ReviewAnswerRecordReport,
} from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import NavigationBar from '@/components/Review/answerRecord/NavigationBar.vue';
import answerRecordList from '@/components/Review/answerRecord/List.vue';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import { PaperReviewRecord } from '@/models/ReviewRecord';
import { LoadingData } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { tabStartPos } from '@/domain/getters';
import {IsSecretResult, IsSecretResultInput} from "@/models/ExamPractice";

@RouteName(ReviewAnswerRecordList)
@Component({
    components: {
        NavigationBar,
        answerRecordList,
        navbar,
    },
})
export default class ReviewAnswerRecordListPageComponent extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    recordList = new Array<PaperReviewRecord>();
    subjectId = '';
    Subjects = [];
    subjectCount = 0;
    subjectName = '';
    subTypeName = '';

    tabIndex = 1;
    leftTabIndex = 0;
    leftIndexAry = new Array<number>();


    get tabPos() {
        return Storage.Instance.get<number>(tabStartPos);
    }

    reviewList() {
        this.navigator.redirect(ReviewList);
    }


    async mounted() {
        //设置tab标签值
        if (this.tabPos != null && this.tabPos >= 0) {
            this.tabIndex = Math.floor(this.tabPos / 10);
            this.leftTabIndex = Math.floor(this.tabPos % 10);
        } else {
            //默认显示顶部一个，左侧第三个
            this.tabIndex = 0;
            this.leftTabIndex = 2;
        }

        await this.GetSubjects();

        //清除启动位置缓存
        Storage.Instance.clear(tabStartPos);
    }

    async GetSubjects() {
        const data = await this.repository.exampractice.GetSubjects(
            this.$store.getters.exam.profession?.id,
            '0'
        );

        this.Subjects = data;
        this.subjectCount = this.Subjects.length;

        if (this.tabIndex >= this.subjectCount) {
            this.tabIndex = this.subjectCount - 1;
        }
        this.subjectId = data[this.tabIndex].id;
        this.subjectName = data[this.tabIndex].subjectName;

        //初始化左侧数组长度
        this.leftIndexAry = [];
        for (let i = 0; i < this.subjectCount; i++) {
            this.leftIndexAry.push(this.leftTabIndex);
        }
        //更新左侧标签记录
        this.leftIndexAry[this.tabIndex] = this.leftTabIndex;
    }

    async changeSubject(id: string, index: number) {
        this.subjectId = id;

        //更新顶部标签记录
        this.tabIndex = index;
        //更新左侧标签记录
        this.leftTabIndex = this.leftIndexAry[this.tabIndex];
    }

    ChangeLeftFlag(leftIndex: number) {
        this.leftIndexAry[this.tabIndex] = leftIndex;
    }
}
