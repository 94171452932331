





















































































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    PracticeExamList,
    PracticeExamQuestionView,
    ReviewAnswerRecordList,
    ReviewAnswerRecordSubjectiveItemReport,
} from '@/domain/views';
import {
    AnswerQuestionInputInfo,
    MyPaperRecordsJgInfo,
    PaperQuestionInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import {
    PaperQuestions,
    QuestionId,
    Replies,
    ExaminationPaperInfos,
    taskType,
    TrueQuestionIds,
    NoRepliedQuestionIds,
} from '@/domain/getters';
import { LoadingTestPaper } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component
@RouteName(ReviewAnswerRecordSubjectiveItemReport)
export default class SubjectiveItemReport extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    paperQuestions = new Array<PaperQuestionInfo>();
    replies = Array<AnswerQuestionInputInfo>();
    myPaperRecordsJg = new MyPaperRecordsJgInfo();
    count = 0;
    get paperId() {
        return this.$route.query.paperId as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get getUnRepliedCount() {
        return Number(this.count) - this.myPaperRecordsJg.replies?.length;
    }

    IsReplyed(id: string) {
        return (
            this.myPaperRecordsJg.replies?.filter(
                x => x.bigQuestionId === id
            ).length > 0
        );
    }
    @Loading('加载中....')
    async GetMyPaperRecordsJg() {
        const data = await this.repository.exampractice.MyPaperRecordsJg({
            paperRecordsId: this.paperRecordsId,
        });
        this.myPaperRecordsJg = data;
        //replies-redis本地缓存
        const inputs = new Array<AnswerQuestionInputInfo>();
        this.myPaperRecordsJg.replies.forEach(x => {
            const replyInfo = new AnswerQuestionInputInfo();
            replyInfo.answer = x.stuAnswer;
            replyInfo.questionId = x.bigQuestionId;
            inputs.push(replyInfo);
        });
        Storage.Instance.set(Replies, inputs);
    }
    @Loading(LoadingTestPaper)
    async GetPaperQuestions() {
        const Data = await this.repository.exampractice.GetPaperInfo(
            this.paperId,
            this.paperRecordsId
        );
        this.paperQuestions = Data;
        this.paperQuestions.forEach((p: any) => {
            p.question.forEach((k: any) => {
                this.count++;
                if (
                    this.myPaperRecordsJg.replies.filter(
                        x => x.bigQuestionId == k.id
                    ).length > 0
                ) {
                    k.stuAnswer = this.myPaperRecordsJg.replies.filter(
                        x => x.bigQuestionId == k.id
                    )[0].stuAnswer;
                }
            });
        });
        Storage.Instance.set(PaperQuestions, Data);
    }
    async GetExaminationPaper() {
        const data = await this.repository.exampractice.GetExaminationPaper(
            {
                paperId: this.paperId,
                paperRecordsId: this.paperRecordsId,
            }
        );
        Storage.Instance.set(ExaminationPaperInfos, data);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.returnQuestionReply);

        await this.GetMyPaperRecordsJg();
        await this.GetExaminationPaper();
        await this.GetPaperQuestions();
    }
    timestampToTime(timestamp: string) {
        //这里需要格外的对timestamp是否为空进行判断,否则会报错
        if(timestamp==""||timestamp==null){
            const date = new Date(); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
            const Y = date.getFullYear() + '/';
            const M =
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1) + '/';
            const D =
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            //处理日期在苹果手机上显示NAN的问题
            return Y + M + D;

        }
        else{
            const date = new Date(Date.parse( timestamp.replace(/-/g, "/"))); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
            const Y = date.getFullYear() + '/';
            const M =
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1) + '/';
            const D =
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            //处理日期在苹果手机上显示NAN的问题
            return Y + M + D;

        }


    }
    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.set(taskType, 1);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: '',
            paperRecordsId: this.paperRecordsId,
        });
    }
    viewAllAlyasis() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.set(taskType, 1);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: '',
            paperRecordsId: this.paperRecordsId,
        });
    }
    returnQuestionReply() {
        this.navigator.redirect(ReviewAnswerRecordList);
    }
}
