


































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject } from 'vue-property-decorator';

import { RouteName,Context } from 'ea-router';
import {
    QuestionBank,
    ReviewAnswerRecordList,
    ReviewWrongTopicList,
    ReviewList,
    QuestionsCollectList,
    ChooseProfession,
} from '@/domain/views';
import {Navigator, Repository} from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestionInfos,
    ChapterQuestions,
    ChapterReplies,
    chaptertaskType,
    ChapterTrueQuestionIds,
    NoRepliedXChapterQuestionIds,
    QuestionId,
    sourcetype,
    WrongTopicQuestions,
    WrongTopicViewType,
    Collectiontype
} from '@/domain/getters';
import { SelectionExam } from '@/domain/selection';
import {IsSecretResult, IsSecretResultInput} from "@/models/ExamPractice";
import Repositories from "@/infrastructure/repository";

@RouteName(ReviewList)
@Component({
    components: {
        navbar,
    },
})
export default class ReviewListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
  @Inject(Repository) readonly repository!: Repositories;

    isReview = true;
    IsAdv = false;
    reviewListShow:boolean = false;
    actions = [{ name: '系统收藏夹',collectiontype:"0" }, { name: '自定义收藏夹',collectiontype:"1" }]
    selectedExam: SelectionExam = new SelectionExam();


    async mounted() {
        if (this.$store.getters.exam) {
            this.selectedExam = { ...this.$store.getters.exam };
        }

        //高级的不显示错题回顾
        if (
            this.selectedExam &&
            this.selectedExam.level &&
            this.selectedExam.level.id == '0890d3fb-fc2a-46ec-9c30-b33bf669d46d'
        ) {
            this.IsAdv = true;
        }

    }



    questionBank() {
        this.navigator.redirect(QuestionBank);
    }
    @NeedLogin
    reviewAnswerRecordList() {
        this.navigator.redirect(ReviewAnswerRecordList);
    }
    @NeedLogin
    reviewWrongTopicList() {
        this.navigator.redirect(ReviewWrongTopicList);
    }
    @NeedLogin
    questionsCollectList() {
        this.reviewListShow = true;
        // this.navigator.redirect(QuestionsCollectList);
    }
    chooseProfession() {
        this.navigator.redirect(ChooseProfession, '', {
            isReview: this.isReview,
        });
    }
    onSelect(e) {
        Storage.Instance.set(Collectiontype,e.collectiontype)
        this.navigator.redirect(QuestionsCollectList,'',{
            collectiontype:e.collectiontype,
        });

    }

    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(WrongTopicQuestions);
        Storage.Instance.clear(QuestionId);
    }
}
