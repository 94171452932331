import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import install from 'vuetify-extension';
import Startup from '@/infrastructure/startup';
import store from './store';
import iscrollview from 'vue-iscroll-view';
import IScroll from 'iscroll';
Vue.config.productionTip = false;
import ScrollLoader from 'vue-scroll-loader';
import vuehtml5plus from 'vue-html5plus';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import "amfe-flexible";

Vue.use(vuehtml5plus);





Vue.use(ScrollLoader);

Vue.use(iscrollview, IScroll);
install(Vue, vuetify);
Startup.configurationApp();

import vueTouch from './infrastructure/native/touch/vueTouch';
Vue.use(vueTouch);

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
new Vue({
    router,
    vuetify,
    store,
    render: h => h(App),
}).$mount('#app');
