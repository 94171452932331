



































































































   import navbar from '@/components/navbar.vue'
   import { Vue, Inject, Component, Prop } from 'vue-property-decorator';
   import { Navigator, Notifier, Repository } from '@/domain/providers';
   import { Navigation } from '@/infrastructure/navigation';
   import { Context, EnableProp, RouteName } from 'ea-router';
   import { Agreementaddress, MyAgreement } from '@/domain/views';
   import Repositories from '@/infrastructure/repository';
   import { AgreementRecordInfo } from '@/models/UserCourseResponse';
   import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
   @Context('agreementId', 'id', 'htmlContent')
   @EnableProp()
   @RouteName(Agreementaddress)
   @Component({
       components: {
           navbar,
       },
   })
   export default class AgreementAddressComponent extends Vue {

data() {
   return {
     checked: true,
    activeIcon: require('../../assets/images/tongyitiaok@2x.png'),
    inactiveIcon: require('../../assets/images/denglui_weixuanzhong@2x.png'),
   };
 }

       @Inject(Navigator) navigator: Navigation;
       @Inject(Repository) repository: Repositories;
       @Inject(Notifier) notifier: any;
       @Prop() readonly id!: string;
       @Prop() readonly htmlContent!: string;
       @Prop() readonly agreementId!: string;
       get courseId() {
           return this.$route.query.courseId as string;
       }
       Input = new AgreementRecordInfo();
       checked = true;
       nickName = '';
       telPhone = '';
       phoneInvalid = true;
       idcardInvalid = true;
       emailInvalid = true;
       MyInfo() {
           this.navigator.redirect(MyAgreement);
       }
       async mounted() {
           //添加手动物理返回键路由事件
           BackRouteConfig.addBackEvent(this.MyInfo);

           //获取用户信息
           if (this.$store.getters.isLogin) {
               const userInfo = await this.repository.user.getInfo();
               this.nickName = userInfo.nickName;
               this.telPhone = userInfo.telphoneNum;
           }
       }

       async AddAgreement() {
           if (this.Input.name == null || this.Input.name == '') {
               this.notifier.warn('请输入姓名');
               return;
           }
           if (this.Input.idCard == null || this.Input.idCard == '') {
               this.notifier.warn('请输入身份证号码');
               return;
           }
           if (
               this.Input.contactNumber == null ||
               this.Input.contactNumber == ''
           ) {
               this.notifier.warn('请输入联系电话');
               return;
           }
           if (this.Input.address == null || this.Input.address == '') {
               this.notifier.warn('请输入地址');
               return;
           }
           if (this.Input.email == null || this.Input.email == '') {
               this.notifier.warn('请输入邮箱');
               return;
           }
           if (this.checked == false) {
               this.notifier.warn('请勾选我同意以上条款');
               return;
           }
           this.CheckIdCard(this.Input.idCard);
           this.CheckPhone(this.Input.contactNumber);
           this.CheckEmail(this.Input.email);

           if (this.idcardInvalid == false) {
               this.notifier.warn('请输入正确的身份证号码');
               return;
           }
           if (this.phoneInvalid == false) {
               this.notifier.warn('请输入正确的手机号');
               return;
           }
           if (this.emailInvalid == false) {
               this.notifier.warn('邮箱格式不正确');
               return;
           }

           this.Input.phone = this.telPhone;
           this.Input.status = 1;
           this.Input.id = this.id;
           this.Input.agreementId = this.agreementId;
           this.Input.courseId = this.courseId;
           const data = await this.repository.user.AddAgreementRecord(
               this.Input
           );
           if (data.success) this.notifier.success('签订成功');
           this.navigator.redirect(MyAgreement);
       }
       CheckIdCard(value) {
           const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
           if (!reg.test(value)) {
               this.idcardInvalid = false;
           } else {
               this.idcardInvalid = true;
           }
       }
       CheckEmail(value) {
           const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
           if (!reg.test(value)) {
               this.emailInvalid = false;
           } else {
               this.emailInvalid = true;
           }
       }
       CheckPhone(value) {
           const reg = /^1[3456789]\d{9}$/;
           if (!reg.test(value)) {
               this.phoneInvalid = false;
           } else {
               this.phoneInvalid = true;
           }
       }
   }
