







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import { ChapterPractice, Option } from '@/models/ChapterPractice';
import Storage from '@/infrastructure/Storage';
import { chaptertaskType } from '@/domain/getters';
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    @Prop() question: ChapterPractice;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    get chaptertaskType() {
        return Storage.Instance.get<number>(chaptertaskType);
    }

    optionsLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

    changedReplies() {
        this.question.stuAnswer = (this.question
            .result as unknown) as string;
        this.$emit('changedReplies');
    }
    getOptionActiveClass(option: Option, question: ChapterPractice) {
        if (this.chaptertaskType == 1 || this.isShowRighteAnswer == true) {
            return 'defaultClass';
        } else {
            const result = (this.question.result as unknown) as Array<
                string
            >;
            if (result.indexOf(option.id) >= 0) {
                return 'activeClass';
            }
            return 'defaultClass';
        }
    }
}
