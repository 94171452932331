import { WxUserInfo } from '@/models/WxUserInfo';
import { Notification } from 'vuetify-extension';

export default class WxLogin {
    private static wxAuthService: any;

    //用户登录事件，微信登录成功后调用
    private static UserLogin: any;

    static Login(callback: any) {
        this.UserLogin = callback;
        window.plus.oauth.getServices(services => {
            this.wxAuthService = services.find(service => {
                return service.id === 'weixin';
            });

            const self = this;
            if (!this.wxAuthService.authResult) {
                this.wxAuthService.login(
                    function(e) {
                        self.authUserInfo(self);
                    },
                    function(e) {
                        Notification.error('登录认证失败.');
                    }
                );
            } else {
                this.authUserInfo(this);
            }
        });
    }

    // 获取微信登录授权对象后获取用户信息操作
    private static authUserInfo(obj: any) {
        const wxSvr = obj.wxAuthService;
        if (!wxSvr) {
            Notification.error('当前环境不支持微信登录.');
            return;
        }
        if (wxSvr.authResult) {
            wxSvr.getUserInfo(
                function(e) {
                    const wxUser = wxSvr.userInfo;

                    // console.log(
                    //     '3.getUserInfo获取用户信息成功： ' +
                    //         JSON.stringify(wxSvr.userInfo)
                    // );
                    const user = new WxUserInfo();
                    user.openid = wxUser.openid;
                    user.nickname = wxUser.nickname;
                    user.headimgurl = wxUser.headimgurl;
                    user.unionid = wxUser.unionid;
                    user.sex = wxUser.sex;
                    user.city = wxUser.city;
                    user.province = wxUser.province;
                    user.country = wxUser.country;

                    obj.UserLogin(user);
                    // console.log('4.缓存用户信息： ' + JSON.stringify(user));
                    // Storage.Instance.set(OpenWxUserInfo, user);

                    //注销登录防止切换账号获取到旧信息
                    wxSvr.logout(
                        function(e) {
                            // console.log(
                            //     '99.注销登录认证成功!' + JSON.stringify(e)
                            // );
                        },
                        function(e) {
                            // console.log(
                            //     '注销登录认证失败: ' + JSON.stringify(e)
                            // );
                        }
                    );
                },
                function(e) {
                    Notification.error('获取用户信息失败.');
                }
            );
        } else {
            Notification.error('未登录认证.');
        }
    }
}
