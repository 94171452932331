import Storage from '@/infrastructure/Storage';
import { UserVersion, SelectedExam, Token } from '@/domain/getters';
import { UserState } from '@/store/modules/user/index';
import { WrongTopicReport } from '@/models/WrongTopicReport';

export const state: UserState = {
    exam: Storage.Instance.get(SelectedExam),
    token: Storage.Instance.get(Token),
    examList: [],
    userVersion: Storage.Instance.get(UserVersion),
    wrongTopicReport: new WrongTopicReport()
};
