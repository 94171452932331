





































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import {
    CourseContrast,
    Course,
    ChooseProfession,
    SlideDetail, AppGuide,
} from '@/domain/views';
import { SlidePhoto } from '@/models/SlidePhoto';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Exam } from '@/models/Exam';
import { Navigation } from '@/infrastructure/navigation';
import CourseNavigation from '@/components/course/CourseNavigation.vue';
import { RouteName } from 'ea-router';

import Storage from '@/infrastructure/Storage';

import { IsUseMessageSend } from '@/domain/getters';
import { UserVersionOutput } from '@/models/UserCourseResponse';
import { Loading } from 'vuetify-extension';
import { LoadingHandPaper1 } from '@/domain/loadingText';
import { RememberVersion } from '@/domain/actions';

@RouteName(Course)
@Component({
    components: {
        CourseNavigation,
    },
})
export default class CoursePageComponent extends Vue {
  @Inject(Repository) repository: Repositories;
  @Inject(Navigator) navigator: Navigation;

  isLoading = true;
  loading = false;




    onRefresh() {
    setTimeout(() => {


      this.getSlidePhotos();
      if (!this.noSelectedExam) {
        this.getExam();
      }
      this.loading = false;
    }, 1000);
  }

  get noSelectedExam(): boolean {
    return this.$store.getters.exam === null;
  }

  mounted() {
    this.getSlidePhotos();
    if (!this.noSelectedExam) {
      this.getExam();
    } else {
      this.navigator.redirect(ChooseProfession);
    }
  }

  async getSlidePhotos() {
    this.photos = await this.repository.slide.Course(1);
    this.isLoading = false;
  }

  async getExam() {
    this.exams = await this.repository.exam.List();
  }

  chooseProfession() {
    this.navigator.redirect(ChooseProfession);
  }

  redirectCourseContrast() {
    this.navigator.redirect(CourseContrast);
  }

  slideDetail(linkAddress: string) {
    if (linkAddress.indexOf('SlideDetail') != -1) {
      this.navigator.redirect(SlideDetail, {
        id: linkAddress.replace('/#/SlideDetail/', ''),
      });
    }
  }





  photos: SlidePhoto[] = [];
  exams: Exam[] = [];
}
