





import { Vue, Component } from 'vue-property-decorator';

import { RouteName } from 'ea-router';
import { About } from '@/domain/views';

@RouteName(About)
@Component({
    components: {},
})
export default class AboutPage extends Vue {
    mounted() {
        throw new Error('报错报错报错了');
    }
}
