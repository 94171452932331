/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-04-08 15:31:26
 * @LastEditTime: 2022-05-05 09:18:49
 * @FilePath: \haokao.app.ui\src\domain\getters.ts
 */
export const SelectedExam = 'UserSelectedExam';
export const Token = 'AccessToken';
export const UserVersion = 'UserVersion';
export const Replies = 'Replies';
export const ChapterReplies = 'ChapterReplies';
export const PaperQuestions = 'PaperQuestions';
export const ExaminationPaperInfos = 'ExaminationPaperInfo';
export const QuestionId = 'QuestionId';
export const ChapterQuestions = 'ChapterQuestions';
export const Questions = 'Questions';
export const NoRepliedXChapterQuestionIds = 'NoRepliedXChapterQuestionIds';

export const ChapterQuestionInfos = 'ChapterQuestionInfos';
export const NoRepliedQuestionIds = 'NoRepliedQuestionIds';
export const TrueQuestionIds = 'TrueQuestionIds';

export const ChapterTrueQuestionIds = 'ChapterTrueQuestionIds';

export const Checked = 'Checked';
export const subjectInfo = 'subjectInfo';
export const examsubjectInfo = 'examsubjectInfo';
export const taskType = 'taskType';
export const chaptertaskType = 'chaptertaskType';
export const sourcetype = 'sourcetype';
export const ReplyParams = 'ReplyParams';
export const ChapterId = 'ChapterId';
export const coursesubjectInfo = 'coursesubjectInfo';

export const SoruceType = 'SoruceType';
export const packageLists = 'packageLists';

export const CurrentType = 'CurrentType';
export const OrderNo = 'OrderNo';
export const PaperRecord = 'PaperRecord';

export const DateNow = 'DateNow';
//tab启动位置
export const tabStartPos = 'tabStartPos';

export const OpenWxUserInfo = 'WxUserInfo';
export const IsUseMessageSend = 'IsUseMessageSend';
export const IsUseSend = 'IsUseSend';
export const WrongTopicQuestions = 'WrongTopicQuestions';
export const WrongTopicReplies = 'WrongTopicReplies';
export const WrongTopicViewType = 'WrongTopicViewType';
export const WrongTopicReportReturn = 'WrongTopicReportReturn';
export const WrongTopicViewOnlyError = 'WrongTopicViewOnlyError';
// 下载
export const DowList = 'DowList';
export const VideoDowtabType = 'VideoDowtabType';
// 试题解析
export const QuestionsRes = 'QuestionsRes';
export const Collectiontype = 'Collectiontype';


