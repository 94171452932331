<template>
    <div></div>
</template>

<script>
    export default {
        name: 'Reply',
    };
</script>

<style scoped></style>
