import { WxUserInfo } from '@/models/WxUserInfo';

export class UserRegisterRequestData {
    TelphoneNum: string;
    LoginPwd: string;
    NickNamw: string;
    RegisterIp: string;
    SubjectId: string;
    SgProjectId: string;
}

//参数对象，微信绑定手机号，验证短信，如果手机号注册，则绑定微信并登录，未注册，返回验证成功
export class WxCheckSmsCodeBindRequestData extends WxUserInfo {
    TelphoneNum: string;
    SmsCode: string;
}

//参数对象，微信注册，完善信息，注册并登录参数
export class WxUserRegisterRequestData extends WxUserInfo {
    TelphoneNum: string;
    LoginPwd: string;
    RegisterIp: string;
    SubjectId: string;
    SgProjectId: string;
}
