









































































import { Component, Inject, Prop, Vue,Ref } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {Navigation} from "@/infrastructure/navigation";
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';

import { VueCropper }  from 'vue-cropper' 


@Component
@Context()
@EnableProp()
@Component({
    components: {
        VueCropper
    },
})

export default class CropperSub extends Vue {
    // @Prop() imgUrl!: string;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    @Ref('VueCropper') readonly VueCropper!: any;
  
    option = {
      img: '', // 裁剪图片的地址
      outputSize: 1,  // 裁剪生成图片的质量
      outputType: 'png', // 格式
      // 只有自动截图开启 宽度高度才生效
      autoCropWidth: 300,
      autoCropHeight: 150,
    };

    @Loading(LoadingMsg)
    getImgUrl(e) {
      this.option.img = e;
    }
    // 取消裁剪
    async closeCropImage(){
        // this.VueCropper.clearCrop();
        this.$emit('crpoImageCancel')
    }
    // 裁剪改变
    realTime(e) {
        
    }
    rotateLeft() {
      this.VueCropper.rotateLeft();
    }
    rotateRight() {
      this.VueCropper.rotateRight();
    }

    // 获取文件
    @Loading(LoadingMsg)
    async cropImage() {
      let that = this;
      this.VueCropper.getCropData(async data => {
        // do something
        let file = that.dataURLtoFile(data,`${ Date.now()}.png`)
        const formData = new FormData();
        formData.append('file', file);
        const fileUrl = await this.repository.user.GetUploadFileUrl(
          formData
      );
      this.option.img = ''; // 清空图片，保证上传同一张照片正常进行
      await this.VueCropper.clearCrop();
      await that.$emit('cropImageRes',fileUrl.fileUrl);
      })
    }
    // base64转file
    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length; 
        let u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
}
