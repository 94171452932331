
































































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
    ExaminationPaperInfo,
    PaperQuestionInfo,
    QuestionDetailInfo,
    QuestionType,
} from '@/models/ExamPractice';
import { RouteName } from 'ea-router';
import {
    ReviewWrongTopicList,
    ReviewWrongTopicQuestionView,
    ReviewWrongTopicReport,
    ReviewWrongTopicTopicList,
} from '@/domain/views';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

import QuestionReplyComponent from '@/components/questionviews/CollectQuestionView.vue';
import Analysis from '@/components/Review/wrongTopic/Analysis.vue';
import { Navigation } from '@/infrastructure/navigation';
import Storage from '@/infrastructure/Storage';
import {
    QuestionId,
    taskType,
    WrongTopicQuestions,
    WrongTopicViewType,
    WrongTopicViewOnlyError,
} from '@/domain/getters';
import { Loading } from 'vuetify-extension';
import { LoadingTestPaper } from '@/domain/loadingText';
import { WrongTopicInfo } from '@/models/ReviewQuestion';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
    },
})
@RouteName(ReviewWrongTopicQuestionView)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;

    PaperQuestions = new Array<WrongTopicInfo>();
    ExaminationPaper = new ExaminationPaperInfo();
    Questions = new Array<QuestionDetailInfo>();

    IsRepeat = true;
    IsShowCollectioned = false;
    question = new QuestionDetailInfo();
    currentQuestionIndex = 1;
    rowsIndex = 1;
    collectShow = false;
    knowledgePointName = '';
    Enum = {
        questionType: QuestionType,
    };
    TotalCount = 0;
    Count2 = 0;
    IsShowAnswer = false;

    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get knowLedgePointId() {
        const knowing: string = this.$route.query
            .knowledgePointId as string;
        return knowing;
    }

    get taskType() {
        return Storage.Instance.get<number>(taskType);
    }

    get wrongTopicViewOnlyError() {
        return Storage.Instance.get<number>(WrongTopicViewOnlyError);
    }

    get wrongTopicViewType() {
        return Storage.Instance.get<number>(WrongTopicViewType);
    }
    get paperQuestionsRedis() {
        return Storage.Instance.get<Array<WrongTopicInfo>>(
            WrongTopicQuestions
        );
    }
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);

        if (this.$route.query.IsShowAnswer != null) {
            this.IsShowAnswer = (this.$route.query
                .IsShowAnswer as unknown) as boolean;
        }

        if (this.paperQuestionsRedis != null) {
            this.PaperQuestions = this.paperQuestionsRedis;
            await this.GetAllQuestions();
            await this.HandInLocalData();
        } else {
            await this.GetWrongTopicQuestions();
            await this.GetAllQuestions();
        }
        this.knowledgePointName = this.PaperQuestions[0].knowledgePointName;
        this.InitTotalCountOrCurrentIndex();
        await this.HandlerBtnController();

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }

    @Loading(LoadingTestPaper)
    async GetWrongTopicQuestions() {
        const Data = await this.repository.reviewRepository.GetWrongTopicInfo(
            1,
            this.knowLedgePointId
        );
        this.PaperQuestions = Data;
        this.InitQuestions();
        this.question = this.PaperQuestions[0].question[0];
        Storage.Instance.set(WrongTopicQuestions, this.PaperQuestions);
    }

    InitQuestions() {
        this.PaperQuestions.forEach((p: any, index) => {
            p.question.forEach((x: any) => {
                x.result = this.IninOptionToNum(x.rightAnswer);
            });
        });
    }
    //初始化选项字母转数字
    IninOptionToNum(answer) {
        const ary = [' ', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        const res = [];
        if (answer == null || answer == undefined) {
            return res;
        }

        ary.forEach((val, index) => {
            if (answer.indexOf(val) >= 0) {
                res.push(index);
            }
        });
        return res;
    }

    InitTotalCountOrCurrentIndex() {
        this.TotalCount = this.Questions.length;
        this.currentQuestionIndex = this.question.rowsIndex;
    }
    async HandInLocalData() {
        if (this.questionId != undefined && this.questionId != null) {
            this.GetCurrentQuestion(this.questionId);
        } else {
            this.question = this.Questions[0];
            await this.HandlerBtnController();
        }
    }

    GetCurrentQuestion(questionId: string) {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if (x.id == questionId) {
                    this.GetComprehensiveType(p, x);
                    this.question = x;
                    this.HandlerBtnController();
                }
            });
        });
    }
    async GetAllQuestions() {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if (this.wrongTopicViewOnlyError == 1) {
                    let stuAnswerStr = x.stuAnswer;
                    if (
                        x.stuAnswer != null &&
                        x.stuAnswer != '' &&
                        x.stuAnswer.length > 1
                    ) {
                        const lst: Array<string> = x.stuAnswer.sort();
                        stuAnswerStr = lst.join();
                    }

                    if (stuAnswerStr != x.corectAnswer) {
                        x.rowsIndex = this.rowsIndex++;
                        this.Count2++;
                        this.GetComprehensiveType(p, x);
                        this.Questions.push(x);
                    }
                } else {
                    x.rowsIndex = this.rowsIndex++;
                    this.Count2++;
                    this.GetComprehensiveType(p, x);
                    this.Questions.push(x);
                }
            });
        });
    }
    GetComprehensiveType(p: PaperQuestionInfo, x: QuestionDetailInfo) {
        if (p.questionType == this.Enum.questionType.Comprehensive) {
            x.subjectTypeName = '案例分析题';
        }
    }

    async preQuestion() {
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async nextQuestion() {
        if (this.currentQuestionIndex >= this.TotalCount) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async HandlerBtnController() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            // this.IsShowAnswer = true;
            this.IsShowCollectioned = true;
        } else {
            this.IsRepeat = true;
            // this.IsShowAnswer = true;
            this.IsShowCollectioned = false;
        }
    }
    async HandlerChangeQuestion() {
        this.question = this.Questions.filter(
            x => x.rowsIndex == this.currentQuestionIndex
        )[0];
        Storage.Instance.set(QuestionId, this.question.id);
    }
    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        } else {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        }
    }

    viewQuestionItems() {
        this.navigator.redirect(ReviewWrongTopicTopicList, '', {
            IsShowHand: false,
            count: this.Count2,
            wrongType: 1,
            IsShowAnswer: this.IsShowAnswer,
            paperRecordsId: this.paperRecordsId,
        });
    }

    redirectList() {
        if (
            this.wrongTopicViewType != null &&
            this.wrongTopicViewType == 1
        ) {
            this.navigator.redirect(ReviewWrongTopicReport);
        } else {
            this.navigator.redirect(ReviewWrongTopicList);
        }
    }
}
