


































































































































































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject,Ref } from 'vue-property-decorator';
import { Context, EnableProp, RouteName } from 'ea-router';
import { Navigation } from '@/infrastructure/navigation';
import { Navigator,Notifier, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import {QuestionResultInfo, QuestionResultInput, SubjectClassInfo,SubjectsActionsInfo} from '@/models/ExamPractice';
import { QuestionRecognition,DistinguishImg,ReviewQuestionsCollectQuestionView,QuestionsCollectList } from '@/domain/views';
import CropperSub from '@/components/questionviews/CropperSub.vue';
import { QuestionsRes,Collectiontype } from '@/domain/getters';
import Storage from '@/infrastructure/Storage';
import { Dialog } from 'vant';
import {SelectionExam} from "@/domain/selection";

import editor from '@/components/richtext/editor/index.vue';



@RouteName(QuestionRecognition)


@Component({
    components: {
        navbar,CropperSub,editor
    },
})
export default class QuestionRecognitionView extends Vue {
@Inject(Navigator) navigator: Navigation;
@Inject(Repository) readonly repository!: Repositories;
@Inject(Notifier) notifier: any;
@Ref('CropperSub') readonly CropperSub!: any;
@Ref('fileInput') readonly fileInput!: any;


Input = {
    QuestionContent: '',
    Option1: '',
    Option2: '',
    Option3: '',
    Option4: '', 
    Option5: '', 
    Option6: '', 
    Option7: '', 
    Option8: '', 
    SubjectId: '', 
    SubjectName: '',
    SubjectType: '',
    SubjectTypeName: '',
    RightAnswer: '', 
    QuestionTextAnalysis: '',
    Number: 0,
    RightAnswers:[],
} 

// new QuestionResultInput();
SubjectsShow:boolean = false;
SubjectsActions= new Array<SubjectsActionsInfo>();
isCrpoImage:boolean = false;
SubjectsTypeShow:boolean = false;
SubjectsTypeActions = new  Array<SubjectClassInfo>();
QuestionRes = new Array<QuestionResultInfo>();


get SubjectType() {
    return this.$route.query.SubjectType as string;
}
get SubjectTypeName() {
    return this.$route.query.SubjectTypeName as string;
}
get SubjectId() {
    return this.$route.query.SubjectId as string;
}
get SubjectName() {
    return this.$route.query.SubjectName as string;
}



    
@Loading(LoadingMsg)
async mounted() {
    //添加手动物理返回键路由事件
    BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
    if (this.SubjectType) this.Input.SubjectType = this.SubjectType;
    if (this.SubjectTypeName) this.Input.SubjectTypeName = this.SubjectTypeName;
    if (this.SubjectId) this.Input.SubjectId = this.SubjectId;
    if (this.SubjectName) this.Input.SubjectName = this.SubjectName;


  if (this.$store.getters.exam.level?.name == '经济师高级' ) {
    this.Input.SubjectName = this.$store.getters.exam.profession?.name;
    this.Input.SubjectId = this.$store.getters.exam.profession?.id;
    this.SubjectsTypeActions = await this.repository.exampractice.SubjectClassList(
        this.$store.getters.exam.profession?.id
    );
  }


    this.QuestionRes = Storage.Instance.get<Array<QuestionResultInfo>>(QuestionsRes);
    if(this.QuestionRes && this.QuestionRes.length>0) {
        this.Input.QuestionContent = this.QuestionRes[0].questionContent;
        if(this.SubjectType == '1') {
            await this.resHandle(4)
        }else if(this.SubjectType == '2'){
            await this.resHandle(5)
        }
        if(this.QuestionRes[1] && this.QuestionRes[1].questionTextAnalysis) {
            this.Input.QuestionTextAnalysis =  this.QuestionRes[1].questionTextAnalysis?this.QuestionRes[1].questionTextAnalysis:'';
        }
    }
    
}
resHandle(leg:number) {
    let that = this;
    let Option,option = '';
    for (let i = 1; i <= leg; i++) {
        Option = 'Option'+i
        option = 'option'+i
        
        
        that.Input[Option] = that.QuestionRes[0][option];
    }
}
ToUserPackageDetail() {
    // this.navigator.back();
    this.navigator.redirect(QuestionsCollectList,'',{
        collectiontype:Storage.Instance.get(Collectiontype)
    })
}

// 获取科目
@Loading(LoadingMsg)
async GetSubjects() {
    const data = await this.repository.exampractice.GetSubjects(
        this.$store.getters.exam.profession?.id,
        '0'
    );
    if(data && data.length>0) {
        this.SubjectsShow = true;
        data.forEach((item:any) => {
            item.name = item.subjectName
        });
    }
    this.SubjectsActions = data;
}
// 选择科目
async SubjectsSelect(e) {
  this.Input.SubjectName = e.name;
  this.Input.SubjectId = e.id;
  this.SubjectsShow = false;
  //这里需要根据科目id加载对应的科目题型
  this.SubjectsTypeActions = await this.repository.exampractice.SubjectClassList(
      e.id
  );
  //判断如果是初级,中级,默认选中单选
  if (this.$store.getters.exam.level?.name == '经济师初级' || this.$store.getters.exam.level?.name == '经济师中级') {
    this.Input.SubjectTypeName = "单项选择题";
    this.Input.SubjectType =this.SubjectsTypeActions.filter(x=>x.name=='单项选择题')[0]?.id;
  }
}
// 题型
async GetSubjectsType() {
    if(!this.Input.SubjectId) {
        this.notifier.warn('请先选择科目');
        return;
    }
    this.SubjectsTypeActions = await this.repository.exampractice.SubjectClassList(
      this.Input.SubjectId
  );
    this.SubjectsTypeShow = true;

}
async SubjectsTypeSelect(e) {
    this.Input.SubjectTypeName = e.name;
    this.Input.SubjectType = e.id;
    this.SubjectsTypeShow = false;
}
// 拍照
async photograph() {
    this.fileInput.value = ''; // 清空图片，保证上传同一张照片能触发change事件
    if(!this.Input.SubjectType) {
        this.notifier.warn('请先选择题型');
        return;
    }
    this.fileInput.click();
}
// 截图回调
async cropImageRes(url) {
    this.isCrpoImage = false;
    this.navigator.redirect(DistinguishImg,'',{
        url:url,
        SubjectType:this.Input.SubjectType,
        SubjectTypeName:this.Input.SubjectTypeName,
        SubjectId:this.Input.SubjectId,
        SubjectName:this.Input.SubjectName,
    })
}

// 上传
async chooseFile(event: any) {
    let that = this;
    const target = event.target ;
    const { files } = target;
    if (!files) return;
    const file: File = files[0];
    // 转换为base64传给裁切组件
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
        if(reader.result) {
            that.CropperSub.getImgUrl(String(reader.result));
            that.isCrpoImage = true;
        }
    }
}

// 上传图片取消
async crpoImageCancel() {
    this.isCrpoImage = false;
}

// 确定录入
@Loading(LoadingMsg)
async confirm() {
    let that = this;
    if(!that.Input.SubjectId) {
        that.notifier.warn('请选择科目');
        return false;
    }
    if(!that.Input.SubjectType) {
        that.notifier.warn('请选择题型');
        return false;
    }
    
     if(!that.Input.QuestionContent) {
        that.notifier.warn('请输入题干');
        return false;
    }
  if(that.Input.SubjectType == '2') {
    // 多选
    if(!this.Input.Option1 || !this.Input.Option2 || !this.Input.Option3 || !this.Input.Option4 || !this.Input.Option5 ) {
      that.notifier.warn('请输入答案');
      return false;
    }
    if(that.Input.RightAnswers.length<1) {
      that.notifier.warn('请选择正确答案');
      return false;
    }else {
      that.Input.RightAnswer = that.Input.RightAnswers.join();
    }
  }else if(that.Input.SubjectType == '1'){
    // 单选
    if(!this.Input.Option1 || !this.Input.Option2 || !this.Input.Option3 || !this.Input.Option4 ) {
      that.notifier.warn('请输入答案');
      return false;
    }
    if(!that.Input.RightAnswer) {
      that.notifier.warn('请选择正确答案');
      return false;
    }
  }
    this.Input.Number = this.Input.SubjectType == '1' ? 4 : this.Input.SubjectType == '2' ?  5 : 0;
    const data = await that.repository.exampractice.AddOcrQuestion(
        that.Input
    )
    if(data.success) {
        Dialog.confirm({
              title: '提示',
              message: '是否继续录入试题',
              confirmButtonText:"是",
              confirmButtonColor:"#4080FD",
              cancelButtonText:"否",
              cancelButtonColor:"#333",
            })
            .then(async () => {
                // on confirm
                that.Input = {
                    QuestionContent: '',
                    Option1: '',
                    Option2: '',
                    Option3: '',
                    Option4: '', 
                    Option5: '', 
                    Option6: '', 
                    Option7: '', 
                    Option8: '', 
                    SubjectId: this.Input.SubjectId, 
                    SubjectName: this.Input.SubjectName, 
                    SubjectType: this.Input.SubjectType, 
                    SubjectTypeName: this.Input.SubjectTypeName, 
                    RightAnswer: '', 
                    QuestionTextAnalysis: '',
                    Number: 0,
                    RightAnswers:[],
                } 
            })
            .catch(() => {
                // 跳转
                that.navigator.redirect(ReviewQuestionsCollectQuestionView,'',{
                    collectiontype: '1',
                    dateType: '1',
                    subType: this.Input.SubjectType,
                    subjectId: that.Input.SubjectId,
                })
            });
    }
}
destroyed() {
    Storage.Instance.clear(QuestionsRes);
}


}
