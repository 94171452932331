import AxiosHttp from '@/infrastructure/http';
import { LoginRequestData } from '@/models/LoginRequestData';
import { UserLoginResponse } from '@/models/UserLoginResponse';
import md5 from 'js-md5';
import { UserInfoResponse } from '@/models/UserInfoResponse';
import { SelectionExam } from '@/domain/selection';
import { CourseStudyRecordInfo } from '@/models/CourseStudyRecordInfo';
import { MessagesOutPut } from '@/models/MessagesOutPut';
import { OrderSheetOutput } from '@/models/OrderSheetOutput';
import { OrderSqInvoice,GetProviceListOuput } from '@/models/OrderSqInvoice';

import {
    UserRegisterRequestData,
    WxUserRegisterRequestData,
    WxCheckSmsCodeBindRequestData,
} from '@/models/UserRegisterRequestData';
import { WxUserInfo } from '@/models/WxUserInfo';

import {
    UploadFileInfo,
    CoursePack,
    UserCourseResponse,
    MyPackageWatchOutput,
    Course,
    AgreementRecordInfo,
    UpdatePwdInfo,
    VerificationCodeInfo,
    OrderRenewal,
    MyCardInfo,UserVersionOutput
} from '@/models/UserCourseResponse';
import { UserCurrentExamInfo } from '@/models/UserCurrentExamInfo';

export default class UserRepository {
    login(data: LoginRequestData) {
        const requestData = {
            phoneNumber: data.phoneNumber,
            password: md5(data.password),
        };
        return AxiosHttp.Instance.postWithoutCatch<UserLoginResponse>(
            '/User/Login',
            requestData
        );
    }

    SmsLogin(phoneNum: string, smsCode: string) {
        return AxiosHttp.Instance.get<UserLoginResponse>(
            '/User/SmsLogin?phoneNum=' + phoneNum + '&smsCode=' + smsCode
        );
    }

    Cancellation() {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/Cancellation',
            null
        );
    }

    getInfo() {
        return AxiosHttp.Instance.get<UserInfoResponse>('/User/Info');
    }

    EditHeadImage(data: FormData) {
        return AxiosHttp.Instance.post<UserInfoResponse>(
            '/User/EditHeadImage',
            data
        );
    }

    getCourse(selectionExam: SelectionExam) {
        const data = {
            examType: selectionExam.type,
            exam: selectionExam.id,
            level: selectionExam.level.id,
            profession: selectionExam.profession?.id,
        };
        return AxiosHttp.Instance.post<UserCourseResponse>(
            '/User/CourseNew',
            data
        );
    }

    AddCourseStudyRecord(courseStudyRecordInfo: CourseStudyRecordInfo) {
        return AxiosHttp.Instance.post<string>(
            '/User/AddCourseStudyRecord',
            courseStudyRecordInfo
        );
    }

    GetMyCourse(selectionExam: SelectionExam) {
        const data = {
            examType: selectionExam.type,
            exam: selectionExam.id,
            level: selectionExam.level.id,
            profession: selectionExam.profession?.id,
        };
        return AxiosHttp.Instance.post<UserCourseResponse>(
            '/User/GetMyCourse',
            data
        );
    }

    getLastStudyCourse(selectionExam: SelectionExam) {
        const data = {
            examType: selectionExam.type,
            exam: selectionExam.id,
            level: selectionExam.level.id,
            profession: selectionExam.profession?.id,
        };
        return AxiosHttp.Instance.post<Course>('/User/LastStudyCourse', data);
    }

    Package(id: string) {
        const param = {
            id: id,
        };
        return AxiosHttp.Instance.get<CoursePack>('/User/Package', param);
    }

    PackageSubjects(id: string, proId: string) {
        const param = {
            id: id,
            proId: proId,
        };
        return AxiosHttp.Instance.get<MyPackageWatchOutput>(
            '/User/PackageSubjects',
            param
        );
    }

    getCurrentExamInfo() {
        return AxiosHttp.Instance.get<UserCurrentExamInfo>(
            '/User/CurrentExamInfo'
        );
    }

    changeCurrentExam(data: UserCurrentExamInfo) {
        AxiosHttp.Instance.postWithoutCatch<any>(
            '/User/ChangeCurrentExam',
            data
        );
    }

    hasPermission(id: string) {
        const param = {
            id: id,
        };
        return AxiosHttp.Instance.get<boolean>('/User/HasPermission', param);
    }

    //检查手机号码是否已经注册，
    // 返回值：suceess为true，表示操作成功，code=1：表示已经注册，其他：未注册；
    // suceess为false操作失败，message为具体信息；
    CheckPhoneIsReg(phone: string) {
        return AxiosHttp.Instance.get<MessagesOutPut>(
            '/User/CheckPhoneIsReg?phoneNum=' + phone
        );
    }

    //检查手机是否已经绑定微信
    // 返回值：suceess为true，表示操作成功，code=1：表示已经绑定，其他：未绑定；
    // suceess为false，操作失败，message为具体信息；
    CheckPhoneIsBindWx(phone: string, wxunionid: string) {
        return AxiosHttp.Instance.get<MessagesOutPut>(
            '/User/CheckPhoneIsBindWx?phoneNum=' +
            phone +
            '&wxUnionId=' +
            wxunionid
        );
    }

    //发送短信验证码
    SendSmsInfo(phone: string) {
        return AxiosHttp.Instance.get<MessagesOutPut>(
            '/User/SendSmsInfo?phoneNum=' + phone
        );
    }

    //检查短信验证码
    CheckPhoneSmsCode(phoneNum: string, smsCode: string) {
        return AxiosHttp.Instance.get<MessagesOutPut>(
            '/User/CheckPhoneSmsCode?phoneNum=' +
            phoneNum +
            '&smsCode=' +
            smsCode
        );
    }
    //微信绑定手机号，验证短信，如果手机号注册，则绑定微信并登录，未注册，返回验证成功
    WxCheckSmsCodeBind(data: WxCheckSmsCodeBindRequestData) {
        const requestData = {
            nickname: data.nickname,
            openid: data.openid,
            headimgurl: data.headimgurl,
            unionid: data.unionid,
            sex: data.sex,
            city: data.city,
            province: data.province,
            country: data.country,
            TelphoneNum: data.TelphoneNum,
            SmsCode: data.SmsCode,
        };
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/WxCheckSmsCodeBind',
            requestData
        );
    }

    //用户完善资料，进行注册，成功，则返回登录凭证，空，则注册失败
    RegiestByApp(data: UserRegisterRequestData) {
        const requestData = {
            TelphoneNum: data.TelphoneNum,
            LoginPwd: md5(data.LoginPwd),
            NickNamw: data.NickNamw,
            RegisterIp: data.RegisterIp,
            SubjectId: data.SubjectId,
            SgProjectId: data.SgProjectId,
        };
        return AxiosHttp.Instance.post<UserLoginResponse>(
            '/User/RegiestByApp',
            requestData
        );
    }

    //微信用户完善资料，进行注册，成功，则返回登录凭证，空，则注册失败
    WxRegiestByApp(data: WxUserRegisterRequestData) {
        const requestData = {
            TelphoneNum: data.TelphoneNum,
            LoginPwd: md5(data.LoginPwd),
            nickname: data.nickname,
            RegisterIp: data.RegisterIp,
            SubjectId: data.SubjectId,
            SgProjectId: data.SgProjectId,
            openid: data.openid,
            headimgurl: data.headimgurl,
            unionid: data.unionid,
            sex: data.sex,
            city: data.city,
            province: data.province,
            country: data.country,
        };
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/WxRegiestByApp',
            requestData
        );
    }

    //微信登录/注册，如果成功，message内容未登录凭证，失败，则为错误提示
    LoginOrRegByAppWx(data: WxUserInfo) {
        const requestData = {
            country: data.country,
            province: data.province,
            city: data.city,
            sex: data.sex,
            openid: data.openid,
            headimgurl: data.headimgurl,
            nickname: data.nickname,
            unionid: data.unionid,
        };
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/LoginOrRegByAppWx',
            requestData
        );
    }

    //APP微信判断是否绑定手机及登录,绑定则登录，未绑定，则返回状态;
    //code：1：用户存在，用户绑定手机号，可登录；2：用户存在，未绑定手机号；3：用户不存在
    WxUserLoginOrBindPhone(wxUnionId: string) {
        return AxiosHttp.Instance.get<MessagesOutPut>(
            '/User/WxUserLoginOrBindPhone?wxUnionId=' + wxUnionId
        );
    }

    GetAgreement() {
        return AxiosHttp.Instance.get<Array<AgreementRecordInfo>>(
            '/User/GetAgreement'
        );
    }

    GetNewVersion() {
        return AxiosHttp.Instance.get<UserVersionOutput>(
            '/User/GetUserVersion'
        );
    }

    MyAgreementDetail(id: string) {
        return AxiosHttp.Instance.get<AgreementRecordInfo>(
            '/User/MyAgreementDetail?id=' + id
        );
    }

    getAgreementDetail(agreementId: string,courseid:string) {
        return AxiosHttp.Instance.get<AgreementRecordInfo>(
            '/User/GetAgreementRecordInfo?agreementId=' + agreementId+'&courseid='+courseid
        );
    }
    GetUserAgreementRecordCount() {
        return AxiosHttp.Instance.get<number>(
            '/User/GetUserAgreementRecordCount'
        );
    }
    AddAgreementRecord(input: AgreementRecordInfo) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/AddAgreementRecord',
            input
        );
    }
    //修改密码
    UpdatePwd(input: UpdatePwdInfo) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/UpdatePwd',
            input
        );
    }
    //验证原始密码
    JudgePwd(input: UpdatePwdInfo) {
        return AxiosHttp.Instance.post<MessagesOutPut>('/User/JudgePwd', input);
    }
    //绑定更换手机号码
    BindPhone(input: VerificationCodeInfo) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/User/BindPhone',
            input
        );
    }

    GetUserOrderList() {
        return AxiosHttp.Instance.get<Array<OrderSheetOutput>>(
            '/UserOrder/GetUserOrderList'
        );
    }
    //获取上传文件地址
    GetUploadFileUrl(data: FormData) {
        return AxiosHttp.Instance.post<UploadFileInfo>(
            '/User/GetUploadFileUrl',
            data
        );
    }

    AddSqlInvoice(data: OrderSqInvoice) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/UserOrder/AddSq',
            data
        );
    }

    GetProviceList() {
        return AxiosHttp.Instance.get<Array<GetProviceListOuput>>(
            '/UserOrder/GetProviceList'
        );
    }

    GetCityListByProviceId(ProviceId: string) {
        return AxiosHttp.Instance.get<Array<GetProviceListOuput>>(
            '/UserOrder/GetCityListByProviceId?ProvinceId='+ProviceId
        );
    }

    //提交续读申请
    AddOrderRenewal(data: OrderRenewal) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/UserOrder/AddOrderRenewal',
            data
        );
    }

    //提交续读申请
    MyCard(IfUse: string) {
        return AxiosHttp.Instance.get<Array<MyCardInfo>>(
            '/User/MyCard?IfUse=' + IfUse
        );
    }
}
