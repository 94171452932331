<template>
    <div>
        <div
            class="prism-player"
            :id="playerId"
            :style="playStyle"
        ></div>
    </div>
</template>
<script type="text/ecmascript">
    import RateComponent from '@/components/videoPlayer/plugin/RateComponent'
        export default {
          name: 'SimpleAliplayer',
          props: {
              value:{
                  type: String,
                  default: '',
              },
            playStyle: {
              type: String,
              default: '',
            },
            aliplayerSdkPath: {
              type: String,
              default: 'https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js',
            },
            autoplay: {
              type: Boolean,
              default: false,
            },
            isLive: {
              type: Boolean,
              default: false,
            },
            playsinline: {
              // H5是否内置播放，有的Android浏览器不起作用。
              type: Boolean,
              default: false,
            },
            width: {
              type: String,
              default: '100%',
            },
            height: {
              type: String,
              default: '5.6266rem',
            },
            controlBarVisibility: {
              // 控制面板的实现，默认为‘hover’， 可选的值为：‘click’、‘hover’、‘always’。
              type: String,
              default: 'hover',
            },
            useH5Prism: {
              type: Boolean,
              default: true,
            },
            useFlashPrism: {
              type: Boolean,
              default: false,
            },
            vid: {
              type: String,
              default: '',
            },
            playauth: {
              type: String,
              default: '',
            },
            source: {
              type: String,
              default: '',
            },
            cover: {
              type: String,
              default: '',
            },
            format: {
              type: String,
              default: 'mp4',
            },
            skinLayout: {
              type: Array,
              default: function() {
                return [];
              },
            },
            x5_video_position: {
              type: String,
              default: 'top',
            },
            x5_type: {
              type: String,
              default: 'h5',
            },
            x5_fullscreen: {
              type: Boolean,
              default: false,
            },
            x5_orientation: {
              type: Number,
              default: 2,
            },
            autoPlayDelay: {
              type: Number,
              default: 0,
            },
            autoPlayDelayDisplayText: {
              type: String,
            },
          },
          data() {
            return {
              videoResult:Object,
              playerId:
                  'aliplayer_' +
                  Math.random()
                      .toString(36)
                      .substr(2),
              scriptTagStatus: 0,
              isReload: false,
              instance: null,
                newplayAuth:'',
              progressInt:0
            };
          },
          created() {
              // 如果全局对象不存在，说明编辑器代码还没有加载完成，需要加载编辑器代码
              this.insertScriptTag();

          },
          mounted() {
              // 如果全局对象不存在，说明编辑器代码还没有加载完成，需要加载编辑器代码
              this.insertScriptTag();
          },
            methods: {
            insertScriptTag() {
              let playerScriptTag = document.getElementById(
                  'playerScriptTag'
              );
              // 如果这个tag不存在，则生成相关代码tag以加载代码
                playerScriptTag = document.createElement('script');
                playerScriptTag.type = 'text/javascript';
                playerScriptTag.src = this.aliplayerSdkPath;
                playerScriptTag.id = 'playerScriptTag';
                const s = document.getElementsByTagName('head')[0];
                s.appendChild(playerScriptTag);
                playerScriptTag.addEventListener('load', () => {
                  this.scriptTagStatus++;
                  playerScriptTag.loaded = true;
                  this.initAliplayer();
                });

            },
            initAliplayer() {
                console.log(this.scriptTagStatus);
              // scriptTagStatus 为 2 的时候，说明两个必需引入的 js 文件都已经被引入，且加载完成
              if (
                   this.scriptTagStatus === 2 &&
                  (this.instance === null || this.reloadPlayer)
              ) {
                  this.instance && this.instance.dispose();
                  this.$nextTick(() => {
                    this.instance = window.Aliplayer({
                      id: this.playerId,
                      autoplay: this.autoplay,
                      isLive: this.isLive,
                      playsinline: this.playsinline,
                      format: this.format,
                      width: this.width,
                      height: this.height,
                      controlBarVisibility: this.controlBarVisibility,
                      useH5Prism: this.useH5Prism,
                      useFlashPrism: this.useFlashPrism,
                      source:this.value,
                      cover: this.cover,
                      x5_video_position: this.x5_video_position,
                      x5_type: this.x5_type,
                      x5_fullscreen: this.x5_fullscreen,
                      x5_orientation: this.x5_orientation,
                      autoPlayDelay: this.autoPlayDelay,
                      autoPlayDelayDisplayText:
                      this.autoPlayDelayDisplayText,
                      definition:'FD,LD',
                      defaultDefinition:'LD',
                        components: [{
                            name: 'RateComponent',
                            type: RateComponent
                        }],
                        skinLayout: [
                            {
                                "name": "bigPlayButton",
                                "align": "cc",
                            },
                            {
                                "name": "H5Loading",
                                "align": "cc"
                            },
                            {
                                "name": "errorDisplay",
                                "align": "tlabs",
                                "x": 0,
                                "y": 0
                            },
                            {
                                "name": "infoDisplay"
                            },
                            {
                                "name": "tooltip",
                                "align": "blabs",
                                "x": 0,
                                "y": 56
                            },
                            {
                                "name": "thumbnail"
                            },
                            {
                                "name": "controlBar",
                                "align": "blabs",
                                "x": 0,
                                "y": 0,
                                "children": [
                                    {
                                        "name": "progress",
                                        "align": "blabs",
                                        "x": 0,
                                        "y": 44
                                    },
                                    {
                                        "name": "playButton",
                                        "align": "tl",
                                        "x": 15,
                                        "y": 12
                                    },
                                    {
                                        "name": "timeDisplay",
                                        "align": "tl",
                                        "x": 10,
                                        "y": 7
                                    },
                                    {
                                        "name": "fullScreenButton",
                                        "align": "tr",
                                        "x": 10,
                                        "y": 12
                                    }
                                ]
                            }
                        ]
                    });
                      this.instance.on('pause', () => {
                          this.$emit('pause', this.instance);
                      });
                  });

              }
            },
          },

        };
</script>

<style lang="postcss" scoped>
    @import 'https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css';
</style>
