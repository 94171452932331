





































































































































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject } from 'vue-property-decorator';
import {Navigator, Repository} from '@/domain/providers';
import {
    PracticeChapterList,
    PracticeExamList,
    PracticeList,
    QuestionBank,
    ChooseProfession,
} from '@/domain/views';
import { RouteName } from 'ea-router';
import { Navigation } from '@/infrastructure/navigation';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import Storage from '@/infrastructure/Storage';
import { examsubjectInfo, subjectInfo } from '@/domain/getters';
import { SelectionExam } from '@/domain/selection';
import Repositories from "@/infrastructure/repository";
import {IsSecretResult, IsSecretResultInput} from "@/models/ExamPractice";
@RouteName(PracticeList)
@Component({
    components: {
        navbar,
    },
})
export default class PracticeListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
   @Inject(Repository) readonly repository!: Repositories;
    isQuestion = true;
    IsAdv = false;
    selectedExam: SelectionExam = new SelectionExam();
     isSecretResult=new IsSecretResult();
    isSecret=false;

    async mounted() {
        if (this.$store.getters.exam) {
            this.selectedExam = { ...this.$store.getters.exam };
        }
        //暂时屏蔽章节练习和章节强化模块
        //高级的屏蔽章节练习和章节强化模块
        if (
            this.selectedExam &&
            this.selectedExam &&
            this.selectedExam.level.id ==
                '0890d3fb-fc2a-46ec-9c30-b33bf669d46d'
        ) {
            this.IsAdv = true;
        }
     await   this.LoadSecret();
    }

  async  LoadSecret() {
    const data = await this.repository.exampractice.IsSecret(new IsSecretResultInput() );
    this.isSecretResult = data;
    this.isSecret= this.isSecretResult.result;
  }

    @NeedLogin
    practiceExamList() {
        Storage.Instance.clear(examsubjectInfo);
        this.navigator.redirect(PracticeExamList, '', { Type: '5' });
    }

  @NeedLogin
  practiceSecretExamList() {
    Storage.Instance.clear(examsubjectInfo);
    this.navigator.redirect(PracticeExamList, '', { Type: '14' });
  }
    @NeedLogin
    practiceChapterList() {
        Storage.Instance.clear(subjectInfo);
        this.navigator.redirect(PracticeChapterList, '', { Type: '2' });
    }
    @NeedLogin
    practiceReinforcementList() {
        Storage.Instance.clear(subjectInfo);
        this.navigator.redirect(PracticeChapterList, '', { Type: '3' });
    }

    questionBank() {
        this.navigator.redirect(QuestionBank);
    }

    chooseProfession() {
        this.navigator.redirect(ChooseProfession, '', {
            isQuestion: this.isQuestion,
        });
    }
}
