






















































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    newpassword,
    verification,
    Bindphone,
    PersonalInfo,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Repositories from '@/infrastructure/repository';
import { Dialog } from 'vant';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(verification)
@Component({
    components:{
        navbar,
    }
})
export default class LoginAuthenticationCodePageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    numPhone = '';
    showKeyboard = true;
    inputSmsValue = '';
    smsSendBtnTxt = '获取验证码';
    isShowSendSmsBtn = true;
    isOpType = true;
    time = 60;
    smsCodeLen = 6;
    get OpType() {
        return this.$route.query.OpType as string;
    }
    ToMyInfo() {
        this.navigator.redirect(PersonalInfo);
    }
    async mounted() {
        //大左滑
        BackRouteConfig.addBackEvent(this.ToMyInfo);
        //获取用户信息
        if (this.$store.getters.isLogin) {
            const userInfo = await this.repository.user.getInfo();
            this.numPhone = userInfo.telphoneNum;
            await this.SendSms();
        }
    }
    //发送短信
    @Loading(LoadingMsg)
    async SendSms() {
        const response = await this.repository.user.SendSmsInfo(this.numPhone);
        if (response.success) {
            this.isShowSendSmsBtn = false;
            this.notifier.success(response.message, 2000);
            this.DescTime();
        } else {
            this.notifier.error(response.message);
        }
    }
    //检查验证码输入
    async InputSmsCode(key) {
        this.inputSmsValue = (this.inputSmsValue + key).slice(
            0,
            this.smsCodeLen
        );
        if (this.inputSmsValue.length == this.smsCodeLen) {
            if (this.OpType == '2') await this.RegNewUser();
            //跳转密码修改页面
            else if (this.OpType == '1') await this.RegNewUserPhone();
            //跳转手机号码修改页面
        }
    }
    DeleteSmsCode() {
        this.inputSmsValue = this.inputSmsValue.slice(
            0,
            this.inputSmsValue.length - 1
        );
    }
    //倒计时
    DescTime() {
        if (this.time <= 0) {
            this.isShowSendSmsBtn = true;
            this.smsSendBtnTxt = '重新获取验证码';
            this.time = 60;
        } else {
            setTimeout(this.DescTime, 1000);
            this.time--;
        }
    }
    @Loading(LoadingMsg)
    async RegNewUser() {
        const response = await this.repository.user.CheckPhoneSmsCode(
            this.numPhone,
            this.inputSmsValue
        );
        if (response.success) {
            //验证成功
            this.navigator.redirect(newpassword);
        } else {
            Dialog.alert({
                title: '提示',
                message: '验证码有误！',
                confirmButtonText: '重新输入',
                confirmButtonColor: '#3f7efd',
            }).then(r => {
                return;
            });
        }
    }

    @Loading(LoadingMsg)
    async RegNewUserPhone() {
        const response = await this.repository.user.CheckPhoneSmsCode(
            this.numPhone,
            this.inputSmsValue
        );
        if (response.success) {
            //验证成功
            this.navigator.redirect(Bindphone, '', {
                phone: this.numPhone,
            });
        } else {
            Dialog.alert({
                title: '提示',
                message: '验证码有误！',
                confirmButtonText: '重新输入',
                confirmButtonColor: '#3f7efd',
            }).then(r => {
                this.inputSmsValue = '';
                return;
            });
        }
    }
}
