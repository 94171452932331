



















































































































import navbar from '@/components/navbar.vue'
import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    PracticeExamList,
    PracticeExamQuestionView,
    PracticeExamSubjectiveItemReport,
} from '@/domain/views';
import {
  AnswerQuestionInputInfo,
  MyPaperRecordsJgInfo,
  PaperQuestionInfo, SubjectRedisInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import {
  PaperQuestions,
  QuestionId,
  Replies,
  TrueQuestionIds,
  NoRepliedQuestionIds,
  ExaminationPaperInfos,
  taskType, examsubjectInfo,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component({
    components:{
        navbar,
    }
})
@RouteName(PracticeExamSubjectiveItemReport)
export default class SubjectiveItemReport extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    paperQuestions = new Array<PaperQuestionInfo>();
    replies = Array<AnswerQuestionInputInfo>();
    myPaperRecordsJg = new MyPaperRecordsJgInfo();
    get paperId() {
        return this.$route.query.paperId as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }
    get count() {
        return this.$route.query.count as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get getUnRepliedCount() {
        return Number(this.count) - this.replies?.length;
    }

    get subjectId() {
        return this.$route.query.subjectId as string;
    }
    get xtab() {
        return this.$route.query.tab as string;
    }

    IsReplyed(id: string) {
        return this.replies?.filter(x => x.questionId === id).length > 0;
    }
    @Loading('加载中....')
    async GetMyPaperRecordsJg() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.returnQuestionReply);

        const data = await this.repository.exampractice.MyPaperRecordsJg({
            paperRecordsId: this.paperRecordsId,
        });
        this.myPaperRecordsJg = data;
    }
    async mounted() {
        this.paperQuestions = Storage.Instance.get<
            Array<PaperQuestionInfo>
        >(PaperQuestions);
        this.replies = Storage.Instance.get<Array<AnswerQuestionInputInfo>>(
            Replies
        );
        await this.GetMyPaperRecordsJg();
    }
    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }
    timestampToTime(timestamp: string) {
        const date = new Date(); //时间戳为10位需 *1000，时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + '/';
        const M =
            (date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1) + '/';
        const D =
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            ' ';
        return Y + M + D;
    }
    viewAllAlyasis() {
        Storage.Instance.clear(QuestionId);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }
    clearStorage() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Replies);
        Storage.Instance.clear(ExaminationPaperInfos);
        Storage.Instance.clear(PaperQuestions);
        Storage.Instance.clear(taskType);
    }
    returnQuestionReply() {
        this.clearStorage();
      const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(
          examsubjectInfo
      );
        this.navigator.redirect(PracticeExamList, '', {Type:subjectinfo.Type});
    }
}
