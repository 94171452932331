import { SubjectInfo } from '@/models/ExamPractice';

export class Course {
    id: string;
    name: string;
    image: string;
    lastWatchTime: string;
    isWatched: boolean;

    chapterId: string;
    videoId: string;
    videoName: string;
    discountEndDate: string;

    courseVideoCount: number;

    courseUserWatchCount: number;
    userLearnProgress: string;
    courseType: string;

    saleType: string;
    productId: string;
    bQ_Code: string;
}

export interface CoursePack extends Course {
    courses: Course[];
}

export class UserCourseResponse {
    packs: CoursePack[] = [];
    courses: Course[] = [];
}

export interface CourseSubjectPack extends Course {
    subjects: Subject[];
}

export interface Subject {
    subjectName: string;
    courses: Course[];
}

export class MyPackageWatchOutput {
    keyValues: Array<MyPackageWatchInfo>;
    subjects: Array<SubjectInfo>;
}
export class MyPackageWatchInfo {
    key: KeyInfo;
    value: Array<ValueInfo>;
}
export class KeyInfo {
    code: string;
    dataTypeId: string;
    desc: string;
    name: string;
    sort: number;
}
export class ValueInfo {
    agreementId: string;
    bQ_Code: string;
    bQ_Desc: string;
    bQ_Name: string;
    chapterId: string;
    contaniCourse: string;
    contaniCourseList: string;
    courseIamge: string;
    courseId: string;
    courseName: string;
    courseSellingPoint: string;
    courseType: string;
    courseUserWatchCount: number;
    courseVideoCount: number;
    description: string;
    discountEndDate: string;
    favourablePrice: number;
    handoutUrl: string;
    id: string;
    iosPrice: number;
    isEconomicBase: number;
    isRecommend: string;
    orderNo: number;
    payState: string;
    payTime: string;
    price: number;
    productId: string;
    saleType: string;
    stageBaseDataID: string;
    stageText: string;
    studyCount: number;
    subjectID: string;
    subjectIds: string;
    subjectName: string;
    userLearnProgress: string;
    videoId: string;
    videoName: string;
    watchTime: string;
}

export class AgreementRecordInfo {
    id: string;
    userId: string;
    name: string;
    idCard: string;
    contactNumber: string;
    address: string;
    email: string;
    agreementId: string;
    agreementName: string;
    courseId: string;
    htmlContent: string;
    addTime: string;
    status: number;
    phone: string;
    count: number;
    agTime: string;
    coursename: string;
}

export class MyCardInfo {
    id: string;
    cardCode: string;
    cardName: string;
    amount: string;
    beginDate: string;
    endDate: string;
    targetCourse: string;
    targetCourseIDs: string;
    record: string;
    ifUse: number;
    cardType: string;
    cardTypeName: string;
    projectClassId: string;
    desc: string;
    url: string;
    isOnlyTch: boolean;
}
export class MyCardInput {
    Id: string;
    CardCode: string;
    CardName: string;
    TargetCourse: string;
    CourseName: string;
    Amount: string;
    BeginDate: string;
    EndDate: string;
    UserId: string;
    CardType: string;
    ProjectClassId: string;
    IfUse: string;
}
export class UploadFileInfo {
    fileUrl: string;
}

export class UpdatePwdInfo {
    Id: string;

    LoginOldPwd: string;

    LoginPwd: string;
}

export class VerificationCodeInfo {
    Code: string;

    TelphoneNum: string;

    Id: string;
    Password: string;
}

export class UpdateOrderStatusInput {
    orderNo: string;
    ordernoNew:string;
}
export class CurriculumPayInfoInput {
    subjectid: string;
    isquestion: string;
}
export class OrderSumbitInput {
    SubjectId: string;
    CourseId: string;
    PcAmount: number;
    CardCode: string;
    OrderAmount: number;
    OrderType: string;
    OrderNo:string;
    isrepeat:boolean;
}
export class SheetModelInfo {
    orderNo: string;
    orderAmount: number;
    pcAmount: number;
}
export class  OrderQueryInput {
    OrderNo: string;

}
export class ToOrderPayInput {
    OrderNo: string;
    Amount: number;
}
export class PayResultInfo {
    err_code_des:string;
    return_code: string;
    return_msg: string;
    appid: string;
    mch_id: string;
    nonce_str: string;
    sign: string;
    result_code: string;
    prepay_id: string;
    trade_type: string;
    mweb_url: string;
    trade_state:string;
}

export class SheetModel {
    courseType: number;
    courseId: string;
    bqCode: string;
    state: number;
    orderAmount:number;
}
export class UserVersionOutput{
    timestmap:number;
    appdownurl:string;
    newversion:string;

}

export class CurriculumPayInfo {
    course: Array<PayCourseInfo>;
    register: RegisterUserInfo;
    discountCard: Array<UserDiscountCardModelInfo>;
    isQuestion: number;
    courseInfo: PayCourseBaseInfo;
    purchaseDiscount: PurchaseDiscountInfo;
    subjectId: string;
    subject: SubjectInfo;
    parentCourseId: string;
}
export class ObjectInfo {
    cardCode: string;
    amount: number;
}
export class UserDiscountCardModelInfo {
    id: string;
    cardCode: string;
    cardName: string;
    amount: number;
    targetCourse: string;
    desc: string;
    projectClassId: string;
    count: number;
    dj: number;
    cardType: string;
    isOnlyTch: boolean;
    isOnlyName: boolean;
    pageShowID: number;
    objectInfo: ObjectInfo;
}
export class PayCourseInfo {
    courseIamge: string;
    courseName: string;
    favourablePrice: number;
    id: string;
    subjectId: string;
    emailNotes: number;
    price: number;
}
export class RegisterUserInfo {}
export class PayCourseBaseInfo {}

export class PurchaseDiscountInfo {}
export class OrderRenewal {
    Reason: string;
    Description: string;
    IDCard: string;
    CourseID: string;
    Image1: string;
    Image2: string;
    Image3: string;
    Image4: string;
    Image5: string;
    CourseName: string;
    Status: string;
    Name: string;
    OrderNo: string;
    Refuse: string;
}
