import AxiosHttp from '@/infrastructure/http';
import {
    GetPaperGroupInfoQuery,
    PaperGroupInfo,
    SubjectInfo,
    ExaminationPaperQuery,
    ExaminationPaperInfo,
    MyPaperRecordsInfo,
    InsertIntoPaperRecordsPcQuery,
    PaperQuestionInfo,
    AnswerQuestionOutInfo,
    MyPaperRecordsJgInfo,
    MyPaperRecordsJgQuery,
    ActionResult,
    GetIsCollectionInfo,
    IsShoppingInfo,
    IsShoppingQuery,
    GetOcrQuestionResultQuery,
    QuestionResultInfo,
    QuestionResultInput, SubjectClassInfo,IsSecretResult,IsSecretResultInput

} from '@/models/ExamPractice';

export default class ExamPracticeRepository {
    GetPaperGroupInfo(
        query: GetPaperGroupInfoQuery
    ): Promise<Array<PaperGroupInfo>> {
        return AxiosHttp.Instance.post<Array<PaperGroupInfo>>(
            '/ExamPractice/GetPaperGroupInfo',
            query
        );
    }
    GetSubjects(
        proId: string,
        subjectType: string
    ): Promise<Array<SubjectInfo>> {
        return AxiosHttp.Instance.get<Array<SubjectInfo>>(
            '/ExamPractice/GetSubjects?proId=' +
                proId +
                '&subjecttype=' +
                subjectType
        );
    }
    SubjectClassList(
        subjectId: string
    ): Promise<Array<SubjectClassInfo>> {
        return AxiosHttp.Instance.get<Array<SubjectClassInfo>>(
            '/ExamPractice/SubjectClassList?subjectId=' +
            subjectId
        );
    }

    GetExaminationPaper(
        query: ExaminationPaperQuery
    ): Promise<ExaminationPaperInfo> {
        return AxiosHttp.Instance.post<ExaminationPaperInfo>(
            '/ExamPractice/GetExaminationPaper',
            query
        );
    }

    InsertIntoPaperRecordsPc(
        query: InsertIntoPaperRecordsPcQuery
    ): Promise<MyPaperRecordsInfo> {
        return AxiosHttp.Instance.post<MyPaperRecordsInfo>(
            '/ExamPractice/InsertIntoPaperRecordsPc',
            query
        );
    }

    GetPaperInfo(
        paperId: string,
        paperRecordsId: string
    ): Promise<Array<PaperQuestionInfo>> {
        return AxiosHttp.Instance.get<Array<PaperQuestionInfo>>(
            '/ExamPractice/GetPaperInfo?paperId=' +
                paperId +
                '&paperRecordsId=' +
                paperRecordsId
        );
    }

    AnswerQuestion(data: any): Promise<AnswerQuestionOutInfo> {
        return AxiosHttp.Instance.post<AnswerQuestionOutInfo>(
            '/ExamPractice/AnswerQuestion',
            data
        );
    }
    //错题纠错
    AddError(data: any): Promise<ActionResult> {
        return AxiosHttp.Instance.post<ActionResult>('/ExamPractice/Add', data);
    }
    //添加收藏
    AddCollectSubject(data: any): Promise<ActionResult> {
        return AxiosHttp.Instance.post<ActionResult>(
            '/ExamPractice/AddCollectSubject',
            data
        );
    }
    CancelCollectSubject(data: any): Promise<ActionResult> {
        return AxiosHttp.Instance.post<ActionResult>(
            '/ExamPractice/CancelCollectSubject',
            data
        );
    }
    GetIsCollection(qid: string): Promise<GetIsCollectionInfo> {
        return AxiosHttp.Instance.get<GetIsCollectionInfo>(
            '/ExamPractice/GetIsCollection?qid=' + qid
        );
    }

    IsShopping(queryData: IsShoppingQuery): Promise<IsShoppingInfo> {
        return AxiosHttp.Instance.post<IsShoppingInfo>(
            '/ExamPractice/IsShopping',
            queryData
        );
    }

    MyPaperRecordsJg(
        query: MyPaperRecordsJgQuery
    ): Promise<MyPaperRecordsJgInfo> {
        return AxiosHttp.Instance.post<MyPaperRecordsJgInfo>(
            '/ExamPractice/MyPaperRecordsJg',
            query
        );
    }
   
    // 图片识别
    GetOcrQuestionResult(Picurl: string,QuestionType:string): Promise<GetOcrQuestionResultQuery> {
        return AxiosHttp.Instance.get<GetOcrQuestionResultQuery>(
            `/ExamPractice/GetOcrQuestionResult?Picurl=${Picurl}&QuestionType=${QuestionType}`
        );
    }

    // 密训卷权限判断
    IsSecret(data:IsSecretResultInput): Promise<IsSecretResult> {
        return AxiosHttp.Instance.post<IsSecretResult>(
            `/ExamPractice/IsSecret`
            ,data

        );
    }


    // 录入
    AddOcrQuestion(data: QuestionResultInput): Promise<ActionResult> {
        return AxiosHttp.Instance.post<ActionResult>(
            '/ExamPractice/AddOcrQuestion',
            data
        );
    }

    // AddOrEditCacheVideo(CourseLearnProgressModelInput: InputsModels) {
    //     return AxiosHttp.Instance.post<ActionResult>(
    //         '/CourseAppraise/AddOrEdit',
    //         CourseLearnProgressModelInput
    //     );
    // }



}
