import {
    CourseIntroduceInfoQuery,
    CourseIntroduceInfoResult,
} from '@/infrastructure/model/CourseIntroduceInfo';
import AxiosHttp from '@/infrastructure/http';
import {
    CourseChapterInfoQuery,
    CourseChapterInfo,
} from '@/infrastructure/model/CourseChapterInfo';

import {
    VideoQuery,
    VideoResult,
} from '@/infrastructure/model/CourseVideoInfo';
import {
    IfAreadyPayQuery,
    IfAreadyPayResult,
} from '@/infrastructure/model/IfAreadyPayInfo';

export class CourseRepository {
    GetCourseIntroduceInfo(
        query: CourseIntroduceInfoQuery
    ): Promise<CourseIntroduceInfoResult> {
        return AxiosHttp.Instance.post<CourseIntroduceInfoResult>(
            '/CourseAppraise/GetCourseIntroduceInfo',
            query
        );
    }

    GetCourseChapterInfo(
        query: CourseChapterInfoQuery
    ): Promise<Array<CourseChapterInfo>> {
        return AxiosHttp.Instance.post<Array<CourseChapterInfo>>(
            '/CourseAppraise/GetChapterMenu',
            query
        );
    }

    GetVideo(query: VideoQuery): Promise<VideoResult> {
        return AxiosHttp.Instance.post<VideoResult>(
            '/CourseAppraise/GetVideo',
            query
        );
    }

    GetCover(videourl:string): Promise<string> {
        return AxiosHttp.Instance.get<string>(
            '/CourseAppraise/GetCoverUrl?videourl='+videourl
        );
    }

    IfAreadyPay(query: IfAreadyPayQuery): Promise<IfAreadyPayResult> {
        return AxiosHttp.Instance.post<IfAreadyPayResult>(
            '/CourseAppraise/IfAreadyPay',
            query
        );
    }
}
