import store from '@/store';
import SimpleNavigation from '@/infrastructure/navigation';
import { UserLogin } from '@/domain/views';

function isLogin() {
    return store.getters.isLogin;
}

export function NeedLogin(target: any, name: any, descriptor: any): any {
    const func = descriptor.value;
    descriptor.value = async function(...args: any) {
        if (!isLogin()) {
            SimpleNavigation.Instance.redirect(UserLogin);
            return;
        }
        func.apply(this, args);
    };
}
