var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navbar',[_c('div',{attrs:{"slot":"left"},on:{"click":_vm.ToUserCourse},slot:"left"},[_c('v-icon',{staticClass:"iconfont body-16",attrs:{"color":"#000"}},[_vm._v(" hk-return-left ")])],1),_c('div',{attrs:{"slot":"center"},slot:"center"},[_vm._v(_vm._s(this.packageName.split('-')[1]))])]),_c('div',{staticClass:"theme-bg--color pt-2 list-content"},[(_vm.package.subjects.length > 1)?_c('van-tabs',{staticClass:"white",attrs:{"animated":"","color":"black"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.package.subjects),function(subjectInfo){return _c('van-tab',{key:subjectInfo.subjectName,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"body black--text",on:{"click":function($event){return _vm.changeSubject(subjectInfo.id)}}},[_vm._v(" "+_vm._s(subjectInfo.subjectName)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"list-border mt-2"}),_vm._l((_vm.package.keyValues),function(myPackageWatchInfo,index){return _c('div',{key:index},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(myPackageWatchInfo.value.length > 0),expression:"myPackageWatchInfo.value.length > 0"}],attrs:{"flat":""}},[_c('div',{staticClass:"collection-name font-weight-bold body px-4"},[_c('div',{staticClass:"d-inline-block mt-n1 vertical-align mr-2"},[_c('v-img',{attrs:{"width":"0.50666667rem","height":"0.50666667rem","src":_vm.GetImage(
                                            myPackageWatchInfo.key.name
                                        )}})],1),_c('p',{staticClass:"mt-n2 d-inline-block"},[_vm._v(" "+_vm._s(myPackageWatchInfo.key.name)+" ")])]),_vm._l((myPackageWatchInfo.value),function(valueInfo,index){return _c('v-card',{key:index,attrs:{"flat":""}},[_c('v-row',{staticClass:"pa-4",attrs:{"no-gutters":""}},[_c('v-col',{on:{"click":function($event){return _vm.VideoDetail(
                                            '',
                                            valueInfo.courseId,
                                            valueInfo.subjectID
                                        )}}},[_c('p',{staticClass:"body-2 text-grey-3"},[_vm._v(" "+_vm._s(valueInfo.courseName)+" ")]),_c('p',{staticClass:"caption text-grey mt-1"},[_vm._v(" "+_vm._s(valueInfo.stageText)+" ")])]),_c('v-col',{staticClass:"pt-3",attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#000","size":"0.32rem"}},[_vm._v(" hk-return-right ")])],1)],1),_c('v-divider',{staticClass:"v-divider-grey"})],1)})],2)],1)})],2)}),1):_c('van-tabs',{staticClass:"white dk",attrs:{"animated":"","color":"black"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.package.subjects),function(subjectInfo){return _c('van-tab',{key:subjectInfo.subjectName,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"body black--text",on:{"click":function($event){return _vm.changeSubject(subjectInfo.id)}}},[_vm._v(" "+_vm._s(subjectInfo.subjectName)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"list-border mt-2"}),_vm._l((_vm.package.keyValues),function(myPackageWatchInfo,index){return _c('div',{key:index},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(myPackageWatchInfo.value.length > 0),expression:"myPackageWatchInfo.value.length > 0"}],attrs:{"flat":""}},[_c('div',{staticClass:"collection-name font-weight-bold body px-4"},[_c('div',{staticClass:"d-inline-block mt-n1 vertical-align mr-2"},[_c('v-img',{attrs:{"width":"0.3733rem","height":"0.3733rem","src":_vm.GetImage(
                                            myPackageWatchInfo.key.name
                                        )}})],1),_c('p',{staticClass:"mt-n2 d-inline-block"},[_vm._v(" "+_vm._s(myPackageWatchInfo.key.name)+" ")])]),_vm._l((myPackageWatchInfo.value),function(valueInfo,index){return _c('v-card',{key:index,attrs:{"flat":""}},[_c('v-row',{staticClass:"pa-4 d-flex align-center",attrs:{"no-gutters":""}},[_c('v-col',{on:{"click":function($event){return _vm.VideoDetail(
                                            '',
                                            valueInfo.courseId,
                                            valueInfo.subjectID
                                        )}}},[_c('p',{staticClass:"body-2 text-grey-3 pr-3"},[_vm._v(" "+_vm._s(valueInfo.courseName)+" ")]),_c('p',{staticClass:"caption text-grey"},[_vm._v(" "+_vm._s(valueInfo.stageText)+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#000","size":"0.32rem"}},[_vm._v(" hk-return-right ")])],1)],1),_c('v-divider',{staticClass:"v-divider-grey"})],1)})],2)],1)})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }