




import { Vue, Component } from 'vue-property-decorator';
import { Toast } from 'vant';
import { LoadingMsg } from '@/domain/loadingText';

@Component
export default class LoadingComponent extends Vue {
    text = LoadingMsg;
    loading = true;
    zIndex = 5;
    instance: any = null;

    mounted() {
        this.instance = Toast.loading({
            message: this.text,
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0,
            // icon: '~@/assets/images/loading.gif',
        });
    }

    Close() {
        this.instance.clear();
        this.removeElement();
    }

    removeElement() {
        this.$destroy();
        this.$el?.parentNode?.removeChild(this.$el);
    }
}
