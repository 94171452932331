



























import navbar from '@/components/navbar.vue'
import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import { Navigator } from '@/domain/providers';
import {  RouteName } from 'ea-router';
import {
    VideoPlay,
} from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
import SimpleAliplayer from '@/components/videoPlayer/SimpleAliplayer.vue';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(VideoPlay)
@Component({
    components: {
        SimpleAliplayer,
        navbar,
    },
})
export default class VideoPage extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Ref('simpleplayer') readonly player!: any;

   mounted(){
       //大左滑
       BackRouteConfig.addBackEvent(this.redirectback);
   }
    redirectback(){

        this.$router.back();
    }
    get videosource() {
        return this.$route.query.videosource;
    }
}
