








































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    Agreementaddress,
    MyAgreement,
    agreementcontent,
    MyInfo,
} from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import { AgreementRecordInfo } from '@/models/UserCourseResponse';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(MyAgreement)
@Component({
    components: {
        navbar,
    },
})
export default class MyAgreementPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    agreementRecordInfos = new Array<AgreementRecordInfo>();
    DataCount = 0;
    isCompleted = false;
    mounted() {
        //大左滑
        BackRouteConfig.addBackEvent(this.MyInfo);
        this.getMyAgreement();
    }
    MyInfo() {
        this.navigator.redirect(MyInfo);
    }
    @Loading(LoadingMsg)
    async getMyAgreement() {
        const data = await this.repository.user.GetAgreement();
        this.agreementRecordInfos = data;
        this.DataCount = this.agreementRecordInfos.length;
        this.isCompleted = true;
    }
    redirectDetail(
        agreementId: string,
        id: string,
        status: number,
        htmlContent: string,
        courseId: string
    ) {
        if (status == 0) {
            this.navigator.redirect(
                Agreementaddress,
                {
                    agreementId: agreementId,
                    id: id,
                    htmlContent: htmlContent,
                },
                {
                    courseId: courseId,
                }
            );
        } else {
            this.navigator.redirect(agreementcontent, {
                id: id,
            });
        }
    }
}
