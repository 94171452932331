var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.courseChapters),function(courseChapterInfo,index){return _c('div',{key:index,staticClass:"part-title"},[_c('v-row',{staticClass:"px-5 py-3 body-2 text-grey-9",attrs:{"no-gutters":""},on:{"click":function($event){_vm.CurrentParentChapterIds.indexOf(courseChapterInfo.id) >= 0
                    ? _vm.parentcallpase(courseChapterInfo)
                    : _vm.parentexpand(courseChapterInfo)}}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[(!_vm.hasPreMission)?_c('div',[(
                            !_vm.isFree(courseChapterInfo) &&
                                courseChapterInfo.pid == '0'
                        )?_c('div',[_c('v-chip',{staticClass:"px-2 orange--text",attrs:{"color":"#FFE7DE","x-small":"","label":""}},[_vm._v(" 付费 ")])],1):(
                            _vm.isFree(courseChapterInfo) &&
                                courseChapterInfo.pid == '0'
                        )?_c('div',[_c('v-chip',{staticClass:"px-2 green--text",attrs:{"color":"#DFF2E8","x-small":"","label":""}},[_vm._v(" 试听 ")])],1):_vm._e()]):_c('div',[(courseChapterInfo.pid === '0')?_c('div',[_c('v-chip',{staticClass:"px-2 primary--text",attrs:{"color":"#E4EFFD","x-small":"","label":""}},[_vm._v(" 学习 ")])],1):_vm._e()])]),_c('v-col',[(courseChapterInfo.name.indexOf('(*)') != -1)?_c('div',[_vm._v(" "+_vm._s(courseChapterInfo.name.replace('(*)', ''))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(_vm._s(courseChapterInfo.name))])]),_c('v-col',{staticClass:"px-2 arrows",attrs:{"cols":"auto"}},[(
                        _vm.CurrentParentChapterIds.indexOf(
                            courseChapterInfo.id
                        ) >= 0
                    )?_c('van-image',{attrs:{"width":"0.4rem","height":"0.4rem","src":require('../../assets/images/open.png')}}):_vm._e(),(
                        _vm.CurrentParentChapterIds.indexOf(
                            courseChapterInfo.id
                        ) < 0
                    )?_c('van-image',{attrs:{"width":"0.4rem","height":"0.4rem","src":require('../../assets/images/close.png')}}):_vm._e()],1)],1),(courseChapterInfo.children != null)?_c('div',_vm._l((courseChapterInfo.children),function(courseChapterInfoVideo,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.CurrentParentChapterIds.indexOf(courseChapterInfo.id) >=
                        0
                ),expression:"\n                    CurrentParentChapterIds.indexOf(courseChapterInfo.id) >=\n                        0\n                "}],key:index},[(courseChapterInfoVideo.videoId != null)?_c('div',{staticClass:"user-card-bg"},[_c('v-row',{class:[
                            courseChapterInfoVideo.videoId === _vm.videoId
                                ? _vm.activeClass
                                : '',
                            'body-3',
                            'px-5',
                            'py-3',
                            'text-grey-3' ],attrs:{"no-gutters":""},on:{"click":function($event){return _vm.VideoDetail(
                                courseChapterInfoVideo.videoId,
                                courseChapterInfoVideo.vip,
                                courseChapterInfoVideo.isTaste,
                                courseChapterInfo.id
                            )}}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[(
                                    courseChapterInfoVideo.vip == '1' &&
                                        courseChapterInfoVideo.pid != '0'
                                )?_c('div',[(
                                        courseChapterInfoVideo.videoId ===
                                            _vm.videoId
                                    )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/play.gif")}})],1):(
                                        courseChapterInfoVideo.pid === '1'
                                    )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/shitinganniu.png")}})],1):_vm._e()]):(
                                    courseChapterInfoVideo.vip == '0' &&
                                        courseChapterInfoVideo.pid != '0'
                                )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/suo.png")}})],1):_vm._e()]),_c('v-col',{staticClass:"px-2"},[(
                                    courseChapterInfoVideo.name.indexOf(
                                        '(*)'
                                    ) != -1
                                )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name)+" ")])])],1)],1):_c('div',[(courseChapterInfoVideo.children != null)?_c('div',[_c('v-row',{staticClass:"px-5 py-3 body-2 text-grey-9",attrs:{"no-gutters":""},on:{"click":function($event){_vm.CurrentChapterIds.indexOf(
                                    courseChapterInfoVideo.id
                                ) >= 0
                                    ? _vm.callpase(courseChapterInfoVideo)
                                    : _vm.expand(courseChapterInfoVideo)}}},[_c('v-col',[(
                                        courseChapterInfoVideo.name.indexOf(
                                            '(*)'
                                        ) != -1
                                    )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name)+" ")])]),_c('v-col',{staticClass:"px-2 arrows",attrs:{"cols":"auto"}},[(
                                        _vm.CurrentChapterIds.indexOf(
                                            courseChapterInfoVideo.id
                                        ) >= 0
                                    )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/open.png")}}):_vm._e(),(
                                        _vm.CurrentChapterIds.indexOf(
                                            courseChapterInfoVideo.id
                                        ) < 0
                                    )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/close.png")}}):_vm._e()],1)],1),_vm._l((courseChapterInfoVideo.children),function(courseChapterInfoNodeTwo,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                _vm.CurrentChapterIds.indexOf(
                                    courseChapterInfoVideo.id
                                ) >= 0
                            ),expression:"\n                                CurrentChapterIds.indexOf(\n                                    courseChapterInfoVideo.id\n                                ) >= 0\n                            "}],key:index},[_c('v-row',{class:[
                                    courseChapterInfoNodeTwo.videoId ===
                                    _vm.videoId
                                        ? _vm.activeClass
                                        : '',
                                    'body-3',
                                    'px-5',
                                    'py-3',
                                    'text-grey-3' ],attrs:{"no-gutters":""},on:{"click":function($event){return _vm.VideoDetail(
                                        courseChapterInfoNodeTwo.videoId,
                                        courseChapterInfoNodeTwo.vip,
                                        courseChapterInfoNodeTwo.isTaste,
                                        courseChapterInfoVideo.id
                                    )}}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[(
                                            courseChapterInfoNodeTwo.vip ==
                                                '1' &&
                                                courseChapterInfoNodeTwo.pid !=
                                                    '0'
                                        )?_c('div',[(
                                                courseChapterInfoNodeTwo.videoId ===
                                                    _vm.videoId
                                            )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/play.gif")}})],1):(
                                                courseChapterInfoNodeTwo.pid ===
                                                    '1'
                                            )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/shitinganniu.png")}})],1):_vm._e()]):(
                                            courseChapterInfoNodeTwo.vip ==
                                                '0' &&
                                                courseChapterInfoNodeTwo.pid !=
                                                    '0'
                                        )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/suo.png")}})],1):_vm._e()]),_c('v-col',{staticClass:"px-2"},[(
                                            courseChapterInfoNodeTwo.name.indexOf(
                                                '(*)'
                                            ) != -1
                                        )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNodeTwo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNodeTwo.name)+" ")])])],1)],1)})],2):_vm._e()])])}),0):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }