



































































































































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { Context, EnableProp, RouteName } from 'ea-router';
import { UserPackageDetail, UserCourse } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { MyPackageWatchOutput } from '@/models/UserCourseResponse';
import { Navigation } from '@/infrastructure/navigation';
import { CourseVideo } from '@/domain/views';
import { VideoRedirect } from '@/infrastructure/model/CourseVideoInfo';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { SubjectRedisInfo } from '@/models/ExamPractice';
import Storage from '@/infrastructure/Storage';
import { coursesubjectInfo, SoruceType, packageLists } from '@/domain/getters';
@RouteName(UserPackageDetail)
@Context('id', 'packageName')
@EnableProp()
@Component({
    components: {
        navbar,
    },
})
export default class UserPackageDetailPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Prop() id: string;
    @Prop() packageName: string;
    package = new MyPackageWatchOutput();
    Enum = {
        VideoRedirect: VideoRedirect,
    };
    tab = null;

    GetImage(stageText: string) {
        let returnImageUrl = '';
        if (stageText == '基础阶段')
            returnImageUrl = require('../../assets/user/jichujieduan@2x.png');
        if (stageText == '临考阶段')
            returnImageUrl = require('../../assets/user/linkao@2x.png');
        if (stageText == '强化阶段')
            returnImageUrl = require('../../assets/user/chongci@2x.png');
        if (stageText == '冲刺阶段')
            returnImageUrl = require('../../assets/user/qianghua@2x.png');
        if (stageText == '其他')
            returnImageUrl = require('../../assets/user/qita@2x.png');
        return returnImageUrl;
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.ToUserCourse);
        const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(
            coursesubjectInfo
        );
        if (subjectinfo == null) {
            await this.getPackageDetail('0');
        } else {
            this.tab = subjectinfo.tab;
            await this.getPackageDetail(subjectinfo.subjectId);
        }
    }
    ToUserCourse() {
        Storage.Instance.clear(packageLists);
        Storage.Instance.clear(coursesubjectInfo);
        this.navigator.redirect(UserCourse);
    }
    @Loading(LoadingMsg)
    async getPackageDetail(subjectId: string) {
        const packages = Storage.Instance.get<MyPackageWatchOutput>(
            packageLists
        );
        if (packages == null) {
            this.package = await this.repository.user.PackageSubjects(
                this.id,
                this.$store.getters.exam.profession?.id
            );
            Storage.Instance.set(packageLists, this.package);
        } else {
            this.package = packages;
        }
        if (subjectId == '0')
            this.package.keyValues.forEach(x => {
                x.value = x.value.filter(
                    p => p.subjectID == this.package.subjects[0].id
                );
            });
        else
            this.package.keyValues.forEach(x => {
                x.value = x.value.filter(p => p.subjectID == subjectId);
            });
    }
    changeSubject(Id: string) {
        this.getPackageDetail(Id);
    }
    VideoDetail(videoId: string, courseId: string, subjectId: string) {
        this.navigator.redirect(
            CourseVideo,
            {
                videoId: 'null',
                courseId: courseId,
            },
            {
                saleType: '0',
                type: this.Enum.VideoRedirect.FromMyPackage,
                package: this.id,
                packageName: this.packageName,
            }
        );

        const subjectinfo = new SubjectRedisInfo();
        subjectinfo.tab = this.tab;
        subjectinfo.subjectId = subjectId;
        Storage.Instance.set(coursesubjectInfo, subjectinfo);
        Storage.Instance.set(SoruceType, 2);
    }
}
