

























































































    import navbar from '@/components/navbar.vue';
    import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
    import { RouteName } from 'ea-router';
    import { Navigator, Repository } from '@/domain/providers';
    import Repositories from '@/infrastructure/repository';
    import { Navigation } from '@/infrastructure/navigation';
    import { Context, EnableProp } from 'ea-router';
    import { Products, CourseDetail, UserCourse, Course } from '@/domain/views';
    import { ProductGroup, ProductGroupSearch } from '@/models/ProductGroup';
    import { Loading } from 'vuetify-extension';
    import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
    import { LoadingMsg } from '@/domain/loadingText';

    @RouteName(Products)
    @Context('bqCode')
    @EnableProp()
    @Component({
    components: {
        navbar,
    },
})
    export default class CourseContrastPageComponent extends Vue {
        @Prop()
        bqCode: string;
        @Inject(Repository) repository: Repositories;
        @Inject(Navigator) navigator: Navigation;

        productGroups = new Array<ProductGroup>();

        mounted() {
            this.GetProductGroups();
        }

        courseIndex() {
            //选中样式重置到0
            sessionStorage.setItem("active", '0');
            this.navigator.redirect(Course);
        }

        @Loading(LoadingMsg)
        async GetProductGroups() {
            const searchInput = new ProductGroupSearch();
            searchInput.bqCode = this.bqCode;
            searchInput.examType = this.$store.getters.exam.type;
            searchInput.levelId = this.$store.getters.exam.level.id;
            searchInput.professionId = this.$store.getters.exam.profession?.id;
            const data = await this.repository.product.ProductGroup(
                searchInput
            );
            this.productGroups = data;
        }

        @NeedLogin
        MyCourse() {
            sessionStorage.setItem("active", '2');
            this.navigator.redirect(UserCourse);
        }

        ProductDetail(courseId: string, isSetMeal: string) {
            this.navigator.redirect(
                CourseDetail,
                {
                    courseId: courseId,
                    saleType: isSetMeal,
                },
                {
                    bqCode: this.bqCode,
                }
            );
        }
    }
