
































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    LoginPassword,
    LoginAuthenticationCode,
    UserLogin,
    MyInfo,
} from '@/domain/views';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Login, MatchExam } from '@/domain/actions';
import Repositories from '@/infrastructure/repository';
import { Rule } from '@/infrastructure/rules';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(LoginPassword)
@Component
export default class LoginPasswordPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Rules) rules: Rule;

    identifier = {
        phoneNumber: '',
        password: '',
    };
    phoneNumber = '';
    isShowPwd = true;
    pwdInputType = 'password';
    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }

    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.backLogin);

        this.identifier.phoneNumber = this.numPhone;

        this.phoneNumber =
            this.numPhone.substr(0, 3) +
            ' ' +
            this.numPhone.substr(3, 4) +
            ' ' +
            this.numPhone.substr(7, 4);
    }

    @Loading(LoadingMsg)
    async login() {
        try {
            const response = await this.repository.user.login(
                this.identifier
            );
            await this.$store.dispatch(Login, response);
            const data = await this.repository.user.getCurrentExamInfo();
            await this.$store.dispatch(MatchExam, data);
            this.notifier.success('登录成功!');
            //this.navigator.back();
            this.navigator.redirect(MyInfo);
        } catch (e) {
            this.notifier.error(e);
        }
    }

    deletePassword() {
        this.identifier.password = '';
    }

    //显示隐藏密码
    ShowPassword() {
        if (this.isShowPwd) {
            this.isShowPwd = false;
            this.pwdInputType = 'input';
        } else {
            this.isShowPwd = true;
            this.pwdInputType = 'password';
        }
    }

    loginAuthenticationCode() {
        this.navigator.replace(LoginAuthenticationCode, {
            phoneNumber: this.numPhone,
            OpType: 'forget',
        });
    }

    backLogin() {
        this.navigator.replace(UserLogin, {
            phoneNumber: this.numPhone,
            sourcetype:2
        });
    }
}
