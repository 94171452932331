var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navbar',[_c('div',{attrs:{"slot":"left"},on:{"click":_vm.redirectlist},slot:"left"},[_c('v-icon',{staticClass:"iconfont body-16",attrs:{"color":"#000"}},[_vm._v(" hk-return-left ")])],1),_c('div',{attrs:{"slot":"center"},slot:"center"},[_vm._v("试卷详情")])]),_c('div',[_c('div',{staticClass:"pa-4 body font-weight-medium"},[_vm._v(" "+_vm._s(_vm.ExaminationPaper.paperName)+" ")]),_c('v-card',{staticClass:"mx-4 pa-4 body-2",attrs:{"flat":""}},[_c('div',{staticClass:"py-2"},[_c('v-icon',{staticClass:"iconfont size17",attrs:{"color":"#444444"}},[_vm._v(" hk-score ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" 试卷分数："+_vm._s(_vm.ExaminationPaper.questionScore)+" ")])],1),_c('div',{staticClass:"py-2 mb-4"},[_c('v-icon',{staticClass:"iconfont size15",attrs:{"color":"#444444"}},[_vm._v(" hk-time ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" 考试时间："+_vm._s(_vm.ExaminationPaper.testTime)+"min ")])],1),_c('v-divider',{staticClass:"v-divider-grey"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"question-type"})]),_c('v-col',[_c('p',{staticClass:"body font-weight-bold "},[_vm._v(" 题型介绍 ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_vm._v(" 总题数 ")]),_vm._l((_vm.ExaminationPaper.questionTypes),function(questionType,index){return _c('div',{key:index,staticClass:"my-3"},[(
                                questionType.questionTypeId ===
                                    _vm.Enum.QuestionType.Single
                            )?_c('span',[_c('span',[_vm._v("单选题")])]):(
                                questionType.questionTypeId ===
                                    _vm.Enum.QuestionType.Muti
                            )?_c('span',[_c('span',[_vm._v("多选题")])]):(
                                questionType.questionTypeId ===
                                    _vm.Enum.QuestionType.Comprehensive
                            )?_c('span',[_c('span',[_vm._v("案列分析题")])]):(
                                questionType.questionTypeId ===
                                    _vm.Enum.QuestionType.LunShu
                            )?_c('span',[_c('span',[_vm._v("论述题")])]):(
                                questionType.questionTypeId ===
                                    _vm.Enum.QuestionType.Judge
                            )?_c('span',[_c('span',[_vm._v("判断题")])]):_vm._e()])})],2),_c('v-col',[_c('div',[_c('span',{staticClass:"ml-2 font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.ExaminationPaper.count)+" ")]),_vm._v(" 道 ")]),_vm._l((_vm.ExaminationPaper.questionTypes),function(questionType,index){return _c('div',{key:index,staticClass:"my-3"},[_c('span',{staticClass:"ml-2"},[_vm._v(" 共 "),_c('span',{staticClass:"mx-n1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(questionType.questionCount)+" ")]),_vm._v(" 题， ")]),_c('span',[_vm._v(" 每题 "),_c('span',{staticClass:"mx-n1 font-weight-bold black--text"},[_vm._v(" "+_vm._s(questionType.questionScore)+" ")]),_vm._v(" 分 ")])])})],2)],1)],1)],1),_c('div',{staticClass:"text-center question-type--btn"},[_c('v-btn',{staticClass:"color-gradient--blue white--text subtitle-0 rounded-pill btn269x50 body-16",attrs:{"depressed":""},on:{"click":function($event){return _vm.questionReply(_vm.PaperRecords.paperId)}}},[_vm._v(" 开始答题 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }