















































































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { LoginWxCompleteInfo, MyInfo, UserLogin } from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import LoginProfession from '@/components/LoginProfession.vue';
import { Login, MatchExam } from '@/domain/actions';
import { SelectionExam } from '@/domain/selection';
import { WxUserRegisterRequestData } from '@/models/UserRegisterRequestData';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Repositories from '@/infrastructure/repository';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import Storage from '@/infrastructure/Storage';
import { WxUserInfo } from '@/models/WxUserInfo';
import { OpenWxUserInfo } from '@/domain/getters';

@RouteName(LoginWxCompleteInfo)
@Component({
    components: {
        LoginProfession,
    },
})
export default class WxCompleteInfoPage extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    show = false;
    selectedExam: SelectionExam = new SelectionExam();
    selectExamInfo = '';
    isShowPwd = true;
    pwdInputType = 'password';
    userNickName = '';
    userPwd = '';

    rqData = new WxUserRegisterRequestData();

    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }
    get wxUser() {
        return Storage.Instance.get<WxUserInfo>(OpenWxUserInfo);
    }

    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.backLogin);

        this.userNickName = this.wxUser.nickname;
        this.getSelectExam();
    }

    deletePassword() {
        this.userPwd = '';
    }
    deleteNickNamw() {
        this.userNickName = '';
    }

    //注册用户
    async RegistUser() {
        this.userNickName = this.userNickName.trim();
        this.userPwd = this.userPwd.trim();
        this.rqData.nickname = this.userNickName;
        this.rqData.LoginPwd = this.userPwd;
        this.rqData.TelphoneNum = this.numPhone;
        if (this.selectExamInfo == '') {
            this.rqData.SgProjectId = '';
            this.rqData.SubjectId = '';
        } else {
            if (this.selectedExam.type == 1) {
                //经济师
                this.rqData.SubjectId = this.selectedExam.profession.id;
            } else if (this.selectedExam.type == 2) {
                //社工
                this.rqData.SgProjectId = this.selectedExam.level.id;
            }
        }
        if (this.rqData.nickname.length == 0) {
            this.notifier.error('请输入昵称');
            return;
        }
        if (this.rqData.nickname.length > 30) {
            this.notifier.error('昵称字数不超过30字');
            return;
        }
        const patrn = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
        if (!patrn.test(this.rqData.LoginPwd)) {
            this.notifier.error('请设置8~16位包含数字和字母的密码');
            return;
        }

        //保持用户信息
        await this.SaveWxUserInfo();
    }

    @Loading(LoadingMsg)
    async SaveWxUserInfo() {
        try {
            this.rqData.headimgurl = this.wxUser.headimgurl;
            this.rqData.unionid = this.wxUser.unionid;
            this.rqData.openid = this.wxUser.openid;
            this.rqData.nickname = this.wxUser.nickname;
            this.rqData.sex = this.wxUser.sex;
            this.rqData.province = this.wxUser.province;
            this.rqData.country = this.wxUser.country;
            this.rqData.city = this.wxUser.city;
            //保存数据
            const response = await this.repository.user.WxRegiestByApp(
                this.rqData
            );
            if (response.success) {
                //微信绑定手机号注册成功，直接登录APP
                await this.$store.dispatch(Login, {
                    token: response.message,
                });
                const data = await this.repository.user.getCurrentExamInfo();
                await this.$store.dispatch(MatchExam, data);
                this.notifier.success('登录成功!');
                this.navigator.redirect(MyInfo);
            } else {
                this.notifier.error(response.message);
            }
        } catch (e) {
            this.notifier.error(e);
        }
    }

    closeDialog() {
        this.show = false;
        this.getSelectExam();
    }

    getSelectExam() {
        if (this.$store.getters.exam) {
            this.selectedExam = { ...this.$store.getters.exam };
        }
        this.selectExamInfo = this.selectedExam.level.name;
        if (this.selectedExam.profession) {
            //经济师
            this.selectExamInfo += this.selectedExam.profession.name;
        }
    }

    //显示隐藏密码
    ShowPassword() {
        if (this.isShowPwd) {
            this.isShowPwd = false;
            this.pwdInputType = 'input';
        } else {
            this.isShowPwd = true;
            this.pwdInputType = 'password';
        }
    }

    backLogin() {
        this.navigator.replace(UserLogin, {
            phoneNumber: this.numPhone,
        });
    }
}
