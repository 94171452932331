














































import navbar from '@/components/navbar.vue' 
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import Repositories from '@/infrastructure/repository';
import { CourseDetail, PaySuccess, UserOrders } from '@/domain/views';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { OrderNo } from '@/domain/getters';
import { SheetModel } from '@/models/UserCourseResponse';
import Storage from '@/infrastructure/Storage';
import { Loading } from 'vuetify-extension';
import { LoadingData } from '@/domain/loadingText';
@RouteName(PaySuccess)
@Component({
  components: {
    navbar,
  },
})
export default class PaySuccessComponent extends Vue {
  @Inject(Navigator) navigator: Navigation;
  @Inject(Repository) repository: Repositories;
  sheetModel = new SheetModel();
  get orderno() {
    return this.$route.query.orderno as string;
  }
  async mounted() {
    //大左滑
    BackRouteConfig.addBackEvent(this.myInfo);
    await this.GetOrderInfo();
  }
  @Loading(LoadingData)
  async GetOrderInfo() {
    this.sheetModel = await this.repository.CurriculumPay.GetOrderInfo(
        this.orderno
    );
  }

  myInfo() {
    //跳转到课程详情界面
    this.navigator.redirect(
        CourseDetail,
        {
          courseId: this.sheetModel.courseId,
          saleType: this.sheetModel.courseType,
        },
        {
          bqCode: this.sheetModel.bqCode,
          currentTab: 1,
        }
    );
  }
}
