
















































































































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
    QuestionType,
    ExaminationPaperInfo,
    MyPaperRecordsInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import { RouteName } from 'ea-router';
import {
    PracticeExamList,
    PracticeExamDetail,
    PracticeExamQuestionReply,
} from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
import { LoadingMsg } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {
  ExaminationPaperInfos,
  NoRepliedQuestionIds,
  PaperQuestions,
  QuestionId,
  Replies,
  TrueQuestionIds,
  taskType,
  PaperRecord, DateNow,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(PracticeExamDetail)
@Component({
    components:{
        navbar,
    }
})
export default class ExamDetail extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    ExaminationPaper = new ExaminationPaperInfo();
    PaperRecords = new MyPaperRecordsInfo();
    Enum = {
        QuestionType: QuestionType,
    };
    get groupId() {
        return this.$route.query.groupId as string;
    }
    get subjectId() {
        return this.$route.query.subjectId as string;
    }
    get tab() {
        return this.$route.query.tab as string;
    }

  get Type() {
    return this.$route.query.Type as string;
  }

    @Loading(LoadingMsg)
    async GetExaminationPaper() {
        const PaperRecordsRedis = Storage.Instance.get<MyPaperRecordsInfo>(
            PaperRecord
        );
        if (PaperRecordsRedis == undefined || PaperRecordsRedis == null) {
            const preData = await this.repository.exampractice.InsertIntoPaperRecordsPc(
                { paperId: this.groupId }
            );
            this.PaperRecords = preData;
            Storage.Instance.set(PaperRecord, this.PaperRecords);
        } else this.PaperRecords = PaperRecordsRedis;
        const data = await this.repository.exampractice.GetExaminationPaper(
            {
                paperId: this.PaperRecords.paperId,
                paperRecordsId: this.PaperRecords.paperRecordsId,
            }
        );
        this.ExaminationPaper = data;
    }
    mounted() {
        this.GetExaminationPaper();

        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectlist);
    }
    questionReply() {
        this.clearStorage();
        //缓存一个当前进入作答界面的时间
      Storage.Instance.set(DateNow, new Date());
        this.navigator.redirect(PracticeExamQuestionReply, '', {
            testTime: this.ExaminationPaper.testTime*60,
            paperId: this.PaperRecords.paperId,
            paperRecordsId: this.PaperRecords.paperRecordsId,
            groupId: this.groupId,
            subjectId: this.subjectId,
            tab: this.tab,
            
        });
    }

    redirectlist() {
        this.navigator.redirect(PracticeExamList, '', {
            subjectId: this.subjectId,
            tab: this.tab,
          Type:this.Type
        });
    }
    clearStorage() {
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Replies);
        Storage.Instance.clear(ExaminationPaperInfos);
        Storage.Instance.clear(PaperQuestions);
        Storage.Instance.clear(taskType);
    }
}
