
























































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator';

import { RouteName } from 'ea-router';

import { Navigator, Repository, Notifier } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    ReviewWrongTopicList,
    ReviewWrongTopicQuestionView,
    ReviewWrongTopicReport,
} from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { ReplyStatus } from '@/models/ExamPractice';
import Storage from '@/infrastructure/Storage';
import {
    QuestionId,
    TrueQuestionIds,
    WrongTopicQuestions,
    WrongTopicReportReturn,
    WrongTopicViewOnlyError,
    WrongTopicViewType,
} from '@/domain/getters';
import { WrongTopicInfo } from '@/models/ReviewQuestion';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@Component
@RouteName(ReviewWrongTopicReport)
export default class Report extends Vue {
    @Inject(Notifier) notifier: any;

    paperQuestions = new Array<WrongTopicInfo>();
    get reportData() {
        return Storage.Instance.get(WrongTopicReportReturn);
    }
    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }
    Enum = {
        ReplyStatus: ReplyStatus,
    };

    wrongTopicListRed() {
        // eslint-disable-next-line no-undef
        this.navigator.redirect(ReviewWrongTopicList);
    }
    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Number((((num / total) * 10000) / 100).toFixed(1));
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);

        Storage.Instance.clear(WrongTopicViewOnlyError);
        Storage.Instance.set(WrongTopicViewType, 1);
        this.navigator.redirect(ReviewWrongTopicQuestionView, '', {
            groupId: '',
            IsShowAnswer: true,
            paperRecordsId: this.paperRecordsId,
        });
    }

    ViewErrorWrongTopicAlyasis() {
        Storage.Instance.clear(QuestionId);
        // Storage.Instance.set(TrueQuestionIds, this.trueQuestionIds);
        // Storage.Instance.set(NoRepliedQuestionIds, this.ErrorQuestionIds);
        Storage.Instance.set(WrongTopicViewOnlyError, 1);
        Storage.Instance.set(WrongTopicViewType, 1);

        if (
            this.reportData.wrongTopicInfoReturn.errorQuestionIds.length <=
                0 &&
            this.reportData.wrongTopicInfoReturn.notAnswer <= 0
        ) {
            this.notifier.success('恭喜您全部答对，没有错题记录');
            return;
        } else {
            this.navigator.redirect(ReviewWrongTopicQuestionView, '', {
                IsShowAnswer: true,
            });
        }
    }

    ViewAllWrongTopicAlyasis() {
        Storage.Instance.clear(WrongTopicViewOnlyError);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.set(WrongTopicViewType, 1);
        this.navigator.redirect(ReviewWrongTopicQuestionView, '', {
            groupId: '',
            paperRecordsId: this.paperRecordsId,
            IsShowAnswer: true,
        });
    }

    // GetReplyStatus(childQuestion: QuestionDetailInfo) {
    //
    //         if (this.reportData.wrongTopicInfoReturn.SucessQuestionIds.some(
    //             x => x == childQuestion.questionId
    //         )) {
    //             return this.Enum.ReplyStatus.ReplyedTrue;
    //         } else  if (this.reportData.wrongTopicInfoReturn.ErrorQuestionIds.some(
    //             x => x == childQuestion.questionId
    //         )) {
    //             return this.Enum.ReplyStatus.ReplyedFlase;
    //         } else {
    //             return this.Enum.ReplyStatus.NoReplyed;
    //         }
    // }

    gradientColor = {
        '0%': '#FDAF95',
        '100%': '#F96E7D',
    };
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.wrongTopicListRed);

        // this.reportData = this.$store.getters.wrongTopicReport;
        this.paperQuestions = Storage.Instance.get<Array<WrongTopicInfo>>(
            WrongTopicQuestions
        );
        //console.log(this.reportData);
    }
}
