












import { Component, Vue, Prop } from 'vue-property-decorator';
import { QuestionType } from '@/models/ExamPractice';
import SingleReplyComponent from '@/components/questionviews/singlequestion/ChapterReply.vue';
import MutiComponent from '@/components/questionviews/mutiquestion/ChapterReply.vue';
import { Context, EnableProp } from 'ea-router';
import { ChapterPractice } from '@/models/ChapterPractice';
@Component({
    components: {
        SingleReplyComponent,
        MutiComponent,
    },
})
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
export default class QuestionViewComponent extends Vue {
    @Prop() question: ChapterPractice;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    Enum = {
        questionType: QuestionType,
    };
    changedReplies() {
        this.$emit('changedReplies', this.question);
    }
    questionComponent(SubjectType: number) {
        switch (SubjectType) {
            case this.Enum.questionType.Single:
                return 'SingleReplyComponent';
            case this.Enum.questionType.Muti:
                return 'MutiComponent';
            case this.Enum.questionType.Comprehensive:
                return 'ComprehensiveReplyComponent';
            case this.Enum.questionType.Judge:
                return 'JudgeReplyComponent';
            case this.Enum.questionType.LunShu:
                return 'LunShuReplyComponent';
        }
        return '';
    }
}
