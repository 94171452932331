import AxiosHttp from '@/infrastructure/http';
import { MessagesOutPut } from '@/models/MessagesOutPut';
import {
    CurriculumPayInfoInput,
    CurriculumPayInfo,
    UpdateOrderStatusInput,
    OrderSumbitInput,
    SheetModelInfo,
    ToOrderPayInput,
    PayResultInfo,
    SheetModel,
    OrderQueryInput
} from '@/models/UserCourseResponse';

export default class CurriculumPayRepository {
    //支付成功更新订单状态
    UpdateOrderStatus(input: UpdateOrderStatusInput) {
        return AxiosHttp.Instance.post<MessagesOutPut>(
            '/CurriculumPay/UpdateOrderStatus',
            input
        );
    }
    //进入支付界面初始化用户优惠券等信息
    CurriculumPayInfo(input: CurriculumPayInfoInput) {
        return AxiosHttp.Instance.post<CurriculumPayInfo>(
            '/CurriculumPay/CurriculumPayInfo',
            input
        );
    }

    //提交订单
    OrderSumbit(input: OrderSumbitInput) {
        return AxiosHttp.Instance.post<SheetModelInfo>(
            '/CurriculumPay/OrderSumbit',
            input
        );
    }

    //发起支付
    ToOrderPay(input: ToOrderPayInput) {
        return AxiosHttp.Instance.post<PayResultInfo>(
            '/CurriculumPay/ToOrderPay',
            input
        );
    }

    //发起支付
    OrderQuery(input: OrderQueryInput) {
        return AxiosHttp.Instance.post<PayResultInfo>(
            '/CurriculumPay/OrderQuery',
            input
        );
    }

    WechatPayNotify(data: object) {
        return AxiosHttp.Instance.postWithoutCatch<MessagesOutPut>(
            '/NotifyPay/WechatPayNotify',
            data
        );
    }
    //轮询查询订单支付状态
    GetOrderInfo(orderNo: string) {
        return AxiosHttp.Instance.get<SheetModel>(
            '/CurriculumPay/GetOrderInfo?orderNo=' + orderNo
        );
    }

    //轮询查询订单支付状态
    GetIsExistToPayOrder(courseId: string) {
        return AxiosHttp.Instance.get<boolean>(
            '/CurriculumPay/GetIsExistToPayOrder?courseId=' + courseId
        );
    }
}
