


















import navbar from '@/components/navbar.vue';
import { Context, EnableProp, RouteName } from 'ea-router';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Course, SlideDetail } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';

@RouteName(SlideDetail)
@Context('id')
@EnableProp()
@Component({
    components: {
        navbar,
    },
})
export default class SlideDetailPageComponent extends Vue {
    @Prop()
    id: string;
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;

    mounted() {
        this.getSlideDetail();
    }

    async getSlideDetail() {
        const data = await this.repository.slide.SlideDetail(this.id);
        this.slideDetail = data;
    }

    courseIndex() {
        this.navigator.redirect(Course);
    }
    slideDetail = {};
}
