





















































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Vue } from 'vue-property-decorator';

import { RouteName } from 'ea-router';
import {
    PracticeExamQuestionReply,
    PracticeExamReport,
    PracticeExamTopicList,
    PracticeExamQuestionView,
    PracticeExamSubjectiveItemReport,
} from '@/domain/views';
import {
    AnswerQuestionInputInfo,
    ExaminationPaperInfo,
    PaperQuestionInfo,
    QuestionDetailInfo,
    QuestionType,
} from '@/models/ExamPractice';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Dialog } from 'vant';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import {
    ExaminationPaperInfos,
    PaperQuestions,
    Replies,
    QuestionId,
    TrueQuestionIds,
    NoRepliedQuestionIds,
} from '@/domain/getters';
import { Loading } from 'vuetify-extension';
import { LoadingHandPaper } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(PracticeExamTopicList)
@Component({
    components:{
        navbar,
    }
})
export default class TopicList extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    ExaminationPaperInfo = new ExaminationPaperInfo();
    paperQuestions = new Array<PaperQuestionInfo>();
    Questions = new Array<QuestionDetailInfo>();
    replies = new Array<AnswerQuestionInputInfo>();
    RepliedCount = 0;
    UnRepliedCount = 0;
    markCount=0;
    Enum = {
        questionType: QuestionType,
    };

    get IsShowHand() {
        return this.$route.query.IsShowHand as string;
    }



    get paperId() {
        return this.$route.query.paperId as string;
    }
    get count() {
        return this.$route.query.count as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }
    get falseQuestionIds() {
        return Storage.Instance.get<Array<string>>(NoRepliedQuestionIds);
    }
    get trueQuestionIds() {
        return Storage.Instance.get<Array<string>>(TrueQuestionIds);
    }
    IsReplyed(id: string) {
        return this.replies?.filter(x => x.questionId === id).length > 0;
    }

    getRedisData() {
        this.paperQuestions = Storage.Instance.get<
            Array<PaperQuestionInfo>
        >(PaperQuestions);
        //console.log('paperQuestions:' + this.paperQuestions);
        this.ExaminationPaperInfo = Storage.Instance.get<
            ExaminationPaperInfo
        >(ExaminationPaperInfos);
        const replies = Storage.Instance.get<
            Array<AnswerQuestionInputInfo>
        >(Replies);
        if (replies != null) {
            this.replies = Storage.Instance.get<
                Array<AnswerQuestionInputInfo>
            >(Replies)?.filter(x => x != null && x.answer != '');
        }
    }

   IsShowSigin(Sigin) {
     if (Sigin && this.IsShowHand)
       return true;
     else
       return false;
   }
    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectPre);

        this.getRedisData();
        this.RepliedCount = this.replies?.length;
        this.UnRepliedCount = Number(this.count) - this.replies?.length;
        //这里验证下是否存在正确的题目,如果从错误的解析跳转过来,需要把正确的题目剔除
        if (this.trueQuestionIds != null && this.falseQuestionIds != null) {
            this.handTrueQuestions();
        }
        this.GetAllQuestions();
    }
    async GetAllQuestions() {
        this.paperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                this.Questions.push(x);
                if(x.signActive){
                  this.markCount++;

                }
            });
        });
    }

    handTrueQuestions() {
        this.paperQuestions.forEach(x => {
            x.question = x.question.filter(
                p => this.trueQuestionIds.indexOf(p.id) == -1
            );
        });
        let q = 0;
        this.paperQuestions.forEach(x => {
            x.question.forEach(m => {
                q++;
                m.rowsIndex = q;
            });
        });
        this.RepliedCount = this.replies.filter(
            x => this.trueQuestionIds.indexOf(x.questionId) == -1
        )?.length;
        this.UnRepliedCount = q - this.RepliedCount;
    }
    handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }
    async handlerReplies() {
        this.replies
            .filter(x => x != null)
            .forEach(x => {
                if (x.type == this.Enum.questionType.Muti) {
                    if (x.answer.indexOf(',') == -1)
                        x.answer = ((x.answer as unknown) as Array<
                            string
                        >).join(',');
                }
            });
    }
    @Loading(LoadingHandPaper)
    async handIn() {
        if (
            this.replies.filter(x => x != null && x.answer != '').length ==
            0
        ) {
            this.notifier.warn('您未开始做题,不能交卷');
            return;
        }
        await this.handlerReplies();
        const data = await this.repository.exampractice.AnswerQuestion(
            this.replies
        );
        this.notifier.success('交卷成功');
        console.log('确认');
        Storage.Instance.set(Replies, data.result);
        if (
            this.Questions.filter(
                x => x.subjectType == this.Enum.questionType.LunShu
            ).length >= this.Questions.length
        ) {
            this.navigator.redirect(PracticeExamSubjectiveItemReport, '', {
                count: this.Questions.length,
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            });
        } else {
            this.navigator.redirect(PracticeExamReport, '', {
                count: this.Questions.length,
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            });
        }
    }
    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        this.navigator.redirect(
            Boolean(this.IsShowHand) == false
                ? PracticeExamQuestionView
                : PracticeExamQuestionReply,
            '',
            {
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            }
        );
    }
    redirectPre() {
        this.navigator.redirect(
            Boolean(this.IsShowHand) == false
                ? PracticeExamQuestionView
                : PracticeExamQuestionReply,
            '',
            {
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            }
        );
    }
}
