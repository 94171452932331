







































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { Rule } from '@/infrastructure/rules';
import { MyInfo, InvoiceSuccess, UserOrders } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { OrderSqInvoice } from '@/models/OrderSqInvoice';

@RouteName(InvoiceSuccess)
@Component({
    components: {
        navbar,
    },
    data() {
        return {
            radio: '1',
        };
    },
})
export default class InvoiceSuccessCom extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    @Inject(Rules) rules: Rule;
    Input = new OrderSqInvoice();
    isAddSpecial = '1';
    SqType = '1';
    mounted() {
        this.Input.invoiceType = 1;
    }
    UserOrders() {
        this.navigator.redirect(UserOrders, null);
    }

    MyInfo() {
        this.navigator.redirect(MyInfo, null);
    }
}
