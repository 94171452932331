

































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Context, EnableProp, RouteName } from 'ea-router';
import { agreementcontent, MyAgreement } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import { AgreementRecordInfo } from '@/models/UserCourseResponse';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(agreementcontent)
@Component({
  components: {
    navbar,
  },
})
@Context('id')
@EnableProp()
export default class UserCardsPageComponent extends Vue {
  @Inject(Navigator) navigator: Navigation;
  @Inject(Repository) repository: Repositories;
  @Prop() readonly id!: string;
  phone = '';
  agreementRecordInfo = new AgreementRecordInfo();
  MyInfo() {
    this.navigator.redirect(MyAgreement);
  }
  async mounted() {
    //添加手动物理返 回键路    由事件
    BackRouteConfig.addBackEvent(this.MyInfo);
    await this.getMyAgreementDetail();
    //获取用户信息
    if (this.$store.getters.isLogin) {
      const userInfo = await this.repository.user.getInfo();
      this.phone = userInfo.telphoneNum;
    }
  }
  @Loading(LoadingMsg)
  async getMyAgreementDetail() {
    const data = await this.repository.user.MyAgreementDetail(this.id);
    this.agreementRecordInfo = data;
  }
}
