var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white fill-height"},[_c('navbar',[_c('div',{attrs:{"slot":"left"},on:{"click":_vm.returnQuestionReply},slot:"left"},[_c('v-icon',{staticClass:"iconfont body-16",attrs:{"color":"#000"}},[_vm._v(" hk-return-left ")])],1),_c('div',{attrs:{"slot":"center"},slot:"center"},[_vm._v(" 练习报告 ")])]),_c('div',{staticClass:"mb-56"},[_c('div',{staticClass:"py-2 px-4 body-3 text-grey-3 theme-bg--color"},[_c('p',[_vm._v("练习名称："+_vm._s(_vm.ChapterQuestionsResult.chapterName))]),_c('p',[_vm._v(" 练习时间："+_vm._s(_vm.timestampToTime(_vm.ChapterQuestionsResult.dateTime))+" ")])]),_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"text-center py-6"},[_c('van-circle',{attrs:{"size":"4.506rem","value":_vm.Percentage(
                            this.trueQuestionIds.length,
                            Number(this.count)
                        ),"color":_vm.gradientColor,"speed":100,"stroke-width":120,"layer-color":"#FCD8DC"}},[_c('div',{staticClass:"report-score"},[_c('p',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.Percentage( this.trueQuestionIds.length, Number(this.count) ))+"% ")]),_c('p',{staticClass:"body-3 text-grey-3"},[_vm._v("正确率")])])])],1),_c('v-row',{staticClass:"text-center body-2 text-grey-3",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"card--size white--text rounded d-inline-block",attrs:{"color":"#24B566"}}),_vm._v(" 答对 "+_vm._s(_vm.trueQuestionIds.length)+" ")],1),_c('v-col',[_c('v-card',{staticClass:"card--size white--text rounded d-inline-block option-bg",attrs:{"color":"#24B566"}}),_vm._v(" 少选 "+_vm._s(_vm.partTrueQuestionIds.length)+" ")],1),_c('v-col',[_c('v-card',{staticClass:"card--size white--text rounded d-inline-block",attrs:{"color":"#F04832"}}),_vm._v(" 答错 "+_vm._s(_vm.falseQuestionIds.length)+" ")],1),_c('v-col',[_c('v-card',{staticClass:"card--size white--text rounded d-inline-block",attrs:{"color":"#999999"}}),_vm._v(" 未做"+_vm._s(_vm.NoRepliedQuestionIds.length)+" ")],1)],1),_c('v-row',{staticClass:"card-center mt-8",attrs:{"no-gutters":""}},_vm._l((_vm.TipsChapterQuestionLists),function(questionItem,index){return _c('v-col',{key:index,staticClass:"mt-5 card-center"},[_c('v-row',{staticClass:"pa-4 white",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"topicList-name-line"})]),_c('v-col',[_c('p',{staticClass:"body-1"},[_vm._v(" "+_vm._s(questionItem.subjectName)+" ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((questionItem.chapterPracticeOutput),function(childQuestion,index){return _c('v-col',{key:index,staticClass:"flex-grow-0"},[(
                                    _vm.GetReplyStatus(childQuestion.id) ===
                                        _vm.Enum.ReplyStatus.ReplyedTrue
                                )?_c('v-card',{staticClass:"card-respondence white--text rounded-pill ma-4",attrs:{"color":"#24B566"},on:{"click":function($event){return _vm.redirectQuestion(childQuestion.id)}}},[_vm._v(" "+_vm._s(childQuestion.Index)+" ")]):_vm._e(),(
                                    _vm.GetReplyStatus(childQuestion.id) ===
                                        _vm.Enum.ReplyStatus.ReplyedPartTrue
                                )?_c('v-card',{staticClass:"card-respondence rounded-pill ma-4 option-bg white--text",attrs:{"color":"#24B566"},on:{"click":function($event){return _vm.redirectQuestion(childQuestion.id)}}},[_vm._v(" "+_vm._s(childQuestion.Index)+" ")]):(
                                    _vm.GetReplyStatus(childQuestion.id) ===
                                        _vm.Enum.ReplyStatus.ReplyedFlase
                                )?_c('v-card',{staticClass:"card-respondence white--text rounded-pill ma-4",attrs:{"color":"#F04832"},on:{"click":function($event){return _vm.redirectQuestion(childQuestion.id)}}},[_vm._v(" "+_vm._s(childQuestion.Index)+" ")]):(
                                    _vm.GetReplyStatus(childQuestion.id) ===
                                        _vm.Enum.ReplyStatus.NoReplyed
                                )?_c('v-card',{staticClass:"card-respondence rounded-pill ma-4",attrs:{"color":"#D9D9D9"},on:{"click":function($event){return _vm.redirectQuestion(childQuestion.id)}}},[_vm._v(" "+_vm._s(childQuestion.Index)+" ")]):_vm._e()],1)}),1)],1)}),1)],1)],1),_c('div',{staticClass:"py-4"}),_c('v-bottom-navigation',{attrs:{"fixed":"","height":"1.7333rem","background-color":"primary","horizontal":""}},[_c('v-row',{staticClass:"my-auto text-center white--text",attrs:{"no-gutters":""}},[_c('v-col',{on:{"click":_vm.viewErrorAlyasis}},[_c('v-btn',{attrs:{"text":"","icon":"","width":"100%","max-width":"100%","height":"1.7333rem","color":"primary","depressed":""}},[_c('span',{staticClass:"white--text ml-n3 body-2"},[_vm._v("错题解析")]),_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#fff","size":"0.4533rem"}},[_vm._v(" hk-error-analysis ")])],1)],1),_c('v-col',{staticClass:"bottom-navigation--border",attrs:{"cols":"auto"}}),_c('v-col',{on:{"click":_vm.viewAllAlyasis}},[_c('v-btn',{attrs:{"text":"","icon":"","width":"100%","max-width":"100%","height":"1.7333rem","color":"primary","depressed":""}},[_c('span',{staticClass:"white--text ml-n3 body-2"},[_vm._v("全部解析")]),_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#fff","size":"0.4533rem"}},[_vm._v(" hk-all-analysis ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }