




































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import SimpleNavigation, { Navigation } from '@/infrastructure/navigation';
import { Context, EnableProp, RouteName,Meta } from 'ea-router';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import { Loading } from 'vuetify-extension';
import { VideoOfflineDownload,BatchDel } from '@/domain/views';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import Repositories from '@/infrastructure/repository';
import VideoDownload from '@/components/course/VideoDownload.vue';
import VideoDowEnd from '@/components/course/VideoDowEnd.vue';
import { InputsModel } from '@/infrastructure/model/CourseAddOrEditQuery';
import Storage from '@/infrastructure/Storage';
import { DowList,VideoDowtabType } from '@/domain/getters';

@RouteName(VideoOfflineDownload)
@Meta({
    keepAlive: true,
})
@Context('courseId')
@EnableProp()
@Component({
    components: {
        navbar,
        VideoDownload,
        VideoDowEnd
    },
})
export default class VideoOfflineDownloadPage extends Vue { 
    @Prop() readonly courseId!: string;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    @Ref('VideoDownloads') readonly VideoDownloads!: any;

    dowLists = new Array<InputsModel>();
    dowEndLists = new Array<InputsModel>();
    tabType:string = 'dowLists'
    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
        this.tabType = Storage.Instance.get<string>(
           VideoDowtabType
        ) || 'dowLists';
        await this.GetList();
    }

    ToUserPackageDetail() {
        Storage.Instance.clear(VideoDowtabType);
        this.navigator.back();
    }
    // 下载列表
    @Loading(LoadingMsg)
    // source true:下载  false：下载完成
    async GetList(source=true,index=0) {
        const data:any = await this.repository.courseAppraise.GetList(
            this.courseId
        );
        if(data.length>0) {
            this.dowEndLists = [];
            this.dowLists = [];
            data.forEach((item: any) => {
                if(item.status == 3) {
                    this.dowEndLists.push(item)
                }else {
                    this.dowLists.push(item)
                }
            })
            // this.dowEndLists = dowEndLists;
            // this.dowLists = dowLists;
            
            Storage.Instance.set(DowList, this[this.tabType]);
            // if(source && this.dowLists.length>0) {
            //     this.$nextTick(() => {
            //         this.VideoDownloads[0].start();
            //     })
            // }
            // alert(index+'第一')
            if(source && this.dowLists.length>0) {
                // alert(index+'第二')
                this.$nextTick(() => {
                    if(index < this.dowLists.length) {
                        this.VideoDownloads[index].start();
                    }
                })
            }
        }else {
            this.dowEndLists = [];
            this.dowLists = [];
        }
        
    }
    getList(data) {
        const ids = data;
        // alert(ids[1]+'下载完了，开始下一个')
        this.GetList(ids[0],ids[1])
    }

    // tab切换
    tabChange(name:string) {
        Storage.Instance.set(DowList, this[name]);
        this.tabType = name
        Storage.Instance.set(VideoDowtabType, this.tabType);
    }
    // 下载或者暂停
    async playOrStart(index) {
        for (let i = 0; i < this.VideoDownloads.length; i++) {
            // 遍历停止下载
            if(i == index) {
                this.VideoDownloads[index].confirmtest();
            }else {
                this.VideoDownloads[i].pause();
            }
        }
    }
    async playOrStop(index) {
        for (let i = 0; i < this.VideoDownloads.length; i++) {
            // 遍历停止下载
            if(i == index) {
                // 开始当前下载
                this.VideoDownloads[index].resume();
            }else {
                this.VideoDownloads[i].pause();
            }
        }
    }
    async playing(index) {
        // alert(index+'暂停了，开始下一个')
        this.VideoDownloads[index+1].start();
    }
    // 改变下载状态
    async statusChange(ids) {
        // alert(ids[2]+'收到下载完成的通知，改变状态')
        const data = ids;
        await this.repository.courseAppraise.UpdateStatus(
            {id:data[1],status:data[0]}
        );
        await this.GetList(ids[3],ids[2]);
    }
    // 批量删除
    async batchDel() {
        this.navigator.redirect(BatchDel, {
            courseId: this.courseId,
        })
    }
    // 页面销毁
    async beforeDestroy (index) {
        for (let i = 0; i < this.VideoDownloads.length; i++) {
            this.VideoDownloads[i].abort();
        }
    }
}
