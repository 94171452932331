




import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Course } from '@/domain/views';

@Component
export default class extends Vue {
    @Inject(Navigator) readonly navigator: Navigation;
    mounted() {
        this.navigator.replace(Course);
    }
}
