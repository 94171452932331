<template>
    <div>
        <tinymce-editor v-model="source" :init="config"></tinymce-editor>

    </div>
</template>

<script>
   import tinymceEditor from '@tinymce/tinymce-vue';

    export default {
      components: {
        tinymceEditor,
      },
        props: ['value', 'placeholder', 'height', 'toolbar', 'inline'],
        data() {
            return {
                source: this.value,
                config: {
                    font_formats:"微软雅黑='微软雅黑';",
                    forced_root_block: false,
                    min_height: this.height || 100,
                    quickbars_insert_toolbar: false,
                    menubar: false, //隐藏菜单栏
                    toolbar: false, //字体大小
                    placeholder: '请输入题干',
                    branding: false,//隐藏右下角技术支持
                },
            };
        },

        watch: {
            value(val) {
                this.source = val;
            },
            source(val) {
                this.$emit('input', val);
            },
        },

    };
</script>
<style>
.tox-statusbar,
.tox-tinymce {
    border: 0px !important;
}
</style>
