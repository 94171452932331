





























































































































































































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Context, EnableProp, RouteName } from 'ea-router';
import { CourseDetail, Paymentorder } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import {
  PayCourseInfo,
  CurriculumPayInfo,
  CurriculumPayInfoInput,
  UserDiscountCardModelInfo,
  OrderSumbitInput,
  SheetModelInfo,
  ObjectInfo,
  ToOrderPayInput,
  PayResultInfo, SheetModel,
} from '@/models/UserCourseResponse';
import Storage from '@/infrastructure/Storage';
import { OrderNo } from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Notification } from 'vuetify-extension';
@Context('saleType', 'courseId')
@EnableProp()
@RouteName(Paymentorder)
@Component({
    components: {
        navbar,
    },
})
export default class InvoiceSuccessCom extends Vue {
  data() {
    return {
      radio: '1',
      activeIcon: require('../../assets/images/danxuan_yixuan@2x.png'),
      inactiveIcon: require('../../assets/images/danxuan_weixuan@2x.png'),
      activeIcon1: require('../../assets/images/denglu_xuanzhong@2x.png'),
      inactiveIcon1: require('../../assets/images/denglui_weixuanzhong@2x.png'),
    };
  }

  @Inject(Navigator) navigator: Navigation;
  @Inject(Notifier) notifier: any;
  @Inject(Repository) repository: Repositories;
  @Prop() saleType: string;
  @Prop() courseId: string;
  IsShowZhujiao = true;
  IsShowDaiJin = true;
  IsShowZheKou = true;
  IsShowName = true;
  radioVouchers = null;
  radioCoupon = [];
  radiozhuJiaos = null;
  radioNames = null;
  radioZhiFu = '2';
  checked = true;
  show = false;
  factPrice = 0.0;
  cardPrice = 0.0;
  disCountPrice = 0.0;
  zhujiaoPrice = 0.0;
  cardzhujiaoPrice = 0.0;
  namePrice = 0.0;
  cardnamePrice= 0.0;
  zhujioaCode = '';
  nameCode = '';
  cardCode = '';
  discountCode = '';
  curriculumPayInfo = new CurriculumPayInfo();
  payCourseInfo = new PayCourseInfo();
  cashCoupons = new Array<UserDiscountCardModelInfo>();
  disCounts = new Array<UserDiscountCardModelInfo>();
  zhujiaoCounts = new Array<UserDiscountCardModelInfo>();
  nameCounts = new Array<UserDiscountCardModelInfo>();
  sheetModelInfo = new SheetModelInfo();
  payResultInfo = new PayResultInfo();
  sheetModel = new SheetModel();
  orderNo = '';
  IsShowUseCard = true;
  radioOne = '';
  radioChange = false;
  radioChangezj = false;
  radioChangename = false;
  get bqCode() {
    return this.$route.query.bqCode;
  }

  get amount() {
    return this.$route.query.amount as string;
  }

  //折扣券点击取消
  radClick(cardCode: string) {
    if (!this.radioChange) {
      this.discountCode = '';
      this.disCountPrice = 0.0;
      this.radioVouchers = null;
    }
    this.radioChange = false;
    this.setPrice();
  }

  //助教专属券单击取消
  radClickZJ() {
    if (!this.radioChangezj) {
      this.zhujioaCode = '';
      this.radiozhuJiaos = null;
      this.zhujiaoPrice = 0.0;
      this.cardzhujiaoPrice = 0.0;
    }
    this.radioChangezj = false;
    this.setPrice();
  }
    //实名专属券单击取消
    radClickName() {
        if (!this.radioChangename) {
            this.nameCode = '';
            this.radioNames = null;
            this.namePrice = 0.0;
            this.cardnamePrice = 0.0;
        }
        this.radioChangename = false;
        this.setPrice();
    }

  async mounted() {
    if (this.amount != null && this.amount != '') {
      this.factPrice = Number(this.amount);
      this.show = true;
    }
    //大左滑
    BackRouteConfig.addBackEvent(this.MyInfo);
    await this.GetCurriculumPayInfo();
    this.InitDataLoad();
  }

  InitDataLoad() {
    //默认选中所有代金券,以及默认选中第一个折扣券
    if (this.cashCoupons != null && this.cashCoupons.length > 0) {
      this.cashCoupons.forEach(p => {
        this.radioCoupon.push(p.objectInfo);
      });
    }
    if (this.disCounts != null && this.disCounts.length > 0) {
      this.radioVouchers = this.disCounts.sort((x, y) => {
        return y.amount - x.amount;
      })[this.disCounts.length - 1].objectInfo;
    }
    if (this.zhujiaoCounts != null && this.zhujiaoCounts.length > 0) {
      this.radiozhuJiaos = this.zhujiaoCounts.sort((x, y) => {
        return y.amount - x.amount;
      })[this.zhujiaoCounts.length - 1].objectInfo;
    }

      if (this.nameCounts  != null && this.nameCounts.length > 0) {
          this.radioNames = this.nameCounts.sort((x, y) => {
              return y.amount - x.amount;
          })[this.nameCounts.length - 1].objectInfo;
      }
  }

  //是否使用优惠券
  ChangeCoupons() {
    if (this.checked) {
      this.cardCode = '';
      this.discountCode = '';
      this.zhujioaCode = '';
      this.zhujiaoPrice = 0.0;
      this.cardzhujiaoPrice = 0.0;
      this.disCountPrice = 0.0;
      this.cardPrice = 0.0;
      this.radiozhuJiaos = null;
      this.radioVouchers = null;
      this.radioCoupon = [];
      this.checked = false;
      this.IsShowZhujiao = false;
      this.IsShowDaiJin = false;
      this.IsShowZheKou = false;
    } else {
      this.IsShowZhujiao = true;
      this.IsShowDaiJin = true;
      this.IsShowZheKou = true;
      this.checked = true;
      this.radiozhuJiaos = null;
      this.radioVouchers = null;
      this.radioCoupon = [];
    }
    this.setPrice();
  }

  //专属券计算
  zhuJiaoSetPrice(cardType: number, cardCode: string) {
    this.radioChangezj = true;
    if (
        this.radiozhuJiaos != null &&
        this.radiozhuJiaos.cardCode == cardCode
    ) {
      if (cardType == 1) {
        //代金券
        this.cardzhujiaoPrice = this.radiozhuJiaos.amount;
        this.zhujiaoPrice = 0;
        this.zhujioaCode = this.radiozhuJiaos.cardCode;
      } else {
        //折扣券
        this.cardzhujiaoPrice = 0;
        this.zhujiaoPrice = parseFloat(this.radiozhuJiaos.amount) / 10;
        this.zhujioaCode = this.radiozhuJiaos.cardCode;
      }
      this.setPrice();
    }
  }
    //实名专属券计算
    nameSetPrice(cardType: number, cardCode: string) {
            // console.log(this.radioNames);
            this.radioChangename=true;
        if (
            this.radioNames != null &&
            this.radioNames.cardCode == cardCode
        ) {
            if (cardType == 1) {
                //代金券
                this.cardnamePrice = this.radioNames.amount;
                this.namePrice = 0;
                this.nameCode = this.radioNames.cardCode;
            } else {
                //折扣券
                this.cardzhujiaoPrice = 0;
                this.namePrice = parseFloat(this.radioNames.amount) / 10;
                this.nameCode = this.radioNames.cardCode;
            }
            this.setPrice();
        }

    }

  //代金 券计算
  cardSetPrice() {
    if (this.radioCoupon != []) {
      this.cardCode = '';
      this.cardPrice = 0.0;
      this.radioCoupon.forEach(obj => {
        this.cardPrice = this.cardPrice + obj.amount;
        if (this.cardCode.indexOf(obj.cardCode) == -1)
          this.cardCode += obj.cardCode + ',';
      });
      this.setPrice();
    }
  }

  //折扣券计算
  discountSetPrice(cardCode: string) {
    this.radioChange = true;
    if (
        this.radioVouchers != null &&
        this.radioVouchers.cardCode == cardCode
    ) {
      this.disCountPrice = parseFloat(this.radioVouchers.amount) / 10;
      this.discountCode = this.radioVouchers.cardCode;
      this.setPrice();
    }
    console.log(this.radioVouchers);
  }

  //金额计算赋值
  setPrice() {
    //初始化实际金额
    this.factPrice = 0;
    //代金券
    this.factPrice =
        this.payCourseInfo.favourablePrice -
        this.cardPrice -
        this.cardzhujiaoPrice-
        this.cardnamePrice;

    //折扣券计算
    if (this.disCountPrice > 0) {
      this.factPrice = Number(
          (this.factPrice * this.disCountPrice).toFixed(2)
      );
    }
    //助教券--需要区分是代金券还是抵扣券
    if (this.zhujiaoPrice > 0) {
      this.factPrice = Number(
          (this.factPrice * this.zhujiaoPrice).toFixed(2)
      );
    }
      //助实名--需要区分是代金券还是抵扣券
      if (this.namePrice > 0) {
          this.factPrice = Number(
              (this.factPrice * this.namePrice).toFixed(2)
          );
      }
  }

  async GetCurriculumPayInfo() {
    const input = new CurriculumPayInfoInput();
    input.isquestion = '1';
    input.subjectid = this.courseId;
    const data = await this.repository.CurriculumPay.CurriculumPayInfo(
        input
    );
    this.curriculumPayInfo = data;
    this.payCourseInfo = this.curriculumPayInfo.course[0];
    this.factPrice = this.payCourseInfo.favourablePrice;
    if (this.curriculumPayInfo.discountCard.length <= 0) {
      this.IsShowUseCard = false;
    }
    //数据转换处理
    this.curriculumPayInfo.discountCard?.forEach(p => {
      const tempObj = new ObjectInfo();
      tempObj.amount = p.amount;
      tempObj.cardCode = p.cardCode;
      p.objectInfo = tempObj;
    });
    console.log(this.curriculumPayInfo.discountCard);
    this.cashCoupons = this.curriculumPayInfo.discountCard?.filter(
        m => m.isOnlyTch == false && m.cardType == '1'&& m.isOnlyName == false
    );
    this.disCounts = this.curriculumPayInfo.discountCard?.filter(
        m => m.isOnlyTch == false && m.cardType == '0'&& m.isOnlyName == false
    );
    this.zhujiaoCounts = this.curriculumPayInfo.discountCard?.filter(
        m => m.isOnlyTch == true&& m.isOnlyName == false
    );
      this.nameCounts = this.curriculumPayInfo.discountCard?.filter(
          m =>  m.isOnlyName == true
      );
  }

  MyInfo() {
    //清空缓存==OrderNo
    Storage.Instance.clear(OrderNo);
    this.navigator.redirect(
        CourseDetail,
        { saleType: this.saleType, courseId: this.courseId },
        { bqCode: this.bqCode }
    );
  }

  async onSubmit() {
    //todo-发起订单需要验证用户在当前课程下是否存在待支付的订单,如果存在弹出提示框 ,引导用户去待支付列表完成支付,避免重复数据的出现
    const isExist = await this.repository.CurriculumPay.GetIsExistToPayOrder(
        this.courseId
    );
    if (isExist) {
      Notification.warn('您已购买,请勿重复提交');
      return;
    }
    this.show = true;
    const cardCodeNew =
        this.cardCode + ',' + this.discountCode + ',' + this.zhujioaCode+ ',' + this.nameCode;
    const input = new OrderSumbitInput();
    input.CourseId = this.courseId;
    input.OrderType = '0';
    input.SubjectId = this.$store.getters.exam.profession?.id;
    input.OrderAmount = this.factPrice;
    input.CardCode = cardCodeNew;
    if (this.checked == false)
      input.CardCode = '';
    const data = await this.repository.CurriculumPay.OrderSumbit(input);
    this.sheetModelInfo = data;
    this.orderNo = this.sheetModelInfo.orderNo;
  }

  async ToPay() {
    const isExist = await this.repository.CurriculumPay.GetIsExistToPayOrder(
        this.courseId
    );
    if (isExist) {
      Notification.warn('您已购买,请勿重复提交');
      return;
    }
    const input = new ToOrderPayInput();
    input.OrderNo = this.sheetModelInfo.orderNo;
    this.sheetModel = await this.repository.CurriculumPay.GetOrderInfo(
        this.sheetModelInfo.orderNo
    );
    input.Amount = this.sheetModel.orderAmount;
    const data = await this.repository.CurriculumPay.ToOrderPay(input);
    this.payResultInfo = data;
    //这里拿到支付的url,唤起微信支付框
    if (this.payResultInfo.return_code == 'SUCCESS') {
     /* let w=window.plus.webview.create(this.payResultInfo.mweb_url, 'pay-url', {
        top: '1000px', bottom: '1000px',
        additionalHttpHeaders: { referer: 'http://app.haokao123.com' }
      });*/

       location.href = this.payResultInfo.mweb_url;
    } else {
      this.notifier.error(this.payResultInfo.return_msg);
    }
  }
}
