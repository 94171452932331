




















































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { AppGuide, Course, MyAgreement } from '@/domain/views';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RememberVersion } from '@/domain/actions';

@RouteName(AppGuide)
@Component
export default class HomePageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    length = 3;
    currentStep = 0;
    show = false;
    IsShow = false;

    get currentStepIndex() {
        return this.$route.query.currentStep as string;
    }

    get isLast(): boolean {
        return this.currentStep === this.length - 1;
    }

    async mounted() {
        if (this.currentStepIndex != null) {
            this.currentStep = Number(this.currentStepIndex);
        }
    }

    async entryApp() {
        this.IsShow = true;
        this.show = true;

    }

    async openSettings() {
        let main = window.plus.android.runtimeMainActivity(); //获取activity
        let Intent = window.plus.android.importClass('android.content.Intent');
        let Settings = window.plus.android.importClass('android.provider.Settings');
        let intent = new Intent(Settings.ACTION_NOTIFICATION_SETTINGS);//可设置表中所有Action字段
        main.startActivity(intent);
    }

    async confirm() {
        this.show = false;
        await this.openSettings();
        await this.UseApp();
    }

 async   cancel() {
        this.show = false;
        await this.UseApp();
    }

    async UseApp() {
        await this.$store.dispatch(RememberVersion);
        this.navigator.redirect(Course);
    }
}
