























































































































import { Component, Inject, Vue } from 'vue-property-decorator';

import { RouteName } from 'ea-router';
import {
    ReviewWrongTopicTopicList,
    ReviewWrongQuestionReply,
    ReviewWrongTopicReport,
    ReviewWrongTopicQuestionView,
} from '@/domain/views';
import {
    AnswerQuestionInputInfo,
    ExaminationPaperInfo,
    QuestionDetailInfo,
    QuestionType,
} from '@/models/ExamPractice';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Dialog } from 'vant';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import {
    QuestionId,
    WrongTopicQuestions,
    WrongTopicReplies,
    WrongTopicReportReturn,
    WrongTopicViewOnlyError,
} from '@/domain/getters';
import { Loading } from 'vuetify-extension';
import { LoadingHandPaper } from '@/domain/loadingText';
import { WrongTopicInfo } from '@/models/ReviewQuestion';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(ReviewWrongTopicTopicList)
@Component
export default class TopicList extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    ExaminationPaperInfo = new ExaminationPaperInfo();
    paperQuestions = new Array<WrongTopicInfo>();
    errorPaperQuestions = new Array<WrongTopicInfo>();
    Questions = new Array<QuestionDetailInfo>();
    replies = new Array<AnswerQuestionInputInfo>();
    RepliedCount = 0;
    UnRepliedCount = 0;
    rowsIndex = 1;
    Enum = {
        questionType: QuestionType,
    };
    IsShowAnswer = false;

    get IsShowHand() {
        return this.$route.query.IsShowHand as string;
    }

    get count() {
        return this.$route.query.count as string;
    }

    get wrongType() {
        return this.$route.query.wrongType as string;
    }

    get wrongTopicViewOnlyError() {
        return Storage.Instance.get<number>(WrongTopicViewOnlyError);
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    IsReplyed(id: string) {
        return this.replies?.filter(x => x.questionId === id).length > 0;
    }
    getRedisData() {
        this.paperQuestions = Storage.Instance.get<Array<WrongTopicInfo>>(
            WrongTopicQuestions
        );

        const replies = Storage.Instance.get<
            Array<AnswerQuestionInputInfo>
        >(WrongTopicReplies);
        if (replies != null) {
            this.replies = Storage.Instance.get<
                Array<AnswerQuestionInputInfo>
            >(WrongTopicReplies)?.filter(x => x != null && x.answer != '');
        }
    }

    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectPre);

        if (this.$route.query.IsShowAnswer != null) {
            this.IsShowAnswer = (this.$route.query
                .IsShowAnswer as unknown) as boolean;
        }

        this.getRedisData();
        this.RepliedCount = this.replies?.length;

        this.GetAllQuestions();
        this.UnRepliedCount = Number(this.count) - this.RepliedCount;
    }

    async GetAllQuestions() {
        this.paperQuestions.forEach((p: WrongTopicInfo) => {
            let index = 0;
            const qtlist = new Array<QuestionDetailInfo>();
            p.question.forEach((x: any) => {
                if (this.wrongTopicViewOnlyError == 1) {
                    let stuAnswerStr = x.stuAnswer;
                    if (
                        x.stuAnswer != null &&
                        x.stuAnswer != '' &&
                        x.stuAnswer.length > 1
                    ) {
                        const lst: Array<string> = x.stuAnswer.sort();
                        stuAnswerStr = lst.join();
                    }

                    if (stuAnswerStr == x.corectAnswer) {
                        //p.question.splice(index, 1);
                        this.RepliedCount--;
                    } else {
                        qtlist.push(x);
                    }
                } else {
                    this.Questions.push(x);
                    qtlist.push(x);
                }
                index++;
            });
            p.question = qtlist;
        });
        if (this.wrongTopicViewOnlyError == 1) {
            this.paperQuestions.forEach((p: WrongTopicInfo) => {
                p.question.forEach((x: any) => {
                    x.rowsIndex = this.rowsIndex++;
                });
            });
        }
    }
    handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }
    async handlerReplies() {
        this.replies
            .filter(x => x != null)
            .forEach(x => {
                if (x.answer != '') {
                    if (x.subjectType == this.Enum.questionType.Muti) {
                        if (x.answer.indexOf(',') == -1)
                            x.answer = ((x.answer as unknown) as Array<
                                string
                            >).join(',');
                    }
                }
            });
    }
    @Loading(LoadingHandPaper)
    async handIn() {
        if (
            this.replies.filter(x => x != null && x.answer != '').length ==
            0
        ) {
            this.notifier.warn('您未开始做题,不能交卷');
            return;
        }
        await this.handlerReplies();

        const data = await this.repository.reviewRepository.WrongTopicInfo(
            this.replies.filter(x => x != null && x.answer != ''),
            this.paperQuestions[0].knowledgePointId
        );
        this.notifier.success('交卷成功');

        Storage.Instance.set(WrongTopicReportReturn, {
            questions: this.Questions,
            wrongTopicInfoReturn: data,
        });

        await this.navigator.redirect(ReviewWrongTopicReport, '', {
            paperRecordsId: this.paperRecordsId,
        });
    }
    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);

        this.redirectPre();
    }
    redirectPre() {
        this.navigator.redirect(
            Boolean(this.IsShowHand) == false
                ? ReviewWrongTopicQuestionView
                : ReviewWrongQuestionReply,
            '',
            {
                paperRecordsId: this.paperRecordsId,
                IsShowAnswer: this.IsShowAnswer,
            }
        );
    }
}
