

















































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
    PaperGroupInfo,
    SubjectInfo,
    SubjectRedisInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import { LoadingData, LoadingMsg } from '@/domain/loadingText';
import { RouteName } from 'ea-router';
import {
    PracticeExamList,
    PracticeExamDetail,
    PracticeList,
} from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
import Storage from '@/infrastructure/Storage';
import { examsubjectInfo, PaperRecord } from '@/domain/getters';
@RouteName(PracticeExamList)
@Component({
    components:{
      navbar,
    }
})
export default class ExamList extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    tab = null;
    PaperGroupList = new Array<PaperGroupInfo>();
    Subjects = new Array<SubjectInfo>();
    SubjectsCount = 0;
    subjectName = '';
    isCompleted = false;
    get Type() {
    return this.$route.query.Type as string;
  }


  @Loading(LoadingMsg)
    async GetPaperGroupInfo(SubjectId: string) {
        const data = await this.repository.exampractice.GetPaperGroupInfo({
            bigType: '2',
            Type: this.Type,
            SubjectId: SubjectId,
        });
        this.PaperGroupList = data;
        this.isCompleted = true;
    }
    async GetSubjects() {
        const data = await this.repository.exampractice.GetSubjects(
            this.$store.getters.exam.profession?.id,
            '0'
        );
        this.Subjects = data;
        this.SubjectsCount = data.length;
        this.subjectName = data[0].subjectName;
    }

    async mounted() {
        await this.GetSubjects();
        const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(
            examsubjectInfo
        );
        if (subjectinfo == null) {
            await this.GetPaperGroupInfo(this.Subjects[0].id);
        } else {
            this.tab = subjectinfo.tab;
            await this.GetPaperGroupInfo(subjectinfo.subjectId);
        }
    }
    async redirectDetail(groupId: string, subjectId: string) {
      Storage.Instance.clear(PaperRecord);
      const data = await this.repository.exampractice.IsShopping({
        subjectid: subjectId,
        relationType: this.Type,
      });
      const subjectinfo = new SubjectRedisInfo();
      subjectinfo.subjectId = subjectId;
      subjectinfo.tab = this.tab;
      subjectinfo.Type = this.Type;
      Storage.Instance.set(examsubjectInfo, subjectinfo);

      if (data.result == true) {
        this.navigator.redirect(PracticeExamDetail, '', {
          groupId: groupId,
          Type: this.Type
        });
      } else {
        this.notifier.success('没有权限！');
        return;
      }
    }
    async changeSubject(Id: string) {
        await this.GetPaperGroupInfo(Id);
    }
    practiceList() {
        this.navigator.redirect(PracticeList);
    }
}
