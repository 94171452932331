


















































































































































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import AppraiseComponent from '@/components/course/CourseAppraise.vue';
import VideoChapterComponent from '@/components/course/VideoChapter.vue';
import { Context, EnableProp, RouteName } from 'ea-router';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import {
    CourseVideo,
    UserPackageDetail,
    UserCourse,
    CourseDetail,
    MyInfo,
    UserLogin,
    VideoOfflineDownload,
} from '@/domain/views';
import {
    VideoResult,
    VideoRedirect,
} from '@/infrastructure/model/CourseVideoInfo';
import { Loading } from 'vuetify-extension';
import { BaseCourseInfo } from '@/infrastructure/model/CourseIntroduceInfo';
import SimpleNavigation, { Navigation } from '@/infrastructure/navigation';
import { Notification } from 'vuetify-extension';
import DeviceScreen from '@/infrastructure/native/Screen';
import store from '@/store';
import { LoadingMsg } from '@/domain/loadingText';
import Aliplayer from '@/components/videoPlayer/Aliplayer.vue';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { MyVideoWatchInput,InputsModel } from '@/infrastructure/model/CourseAddOrEditQuery';
import Storage from '@/infrastructure/Storage';
import { ChapterId, SoruceType } from '@/domain/getters';
@RouteName(CourseVideo)
@Context('videoId', 'courseId')
@EnableProp()
@Component({
    components: {
        Aliplayer,
        AppraiseComponent,
        VideoChapterComponent,
        navbar,
    },
})
export default class VideoPage extends Vue {
    @Prop() readonly videoId!: string;
    @Prop() readonly courseId!: string;

    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    @Ref('player') readonly player!: any;
    @Ref('checkboxGroup') readonly checkboxGroup!: any;

    videoPlayInfo = new VideoResult();
    baseCourseInfo = new BaseCourseInfo();
    myVideoWatchInput = new MyVideoWatchInput();
    sheet = false;
    addAppraise = false;
    submitCount = 0;
    rating = 5;
    flag = true;
    AppraiseCotent = '';
    isVideoShow = false;
    CourseVideoLists = new Array<InputsModel>();
    result = new Array<InputsModel>();
    checkedS = true;

    Enum = {
        VideoRedirect: VideoRedirect,
    };
    courseVideoId = '';
    posterImg = require('@/assets/images/novideo.png');
    playerOptions = {
        controls: true,
        loop: false,
        preload: 'auto',
        fluid: true,
        poster: this.posterImg,
        playbackRates: [0.5, 1, 1.5, 2],
    };
    get needLandScape() {
        return this.videoPlayInfo.width >= this.videoPlayInfo.height;
    }
    get childid() {
        return this.$route.query.childid as string;
    }
    get packageName() {
        return this.$route.query.packageName as string;
    }
    get id() {
        return this.$route.query.id as string;
    }
    get package() {
        return this.$route.query.package;
    }
    get type() {
        return Number(this.$route.query.type);
    }
    get saleType() {
        return this.$route.query.saleType;
    }
    get bqCode() {
        return this.$route.query.bqCode;
    }
    ToUserPackageDetail() {
        switch (this.type) {
            case this.Enum.VideoRedirect.FromMyPackage:
                this.navigator.redirect(UserPackageDetail, {
                    id: this.package,
                    packageName: this.packageName,
                });
                break;
            case this.Enum.VideoRedirect.FromMyCourse:
                this.navigator.redirect(UserCourse);
                break;
            case this.Enum.VideoRedirect.fromMyInfo:
                this.navigator.redirect(MyInfo);
                break;
            case this.Enum.VideoRedirect.FromCourseDetail:
                this.navigator.redirect(
                    CourseDetail,
                    {
                        courseId: this.package,
                        saleType: this.saleType,
                    },
                    { bqCode: this.bqCode }
                );
                break;
            default:
                this.navigator.back();
                break;
        }
    }

    requestFullScreen() {
        if (this.needLandScape) {
            DeviceScreen.setLandscapePrimary();
        }
    }
    cancelFullScreen() {
        DeviceScreen.setPortraitPrimary();
    }
    end() {
        (this.$refs.videoChapter as VideoChapterComponent).NextPlay(
            this.courseVideoId
        );
    }

    async GetCourseIntroduceInfo() {
        const data = await this.repository.course.GetCourseIntroduceInfo({
            courseId: this.courseId,
            saleType: '0',
        });
        this.baseCourseInfo = data.baseCourseInfo;
    }

    async GetCourseVideoInfo(videoId: string, vip: string) {
        if (vip !== '1') {
            Notification.warn('您没有购买课程无法播放');
            return;
        }
        this.videoPlayInfo = this.videoPlayInfo = await this.repository.course.GetVideo(
            {
                Id: videoId,
            }
        );
        const sourceType = Storage.Instance.get<number>(SoruceType);
        this.myVideoWatchInput = await this.repository.courseAppraise.GetVideoProgress(
            videoId
        );
        if (!this.videoPlayInfo) return;
        await this.$nextTick();
        this.player.initload(
            this.videoPlayInfo.videoId,
            this.videoPlayInfo.playAuth,
            sourceType == 1 ? this.myVideoWatchInput.progress : 0
        );
        this.AddCourseStudyRecord(videoId);
    }

    AddVideoProgress(progress, videoId) {
        if (!store.getters.isLogin) return;
        const chapterId = Storage.Instance.get<string>(ChapterId);
        this.repository.courseAppraise.AddVideoProgress({
            CourseId: this.courseId,
            ChapterId: chapterId == null ? '0' : chapterId,
            VideoId: videoId,
            Progress: progress,
        });
    }

    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
        this.sheet = true;
        this.courseVideoId = this.videoId;
        await this.GetCourseIntroduceInfo();

        if (this.existedVideoId()) {
            await this.GetCourseVideoInfo(this.videoId, '1');
        }
    }
    UploadIsOver(videoId: string, vip: string) {
        Storage.Instance.set(SoruceType, 3);
        this.courseVideoId = videoId;
        this.GetCourseVideoInfo(videoId, vip);
    }

    AddCourseStudyRecord(videoId: string) {
        if (!store.getters.isLogin) return;
        this.repository.user.AddCourseStudyRecord({
            courseId: this.courseId,
            videoId: videoId,
        });
    }

    addAppraiseClick() {
        this.submitCount = 0;
        this.addAppraise = true;
    }
    catalogueClick() {
        this.addAppraise = false;
    }
    sheetClick() {
        if (this.sheet == false) this.sheet = true;
        else this.sheet = false;
        this.submitCount = 0;
    }
    appraise() {
        if (!this.AppraiseCotent) {
            Notification.warn('请输入评价内容');
            return false;
        }
        if(this.flag) {
            this.AddOrEdit();

        }else {
            Notification.warn('操作频繁，请稍后发表评价');
        }
    }
    NoLoginRedirect() {
        if (!store.getters.isLogin) {
            SimpleNavigation.Instance.redirect(UserLogin);
        }
    }

    @NeedLogin
    async AddOrEdit() {
        console.log(this.submitCount);
        this.submitCount++;
        //增加五秒内不能重复评论的问题by-hody-2022-03-22
        if (this.submitCount <= 1) {
            const data = await this.repository.courseAppraise.AddOrEdit({
                CourseId: this.courseId,
                AppraiseCotent: this.AppraiseCotent,
                AppraiseLevel: this.rating,
            });
            if (data.success == true) {
                this.flag = false;
                setTimeout(() => {
                    this.flag = true;
                }, 5000);
                Notification.success(data.message);
            } else {
                Notification.warn(data.message);
            }
            this.sheet = false;
            this.AppraiseCotent = '';
        }
    }

    private existedVideoId() {
        const videoId = this.videoId;
        if (videoId === null) return false;
        if (videoId === undefined) return false;
        if (videoId === 'null') return false;
        if (videoId === '') return false;
        return true;
    }
    // 视频下载
    @Loading(LoadingMsg)
    async downloadList() {
        this.CourseVideoLists = await this.repository.courseAppraise.GetCourseVideoLists(
            this.courseId
        );
        this.isVideoShow = true;
        this.$nextTick(() => {
            this.checkboxGroup.toggleAll({
              checked: true,
              skipDisabled: true,
            });
        })

    }
    closeVideoShow() {
        this.isVideoShow = false;
    }
    // 单个选择
    toggle(index,status) {
        if(status == 3) return false;
        this.$refs.checkboxes[index].toggle();
    }
    checkGroupChange() {
        if(this.result.length === this.CourseVideoLists.length) {
            this.checkedS = true;
        }else {
            this.checkedS = false;
        }
    }
    // 开始下载
    @Loading(LoadingMsg)
    async downloading() {
        const data = await this.repository.courseAppraise.AddOrEditCacheVideo(
            {inputs: this.result }
        );
        if(data.success == true) {
            // 跳转到下载列表页
            await this.goDowLists()
            // this.navigator.redirect(VideoOfflineDownload, {
            //     courseId: this.courseId,
            // })
        }
    }
    // 下载列表
    async goDowLists() {
        this.navigator.redirect(VideoOfflineDownload, {
            courseId: this.courseId,
        })
    }
    // 全选触发
    checkAll() {
        this.checkedS = !this.checkedS;
        if(this.checkedS) {
            this.checkboxGroup.toggleAll({
              checked: true,
              skipDisabled: true
            });
        }else {
            this.checkboxGroup.toggleAll({skipDisabled: true});
        }
    }
    
}
