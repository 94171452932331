






























import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { PayRedirect, PaySuccess, UserOrders } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { OrderQueryInput, PayResultInfo, SheetModel } from '@/models/UserCourseResponse';
@RouteName(PayRedirect)
@Component({})
export default class PayRedirectPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    sheetModel = new PayResultInfo();
    IsShow = true;
    show = true;

    get orderno() {
        return this.$route.query.orderno as string;
    }

    get active() {
        return this.$route.query.active as string;
    }
    async mounted() {
      sessionStorage.setItem("active", this.active);
    }
    cancel() {
        this.navigator.redirect(UserOrders);
    }
    async confirm() {
        const input = new OrderQueryInput();
        input.OrderNo = this.orderno;
        this.sheetModel = await this.repository.CurriculumPay.OrderQuery(
            input
        );
        if (this.sheetModel.trade_state == 'SUCCESS')
            this.navigator.redirect(PaySuccess, {}, { orderno: this.orderno });
        else
            this.navigator.redirect(UserOrders);
    }
}
