import AxiosHttp from '@/infrastructure/http';
import { CourseContrastInfo } from '@/infrastructure/model/CourseContrastInfo';

export default class CourseContrastRepository {
    List(level: string) {
        const data = {
            examLevel: level,
        };
        return AxiosHttp.Instance.post<CourseContrastInfo[]>(
            '/CourseContrast/List',
            data
        );
    }
}
