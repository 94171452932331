import InstallRepository from '@/infrastructure/repository/InstallRepository';
import { SlideRepository } from '@/infrastructure/repository/SlideRepository';
import ExamRepository from '@/infrastructure/repository/ExamRepository';
import { CourseAppraiseRepository } from '@/infrastructure/repository/CourseAppraiseRepository';
import { CourseRepository } from '@/infrastructure/repository/CourseRepository';
import CourseContrastRepository from '@/infrastructure/repository/CourseContrastRepository';
import UserRepository from '@/infrastructure/repository/UserRepository';
import ProductRepository from '@/infrastructure/repository/ProductRepository';
import ExamPracticeRepository from '@/infrastructure/repository/ExamPracticeRepository';
import ChapterPracticeRepository from '@/infrastructure/repository/ChapterPracticeRepository';
import ReviewRepository from '@/infrastructure/repository/ReviewRepository';
import CurriculumPayRepository from '@/infrastructure/repository/CurriculumPayRepository';
export default class Repositories {
    install: InstallRepository = new InstallRepository();
    slide: SlideRepository = new SlideRepository();
    exam: ExamRepository = new ExamRepository();
    courseAppraise: CourseAppraiseRepository = new CourseAppraiseRepository();
    course: CourseRepository = new CourseRepository();
    courseContrast: CourseContrastRepository = new CourseContrastRepository();
    user: UserRepository = new UserRepository();
    product: ProductRepository = new ProductRepository();
    exampractice: ExamPracticeRepository = new ExamPracticeRepository();
    chapterPractice: ChapterPracticeRepository = new ChapterPracticeRepository();
    reviewRepository: ReviewRepository = new ReviewRepository();
    CurriculumPay: CurriculumPayRepository = new CurriculumPayRepository();
}
