















import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { Rule } from '@/infrastructure/rules';
import {  Feedback ,MyInfo} from '@/domain/views';
import Repositories from '@/infrastructure/repository';

import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(Feedback)
@Component({
    components: {
        navbar,
    },

})
export default class FeedbackComponents extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    @Inject(Rules) rules: Rule;
    MyInfo(){
        this.navigator.redirect(MyInfo, null);

    }
    mounted() {
        BackRouteConfig.addBackEvent(this.MyInfo);
    }

}
