export class CourseIntroduceInfoQuery {
    courseId!: string;
    saleType!: string;
}

export class CourseIntroduceInfoResult {
    baseCourseInfo!: BaseCourseInfo;
    baseData!: Array<ServiceBaseDateInfo>;
}

export class BaseCourseInfo {
    courseName!: string;
    courseSellingPointList!: Array<string>;
    favourablePrice!: number;
    price!: number;
    validityEndDate!: string;
    video_Time!: string;
    courseImage!: string;
    video_Count!: number;
    courseContent: string;
}

export class ServiceBaseDateInfo {
    addTime!: string;
    addUserId!: string;
    code!: string;
    dataTypeId!: string;
    dataTypeName!: string;
    desc!: string;
    id!: string;
    isDelete!: number;
    name!: string;
    sort!: number;
    updateTime!: string;
    updateUserId!: string;
}
