






















































































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { UserCards, MyInfo, Course } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { LoadingData } from '@/domain/loadingText';
import { MyCardInfo } from '@/models/UserCourseResponse';
@RouteName(UserCards)
@Component({
    components: {
        navbar,
    },
})
export default class UserCardsPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    cardOutputs = new Array<MyCardInfo>();
    UsedCardOutputs = new Array<MyCardInfo>();
    ExpriedCardOutputs = new Array<MyCardInfo>();
    IsShowNoData = false;
    MyInfo() {
        this.navigator.redirect(MyInfo, null);
    }
    get UnUseCount() {
        return this.cardOutputs.filter(x => x.ifUse == 0).length;
    }
    async mounted() {
        await this.getMyCard();
    }
    timestampToTime(timestamp: string) {
        const date = new Date(Date.parse(timestamp.replace(/-/g, "/")));//时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + '年';
        const M =
            (date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1) + '月';
        const D =
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            '日';
        return Y + M + D;
    }
    @Loading(LoadingData)
    async getMyCard() {
        this.cardOutputs = await this.repository.user.MyCard('0');
        if (this.cardOutputs.length <= 0) this.IsShowNoData = true;
        else this.IsShowNoData = false;
    }
    @Loading(LoadingData)
    async GetUsedData() {
        this.UsedCardOutputs = await this.repository.user.MyCard('1');
        if (this.UsedCardOutputs.length <= 0) this.IsShowNoData = true;
        else this.IsShowNoData = false;
    }
    @Loading(LoadingData)
    async GetExpreidData() {
        this.ExpriedCardOutputs = await this.repository.user.MyCard('2');
        if (this.ExpriedCardOutputs.length <= 0) this.IsShowNoData = true;
        else this.IsShowNoData = false;
    }
    //去使用, 跳转到课程列表界面去
    ToUse() {
        this.navigator.redirect(Course, null, '');
    }
}
