import { Exam } from '@/models/Exam';
import { SelectionExam, SelectionLevel } from '@/domain/selection';
import { UserState } from '@/store/modules/user/index';
import config from '@/config';
import { WrongTopicReport } from '@/models/WrongTopicReport';

const selectedExam: any = (state: UserState): SelectionExam | null => {
    return state.exam;
};
const selectionExam: any = (state: UserState): string => {
    if (state.exam === null) return '暂未选择';
    return `${state.exam.level.name}${
        state.exam.profession ? state.exam.profession.name : ''
    }`;
};
const selectionExamLevel: any = (state: UserState): SelectionLevel | null => {
    if (state.exam === null) return null;
    return state.exam.level;
};
const isLogin: any = (state: UserState): boolean => {
    if (state.token === null || state.token.length <= 0) return false;
    return true;
};
const getExamList: any = (state: UserState): Exam[] => {
    return state.examList;
};
const isFirstUse: any = (state: UserState): boolean => {
    return !state.userVersion;
};
const usedLatestVersion: any = (state: UserState): boolean => {
    const version = state.userVersion;
    const appVersion = config.Version;
    return version === appVersion;
};

const wrongTopicReport: any = (state: UserState): WrongTopicReport | null => {
    return state.wrongTopicReport;
};
export const getter = {
    exam: selectedExam,
    selectionExam: selectionExam,
    examLevel: selectionExamLevel,
    isLogin: isLogin,
    examList: getExamList,
    isFirstUse: isFirstUse,
    usedLatestVersion: usedLatestVersion,
    wrongTopicReport:wrongTopicReport
};
