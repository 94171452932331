























































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigation } from '@/infrastructure/navigation';
import {
    QuestionBank,
    PracticeList,
    ReviewList,
    ChooseProfession,
} from '@/domain/views';
import { Navigator } from '@/domain/providers';
@RouteName(QuestionBank)
@Component({
    components: {},
})
export default class QuestionBankPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    isQuestionBank = true;

    chooseProfession() {
        this.navigator.redirect(ChooseProfession, '', {
            isQuestionBank: this.isQuestionBank,
        });
    }
    practiceList() {
        this.navigator.redirect(PracticeList);
    }
    reviewList() {
        this.navigator.redirect(ReviewList);
    }
}
