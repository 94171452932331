







































































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import {
    ReviewWrongQuestionReply,
    ReviewWrongTopicQuestionView,
} from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import { WrongTopicRecord } from '@/models/ReviewRecord';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {
    QuestionId,
    tabStartPos,
    WrongTopicQuestions,
    WrongTopicReplies,
    WrongTopicViewOnlyError,
} from '@/domain/getters';

@Context('subjectId', 'tabIndex', 'leftTabIndex')
@Component
@EnableProp()
export default class ReviewWrongTopicListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Prop() subjectId: string;
    @Prop() readonly tabIndex: number;
    @Prop() readonly leftTabIndex: number;
    @Inject(Repository) readonly repository!: Repositories;

    dataTipMsg = '';
    currentWrongType = 0;
    activeKey = this.leftTabIndex;
    practice = [
        { practiceList: '待消灭错题', WrongType: 0 },
        { practiceList: '已消灭错题 ', WrongType: 1 },
    ];

    dataList = new Array<WrongTopicRecord>();

    redirectReviewWrongTopic(
        id: string,
        wrongType: number,
        knowledgePointName: string
    ) {
        //添加tab启动位置
        Storage.Instance.set(
            tabStartPos,
            this.tabIndex * 10 + this.activeKey
        );
        this.clearStroage();
        if (wrongType == 0) {
            this.navigator.redirect(ReviewWrongQuestionReply, '', {
                knowledgePointId: id,
                wrongType: wrongType,
                knowledgePointName: knowledgePointName,
            });
        } else {
            this.navigator.redirect(ReviewWrongTopicQuestionView, '', {
                knowledgePointId: id,
                wrongType: wrongType,
                IsShowAnswer: false,
                knowledgePointName: knowledgePointName,
            });
        }
    }

    @Loading(LoadingMsg)
    async changeWrongType(wrongType: number, index: number) {
        //更新左侧tab标签记录
        this.activeKey = index;
        this.currentWrongType = wrongType;
        await this.GetWrongTopicData();
        this.$emit('ChangeLeftFlag', index);
    }

    @Loading(LoadingMsg)
    async GetWrongTopicData() {
        //初始化数据
        this.dataList = new Array<WrongTopicRecord>();
        this.dataTipMsg = '';

        const data = await this.repository.reviewRepository.WrongTopicList(
            this.subjectId,
            this.currentWrongType
        );
        this.dataList = data;

        if (this.dataList.length == 0) {
            this.dataTipMsg = '暂无数据...';
        }
    }

    async mounted() {
        this.currentWrongType = this.practice[this.leftTabIndex].WrongType;

        await this.GetWrongTopicData();
    }
    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(WrongTopicQuestions);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(WrongTopicReplies);
        Storage.Instance.clear(WrongTopicViewOnlyError);
    }
}
