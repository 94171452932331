










































































































































































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { AppGuide, PersonalInfo, RegistrationAgreement, UserLogin } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
@RouteName(RegistrationAgreement)
@Component({
    components: {
        navbar,
    },
})
export default class Registration extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;

    get type() {
        return this.$route.query.type as string;
    }
    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }

    redirectList() {
        if (this.type == '1')
            this.navigator.redirect(PersonalInfo, {}, { currentStep: 2 });
        else
            this.navigator.redirect(UserLogin,'',{phoneNumber:this.numPhone,sourcetype:1});
    }
}
