export class OrderSqInvoice {
    id: string;
    sqType: number;
    unitName: string;
    orderId: string;
    trueName: string;
    address: string;
    receiveName: string;
    telPhone: string;
    SqTypeName: string;
    sqStatus: number;
    sqStatusName:boolean;
    taxNo:string;
    invoiceNo:string;
    logisticsCompany:string;
    sendTime:string;
    invoiceType :number;
    invoiceRemark:string;
    unitTel:string;
    unitAddress:string;
    unitBankNo:string;
    unitBankName:string;
    isRemark:boolean;
    projectName:string;
    ProvinceId:string;
    ProvinceName:string;
    CityId:string;
    CityName:string;
}

export  class GetProviceListOuput{
    Key:string;
    Value:string;


}




