










































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import { ReviewQuestionsCollectQuestionView } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { QuestionsCollect } from '@/models/ReviewRecord';
import Repositories from '@/infrastructure/repository';
import { LoadingData } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { Questions, QuestionId, tabStartPos } from '@/domain/getters';

@Component
@Context('subjectId', 'tabIndex', 'leftTabIndex')
@EnableProp()
export default class ReviewQuestionsCollectListComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Prop() readonly subjectId: string;
    @Prop() readonly tabIndex: number;
    @Prop() readonly leftTabIndex: number;
    @Prop() readonly collectiontype: string;


    
    @Inject(Repository) readonly repository!: Repositories;

    activeKey = this.leftTabIndex;
    dateType = 1;
    qtList = new Array<QuestionsCollect>();
    dataTipMsg = '';
    ReviewTypeList = [
        { name: '近1个月', type: 1 },
        { name: '近1-2个月', type: 2 },
        { name: '近2-3个月', type: 3 },
        { name: '3个月前', type: 4 },
    ];

    async mounted() {
        await this.getQuestionList(
            this.ReviewTypeList[this.activeKey].type,
            this.activeKey
        );
    }

    async getQuestionList(type: number, index: number) {
        this.dateType = type;
        //更新左侧tab标签记录
        this.activeKey = index;
        this.$emit('ChangeLeftFlag', index);

        //初始化数据
        this.qtList = new Array<QuestionsCollect>();
        this.dataTipMsg = '';

        const data = await this.repository.reviewRepository.QuestionCollectedByDate(
            this.subjectId,
            type,
            this.collectiontype
        );

        this.qtList = data;
        if (this.qtList.length == 0) {
            this.dataTipMsg = '暂无数据...';
        }
    }

    reviewQuestionsCollectQuestionView(subType: string) {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Questions);

        const tabPos = this.tabIndex * 10 + this.activeKey;
        //添加tab启动位置
        Storage.Instance.set(tabStartPos, tabPos);
      console.log(88888);
       console.log(this.collectiontype);
        this.navigator.redirect(ReviewQuestionsCollectQuestionView, '', {
            subType: subType,
            subjectId: this.subjectId,
            dateType: this.dateType,
            collectiontype: this.collectiontype
        });
    }
}
