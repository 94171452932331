

































import { Vue, Component, Inject } from 'vue-property-decorator';
import { Course, MyInfo, QuestionBank } from '@/domain/views';
import { getRoute } from '@/infrastructure/extension/router';
import { Navigator } from '@/domain/providers';
import SimpleNavigation from '@/infrastructure/navigation';

@Component
export default class EntryLayoutComponent extends Vue {
    data() {
        return {
            active:Number(sessionStorage.getItem("active")) || 0,
            icon: {
                active: require('../../assets/images/icon_kecheng_1.png'),
                inactive: require('../../assets/images/icon_kecheng_2.png'),
                active1: require('../../assets/images/icon_tiku_1.png'),
                inactive1: require('../../assets/images/icon_tiku_2.png'),
                active2: require('../../assets/images/icon_wode_1.png'),
                inactive2: require('../../assets/images/icon_wode_2.png'),
            },
        };
    }

    onChang(index){
        sessionStorage.setItem("active", index);
    }

    @Inject(Navigator) navigator: SimpleNavigation;
    get CourseRoute() {
        return getRoute(Course);
    }

    get MyInfoRoute() {
        return getRoute(MyInfo);
    }

    get QuestionBankRoute() {
        return getRoute(QuestionBank);
    }

    isCurrent(route: any): boolean {
        return this.navigator.isCurrentRoute(route);
    }
}
