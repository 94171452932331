























































































































































































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    CourseVideo,
    UserCourse,
    UserPackageDetail,
    MyInfo,
} from '@/domain/views';
import { Repository, Navigator, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { UserCourseResponse } from '@/models/UserCourseResponse';
import { Navigation } from '@/infrastructure/navigation';
import { VideoRedirect } from '@/infrastructure/model/CourseVideoInfo';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import Storage from '@/infrastructure/Storage';
import { ChapterId, coursesubjectInfo, SoruceType } from '@/domain/getters';
import { CourseChapterInfo } from '@/infrastructure/model/CourseChapterInfo';

@RouteName(UserCourse)
@Component({
    components:{
        navbar,
    }
})
export default class UserCoursePageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    Enum = {
        VideoRedirect: VideoRedirect,
    };
    courseChapters = new Array<CourseChapterInfo>();
    loadCompleted = false;
    DataCount = 0;
    mounted() {
        //添加手动物理返   回键路由事件
        BackRouteConfig.addBackEvent(this.ToMyInfo);
        this.getCourse();
    }
    getImage(bqCode: string) {

        try{

            if (bqCode == null || bqCode == '')
                return require('../../assets/images/morentu@2x.png');
            else return require('../../assets/images/' + bqCode + '.png');
        }catch
        {
            return require('../../assets/images/morentu@2x.png');

        }

    }
    @Loading(LoadingMsg)
    async getCourse() {
        this.data = await this.repository.user.getCourse(
            this.$store.getters.exam
        );
        this.loadCompleted = true;
        console.log(this.data);
        this.DataCount = this.data.courses.length + this.data.packs.length;
    }

    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Number((((num / total) * 10000) / 100).toFixed(1));
    }

    ToMyInfo() {
        Storage.Instance.clear(ChapterId);
        Storage.Instance.clear(coursesubjectInfo);
        this.navigator.redirect(MyInfo);
    }

    packageDetail(id: string, packageName: string, chapterid: string) {
        Storage.Instance.set(ChapterId, chapterid);
        this.navigator.redirect(UserPackageDetail, {
            id: id,
            packageName: packageName,
        });
    }
    async VideoDetailNew(
        courseid: string,
        courseType: string,
        saleType: string
    ) {
        //读取第一个视频的chapterid以及 videoid
        Storage.Instance.set(SoruceType, 3);
        let videoId = '';
        let chapterId = '';
        const data = await this.repository.course.GetCourseChapterInfo({
            courseId: courseid,
            saleType: saleType,
        });
        this.courseChapters = data;
        if(data.length==0) {
            this.notifier.warn('该课程暂无学习视频');
            return;
        }
        if (saleType == '1') {
            //默认播放第一个,普通报班课程
            courseid = this.courseChapters[0].children[0].courseId;
            videoId = this.courseChapters[0].children[0].children[0].videoId;
            chapterId = this.courseChapters[0].children[0].id;
        } else {
            //默认播放第一个--点播视频
            courseid = this.courseChapters[0].courseId;
            videoId = this.courseChapters[0].children[0].videoId;
            chapterId = this.courseChapters[0].id;
        }
        Storage.Instance.set(ChapterId, chapterId);
        if (this.courseChapters != null && this.courseChapters.length > 0) {
            this.navigator.redirect(
                CourseVideo,
                {
                    videoId: videoId,
                    courseId: courseid,
                },
                {
                    id: chapterId,
                    type: this.Enum.VideoRedirect.FromMyCourse,
                }
            );
        }
    }

    VideoDetail(videoId: string, courseId: string, chapterId: string) {
        Storage.Instance.set(ChapterId, chapterId);
        Storage.Instance.set(SoruceType, 1);
        this.navigator.redirect(
            CourseVideo,
            {
                videoId: videoId,
                courseId: courseId,
            },
            {
                id: chapterId,
                type: this.Enum.VideoRedirect.FromMyCourse,
            }
        );
    }

    data: UserCourseResponse = new UserCourseResponse();
}
