



































import { Vue, Component, Inject,Ref } from 'vue-property-decorator';
import { cropperDemo } from '@/domain/views';
import { Context, EnableProp, RouteName } from 'ea-router';
import { Navigator, Notifier, Repository } from '@/domain/providers';
 import Repositories from '@/infrastructure/repository';

import CropperSub from '@/components/questionviews/CropperSub.vue';

@RouteName(cropperDemo)
@Context()
@EnableProp()
@Component({
    components: {
        CropperSub
    },
})


export default class CropperDemo extends Vue {
    @Inject(Repository) repository: Repositories;
    @Ref('CropperSub') readonly CropperSub!: any;

    avatarImage: string = "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=319223871,3949806150&fm=26&gp=0.jpg";
    isVideoShow:boolean = false;



    async chooseFile(event: any) {
        let that = this;
        const target = event.target ;
        const { files } = target;
        if (!files) return;
        const file: File = files[0];
        // 转换为base64传给裁切组件
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
            that.isVideoShow = true;
            setTimeout(() => {
                that.CropperSub.getImgUrl(String(reader.result))
            })
            
        }
    }
    async crpoImageCancel() {
        this.isVideoShow = false;
    }



}
