








































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { QuestionDetailInfo, Option } from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
import Storage from '@/infrastructure/Storage';
import { taskType } from '@/domain/getters';
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    optionsLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    get taskType() {
        return Storage.Instance.get<number>(taskType);
    }

    changedReplies() {
        this.question.stuAnswer = (this.question
            .result as unknown) as string;
        this.$emit('changedReplies');
    }
    replaceHtmlTag(content) {
        if (!content) {
            return '';
        }
        return content;
    }

    getOptionActiveClass(option: Option, question: QuestionDetailInfo) {
        if (this.taskType == 1 || this.isShowRighteAnswer == true) {
            return 'defaultClass';
        } else {
            const result = (this.question.result as unknown) as Array<
                string
            >;
            if (result.indexOf(option.id) >= 0) {
                return 'activeClass';
            }
            return 'defaultClass';
        }
    }
}
