















































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { RouteName,Context } from 'ea-router';
import { QuestionsCollectList,QuestionRecognition } from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
import questionsCollectList from '@/components/Review/questionsCollect/List.vue';
import NavigationBar from '@/components/Review/questionsCollect/NavigationBar.vue';
import { SubjectInfo } from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { tabStartPos,Collectiontype } from '@/domain/getters';

@RouteName(QuestionsCollectList)
@Component({
    components: { questionsCollectList, NavigationBar },
})
export default class QuestionsCollectListPage extends Vue {
    
    @Inject(Navigator) navigator: Navigation;

    @Inject(Repository)
    readonly repository!: Repositories;
    tabIndex = 0;
    leftTabIndex = 0;
    leftIndexAry = new Array<number>();

    subjectId = '';
    Subjects = new Array<SubjectInfo>();
    subjectCount = 0;
    dataType = 1;
    get tabPos() {
        return Storage.Instance.get<number>(tabStartPos);
    }
    get collectiontype() {
        return this.$route.query.collectiontype as string || Storage.Instance.get(Collectiontype) as string;
    }

    @Loading(LoadingMsg)
    async mounted() {
        //设置tab标签值
        if (this.tabPos != null && this.tabPos >= 0) {
            this.tabIndex = Math.floor(this.tabPos / 10);
            this.leftTabIndex = Math.floor(this.tabPos % 10);
        }
        await this.GetSubjects();
        //清除启动位置缓存
        Storage.Instance.clear(tabStartPos);
    }

    async GetSubjects() {
        const data = await this.repository.exampractice.GetSubjects(
            this.$store.getters.exam.profession?.id,
            '0'
        );

        this.Subjects = data;
        this.subjectCount = this.Subjects.length;

        if (this.tabIndex >= this.subjectCount) {
            this.tabIndex = this.subjectCount - 1;
        }
        this.subjectId = this.Subjects[this.tabIndex].id;

        //初始化左侧数组长度
        this.leftIndexAry = [];
        for (let i = 0; i < this.subjectCount; i++) {
            this.leftIndexAry.push(0);
        }
        //更新左侧标签记录
        this.leftIndexAry[this.tabIndex] = this.leftTabIndex;
    }

    async changeSubject(Id: string, index: number) {
        this.subjectId = Id;
        //更新顶部标签记录
        this.tabIndex = index;
        //更新左侧标签记录
        this.leftTabIndex = this.leftIndexAry[this.tabIndex];
    }

    ChangeLeftFlag(leftIndex: number) {
        this.dataType = leftIndex+1;
        this.leftIndexAry[this.tabIndex] = leftIndex;
    }
    // 录入试题
    add() {
        this.navigator.redirect(QuestionRecognition,'', {
            dataType: this.dataType
        });
    }
}
