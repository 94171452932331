




import { Vue, Component } from 'vue-property-decorator';
import { Toast } from 'vant';
@Component
export default class NotificationComponent extends Vue {
    message = '';
    duration = 2000;

    mounted() {
        Toast({
            message: this.message,
            duration: this.duration,
        });
    }

    Close() {
        this.removeElement();
    }
    removeElement() {
        this.$destroy();
        this.$el?.parentNode?.removeChild(this.$el);
    }
}
