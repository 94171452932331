
















































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Vue } from 'vue-property-decorator';

import { RouteName } from 'ea-router';
import {
    PracticeChapterTopicList,
    PracticeChapterQuestionView,
    PracticeChapterQuestionReply,
    PracticeChapterReport,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import {
    ChapterPractice,
    CourseChapterQuestionsDetail,
    ExaminationInfo,
    InsertChapterQuestionsDetailQuery,
} from '@/models/ChapterPractice';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestions,
    Questions,
    ChapterReplies,
    QuestionId,
    ChapterTrueQuestionIds,
    NoRepliedXChapterQuestionIds,
} from '@/domain/getters';
import { Dialog } from 'vant';
import { QuestionType } from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import { LoadingHandPaper } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(PracticeChapterTopicList)
@Component({
    components:{
        navbar,
    }
})
export default class TopicList extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    Enum = {
        questionType: QuestionType,
    };
    TipchapterQuestions = new Array<ExaminationInfo>();
    chapterQuestions = new Array<ChapterPractice>();
    replies = new Array<CourseChapterQuestionsDetail>();
    RepliedCount = 0;
    UnRepliedCount = 0;
    Index = 0;
    get chapterQuestionsId() {
        return this.$route.query.chapterQuestionsId as string;
    }
    get count() {
        return this.$route.query.count as string;
    }
    get relationType() {
        return this.$route.query.relationType as string;
    }
    get knowledgePointId() {
        return this.$route.query.knowledgePointId as string;
    }

    get IsShowHand() {
        return this.$route.query.IsShowHand as string;
    }
    get trueQuestionIds() {
        return Storage.Instance.get<Array<string>>(ChapterTrueQuestionIds);
    }

    get falseQuestionIds() {
        return Storage.Instance.get<Array<string>>(
            NoRepliedXChapterQuestionIds
        );
    }

    IsReplyed(id: string) {
        return (
            this.replies?.filter(
                x =>
                    x.questionId == id &&
                    x.stuAnswer != '' &&
                    x.stuAnswer != null
            ).length > 0
        );
    }
    GetRedisData() {
        this.chapterQuestions = Storage.Instance.get<Array<ChapterPractice>>(Questions);
        this.TipchapterQuestions = Storage.Instance.get<Array<ExaminationInfo>>(ChapterQuestions);
        if (this.trueQuestionIds != null ) {
            for (let x = 0; x < this.TipchapterQuestions.length; x++) {
                //只要从错题跳转过来才过滤反之不过滤
                this.TipchapterQuestions[x].chapterPracticeOutput =
                    this.TipchapterQuestions[x].chapterPracticeOutput.filter(k => this.trueQuestionIds.indexOf(k.id) < 0);
                this.TipchapterQuestions[x].chapterPracticeOutput.forEach((p: any) => {
                    this.Index++;
                    p.Index = this.Index;
                });
            }
        }
        else{
            this.TipchapterQuestions.forEach(x=>{
                x.chapterPracticeOutput.forEach(p=>{
                    this.Index++;
                    p.Index = this.Index;
                })
            })
        }
        this.replies = Storage.Instance.get<Array<CourseChapterQuestionsDetail>>(ChapterReplies);
    }

    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectPre);

        this.GetRedisData();
        this.RepliedCount = this.replies.filter(
            x => x.stuAnswer != '' && x.stuAnswer != null
        )?.length;
        this.UnRepliedCount =
            this.chapterQuestions.length - this.RepliedCount;
        if (this.trueQuestionIds != null && this.falseQuestionIds != null) {
            this.handTrueQuestions();
        }
    }

    handTrueQuestions() {
        this.chapterQuestions = this.chapterQuestions.filter(
            x => this.trueQuestionIds.indexOf(x.id) == -1
        );
        let q = 0;
        this.chapterQuestions.forEach(x => {
            q++;
            x.Index = q;
        });
        this.RepliedCount = this.replies.filter(
            x => this.trueQuestionIds.indexOf(x.questionId) == -1
        )?.length;
        this.UnRepliedCount = q - this.RepliedCount;
    }

    async handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }
    BuildHandData() {
        const queryData = new InsertChapterQuestionsDetailQuery();
        queryData.chapterQuestionsId = this.chapterQuestionsId;
        queryData.knowLedgePoint = this.knowledgePointId;
        queryData.relationType = Number(this.relationType);
        const queries = new Array<CourseChapterQuestionsDetail>();
        this.replies.forEach(x => {
            const courseChapterQuestionsDetail = new CourseChapterQuestionsDetail();
            courseChapterQuestionsDetail.questionId = x.questionId;
            courseChapterQuestionsDetail.type = x.type;
            if (
                x.type == this.Enum.questionType.Muti &&
                x.stuAnswer.length > 0
            ) {
                x.stuAnswer = ((x.stuAnswer as unknown) as Array<
                    string
                >).join(',');
                courseChapterQuestionsDetail.stuAnswer = x.stuAnswer;
                queries.push(courseChapterQuestionsDetail);
            } else if (x.type == this.Enum.questionType.Single) {
                courseChapterQuestionsDetail.stuAnswer = x.stuAnswer;
                queries.push(courseChapterQuestionsDetail);
            }
        });
        queryData.courseChapterQuestionsDetail = queries;
        return queryData;
    }

    @Loading(LoadingHandPaper)
    async handIn() {
        if (
            this.replies.filter(x => x != null && x.stuAnswer != '')
                .length == 0
        ) {
            this.notifier.warn('您未开始做题,不能交卷');
            return;
        }
        const data = await this.repository.chapterPractice.InsertChapterQuestionsDetail(
            this.BuildHandData()
        );
        this.notifier.success('交卷成功');
        console.log('确认');
        Storage.Instance.set(
            ChapterReplies,
            data.courseChapterQuestionsDetail
        );
        this.navigator.redirect(PracticeChapterReport, '', {
            count: this.count,
            chapterQuestionsId: this.chapterQuestionsId,
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
        });
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        this.navigator.redirect(
            Boolean(this.IsShowHand) == false
                ? PracticeChapterQuestionView
                : PracticeChapterQuestionReply,
            '',
            {
                relationType: this.relationType,
                knowledgePointId: this.knowledgePointId,
                chapterQuestionsId: this.chapterQuestionsId,
            }
        );
    }
    redirectPre() {
        let returnUrl = '';
        if (Boolean(this.IsShowHand) == false) {
            returnUrl = PracticeChapterQuestionView;
        } else {
            returnUrl = PracticeChapterQuestionReply;
        }
        this.navigator.redirect(returnUrl, '', {
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
            chapterQuestionsId: this.chapterQuestionsId,
        });
    }
}
