


















































































































































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { RouteName } from 'ea-router';
import {
    PracticeChapterTopicList,
    PracticeChapterQuestionReply,
    PracticeChapterList,
    PracticeChapterReport,
} from '@/domain/views';
import QuestionReplyComponent from '@/components/questionviews/ChapterQuestionView.vue';
import Analysis from '@/components/questionviews/ChapterAnalysis.vue';
import { Dialog } from 'vant';
import { Navigation } from '@/infrastructure/navigation';
import { LoadingHandPaper, LoadingTestPaper } from '@/domain/loadingText';
import {
    ChapterPractice,
    ChapterQuestionInfo,
    ChapterQuestionsDetailInfo,
    CourseChapterQuestionsDetail,
    ExaminationInfo,
    InsertChapterQuestionsDetailQuery,
} from '@/models/ChapterPractice';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestionInfos,
    NoRepliedXChapterQuestionIds,
    ChapterReplies,
    ChapterQuestions,
    Questions,
    QuestionId,
    ChapterTrueQuestionIds,
    chaptertaskType,
    sourcetype,
} from '@/domain/getters';
import {
    AnswerQuestionInputInfo,
    PaperQuestionInfo,
    QuestionDetailInfo,
    QuestionType,
} from '@/models/ExamPractice';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
        navbar,
    },
})
@RouteName(PracticeChapterQuestionReply)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    chapterQuestionInfo = new ChapterQuestionInfo();
    chapterQuestions = new Array<ExaminationInfo>();
    Questions = new Array<ChapterPractice>();
    question = new ChapterPractice();
    ChapterQuestionsResult = new ChapterQuestionsDetailInfo();
    currentQuestionIndex = 1;
    Index = 0;
    IsShowAnswer = false;
    IsRepeat = false;
    IsShowAnswerBtn = true;
    IsShowCollectioned = false;
    collectShow = false;
    isShowRighteAnswer = false;
    isEnable = false;
    Enum = {
        questionType: QuestionType,
    };
    unrepliedids = [];
    get relationType() {
        return this.$route.query.relationType as string;
    }
    get knowledgePointId() {
        return this.$route.query.knowledgePointId as string;
    }
    get isRestart() {
        return this.$route.query.isRestart as string;
    }
    get chidChapterQuestionId() {
        return this.$route.query.chidChapterQuestionId as string;
    }

    get chapterQuestionsRedis() {
        return Storage.Instance.get<Array<ExaminationInfo>>(
            ChapterQuestions
        );
    }
    get ChapterQuestionInfoRedis() {
        return Storage.Instance.get<ChapterQuestionInfo>(
            ChapterQuestionInfos
        );
    }
    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get replies() {
        return this.Questions.map(x => {
            return {
                questionId: x.id,
                stuAnswer: x.stuAnswer==null?"":x.stuAnswer,
                type: x.subjectType,
            };
        });
    }

    @Loading(LoadingTestPaper)
    async GetChapterPracticeList() {
        const Data = await this.repository.chapterPractice.GetChapterPracticeList(
            this.knowledgePointId,
            this.relationType
        );
        this.chapterQuestions = Data;
        if (this.isRestart == '1') {
            //replies-redis本地缓存
            this.chapterQuestions.forEach(k => {
                k.chapterPracticeOutput.forEach(p => {
                    const result = this.ChapterQuestionsResult.chapterQuestionsDetailOutput.filter(
                        b => b.questionId == p.id
                    )[0];
                    if (result == null || result.stuAnswer == '') {
                        if (this.unrepliedids.indexOf(p.id) == -1)
                            this.unrepliedids.push(p.id);
                    } else {
                        if (
                            k.subjectType ==
                            this.Enum.questionType.Comprehensive
                        ) {
                            p.result = result.result;
                            p.stuAnswer = (result.result as unknown) as string;
                        }
                        if (k.subjectType == this.Enum.questionType.Single)
                            p.stuAnswer = result.stuAnswer;
                        if (k.subjectType == this.Enum.questionType.Muti) {
                            p.result = result.result;
                            p.stuAnswer = (result.result as unknown) as string;
                        }
                    }
                });
                k.chapterPracticeOutput.sort(function(a: any, b: any) {
                    return b - a;
                });
            });
        }
        if (this.isRestart == '1') {
            Storage.Instance.set(QuestionId, this.unrepliedids[0]);
        } else {
            this.question = this.Questions[0];
        }
        Storage.Instance.set(ChapterQuestions, this.chapterQuestions);
    }

    async GetChapterQuestion() {
        const Data = await this.repository.chapterPractice.ChapterPractice({
            knowLedgePoint: this.knowledgePointId,
            relationType: this.relationType,
        });
        this.chapterQuestionInfo = Data;
        Storage.Instance.set(
            ChapterQuestionInfos,
            this.chapterQuestionInfo
        );
    }
    async changedReplies(question: ChapterPractice) {
        //单选作答实现自动切题
        const that = this;
        if (question.subjectType == QuestionType.Single) {
            setTimeout(async function() {
                await that.nextQuestion();
            }, 1 * 100);
        }
        Storage.Instance.set(ChapterReplies, this.replies);
        Storage.Instance.set(ChapterQuestions, this.chapterQuestions);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);

        if (
            this.chapterQuestionsRedis != undefined &&
            this.ChapterQuestionInfoRedis != undefined
        ) {
            await this.HandInLocalData();
        } else {
            if (this.isRestart == '1')
                await this.GetChapterQuestionsResult();
            await this.GetChapterQuestion();
            await this.GetChapterPracticeList();
            const questionId = Storage.Instance.get<string>(QuestionId);
            if (questionId != undefined && questionId != null) {
                this.chapterQuestions.forEach((x: any) => {
                    x.chapterPracticeOutput.forEach((x: any) => {
                        if (x.id == questionId) {
                            this.question = x;
                        }
                    });
                });
            } else {
                this.question = this.chapterQuestions[0].chapterPracticeOutput[0];
            }
        }
        await this.GetAllQuestions();
        this.currentQuestionIndex = this.question.Index;

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }

    async GetChapterQuestionsResult() {
        const data = await this.repository.chapterPractice.GetChapterQuestionsResult(
            this.chidChapterQuestionId
        );
        this.ChapterQuestionsResult = data;
    }

    async HandInLocalData() {
        this.chapterQuestionInfo = this.ChapterQuestionInfoRedis;
        this.chapterQuestions = this.chapterQuestionsRedis;
        if (this.questionId != undefined && this.questionId != null) {
            this.chapterQuestions.forEach((x: any) => {
                x.chapterPracticeOutput.forEach((x: any) => {
                    if (x.id == this.questionId) {
                        this.question = x;
                    }
                });
            });
        } else {
            this.question = this.chapterQuestions[0].chapterPracticeOutput[0];
        }
    }

    async GetAllQuestions() {
        this.chapterQuestions.forEach((x: any) => {
            x.chapterPracticeOutput.forEach((p: any) => {
                if (x.subjectType == this.Enum.questionType.Comprehensive) {
                    p.subjectTypeName = '案例分析题';
                } else if (x.subjectType == this.Enum.questionType.LunShu) {
                    p.subjectTypeName = '论述题';
                } else if (x.subjectType == this.Enum.questionType.Single) {
                    p.subjectTypeName = '单选题';
                } else if (x.subjectType == this.Enum.questionType.Muti) {
                    p.subjectTypeName = '多选题';
                }
                this.Index++;
                p.Index = this.Index;

                this.Questions.push(p);
            });
        });
        Storage.Instance.set(ChapterReplies, this.replies);
        Storage.Instance.set(Questions, this.Questions);
    }

    async preQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async nextQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex >= this.Questions.length) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async HandlerBtnController() {
        this.IsShowAnswer = false;
        this.IsShowAnswerBtn = true;
        this.IsRepeat = false;
        this.IsShowCollectioned = false;
        this.isShowRighteAnswer = false;
    }
    async HandlerChangeQuestion() {
        this.question = this.Questions.filter(
            x => x.Index == this.currentQuestionIndex
        )[0];
        Storage.Instance.set(QuestionId, this.question.id);
    }

    async handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }
    BuildHandData() {
        const queryData = new InsertChapterQuestionsDetailQuery();
        queryData.chapterQuestionsId = this.chapterQuestionInfo.id;
        queryData.knowLedgePoint = this.chapterQuestionInfo.knowLedgePointId;
        queryData.relationType = Number(this.relationType);
        const queries = new Array<CourseChapterQuestionsDetail>();
        console
        this.replies.forEach(x => {
            const courseChapterQuestionsDetail = new CourseChapterQuestionsDetail();
            courseChapterQuestionsDetail.questionId = x.questionId;
            courseChapterQuestionsDetail.type = x.type;
            if (
                x.type == this.Enum.questionType.Muti
                // && x.stuAnswer.length > 0
            ) {
                if(x.stuAnswer!=null&&x.stuAnswer!=""){
                    x.stuAnswer = ((x.stuAnswer as unknown) as Array<
                        string
                        >).sort().join(',');
                }

                courseChapterQuestionsDetail.stuAnswer = x.stuAnswer;
                queries.push(courseChapterQuestionsDetail);
            } else if (x.type == this.Enum.questionType.Single) {
                courseChapterQuestionsDetail.stuAnswer = x.stuAnswer;
                queries.push(courseChapterQuestionsDetail);
            }
        });
        queryData.courseChapterQuestionsDetail = queries;
        return queryData;
    }

    @Loading(LoadingHandPaper)
    async handIn() {
        if (
            this.replies.filter(x => x != null && x.stuAnswer != '')
                .length == 0
        ) {
            this.notifier.warn('您未开始做题,不能交卷');
            return;
        }
        const data = await this.repository.chapterPractice.InsertChapterQuestionsDetail(
            this.BuildHandData()
        );
        this.notifier.success('交卷成功');
        console.log('确认');
        Storage.Instance.set(
            ChapterReplies,
            data.courseChapterQuestionsDetail
        );
        this.navigator.redirect(PracticeChapterReport, '', {
            count: this.Questions.length,
            chapterQuestionsId: this.chapterQuestionInfo.id,
            relationType: this.chapterQuestionInfo.relationType,
            knowledgePointId: this.chapterQuestionInfo.knowLedgePointId,
        });
    }

    async viewAnswer() {
        this.isShowRighteAnswer = true;
        this.isEnable = true;
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowAnswerBtn = false;
            this.IsShowCollectioned = true;
            this.IsShowAnswer = true;
        } else {
            this.IsShowAnswer = true;
            this.IsRepeat = true;
            this.IsShowAnswerBtn = false;
        }
    }
    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }
    async collectClick() {
        const data = await this.GetIsCollection();
        if (data <= 0) {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        } else {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        }
    }
    viewQuestionItems() {
        this.navigator.redirect(PracticeChapterTopicList, '', {
            count: this.Questions.length,
            chapterQuestionsId: this.chapterQuestionInfo.id,
            IsShowHand: true,
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
        });
    }

    redirectList() {
        Dialog.confirm({
            title: '提示',
            message:
                '直接退出后，答题记录将不会被保存\n如需保存记录，请点击“交卷”按钮',
            confirmButtonText: '继续答题',
            cancelButtonText: '仍要退出',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {})
            .catch(() => {
                this.ConfirmRedirect();
            });
    }
    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(ChapterQuestionInfos);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        Storage.Instance.clear(ChapterReplies);
        Storage.Instance.clear(ChapterQuestions);
        Storage.Instance.clear(Questions);
        Storage.Instance.clear(chaptertaskType);
        Storage.Instance.clear(sourcetype);
    }
    ConfirmRedirect() {
        this.clearStroage();
        this.navigator.redirect(PracticeChapterList, '', {
            Type: this.relationType,
        });
    }
}
