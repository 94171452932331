export const Home = 'home';
export const Course = 'Course';
export const QuestionBank = 'QuestionBankIndex';
export const QuestionRecognition = 'questionRecognition';
export const DistinguishImg = 'distinguishImg';

export const ReviewList = 'ReviewList';
export const ReviewAnswerRecordList = 'ReviewAnswerRecordList';
export const ReviewAnswerRecordQuestionView = 'ReviewAnswerRecordQuestionView';
export const ReviewQuestionsCollectQuestionView =
    'ReviewQuestionsCollectQuestionView';
export const ReviewAdvancedList = 'ReviewAdvancedList';
export const ReviewReport = 'ReviewReport';
export const Feedback = 'Feedback';
export const ReviewAnswerRecordReport = 'ReviewAnswerRecordReport';
export const ReviewAnswerRecordChapterReport =
    'ReviewAnswerRecordChapterReport';
export const ReviewWrongTopicList = 'ReviewWrongTopicList';
export const ReviewWrongTopicAdvancedList = 'ReviewWrongTopicAdvancedList';
export const ReviewWrongTopicReport = 'ReviewWrongTopicReport';
export const ReviewWrongTopicTopicList = 'ReviewWrongTopicTopicList';
export const ReviewWrongTopicQuestionView = 'ReviewWrongTopicQuestionView';
export const ReviewWrongTopicErrorCorrection =
    'ReviewWrongTopicErrorCorrection';

export const QuestionsCollectList = 'QuestionsCollectList';
export const ReviewQuestionsCollectTopicList =
    'ReviewQuestionsCollectTopicList';
export const ReviewWrongQuestionReply = 'ReviewWrongQuestionReply';
export const MyInfo = 'MyInfo';
export const About = 'About';
export const SimpleVideoNew = 'SimpleVideoNew';



export const testDown = 'testDown';
export const CourseDetail = 'CourseDetail';
export const CourseContrast = 'CourseContrast';
export const Products = 'Products';
export const UserLogin = 'UserLogin';
export const CourseVideo = 'CourseVideo';
export const VideoPlay = 'VideoPlay';
export const UserCourse = 'UserCourse';
export const UserPackageDetail = 'UserPackageDetail';
export const AliPlayer = 'AliPlayer';
export const ChooseProfession = 'ChooseProfession';
export const QuestionsCollectAdvancedList = 'QuestionsCollectAdvancedList';
export const SlideDetail = 'SlideDetail';
export const UserOrders = 'UserOrders';
export const UserCards = 'UserCards';
export const UserInvoice = 'UserInvoice';
export const PersonalInfo = 'PersonalInfo';
export const ChangePhone = 'ChangePhone';
export const AppGuide = 'AppGuide';
export const Order = 'Order';
export const Paymentorder = 'Paymentorder';
export const Advisory = 'Advisory';
export const PracticeList = 'PracticeList';
export const cropperDemo = 'cropperDemo';
export const PracticeExamList = 'PracticeExamList';
export const PracticeExamDetail = 'PracticeExamDetail';
export const PracticeExamQuestionReply = 'PracticeExamQuestionReply';
export const PracticeExamTopicList = 'PracticeExamTopicList';
export const ChapterList = 'ChapterList';
export const PracticeExamReport = 'PracticeExamReport';
export const PracticeChapterReport = 'PracticeChapterReport';
export const PracticeChapterQuestionReply = 'PracticeChapterQuestionReply';
export const PracticeChapterList = 'PracticeChapterList';
export const PracticeReinforcementList = 'PracticeReinforcementList';
export const PracticeChapterTopicList = 'PracticeChapterTopicList';
export const PracticeExamErrorCorrection = 'PracticeExamErrorCorrection';
export const PracticeExamQuestionView = 'PracticeExamQuestionView';
export const PracticeChapterQuestionView = 'PracticeChapterQuestionView';
export const PracticeChapterErrorCorrection = 'PracticeChapterErrorCorrection';
export const PracticeExamSubjectiveItemReport =
    'PracticeExamSubjectiveItemReport';
export const PracticeChapterResultReport = 'PracticeChapterResultReport';
export const LoginPassword = 'LoginPassword';
export const LoginAuthenticationCode = 'LoginAuthenticationCode';
export const LoginCompleteInfo = 'LoginCompleteInfo';
export const LoginProfession = 'LoginProfession';
export const ReviewAnswerRecordSubjectiveItemReport =
    'ReviewAnswerRecordSubjectiveItemReport';
export const NotNetwork = 'NotNetwork';
export const MyAgreement = 'MyAgreement';
export const LoginWxBindPhoneNum = 'LoginWxBindPhoneNum';
export const LoginWxVerifyCode = 'LoginWxVerifyCode';
export const LoginWxCompleteInfo = 'LoginWxCompleteInfo';
export const Agreementaddress = 'Agreementaddress';
export const agreementcontent = 'agreementcontent';
export const InvoiceSuccess = 'InvoiceSuccess';
export const UserPerfectInformation = 'UserPerfectInformation';

export const Modifypassword = 'Modifypassword';
export const newpassword = 'newpassword';
export const verification = 'verification';
export const Bindphone = 'Bindphone';
export const Bindingsuccess = 'Bindingsuccess';
export const PaySuccess = 'PaySuccess';
export const PayRedirect = 'PayRedirect';
export const RegistrationAgreement = 'RegistrationAgreement';
export const Privacypolicy = 'Privacypolicy';
export const VideoOfflineDownload = 'VideoOfflineDownload';
export const BatchDel = 'BatchDel';

