



































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Context, EnableProp, RouteName } from 'ea-router';
import { Advisory, CourseDetail } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
@RouteName(Advisory)
@Context('saleType', 'courseId')
@EnableProp()
@Component({
    components: {
        navbar,
    },
})
export default class OrderPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    @Prop() courseId: string;
    @Prop() saleType: string;
    get bqCode() {
        return this.$route.query.bqCode;
    }

    CourseDetail() {
        this.navigator.redirect(CourseDetail, { saleType: this.saleType, courseId: this.courseId },{ bqCode: this.bqCode });
    }


}
