







































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Home } from '@/domain/views';
import { Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import Weatherforecast from '@/models/weatherforecast';
import { Loading } from 'vuetify-extension';
import { LoadingData } from '@/domain/loadingText';

@RouteName(Home)
@Component
export default class HomePageComponent extends Vue {
    @Inject(Repository) repository: Repositories;

    data: Weatherforecast[] = [];
    headers = [
        { text: '描述', value: 'summary' },
        { text: '温度(C)', value: 'temperatureC' },
        { text: '温度(F)', value: 'temperatureF' },
        { text: '时间', value: 'date' },
    ];
    pad = {
        padding: '0 0',
    };

    async created() {
        const boxWidth = document.documentElement.clientWidth;
        const padding = ((boxWidth - 16) % 59) / 2;
        this.pad = {
            padding: '0 ' + padding + 'px',
        };
        console.log(boxWidth);
    }

    mounted() {
        this.getData();
    }

    @Loading(LoadingData)
    async getData() {
        this.data = await this.repository.install.test();
    }
}
