













import {Vue,Inject, Prop, Component} from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Context, EnableProp } from 'ea-router';
import {SimpleVideoNew} from '@/domain/views';
import navbar from "@/components/navbar.vue";
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Navigator, Repository } from '@/domain/providers';
import SimpleNavigation, { Navigation } from '@/infrastructure/navigation';

import Repositories from '@/infrastructure/repository';


@RouteName(SimpleVideoNew)
@Context('VideoUrl','videoId', 'courseId','videoName')
@EnableProp()
@Component({
  components: {
    navbar,
  },
})
export default class SimpleVideoPage extends Vue {
  @Prop() readonly VideoUrl: string;
  @Prop() readonly videoId: string;
  @Prop() readonly courseId: string;
  @Prop() readonly videoName: string;
  @Inject(Navigator) navigator: Navigation;
  @Inject(Repository) readonly repository!: Repositories;

  player:any = null;
  timeNum:number = 0; // timeupdate 250ms执行一次，初始定义为0，进来就更新一次进度，之后每次定义为480,2分钟更新一次进度
  Progress:number = 0;
  async mounted() {
    //添加手动物理返回键路由事件
      BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
      await this.createPlayer();
      await this.GetVideoProgressHc();
  }
  // 创建播放器 'file://'+
  async createPlayer() {
    let that=this;
    if(!that.player){
    	that.player = new window.plus.video.VideoPlayer("currentVideo", {
        'src': 'file://' + that.VideoUrl,//视频资源地址,支持本地地址,也支持网络地址及直播流
        'controls': true, //是否显示默认播放控件
        'autoplay': true,//是否自动播放
        'show-progress': true,//是否显示播放进度
        'show-fullscreen-btn': true,//是否显示全屏按钮
        'show-play-btn': true,//是否显示视频底部控制栏的播放按钮
        'show-center-play-btn': true,//是否显示视频中间的播放按钮
        'enable-progress-gesture': true,//是否开启控制进度的手势
        'objectFit': "fill",//当视频大小与video容器大小不一致时,视频的表现形式contain(包含),fill(填充),cover(覆盖),默认值为contain.
        'poster': '',//视频封面的图片网络资源地址
        'top':  '45px',//VideoPlayer控件左上角的垂直偏移量
        'left': '0px',//VideoPlayer控件左上角的水平偏移量
        'width': '100%',//VideoPlayer控件的宽度
        'height': '5.62667rem',//VideoPlayer控件的高度
        'position': 'static'//VideoPlayer控件在Webview窗口的布局模式,static静态布局模式;absolute绝对布局模式,默认static
    })
      
      
      // 监听播放进度更新事件
	    that.player.addEventListener('timeupdate', function(e){
	    	  // alert(JSON.stringify(e));
	    	  // alert(e.detail.currentTime);
          that.Progress = e.detail.duration
          if(that.timeNum <= 0) {
            that.AddVideoProgressHc(e.detail.currentTime);
          }else {
            that.timeNum--
          }
	    }, false);

      // 视频暂停事件
	    that.player.addEventListener('pause', function(e){
          that.AddVideoProgressHc(that.Progress);
	    }, false);

      // 视频结束事件
	    that.player.addEventListener('ended', function(e){
          that.AddVideoProgressHc(that.Progress);
	    }, false);
    }
  }

  // 播放进度上报
  async AddVideoProgressHc(progress) {
      this.timeNum = 480;
      // alert(progress)
      this.repository.courseAppraise.AddVideoProgressHc({
          CourseId: this.courseId,
          VideoId: this.videoId,
          ChapterId: '0',
          Progress:  Math.floor(progress),
      });
  }
  // 播放进度获取
  async GetVideoProgressHc() {
    const data = await this.repository.courseAppraise.GetVideoProgressHc(
      this.videoId
    );
    if(data && data.progress>0) {
      await this.seekVideo(data.progress)
    }else {
      await this.seekVideo(0);
    }

  }
  // 跳转指定位置
  async seekVideo(progress:number) {
    if(this.player) {
      this.player.seek(progress);
      await this.playVideo();
    } 
  }
  // 全屏
  async fullscreenVideo(){
    if(this.player) this.player.requestFullScreen();
  }
  // 播放
	async playVideo() {
    if(this.player) {
      this.player.play();
      await this.fullscreenVideo();
    } 
  }
  
  ToUserPackageDetail() {
    this.navigator.back();
  }
  async beforeDestroy () { 
    if(this.player)this.player.close();
  }
}
