import SimpleNavigation from '@/infrastructure/navigation';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { NotNetwork } from '@/domain/views';

export default class HtmlPlusListener {
    private static _instance: HtmlPlusListener = new HtmlPlusListener();

    static get Instance(): HtmlPlusListener {
        return HtmlPlusListener._instance;
    }

    init() {
        //返回路配置由初始化
        BackRouteConfig.Init();

        const plus = window.plus;
        plus.key.addEventListener('backbutton', () => this.backButtonHandler());

        //添加网络信号监控
        document.addEventListener('netchange', () => this.onNetChange());
    }

    private backButtonHandler() {
        BackRouteConfig.BackRedirect();
    }

    //添加网络监测，如果没有信号，则跳转到无信号提示页面
    private onNetChange() {
        const plus = window.plus;

        const nt = plus.networkinfo.getCurrentType();
        switch (nt) {
            case plus.networkinfo.CONNECTION_ETHERNET:
            case plus.networkinfo.CONNECTION_WIFI:
                //alert('Switch to Wifi networks!');
                //console.log('wifi网络');
                break;
            case plus.networkinfo.CONNECTION_CELL2G:
            case plus.networkinfo.CONNECTION_CELL3G:
            case plus.networkinfo.CONNECTION_CELL4G:
            case plus.networkinfo.CONNECTION_CELL5G:
                //alert('Switch to Cellular networks!');
                //console.log('移动网络');
                break;
            default:
                //console.log('网络异常提醒');
                SimpleNavigation.Instance.redirect(NotNetwork);
                break;
        }
    }
}
