


















































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    LoginWxVerifyCode,
    LoginWxCompleteInfo,
    LoginWxBindPhoneNum,
    MyInfo,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Repositories from '@/infrastructure/repository';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Dialog } from 'vant';
import Storage from '@/infrastructure/Storage';
import { WxUserInfo } from '@/models/WxUserInfo';
import { OpenWxUserInfo } from '@/domain/getters';
import { WxCheckSmsCodeBindRequestData } from '@/models/UserRegisterRequestData';
import { Login, MatchExam } from '@/domain/actions';

@RouteName(LoginWxVerifyCode)
@Component
export default class WxVerifyCodePage extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;

    showKeyboard = true;
    inputSmsValue = '';
    phoneNumberShow = '';
    smsSendBtnTxt = '获取验证码';
    isShowSendSmsBtn = true;
    time = 60;
    smsCodeLen = 6;

    rqData = new WxCheckSmsCodeBindRequestData();
    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }
    get wxUser() {
        return Storage.Instance.get<WxUserInfo>(OpenWxUserInfo);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.backWxBind);

        this.phoneNumberShow =
            this.numPhone.substr(0, 3) +
            ' ' +
            this.numPhone.substr(3, 4) +
            ' ' +
            this.numPhone.substr(7, 4);

        await this.SendSms();
    }

    //发送短信
    @Loading(LoadingMsg)
    async SendSms() {
        const response = await this.repository.user.SendSmsInfo(
            this.numPhone
        );
        if (response.success) {
            this.isShowSendSmsBtn = false;
            this.notifier.success(response.message, 2000);
            this.DescTime();
        } else {
            this.notifier.error(response.message);
        }
    }

    //检查验证码输入
    async InputSmsCode(key) {
        this.inputSmsValue = (this.inputSmsValue + key).slice(
            0,
            this.smsCodeLen
        );
        if (this.inputSmsValue.length == this.smsCodeLen) {
            await this.BindUserAndCheck();
        }
    }
    DeleteSmsCode() {
        this.inputSmsValue = this.inputSmsValue.slice(
            0,
            this.inputSmsValue.length - 1
        );
    }

    //倒计时
    DescTime() {
        if (this.time <= 0) {
            this.isShowSendSmsBtn = true;
            this.smsSendBtnTxt = '重新获取验证码';
            this.time = 60;
        } else {
            setTimeout(this.DescTime, 1000);
            this.time--;
        }
    }

    //
    @Loading(LoadingMsg)
    async BindUserAndCheck() {
        this.rqData.headimgurl = this.wxUser.headimgurl;
        this.rqData.unionid = this.wxUser.unionid;
        this.rqData.openid = this.wxUser.openid;
        this.rqData.nickname = this.wxUser.nickname;
        this.rqData.sex = this.wxUser.sex;
        this.rqData.province = this.wxUser.province;
        this.rqData.country = this.wxUser.country;
        this.rqData.city = this.wxUser.city;
        this.rqData.TelphoneNum = this.numPhone;
        this.rqData.SmsCode = this.inputSmsValue;

        const response = await this.repository.user.WxCheckSmsCodeBind(
            this.rqData
        );

        if (response.success) {
            if (response.code == 1) {
                //原手机用户绑定微信成功，直接登录app
                await this.$store.dispatch(Login, {
                    token: response.message,
                });
                const data = await this.repository.user.getCurrentExamInfo();
                await this.$store.dispatch(MatchExam, data);
                this.notifier.success('登录成功!');
                this.navigator.redirect(MyInfo);
            } else {
                //手机号未注册用户，需要完善资料
                this.navigator.replace(LoginWxCompleteInfo, {
                    phoneNumber: this.numPhone,
                });
            }
        } else {
            //弹出错误提示信息
            Dialog.alert({
                title: '提示',
                message: response.message,
                confirmButtonText: '重新输入',
                confirmButtonColor: '#3f7efd',
            }).then(r => {
                //手机号已经绑定微信，需要重新输入
                if (response.code == 2) {
                    this.backWxBind();
                } else {
                    this.inputSmsValue = '';
                    this.showKeyboard = true;
                }
            });
        }
    }

    //返回绑定手机页面
    backWxBind() {
        this.navigator.replace(LoginWxBindPhoneNum, {
            phoneNumber: this.numPhone,
        });
    }
}
