import {
    Login,
    Logout,
    MatchExam,
    RememberVersion,
    SelectExam,
} from '@/domain/actions';
import { SelectionExam } from '@/domain/selection';
import { UserCurrentExamInfo } from '@/models/UserCurrentExamInfo';
import {
    ChangeExam,
    ClearUserAuthData,
    SetUserData,
    UpdateUserVersion,
} from '@/domain/mutations';
import { UserLoginResponse } from '@/models/UserLoginResponse';
import { Exam, ExamLevel } from '@/models/Exam';
import Repositories from '@/infrastructure/repository';
import store from '@/store';
import config from '@/config';

const repository = new Repositories();

function getExamByLevelWithProfession(
    data: UserCurrentExamInfo,
    exam: Exam,
    level: ExamLevel
): SelectionExam | null {
    if (level.professions) {
        for (
            let professionIndex = 0;
            professionIndex < level.professions.length;
            professionIndex++
        ) {
            const profession = level.professions[professionIndex];
            if (profession.id === data.profession) {
                const data = {
                    id: exam.id,
                    name: exam.name,
                    type: exam.type,
                    level: level,
                    profession: profession,
                };
                return data;
            }
        }
    }
    return null;
}

function getExamByLevel(
    data: UserCurrentExamInfo,
    exam: Exam,
    level: ExamLevel
): SelectionExam | null {
    if (level.id === data.level) {
        return {
            id: exam.id,
            name: exam.name,
            type: exam.type,
            level: level,
        };
    }
    return null;
}

function getMatchExamByExam(
    data: UserCurrentExamInfo,
    exam: Exam
): SelectionExam | null {
    for (let levelIndex = 0; levelIndex < exam.levels.length; levelIndex++) {
        const level = exam.levels[levelIndex];
        if (data.hasProfession) {
            const result = getExamByLevelWithProfession(data, exam, level);
            if (result) return result;
        } else {
            const result = getExamByLevel(data, exam, level);
            if (result) return result;
        }
    }
    return null;
}

function getMatchExam(
    data: UserCurrentExamInfo,
    exams: Exam[]
): SelectionExam | null {
    for (let examIndex = 0; examIndex < exams.length; examIndex++) {
        const exam = exams[examIndex];
        const result = getMatchExamByExam(data, exam);
        if (result) return result;
    }
    return null;
}

export const actions = {
    async [SelectExam](context: any, exam: SelectionExam) {
        const data: UserCurrentExamInfo = {
            type: exam.type,
            level: exam.level.id,
            profession: exam.profession ? exam.profession.id : '',
            hasProfession: !!exam.profession,
        };
        if (store.getters.isLogin) {
            await repository.user.changeCurrentExam(data);
        }
        context.commit(ChangeExam, exam);
    },
    [Login](context: any, data: UserLoginResponse) {
        context.commit(SetUserData, data);
    },
    [Logout](context: any) {
        context.commit(ClearUserAuthData);
    },
    async [MatchExam](context: any, data: UserCurrentExamInfo) {
        if (context.getters.examList.length <= 0) {
            context.state.examList = await repository.exam.List();
        }

        const examList = context.state.examList.filter(
            (x: Exam) => x.type === data.type
        );

        const matchExam = getMatchExam(data, examList);
        if (!matchExam) return;
        context.commit(ChangeExam, matchExam);
    },
    [RememberVersion](context: any) {
        const version = config.Version;
        context.commit(UpdateUserVersion, version);
    },
};
