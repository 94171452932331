












































































































































































































































































































































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { Rule } from '@/infrastructure/rules';
import { InvoiceSuccess, UserInvoice, UserOrders } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import {GetProviceListOuput, OrderSqInvoice} from '@/models/OrderSqInvoice';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
@RouteName(UserInvoice)
@Component({
    components: {
        navbar,
    },
    data() {
        return {
            radio: '1',
        };
    },
})
export default class MyOrderPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    @Inject(Rules) rules: Rule;
    Input = new OrderSqInvoice();
    isAddSpecial = '1';
    SqType = '1';
    phoneInvalid = true;
    provinceShow = false;
    cityShow = false;
    provincelist=new Array<GetProviceListOuput>();
   citylist=new Array<GetProviceListOuput>();
    mounted() {
        this.Input.invoiceType = 1;
        this.Input.orderId = this.orderid;
        this.LoadProvinceData();
    }

  async LoadProvinceData() {
    this.provincelist = await this.repository.user.GetProviceList(
    );
  }
  async changeProvince(ProvinceId) {
      console.log(ProvinceId);
    this.citylist = await this.repository.user.GetCityListByProviceId(ProvinceId);
  }
    /*单选框图标*/
    data() {
        return {
            radio: '1',
            activeIcon: require('../../assets/images/denglu_xuanzhong@2x.png'),
            inactiveIcon: require('../../assets/images/denglui_weixuanzhong@2x.png'),
        };
    }
    UserOrders() {
        this.navigator.redirect(UserOrders, null);
    }

    get orderid() {
        return this.$route.query.id as string;
    }

    get courseName() {
        return this.$route.query.courseName as string;
    }
    @Loading(LoadingMsg)
    async postInvoice() {
        if (
            (this.Input.unitName == null || this.Input.unitName == '') &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入单位名称');
            return;
        }
        if (
            (this.Input.taxNo == '' || this.Input.taxNo == null) &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入税号');
            return;
        }
        if (
            (this.Input.unitAddress == '' || this.Input.unitAddress == null) &&
            this.isAddSpecial == '2' &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入单位地址');
            return;
        }
        if (
            (this.Input.unitTel == '' || this.Input.unitTel == null) &&
            this.isAddSpecial == '2' &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入单位电话');
            return;
        }
        if (
            (this.Input.unitBankNo == '' || this.Input.unitBankNo == null) &&
            this.isAddSpecial == '2' &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入单位银行账号');
            return;
        }
        if (
            (this.Input.unitBankName == '' ||
                this.Input.unitBankName == null) &&
            this.isAddSpecial == '2' &&
            this.SqType == '1'
        ) {
            this.notifier.warn('请输入银行名称');
            return;
        }
        if (
            (this.Input.trueName == '' || this.Input.trueName == null) &&
            this.SqType == '2'
        ) {
            this.notifier.warn('请输入姓名');
            return;
        }
        if (this.Input.receiveName == '' || this.Input.receiveName == null) {
            this.notifier.warn('请输入收件人姓名');
            return;
        }

        if (!this.Input.ProvinceId) {
            this.notifier.warn('请选择省份');
            return;
        }
        if (!this.Input.CityId) {
            this.notifier.warn('请选择城市');
            return;
        }

        if (this.Input.address == '' || this.Input.address == null) {
            this.notifier.warn('请输入收件人地址');
            return;
        }
        if (this.Input.telPhone == '' || this.Input.telPhone == null) {
            this.notifier.warn('请输入收件人电话');
            return;
        }
        this.CheckPhone(this.Input.telPhone);
        if (this.phoneInvalid == false) {
            this.notifier.warn('请输入正确的收件人电话');
            return;
        }
        this.Input.sqType = Number(this.SqType);
        this.Input.invoiceType = Number(this.isAddSpecial);
        if (this.Input.sqType == 2) {
            this.Input.unitBankName = '';
            this.Input.unitBankNo = '';
            this.Input.unitTel = '';
            this.Input.unitAddress = '';
            this.Input.taxNo = '';
            this.Input.unitName = '';
            this.Input.invoiceType=1;//如果是个人开票，这里固定取1
        }
        if (this.Input.sqType == 1) {
            this.Input.trueName = '';
        }
        if (this.Input.invoiceType == 1) {
            this.Input.unitBankName = '';
            this.Input.unitBankNo = '';
            this.Input.unitTel = '';
            this.Input.unitAddress = '';
        }
        if (this.Input.isRemark) this.Input.invoiceRemark = this.courseName;

        const data = await this.repository.user.AddSqlInvoice(this.Input);
        if (data.success) {
            // this.notifier.success('申请成功');
            this.navigator.redirect(InvoiceSuccess);
        } else {
            this.notifier.success(data.message);
        }
    }
    CheckPhone(value) {
        const reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
            this.phoneInvalid = false;
        } else {
            this.phoneInvalid = true;
        }
    }
    changePtOrAdd(state: string) {
        this.isAddSpecial = state;
    }
    changeSqType(state: string) {
        this.SqType = state;
    }
    // 省份
    provinceConfirm(value) {
        this.Input.ProvinceName = value.value;
        this.Input.ProvinceId = value.key;
        this.provinceShow = false;
        this.changeProvince(value.key);
    }
    // 省份改变，清空已选择得城市
    provinceChange() {
        this.Input.CityId = '';
        this.Input.CityName = '';
    }
    // 城市
    citySelect() {
        if(!this.Input.ProvinceId) {
            this.notifier.warn('请先选择省份');
            return;
        }else {
            this.cityShow = true;
        }
    }
    cityConfirm(value) {
        this.Input.CityId = value.key;
        this.Input.CityName = value.value;
        this.cityShow = false;
    }

}
