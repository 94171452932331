



















































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { Rule } from '@/infrastructure/rules';
import {
    Bindphone,
    Bindingsuccess,
    PersonalInfo,
} from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { VerificationCodeInfo } from '@/models/UserCourseResponse';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(Bindphone)
@Component({
    components: {},
})
export default class InvoiceSuccessCom extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    @Inject(Rules) rules: Rule;
    mobilePhone = '';
    IsShowTimeDown = false;
    time = 60;
    isShowSendSmsBtn = true;
    smsSendBtnTxt = '获取验证码';
    phoneInvalid = true;
    SmsInvalid = true;
    smsCode = '';
    bindInfo = new VerificationCodeInfo();
    get phone() {
        return this.$route.query.phone as string;
    }


    get IsDisable() {
        if(this.mobilePhone!=''&&this.smsCode!='')
            return false;
        else
            return true;
    }
    ChangeInput(){
        this.CheckPhone(this.mobilePhone);
        if(this.phoneInvalid)
            //验证码符合验证规则通过
           this.isShowSendSmsBtn=false;
        else
            this.isShowSendSmsBtn=true;
    }
    mounted() {
        //大左滑
        BackRouteConfig.addBackEvent(this.redirectSmsCode);
    }
    redirectSmsCode() {
        this.navigator.redirect(PersonalInfo);
    }
    //获取手机验证码
    async getSmsCode() {
        if (this.phone == this.mobilePhone) {
            this.notifier.warn('请输入新的手机号码');
            return;
        }
        //如果当前的手机号码在数据库已经存在给出提示并且将获取验证码的按钮置灰todo
        const responseReg = await this.repository.user.CheckPhoneIsReg(
            this.mobilePhone
        );
        if (responseReg.success) {
            if (responseReg.code == 1) {
                //已经注册,
                this.notifier.warn('该手机号已被占用！');
                return;
            }
        }

        const response = await this.repository.user.SendSmsInfo(
            this.mobilePhone
        );
        if (response.success) {
            this.IsShowTimeDown = true;
            this.isShowSendSmsBtn = true;
            this.notifier.success(response.message, 2000);
            this.DescTime();
        } else {
            this.notifier.error(response.message);
        }
    }
    deletePassword() {
        this.smsCode = '';
    }
    deletePhone() {
        this.mobilePhone = '';
        this.isShowSendSmsBtn=true;
    }
    //倒计时
    DescTime() {
        if (this.time <= 0) {
            this.isShowSendSmsBtn = false;
            this.IsShowTimeDown = false;
            this.smsSendBtnTxt = '重新获取验证码';
            this.time = 60;
        } else {
            /*      this.smsSendBtnTxt = '获取验证码';*/
            setTimeout(this.DescTime, 1000);
            this.time--;
        }
    }
    CheckSmsCode(value) {
        const reg = /^\d{6}$/;
        if (!reg.test(value)) {
            this.SmsInvalid = false;
        } else {
            this.SmsInvalid = true;
        }
    }
    CheckPhone(value) {
        const reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
            this.phoneInvalid = false;
        } else {
            this.phoneInvalid = true;
        }
    }
    //更换绑定的手机号码
    async ModifyPhone() {
        if (this.mobilePhone == '' || this.mobilePhone == null) {
            this.notifier.warn('请输入手机号码');
            return;
        }
        if (this.smsCode == '' || this.smsCode == null) {
            this.notifier.warn('请输入验证码');
            return;
        }
        this.CheckPhone(this.mobilePhone);
        this.CheckSmsCode(this.smsCode);
        if (this.phoneInvalid == false) {
            this.notifier.warn('请输入正确的手机号');
            return;
        }
        if (this.SmsInvalid == false) {
            this.notifier.warn('请输入正确的验证码');
            return;
        }
        this.bindInfo.Code = this.smsCode;
        this.bindInfo.TelphoneNum = this.mobilePhone;
        const response = await this.repository.user.BindPhone(
            this.bindInfo
        );
        if (response.success) {
            this.navigator.redirect(Bindingsuccess);
        } else {
            this.notifier.error(response.message);
        }
    }
}
