export class ChapterQuestionsDetailInfo {
    chapterId: string;
    chapterName: string;
    dateTime: string;
    chapterQuestionsDetailOutput: Array<ChapterQuestionsDetailChildInfo>;
}

export class ChapterQuestionsDetailChildInfo {
    Index: number;
    chapterTiele: string;
    capterName: string;

    courseName: string;

    dateTime: string;

    count: number;

    whether: string;

    isWhether: number;

    collectionId: string;

    questionId: string;

    questionTextAnalysis: string;

    chapterQuestionsId: string;

    userId: string;

    bigQuestionId: string;

    smallQuestionId: string;

    stuAnswer: string;

    addTime: string;

    description: string;

    questionContent: string;

    number: number;

    subjectName: string;

    subjectType: string;

    option1: string;

    option2: string;

    option3: string;

    option4: string;

    option5: string;

    option6: string;
    option7: string;
    option8: string;
    option: Array<Option>;
    rightAnswer: string;

    isCollection: number;
    videoId: string;

    knowledgePointName: string;

    videoName: string;
    code: string;

    input_type: string;

    type: string;

    stuAnswerOriginal: string;

    rightAnswerOriginal: string;
    result: Array<number>;
    replyStatus: number;
}
export class SubjectRedisInfo {
    subjectId: string;
    tab: number;
}

export class Option {
    content: string;
    id: string;
    ischeck: string;
}

export class ExaminationInfo {
    subjectType: number;

    subjectName: string;
    chapterPracticeOutput: Array<ChapterPractice>;
}
export class ChapterPractice {
    Index = 1;
    id: string;
    courseId: string;

    subjectId: string;
    description: string;

    questionTitle: string;

    questionContent: string;

    subjectType: number;
    subjectTypeName: string;

    input_type: string;

    option1: string;

    option2: string;

    option3: string;

    option4: string;

    option5: string;

    option6: string;

    option7: string;

    option8: string;

    type: number;

    number: number;

    option: Array<Option>;

    rightAnswer: string;

    questionTextAnalysis: string;
    questionVedioAnalysis: string;
    isCollection: number;
    corectAnswer: string;

    stuAnswer: string;
    result: Array<number>;
}
export class ReplyInfo {
    stuAnswer: string;
    questionId: string;
    type: number;
}

export class ReplyParam {
    chapterId: string;
    topicCount: number;
    chidChapterQuestionId: string;
}

export class GetQuestionInfoBySubjectNewQuery {
    type: string;
    subjectId: string;
}

export class ChapterPracticeInfo {
    id: string;
    name: string;

    chapterCount: number;

    chapters: Array<ChapterInfo>;
}

export class ChapterInfo {
    id: number;
    name: number;

    subjectId: number;
    chapterQuestionsId: number;

    topicCount: number;
    answerCount: number;
    correctCount: number;
}

export class ChapterPracticeQuery {
    knowLedgePoint: string;
    relationType: string;
}

export class InsertChapterQuestionsDetailQuery {
    courseChapterQuestionsDetail: Array<CourseChapterQuestionsDetail>;

    chapterQuestionsId: string;

    knowLedgePoint: string;

    relationType: number;
}
export class CourseChapterQuestionsDetail {
    chapterQuestionsId: string;

    questionId: string;

    type: number;

    stuAnswer: string;

    score: number;

    isWhether: number;
    replyStatus: number;
}

export class InsertChapterQuestionsDetailInfo {
    courseChapterQuestionsDetail: Array<CourseChapterQuestionsDetail>;
}

export class ChapterQuestionInfo {
    id: string;

    practiceNo: string;

    knowLedgePointId: string;

    relationType: string;

    name: string;
}
