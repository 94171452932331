



































































import { Paging } from '@/domain/Paging';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { CourseAppraiseItem } from '@/infrastructure/model/CourseAddOrEditQuery';
import { Loading } from 'vuetify-extension';
import { Context, EnableProp } from 'ea-router';
import { LoadingMsg } from '@/domain/loadingText';
@Context('courseId', 'coursetype')
@EnableProp()
@Component
export default class CourseAppraiseComponent extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Prop() readonly courseId: string;
    @Prop() readonly coursetype: number;
    AppraiseList = new Array<CourseAppraiseItem>();
    totalCount = 0;
    isLoading = false;
    isLoadingEnd = false;
    Paging = new Paging();
    @Loading(LoadingMsg)
    async GetAppraiseListByCourseId() {
        const data = await this.repository.courseAppraise.GetAppraiseListByCourseId(
            {
                coursetype: this.coursetype,
                courseId: this.courseId,
                nd: '1608865822946',
                rows: this.Paging.PageSize,
                page: this.Paging.PageIndex,
            }
        );
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        data.pageData.forEach(function(item) {
            that.AppraiseList.push(item);
        });
        this.isLoadingEnd = data.pageData.length <= 0 ? true : false;
        this.Paging.PageIndex = data.pageIndex;
        this.Paging.PageCount = data.pageCount;
        this.totalCount = data.totalRecordsCount;
    }

    mounted() {
        if (this.isLoadingEnd) return;
        this.GetAppraiseListByCourseId();
    }
    load(scroll: any) {
        if (this.isLoading || this.isLoadingEnd) return;
        this.isLoading = true;
        this.Paging.PageIndex += 1;
        this.GetAppraiseListByCourseId();
        this.isLoading = false;
    }
}
