





























































































































































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    ReviewAnswerRecordReport,
    ReviewAnswerRecordList,
    PracticeExamQuestionView,
} from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import {
    MyPaperRecordsJgInfo,
    PaperQuestionInfo,
    QuestionDetailInfo,
    ReplyStatus,
    AnswerQuestionInputInfo,
} from '@/models/ExamPractice';
import { LoadingTestPaper } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {
    TrueQuestionIds,
    NoRepliedQuestionIds,
    ExaminationPaperInfos,
    PaperQuestions,
    taskType,
    QuestionId,
    Replies,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component
@RouteName(ReviewAnswerRecordReport)
export default class ReviewAnswerReport extends Vue {
    gradientColor = {
        '0%': '#fdaf95',
        '100%': '#f96e7d',
    };
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    paperQuestions = new Array<PaperQuestionInfo>();
    myPaperRecordsJg = new MyPaperRecordsJgInfo();
    trueQuestionIds = new Array<string>();
    falseQuestionIds = new Array<string>();
    partTrueQuestionIds = new Array<string>();
    NoRepliedQuestionIds = new Array<string>();
    ErrorQuestionIds = new Array<string>();
    Enum = {
        ReplyStatus: ReplyStatus,
    };
    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }
    get paperId() {
        return this.$route.query.paperId as string;
    }
    @Loading(LoadingTestPaper)
    async GetPaperQuestions() {
        const Data = await this.repository.exampractice.GetPaperInfo(
            this.paperId,
            this.paperRecordsId
        );
        this.paperQuestions = Data;
        this.paperQuestions.forEach((p: any) => {
            p.question.forEach((k: any) => {
                if (
                    this.myPaperRecordsJg.replies.filter(
                        x => x.bigQuestionId == k.id
                    ).length > 0
                ) {
                    const question = this.myPaperRecordsJg.replies.filter(
                        x => x.bigQuestionId == k.id
                    )[0];
                    k.stuAnswer = question.stuAnswer;
                    k.result = question.result;
                }
            });
        });
        Storage.Instance.set(PaperQuestions, Data);
    }
    async GetExaminationPaper() {
        const data = await this.repository.exampractice.GetExaminationPaper(
            {
                paperId: this.paperId,
                paperRecordsId: this.paperRecordsId,
            }
        );
        Storage.Instance.set(ExaminationPaperInfos, data);
    }

    @Loading(LoadingTestPaper)
    async GetMyPaperRecordsJg() {
        const data = await this.repository.exampractice.MyPaperRecordsJg({
            paperRecordsId: this.paperRecordsId,
        });
        this.myPaperRecordsJg = data;
        //replies-redis本地缓存
        const inputs = new Array<AnswerQuestionInputInfo>();
        this.myPaperRecordsJg.replies.forEach(x => {
            const replyInfo = new AnswerQuestionInputInfo();
            replyInfo.answer = x.stuAnswer;
            replyInfo.questionId = x.bigQuestionId;
            if (
                inputs.filter(p => p.questionId == x.bigQuestionId)
                    .length <= 0
            )
                inputs.push(replyInfo);
        });
        Storage.Instance.set(Replies, inputs);
    }
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.reviewAnswerRecordList);

        await this.GetMyPaperRecordsJg();
        await this.GetExaminationPaper();
        await this.GetPaperQuestions();
    }
    GetReplyStatus(childQuestion: QuestionDetailInfo) {
        const ReplyInfo = this.myPaperRecordsJg.replies.filter(
            x => x.bigQuestionId == childQuestion.id
        )[0];

        if (ReplyInfo != undefined) {
            if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedTrue
            ) {
                this.filterTrueQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedTrue;
            } else if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedFlase
            ) {
                this.filterFalseQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedFlase;
            } else if (
                ReplyInfo.replyStatus ==
                this.Enum.ReplyStatus.ReplyedPartTrue
            ) {
                this.filterPartTrueQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedPartTrue;
            } else {
                this.filterNoRepliedQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.NoReplyed;
            }
        } else {
            this.filterNoRepliedQuestionIds(childQuestion);
            return this.Enum.ReplyStatus.NoReplyed;
        }
    }
    filterNoRepliedQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.NoRepliedQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.NoRepliedQuestionIds.push(childQuestion.id);
        }
    }
    filterPartTrueQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.partTrueQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.partTrueQuestionIds.push(childQuestion.id);
        }
    }
    filterFalseQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.falseQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.falseQuestionIds.push(childQuestion.id);
        }
    }
    filterTrueQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.trueQuestionIds.indexOf(childQuestion.id) == -1) {
            this.trueQuestionIds.push(childQuestion.id);
        }
    }
    timestampToTime(timestamp: string) {
        //这里需要格外的对timestamp是否为空进行判断,否则会报错
        if(timestamp==""||timestamp==null){
            const date = new Date(); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
            const Y = date.getFullYear() + '/';
            const M =
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1) + '/';
            const D =
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            //处理日期在苹果手机上显示NAN的问题
            return Y + M + D;

        }
        else{
            const date = new Date(Date.parse( timestamp.replace(/-/g, "/"))); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
            const Y = date.getFullYear() + '/';
            const M =
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1) + '/';
            const D =
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            //处理日期在苹果手机上显示NAN的问题
            return Y + M + D;

        }


    }

    reviewAnswerRecordList() {
        this.navigator.redirect(ReviewAnswerRecordList);
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.set(taskType, 1);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: '',
            paperRecordsId: this.paperRecordsId,
        });
    }
    reviewAnswerRecordQuestionView() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.set(taskType, 1);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: '',
            paperRecordsId: this.paperRecordsId,
        });
    }
    viewErrorAlyasis() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.set(TrueQuestionIds, this.trueQuestionIds);
        Storage.Instance.set(NoRepliedQuestionIds, this.ErrorQuestionIds);
        Storage.Instance.set(taskType, 1);
        if (this.ErrorQuestionIds.length <= 0) {
            this.notifier.success('恭喜您获得满分，没有错题记录');
            return;
        } else {
            this.navigator.redirect(PracticeExamQuestionView, '', {
                paperId: this.paperId,
                groupId: '',
                paperRecordsId: this.paperRecordsId,
            });
        }
    }
}
