var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.question.description)?_c('div',[_c('div',{staticClass:"text-grey pa-4 question-img body-2 question-main-scroll scrollable",domProps:{"innerHTML":_vm._s(_vm.question.description)}}),_c('v-divider',{staticClass:"v-divider-grey"})],1):_vm._e(),_c('div',{staticClass:"body-2 text-grey-3 pa-4 options scrollable",domProps:{"innerHTML":_vm._s(_vm.question.questionContent)}}),_c('div',[_c('van-radio-group',{on:{"change":_vm.changedReplies},model:{value:(_vm.question.stuAnswer),callback:function ($$v) {_vm.$set(_vm.question, "stuAnswer", $$v)},expression:"question.stuAnswer"}},_vm._l((_vm.question.option),function(option,index){return _c('van-radio',{key:index,class:{ whiteBg: index % 2 == 0, grayBg: index % 2 != 0 },attrs:{"disabled":_vm.isEnable,"name":option.id},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-card',{class:[
                            _vm.isShowRighteAnswer == true
                                ? _vm.getOptionActiveClassCurrent(
                                      option,
                                      _vm.question
                                  )
                                : _vm.getOptionActiveClass(option, _vm.question),
                            'white--text',
                            'rounded-pill',
                            'body-2',
                            'text-center' ],attrs:{"flat":"","width":"0.5333rem","height":"0.5333rem"}},[_vm._v(" "+_vm._s(_vm.optionsLetter[index])+" ")])]},proxy:true}],null,true)},[_c('v-row',{staticClass:"pl-2 body-2 my-4",attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{domProps:{"innerHTML":_vm._s(option.content)}})])],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }