export class SelectionExam {
    id: string;
    name: string;
    type: number;
    level: SelectionLevel;
    profession?: SelectionProfession;
}

export class SelectionLevel {
    id: string;
    name: string;
}

interface SelectionProfession {
    id: string;
    name: string;
}
