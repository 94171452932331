























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Option } from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
import { ChapterPractice } from '@/models/ChapterPractice';
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
@Component
export default class QuestionViewComponent extends Vue {
    radio = '1';
    @Prop() question: ChapterPractice;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    changedReplies() {
        this.question.stuAnswer = (this.question
            .result as unknown) as string;
        this.$emit('changedReplies');
    }
    optionsLetter = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    replaceHtmlTag(content) {
        if (!content) return '';
        return content
            .replace(/<[^>]+>/g, '')
            .replace('&#xa0;', '')
            .replace('&nbsp;', '');
    }
    getOptionActiveClassCurrent(option: Option, question: ChapterPractice) {
        if (
            this.question.stuAnswer == this.question.corectAnswer &&
            option.id == this.question.corectAnswer
        )
            return 'greenClass';
        else {
            if (
                this.question.stuAnswer != this.question.rightAnswer &&
                this.question.stuAnswer == option.id
            ) {
                return 'redClass';
            }
        }
        if (option.id == this.question.corectAnswer) return 'greenClass';
        return 'defaultClass';
    }
    getOptionActiveClass(option: Option, question: ChapterPractice) {
        if (question.stuAnswer == option.id) return 'activeClass';

        return 'defaultClass';
    }
}
