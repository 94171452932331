




























































































































































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import CourseAppraiseComponent from '@/components/course/CourseAppraise.vue';
import CourseChapterComponent from '@/components/course/Chapter.vue';
import SingleCourseChapterComponent from '@/components/course/SingleCourseChapter.vue';

import Repositories from '@/infrastructure/repository';
import {
    BaseCourseInfo,
    ServiceBaseDateInfo,
} from '@/infrastructure/model/CourseIntroduceInfo';

import { Context, EnableProp, RouteName } from 'ea-router';
import {
    CourseDetail,
    Products,
    Order,
    Advisory,
    CourseVideo,
    UserPackageDetail,Paymentorder
} from '@/domain/views';
import { Loading, Notification } from 'vuetify-extension';
import { Navigator, Repository } from '@/domain/providers';
import { Paging } from '@/domain/Paging';
import { Navigation } from '@/infrastructure/navigation';
import { Page } from '@/infrastructure/navigation/page';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import Storage from '@/infrastructure/Storage';
import { OrderNo } from '@/domain/getters';
@RouteName(CourseDetail)
@Context('saleType', 'courseId')
@EnableProp()
@Component({
    components: {
        CourseAppraiseComponent: CourseAppraiseComponent,
        CourseChapterComponent,
        SingleCourseChapterComponent,
    },
})
export default class DetailPage extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Prop() courseId: string;
    @Prop() saleType: string;
    @Inject(Navigator) navigator: Navigation;
    Paging = new Paging();
    totalCount = 0;
    baseCourseInfo = new BaseCourseInfo();
    baseData = new Array<ServiceBaseDateInfo>();
    courseImage = '';
    userHasPermission = false;
    currentTab = 0;

    get hasPermission() {
        if (!this.$store.getters.isLogin) return false;
        return this.userHasPermission;
    }

    get bqCode() {
        return this.$route.query.bqCode;
    }
    get currentTabX() {
        return this.$route.query.currentTab;
    }

    async GetCourseIntroduceInfo() {
        const data = await this.repository.course.GetCourseIntroduceInfo({
            courseId: this.courseId,
            saleType: this.saleType,
        });
        this.baseCourseInfo = data.baseCourseInfo;
        this.baseData = data.baseData;
        this.courseImage = this.baseCourseInfo.courseImage;
        await this.GetAppraiseListByCourseId();
    }
    async GetAppraiseListByCourseId() {
        const data = await this.repository.courseAppraise.GetAppraiseListByCourseId(
            {
                coursetype: Number(this.saleType),
                courseId: this.courseId,
                nd: '1608865822946',
                rows: this.Paging.PageSize,
                page: this.Paging.PageIndex,
            }
        );
        this.totalCount = data.totalRecordsCount;
    }

    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectBck);

        await this.GetPermission();
        await this.GetCourseIntroduceInfo();
        if (this.currentTabX != '') {
            this.currentTab = Number(this.currentTabX);
        }
    }
    redirectBck() {
        this.navigator.redirect(Products, {
            bqCode: this.bqCode,
        });
    }
    @NeedLogin
    async order() {
        const isExist = await this.repository.CurriculumPay.GetIsExistToPayOrder(
            this.courseId
        );
       if (isExist) {
            Notification.warn('您已购买,请勿重复提交');
            return;
        }
        this.navigator.redirect(
            Paymentorder,
            { saleType: this.saleType, courseId: this.courseId },
            { bqCode: this.bqCode }
        );
    }

    advisory() {
        this.navigator.redirect(Advisory, { saleType: this.saleType, courseId: this.courseId },{ bqCode: this.bqCode });
    }

    private async GetPermission() {
        if (!this.$store.getters.isLogin) return;
        this.userHasPermission = await this.repository.user.hasPermission(
            this.courseId
        );
    }

    preview() {
        this.currentTab = 1;
    }

    study() {
        const type = Number.parseInt(this.saleType);
        if (type === 0) {
            const page: Page = {
                name: CourseVideo,
                context: {
                    courseId: this.courseId,
                    videoId: 'null',
                },
                query: {
                    saleType: this.saleType,
                    bqCode: this.bqCode,
                },
            };
            this.navigator.redirectPage(page);
        } else if (type === 1) {
            const page: Page = {
                name: UserPackageDetail,
                context: {
                    id: this.courseId,
                    packageName: this.baseCourseInfo.courseName,
                },
            };
            this.navigator.redirectPage(page);
        }
    }
}
