


































































































































import { Vue, Component } from 'vue-property-decorator';
import { Dialog, Notify, Toast } from 'vant';
import Weatherforecast from '@/models/weatherforecast';
@Component
export default class DemoPageComponent extends Vue {
    activeNames = ['1'];
    border = false;
    count = 0;
    active = 0;
    isLoading = false;
    list = [];
    loading = false;
    finished = false;
    radio = '1';
    currentRate = 0;
    data: Weatherforecast[] = [];
    columns = [
        ' 冲刺点晴课 ',
        ' 教材精讲课 ',
        ' vip特色班 ',
        ' vip特色班 【一次重学】',
    ];

    get text() {
        return this.currentRate.toFixed(0) + '%';
    }

    confirm() {
        Dialog.confirm({
            title: '标题',
            message: '弹窗内容',
        })
            .then(() => {
                // on confirm
            })
            .catch(() => {
                // on cancel
            });
    }
    onRefresh() {
        setTimeout(() => {
            // Toast('刷新成功');
            this.isLoading = false;
            this.count++;
        }, 1000);
    }
    onClickLeft() {
        // Toast('返回');
    }

    onCancel() {
        Toast('取消');
    }

    //加载动画
    btnClick() {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
        });
    }

    //上拉加载
    async onLoad() {
        // 异步更新数据
        // setTimeout 仅做示例，真实场景中一般为 ajax 请求
        setTimeout(() => {
            for (let i = 0; i < 5; i++) {
                // this.list.push(this.list.length + 1);
            }

            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.list.length >= 40) {
                this.finished = true;
            }
        }, 1000);
    }

    mounted() {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner',
        });
    }
}
