import { HttpErrorHandler } from '.';
import { Loader } from 'vuetify-extension';
import { Notification } from 'vuetify-extension';

export default class AxiosHttpErrorHandler implements HttpErrorHandler {
    process(error: any): void {
        Loader.CloseAll();

        if (error.message == 'Network Error') {
            //console.log('网络异常');
            Notification.error('网络异常');
        }
    }
}
