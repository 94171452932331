import AxiosHttp from '@/infrastructure/http';
import {
    ExaminationInfo,
    ChapterQuestionsDetailInfo,
    ChapterPracticeInfo,
    InsertChapterQuestionsDetailQuery,
    InsertChapterQuestionsDetailInfo,
    ChapterQuestionInfo,
    ChapterPracticeQuery,
} from '@/models/ChapterPractice';

export default class ChapterPracticeRepository {
    GetChapterQuestionsResult(
        chapterQuestionsId: string
    ): Promise<ChapterQuestionsDetailInfo> {
        return AxiosHttp.Instance.get<ChapterQuestionsDetailInfo>(
            '/ChaterPractice/GetChapterQuestionsResult?chapterQuestionsId=' +
                chapterQuestionsId
        );
    }
    GetChapterPracticeList(
        knowledgePointId: string,
        relationType: string
    ): Promise<Array<ExaminationInfo>> {
        return AxiosHttp.Instance.get<Array<ExaminationInfo>>(
            '/ChaterPractice/GetChapterPracticeList_new_chaper?knowledgePointId=' +
                knowledgePointId +
                '&relationType=' +
                relationType
        );
    }
    GetQuestionInfoBySubjectNew(
        Type: string,
        SubjectId: string
    ): Promise<Array<ChapterPracticeInfo>> {
        return AxiosHttp.Instance.get<Array<ChapterPracticeInfo>>(
            '/ChaterPractice/GetQuestionInfoBySubjectNew?Type=' +
                Type +
                '&SubjectId=' +
                SubjectId
        );
    }
    InsertChapterQuestionsDetail(
        query: InsertChapterQuestionsDetailQuery
    ): Promise<InsertChapterQuestionsDetailInfo> {
        return AxiosHttp.Instance.post<InsertChapterQuestionsDetailInfo>(
            '/ChaterPractice/InsertChapterQuestionsDetail',
            query
        );
    }

    ChapterPractice(query: ChapterPracticeQuery): Promise<ChapterQuestionInfo> {
        return AxiosHttp.Instance.post<ChapterQuestionInfo>(
            '/ChaterPractice/ChapterPractice',
            query
        );
    }
}
