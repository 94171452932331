





































































































































































import navbar from '@/components/navbar.vue'
import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';

import { Loading } from 'vuetify-extension';
import { RouteName } from 'ea-router';
import {
    PracticeChapterTopicList,
    PracticeChapterQuestionView,
    PracticeChapterReport,
    ReviewAnswerRecordChapterReport,
} from '@/domain/views';

import QuestionReplyComponent from '@/components/questionviews/ChapterQuestionView.vue';
import Analysis from '@/components/questionviews/ChapterAnalysis.vue';

import { Navigation } from '@/infrastructure/navigation';
import { LoadingTestPaper } from '@/domain/loadingText';
import {
    ChapterPractice,
    ChapterQuestionInfo,
    ExaminationInfo,
} from '@/models/ChapterPractice';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestions,
    Questions,
    NoRepliedXChapterQuestionIds,
    QuestionId,
    ChapterQuestionInfos,
    ChapterTrueQuestionIds,
    chaptertaskType,
} from '@/domain/getters';
import { QuestionType } from '@/models/ExamPractice';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
        navbar,
    },
})
@RouteName(PracticeChapterQuestionView)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    chapterQuestionInfo = new ChapterQuestionInfo();
    chapterQuestions = new Array<ExaminationInfo>();
    Questions = new Array<ChapterPractice>();
    question = new ChapterPractice();
    currentQuestionIndex = 1;
    Index = 0;
    Count2 = 0;
    IsShowAnswer = true;
    IsRepeat = false;
    IsShowCollectioned = false;
    collectShow = false;
    TotalCount = 0;
    Enum = {
        questionType: QuestionType,
    };
    get relationType() {
        return this.$route.query.relationType as string;
    }
    get knowledgePointId() {
        return this.$route.query.knowledgePointId as string;
    }
    get isShow() {
        return this.$route.query.isShow as string;
    }
    get chaptertaskType() {
        return Storage.Instance.get<number>(chaptertaskType);
    }

    get chapterQuestionsId() {
        return this.$route.query.chapterQuestionsId as string;
    }

    get chapterQuestionsRedis() {
        return Storage.Instance.get<Array<ExaminationInfo>>(ChapterQuestions);
    }
    get ChapterQuestionInfoRedis() {
        return Storage.Instance.get<ChapterQuestionInfo>(ChapterQuestionInfos);
    }
    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get falseQuestionIds() {
        return Storage.Instance.get<Array<string>>(
            NoRepliedXChapterQuestionIds
        );
    }
    get trueQuestionIds() {
        return Storage.Instance.get<Array<string>>(ChapterTrueQuestionIds);
    }

    @Loading(LoadingTestPaper)
    async GetChapterPracticeList() {
        const Data = await this.repository.chapterPractice.GetChapterPracticeList(
            this.knowledgePointId,
            this.relationType
        );
        this.chapterQuestions = Data;
        this.question = this.chapterQuestions[0].chapterPracticeOutput[0];
        Storage.Instance.set(ChapterQuestions, this.chapterQuestions);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);

        if (
            this.chapterQuestionsRedis != undefined &&
            this.ChapterQuestionInfoRedis != undefined
        ) {
            await this.HandInLocalData();
        } else {
            await this.GetChapterPracticeList();
        }
        await this.GetAllQuestions();
        this.InitTotalCountOrCurrentIndex();
        await this.HandlerBtnController();

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }

    InitTotalCountOrCurrentIndex() {
        if (
            this.falseQuestionIds != undefined &&
            this.falseQuestionIds.length > 0
        ) {
            this.TotalCount = this.falseQuestionIds.length;
            if (this.questionId != null) {
                this.currentQuestionIndex = this.question.Index;
            } else this.currentQuestionIndex = 1;
        } else {
            this.TotalCount = this.Questions.length;
            this.currentQuestionIndex = this.question.Index;
        }
    }

    async HandInLocalData() {
        this.chapterQuestionInfo = this.ChapterQuestionInfoRedis;
        this.chapterQuestions = this.chapterQuestionsRedis;
        if (this.questionId != undefined && this.questionId != null) {
            this.chapterQuestions.forEach((x: any) => {
                x.chapterPracticeOutput.forEach((x: any) => {
                    if (x.id == this.questionId) {
                        this.question = x;
                    }
                });
            });
        } else {
            if (
                this.falseQuestionIds != undefined &&
                this.falseQuestionIds.length > 0
            ) {
                const questionId = this.falseQuestionIds[0];
                this.GetCurrentQuestion(questionId);
            } else {
                this.question = this.chapterQuestions[0].chapterPracticeOutput[0];
            }
        }
    }

    GetCurrentQuestion(questionId: string) {
        this.chapterQuestions.forEach((p: any) => {
            p.chapterPracticeOutput.forEach((x: any) => {
                if (x.id == questionId) {
                    this.question = x;
                    this.HandlerBtnController();
                }
            });
        });
    }

    async GetAllQuestions() {
        this.chapterQuestions.forEach((x: any) => {
            x.chapterPracticeOutput.forEach((p: any) => {
                if (x.subjectType == this.Enum.questionType.Comprehensive) {
                    p.subjectTypeName = '案例分析题';
                } else if (x.subjectType == this.Enum.questionType.LunShu) {
                    p.subjectTypeName = '论述题';
                } else if (x.subjectType == this.Enum.questionType.Single) {
                    p.subjectTypeName = '单选题';
                } else if (x.subjectType == this.Enum.questionType.Muti) {
                    p.subjectTypeName = '多选题';
                }
                this.Count2++;
                //把作答正确过滤掉
                if (this.trueQuestionIds != null) {
                    if (this.trueQuestionIds.indexOf(p.id) == -1) {
                        this.Index++;
                        p.Index = this.Index;

                        this.Questions.push(p);
                    }
                } else {
                    this.Index++;
                    p.Index = this.Index;

                    this.Questions.push(p);
                }
            });
        });
        Storage.Instance.set(Questions, this.Questions);
    }
    async preQuestion() {
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async nextQuestion() {
        if (this.currentQuestionIndex >= this.Questions.length) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async HandlerBtnController() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowAnswer = true;
            this.IsShowCollectioned = true;
        } else {
            this.IsRepeat = true;
            this.IsShowAnswer = true;
            this.IsShowCollectioned = false;
        }
    }
    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }
    async HandlerChangeQuestion() {
        if (
            this.falseQuestionIds != undefined &&
            this.falseQuestionIds.length > 0
        ) {
            this.question = this.Questions.filter(
                x =>
                    x.id == this.falseQuestionIds[this.currentQuestionIndex - 1]
            )[0];
        } else {
            this.question = this.Questions.filter(
                x => x.Index == this.currentQuestionIndex
            )[0];
        }
        Storage.Instance.set(QuestionId, this.question.id);
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        } else {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        }
    }

    viewQuestionItems() {
        this.navigator.redirect(PracticeChapterTopicList, '', {
            chapterQuestionsId: this.chapterQuestionsId,
            IsShowHand: false,
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
        });
    }

    redirectList() {
        if (this.chaptertaskType != null && this.chaptertaskType == 1) {
            this.navigator.redirect(ReviewAnswerRecordChapterReport, '', {
                count: this.Count2,
                chapterQuestionsId: this.chapterQuestionsId,
                relationType: this.relationType,
                knowledgePointId: this.knowledgePointId,
            });
        } else {
            this.navigator.redirect(PracticeChapterReport, '', {
                count: this.Count2,
                chapterQuestionsId: this.chapterQuestionsId,
                relationType: this.relationType,
                knowledgePointId: this.knowledgePointId,
            });
        }
    }
}
