



































































































































































































































































































































































































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import SimpleNavigation, { Navigation } from '@/infrastructure/navigation';
import { CourseChapterInfo } from '@/infrastructure/model/CourseChapterInfo';
import { Context, EnableProp } from 'ea-router';
import store from '@/store';
import { UserLogin } from '@/domain/views';
import { LoadingMsg } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { ChapterId } from '@/domain/getters';
@Component
@Context('saleType', 'courseId', 'videoId', 'id', 'childid')
@EnableProp()
export default class CourseVideoChapterComponent extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Prop() readonly courseId: string;
    @Prop() readonly saleType: string;
    @Prop() readonly videoId: string;
    @Prop() readonly id: string;
    @Prop() readonly childid: string;
    @Inject(Navigator) navigator: Navigation;

    activeClass = 'active-class';
    open = ['public'];
    courseChapters = new Array<CourseChapterInfo>();
    courseChaptersList = new Array<CourseChapterInfo>();
    hasPreMission = false;

    CurrentChapterIds = new Array<string>();

    CurrentParentChapterIds = new Array<string>();

    @Loading(LoadingMsg)
    async GetCourseChapterInfo() {
        const data = await this.repository.course.GetCourseChapterInfo({
            courseId: this.courseId,
            saleType: '0',
        });
        await this.BuildChaptersData(data);
        this.courseChapters = data;
        this.BuildCourseChaptersList(this.courseChapters);
    }
    mounted() {
        // this.$nextTick(async () => {
        //     await this.GetCourseChapterInfo();
        // });
    }

    async created() {
        if (this.childid != '' && this.childid != undefined) {
            this.CurrentChapterIds.push(this.childid);
        }
        this.CurrentParentChapterIds.push(this.id);
        if (store.getters.isLogin) {
            const data = await this.repository.user.GetMyCourse(
                this.$store.getters.exam
            );
            const courseIds = [];
            for (const courseIndex in data.courses) {
                courseIds.push(data.courses[courseIndex].id);
            }
            for (const packIndex in data.packs) {
                for (const packCourseIndex in data.packs[packIndex].courses) {
                    courseIds.push(
                        data.packs[packIndex].courses[packCourseIndex].id
                    );
                }
            }
            if (courseIds.indexOf(this.courseId) != -1) {
                this.hasPreMission = true;
            }
        }

        await this.GetCourseChapterInfo();
    }
    isFree(chapter: any) {
        const children = chapter.children;
        const childrenIsVideo = children.every((x: any) => x.videoId !== null);
        if (childrenIsVideo) {
            return children.some((x: any) => x.isTaste === 1);
        }
        for (const index in children) {
            const childChapter = children[index];
            if (this.isFree(childChapter)) return true;
        }
        return false;
    }
    VideoDetail(
        videoId: string,
        vip: string,
        isTaste: number,
        chapterid: string
    ) {
        Storage.Instance.set(ChapterId, chapterid);
        if (!store.getters.isLogin) {
            if (isTaste == 0 || isTaste == null) {
                //未登 录&非试听
                SimpleNavigation.Instance.redirect(UserLogin);
                return;
            } else if (isTaste == 1 && vip == '0') {
                //未登录&非试听&没有权限
                SimpleNavigation.Instance.redirect(UserLogin);
                return;
            } else {
                this.$emit('UploadIsOver', videoId, vip);
            }
        } else {
            this.$emit('UploadIsOver', videoId, vip);
        }
    }

    NextPlay(videoId: string) {
        const curVideoIndex = this.courseChaptersList.findIndex(function(
            data: CourseChapterInfo
        ) {
            return data.videoId == videoId;
        });
        if (curVideoIndex < this.courseChaptersList.length) {
            this.$emit(
                'UploadIsOver',
                this.courseChaptersList[curVideoIndex + 1].videoId,
                this.courseChaptersList[curVideoIndex + 1].vip
            );
        }
    }
    async BuildChaptersData(data: Array<CourseChapterInfo>) {
        data.forEach(function(el: any) {
            el.children.forEach(function(el1: any) {
                if (el1.children == null) el1.children = [];
                el1.children.forEach(function(el2: any) {
                    if (el2.children == null) el2.children = [];
                    el2.children.forEach(function(el3: any) {
                        if (el3.children == null) el3.children = [];
                    });
                });
            });
        });
    }
    BuildCourseChaptersList(data: Array<CourseChapterInfo>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        data.forEach(function(el: any) {
            if (el.children != null && el.children.length > 0) {
                return that.BuildCourseChaptersList(el.children);
            } else if (el.videoId != null) {
                that.courseChaptersList.push(el);
            }
        });
    }
    callpase(item: CourseChapterInfo) {
        const index = this.CurrentChapterIds.indexOf(item.id);
        this.CurrentChapterIds.splice(index, 1);
    }
    expand(item: CourseChapterInfo) {
        this.CurrentChapterIds.push(item.id);
    }

    parentcallpase(item: CourseChapterInfo) {
        const index = this.CurrentParentChapterIds.indexOf(item.id);
        this.CurrentParentChapterIds.splice(index, 1);
    }
    parentexpand(item: CourseChapterInfo) {
        this.CurrentParentChapterIds.push(item.id);
    }
}
