























































































































































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import {
    QuestionDetailInfo,
    QuestionType,
    ReplyType,
} from '@/models/ExamPractice';
import { Context, EnableProp } from 'ea-router';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { ReviewWrongTopicErrorCorrection, VideoPlay } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
@Component
@Context(
    'question',
    'paperId',
    'paperRecordsId',
    'ishandShow',
    'IsShowAnswer'
)
@EnableProp()
export default class QuestionAnswerComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() readonly ishandShow!: boolean;
    @Prop() readonly paperId!: string;
    @Prop() readonly IsShowAnswer: boolean;
    @Prop() readonly paperRecordsId!: string;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    Enum = {
        questionType: QuestionType,
    };
    ReplyEnum = {
        ReplyType: ReplyType,
    };
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    preview(videosource){
        this.navigator.redirect(VideoPlay, '', {
            videosource: videosource,
        });

    }
    GetReplyStatus(question: QuestionDetailInfo) {
        //console.log('GetReplyStatus');
        let result = this.ReplyEnum.ReplyType.Error;
        switch (question.subjectType) {
            case this.Enum.questionType.Single:
                if (question.stuAnswer == question.corectAnswer)
                    result = this.ReplyEnum.ReplyType.Correct;
                break;
            case this.Enum.questionType.Muti ||
                this.Enum.questionType.Comprehensive:
                if (question.stuAnswer != null) {
                    let rightCount = 0;
                    const answerArr = (question.stuAnswer as unknown) as Array<
                        string
                    >;
                    const rightAnswer = question.corectAnswer.split(',');
                    for (let j = 0; j < answerArr.length; j++) {
                        if (
                            answerArr[j] != null &&
                            rightAnswer.indexOf(String(answerArr[j])) > -1
                        ) {
                            rightCount += 1;
                            continue;
                        } else {
                            return result;
                        }
                    }
                    if (rightCount > 0) {
                        if (rightAnswer.length == rightCount)
                            result = this.ReplyEnum.ReplyType.Correct;
                        else result = this.ReplyEnum.ReplyType.PartCorrect;
                    }
                }
                break;
        }
        return result;
    }

    getLetterSequence(index: number) {
        return this.letters.charAt(index - 1);
    }
    ViewErrorQuestion() {
        this.navigator.redirect(ReviewWrongTopicErrorCorrection, '', {
            questionId: this.question.id,
            paperRecordsId: this.paperRecordsId,
            ishandShow: this.ishandShow,
            IsShowAnswer: this.IsShowAnswer,
        });
    }

    translateAnswer(question: QuestionDetailInfo) {
        const tempData = new Array<number>();
        let stuAnswer = '';
        if (
            this.question.stuAnswer == '0' ||
            this.question.stuAnswer == null ||
            this.question.stuAnswer == undefined
        )
            return;
        switch (question.subjectType) {
            case this.Enum.questionType.Single:
                stuAnswer = this.getLetterSequence(
                    Number(question.stuAnswer)
                );
                break;
            case this.Enum.questionType.Muti:
                if (question.stuAnswer.indexOf(',') != -1)
                    question.stuAnswer = (question.stuAnswer
                        .split(',')
                        .sort() as unknown) as string;
                for (
                    let index = 0;
                    index <= question.stuAnswer.length - 1;
                    index++
                ) {
                    tempData.push(Number(question.stuAnswer[index]));
                }
                tempData.sort().forEach(x => {
                    stuAnswer += this.getLetterSequence(x) + ',';
                });
                stuAnswer = stuAnswer.substring(0, stuAnswer.length - 1);
                break;
            case this.Enum.questionType.Comprehensive:
                stuAnswer = question.stuAnswer;
                break;
            case this.Enum.questionType.LunShu:
                stuAnswer = question.stuAnswer;
                break;
        }
        return stuAnswer;
    }
}
