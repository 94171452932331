








































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    PracticeExamErrorCorrection,
    PracticeExamQuestionReply,
    PracticeExamQuestionView,
} from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Storage from '@/infrastructure/Storage';
import { QuestionId } from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(PracticeExamErrorCorrection)
@Component
export default class ErrorCorrection extends Vue {
    checked = false;
    get paperId() {
        return this.$route.query.paperId as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }
    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }
    get questionId() {
        return this.$route.query.questionId as string;
    }

    get ishandShow() {
        return this.$route.query.ishandShow;
    }
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    content = '';
    result = [
        { id: '1', name: '错别字' },
        { id: '2', name: '题干有误' },
        { id: '3', name: '答案有误' },
        { id: '4', name: '解析有误' },
        { id: '5', name: '知识已变动' },
        { id: '6', name: '其他错误' },
    ];
    answer = [];
    submitCount = 0;

    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectQuestionReply);
    }

    redirectQuestionReply() {
        Storage.Instance.set(QuestionId, this.questionId);
        this.navigator.redirect(
            Boolean(this.ishandShow) == false
                ? PracticeExamQuestionView
                : PracticeExamQuestionReply,
            '',
            {
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            }
        );
    }
    get canSubmit() {
        return this.content.length > 0 && this.answer.length > 0;
    }

    async btnSubmit() {
        this.submitCount++;
        if (this.submitCount <= 1) {
            Storage.Instance.set(QuestionId, this.questionId);
            if (this.content !== '' && this.result.length > 0) {
                const checkerTypeTexts = [];
                this.answer.forEach(x => {
                    checkerTypeTexts.push(
                        this.result.filter(p => p.id == x)[0].name
                    );
                });
                const data = await this.repository.exampractice.AddError({
                    QuestionId: this.questionId,
                    CheckerTypeIds: this.answer.join(','),
                    content: this.content,
                    CheckerTypeTexts: checkerTypeTexts.join(','),
                });
                if (data.success) {
                    this.notifier.success('感谢您的反馈！');
                    this.navigator.redirect(
                        Boolean(this.ishandShow) == false
                            ? PracticeExamQuestionView
                            : PracticeExamQuestionReply,
                        '',
                        {
                            paperId: this.paperId,
                            groupId: this.groupId,
                            paperRecordsId: this.paperRecordsId,
                        }
                    );
                } else this.notifier.success('反馈失败！');
            }
        }
    }
}
