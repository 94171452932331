/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-02-25 16:58:48
 * @LastEditTime: 2022-05-03 16:52:06
 * @FilePath: \haokao.app.ui\src\infrastructure\repository\ReviewRepository.ts
 */
import AxiosHttp from '@/infrastructure/http';
import {
    PaperReviewRecord,
    ChapterReviewRecord,
    QuestionsCollect,
    WrongTopicRecord,
    WrongTopicInfoReturn
} from '@/models/ReviewRecord';
import {
    WrongTopicInfo
} from '@/models/ReviewQuestion';
import { QuestionDetailInfo } from '@/models/ExamPractice';

export default class ReviewRepository {
    ReviewRecordList(subjectId: string, type: string) {
        const param = {
            subjectId: subjectId,
            type: type,
        };
        return AxiosHttp.Instance.get<Array<PaperReviewRecord>>(
            '/review/PaperRecordList',
            param
        );
    }

    ChapterRecordList(subjectId: string, type: string) {
        const param = {
            subjectId: subjectId,
            type: type,
        };
        return AxiosHttp.Instance.get<Array<ChapterReviewRecord>>(
            '/review/GetChapterRecordList',
            param
        );
    }

    WrongTopicList(subjectId: string, wrongType: number) {
        const param = {
            subjectId: subjectId,
            wrongType: wrongType,
        };
        return AxiosHttp.Instance.get<Array<WrongTopicRecord>>(
            '/review/GetWrongTopicList',
            param
        );
    }

    WrongTopicInfo(data: any, knowledgePointId: string): Promise<WrongTopicInfoReturn> {

        return AxiosHttp.Instance.post<WrongTopicInfoReturn>(
        '/review/WrongTopicInfo?knowledgePointId='+knowledgePointId,
         data
         );
    }

    GetWrongTopicInfo(wrongType: number, knowledgePointId: string) {
        const param = {
            wrongType: wrongType,
            knowledgePointId: knowledgePointId,
        };
        return AxiosHttp.Instance.get<Array<WrongTopicInfo>>(
            '/review/GetWrongTopicInfo',
            param
        );
    }




    QuestionCollectedByDate(
        subjectId: string,
        type: number,
        collectiontype:string
    ): Promise<Array<QuestionsCollect>> {
        return AxiosHttp.Instance.get<Array<QuestionsCollect>>(
            '/Review/QuestionCollectedByDate?type=' +
                type +
                '&subjectId=' +
                subjectId +
                '&collectiontype=' +
                collectiontype
        );
    }

    GetMyQuestionsResult(
        subjectId: string,
        dateType: string,
        subType: string,
        collectiontype: string
    ): Promise<Array<QuestionDetailInfo>> {
        return AxiosHttp.Instance.get<Array<QuestionDetailInfo>>(
            '/Review/MyQuestionsResult?subType=' +
                subType +
                '&subjectId=' +
                subjectId +
                '&dateType=' +
                dateType + 
                '&collectiontype=' +
                collectiontype
        );
    }
}
