import { SelectionExam } from '@/domain/selection';
import { Exam } from '@/models/Exam';
import { state } from '@/store/modules/user/state';
import { getter } from '@/store/modules/user/getters';
import { actions } from '@/store/modules/user/actions';
import { mutations } from '@/store/modules/user/mutations';
import { WrongTopicReport } from '@/models/WrongTopicReport';

export interface UserState {
    exam: SelectionExam | null;
    token: string | null;
    examList: Exam[];
    userVersion: string | null;
    wrongTopicReport: WrongTopicReport | null
}

export default {
    state: state,
    getters: getter,
    mutations: mutations,
    actions: actions,
};
