












































































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
  MyInfo,
  PersonalInfo,
  ChooseProfession,
  Modifypassword,
  verification, RegistrationAgreement, Privacypolicy, UserLogin,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import LoginProfession from '@/components/LoginProfession.vue';
import { Logout } from '@/domain/actions';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { LoadingModify } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {  Checked } from '@/domain/getters';
import {Dialog} from "vant";
@RouteName(PersonalInfo)
@Component({
    components: {
        LoginProfession,
        navbar,
    },
})
export default class PersonalInfoPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;
    info = {};
    infoProfession = true;
    show = false;
    mounted() {
        if (this.$store.getters.isLogin) {
            this.getUserInfo();
        }
    }
    closeDialog() {
        this.show = false;
    }
    chooseProfession() {
        this.navigator.redirect(ChooseProfession, '', {
            infoProfession: this.infoProfession,
        });
    }
    //账号注销
async  Cancellation() {
  Dialog.confirm({
    title: '提示',
    message: '确定注销吗？',
    confirmButtonText: '是',
    cancelButtonText: '否',
    confirmButtonColor: '#3f7efd',
  })
      .then(async () => {
        await this.repository.user.Cancellation();
        this.notifier.success('注销成功');
        this.navigator.redirect(UserLogin);
      })
      .catch(() => {
      });


}
    async getUserInfo() {
        this.info = await this.repository.user.getInfo();
    }
    ToMyInfo() {
        this.navigator.redirect(MyInfo);
    }

    LogOut() {
        //清除勾选的缓存
        Storage.Instance.clear(Checked);
        this.$store.dispatch(Logout);
        this.notifier.success('退出成功');
        this.ToMyInfo();
    }
    //按密码修改
    EditPassWord() {
        this.navigator.redirect(Modifypassword);
    }
    //密码-手机验证码修改
    Verification() {
        this.navigator.redirect(verification, '', { OpType: '2' });
    }
    //修改手机号码
    ChangePhone() {
        this.navigator.redirect(verification, '', { OpType: '1' });
    }
    //注册协议
  redirectRegister(){

    this.navigator.redirect(RegistrationAgreement,{},{type:1});
  }
  //隐私政策
  redirectPrivacy(){
    this.navigator.redirect(Privacypolicy,{},{type:1});

  }

    @Loading(LoadingModify)
    async chooseFile(event: any) {
        const files = event.target.files;
        const fileName = files[0].name;
        const allImgExt = '.jpg|.jpeg|.bmp|.png|';
        const extName = fileName
            .substring(fileName.lastIndexOf('.'))
            .toLowerCase();
        if (allImgExt.indexOf(extName + '|') == -1) {
            this.notifier.error('请选择图片上传');
            return false;
        }
        if (files[0].size / (1024 * 1024) > 5) {
            this.notifier.error('文件大小不能超过5M');
            return;
        }
        const formData = new FormData();
        formData.append('file', files[0]);
        console.log(formData);
        await this.repository.user.EditHeadImage(formData);
        await this.getUserInfo();
    }
}
