/*import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';*/

export function InitSentry(Vue: any, dsn: string) {
    /* Sentry.init({
        Vue,
        dsn: dsn,
        integrations: [new Integrations.BrowserTracing()],

        tracesSampleRate: 1.0,
    });*/
}
