


























import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import PageNotFound from '../../components/PageNotFound.vue';
import { RouteName } from 'ea-router';
import { Order } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
@RouteName(Order)
@Component({
    components: {
        PageNotFound,
    },
})
export default class OrderPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    CourseDetail() {
        this.$router.go(-1);
    }
}
