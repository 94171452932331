export default class DeviceScreen {
    static setLandscapePrimary() {
        DeviceScreen.setOrientation('landscape-primary');
    }

    static setPortraitPrimary() {
        DeviceScreen.setOrientation('portrait-primary');
    }

    private static setOrientation(orientation: string) {
        if (!window.plus) return;
        const api = window.plus.screen;
        api.lockOrientation(orientation);
    }

    static SetStatusBarWhite() {
        //白色底色，深色字体
        DeviceScreen.setStatusBar('#ffffff', 'dark');
    }

    //ftColor:文字颜色，dark：深色，light：浅色
    private static setStatusBar(bgColor: string, ftColor: string) {
        if (!window.plus) return;

        const ngv = window.plus.navigator;
        ngv.setStatusBarBackground(bgColor);
        ngv.setStatusBarStyle(ftColor);
    }
}
