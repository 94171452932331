import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import * as Keys from './keys';

extend(Keys.Required, {
    ...required,
    message: filedName => {
        return `请填写 ${filedName}.`;
    },
});
