











































































































































































































import navbar from '@/components/navbar.vue'
import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    PracticeChapterQuestionView,
    PracticeChapterReport,
    PracticeChapterList,
} from '@/domain/views';
import {
    CourseChapterQuestionsDetail,
    ChapterPractice,
    ChapterQuestionsDetailInfo,
    ExaminationInfo,
} from '@/models/ChapterPractice';
import { Loading } from 'vuetify-extension';
import Repositories from '@/infrastructure/repository';
import {
    ChapterReplies,
    Questions,
    NoRepliedXChapterQuestionIds,
    QuestionId,
    ChapterTrueQuestionIds,
    ChapterQuestionInfos,
    ChapterQuestions,
    chaptertaskType,
    sourcetype,
} from '@/domain/getters';
import Storage from '@/infrastructure/Storage';
import { ReplyStatus } from '@/models/ExamPractice';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@Component({
    components:{
        navbar,
    }
})
@RouteName(PracticeChapterReport)
export default class Report extends Vue {
    gradientColor = {
        '0%': '#FDAF95',
        '100%': '#F96E7D',
    };
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    currentRate = 0;
    Index = 0;
    @Inject(Navigator) navigator: Navigation;
    ChapterQuestionsResult = new ChapterQuestionsDetailInfo();
    ChapterQuestionLists = new Array<ChapterPractice>();
    TipsChapterQuestionLists = new Array<ExaminationInfo>();

    replies = new Array<CourseChapterQuestionsDetail>();
    Enum = {
        ReplyStatus: ReplyStatus,
    };
    trueQuestionIds = new Array<string>();
    falseQuestionIds = new Array<string>();
    partTrueQuestionIds = new Array<string>();
    NoRepliedQuestionIds = new Array<string>();
    ErrorQuestionIds = new Array<string>();
    get chapterQuestionsId() {
        return this.$route.query.chapterQuestionsId as string;
    }
    get relationType() {
        return this.$route.query.relationType as string;
    }
    get knowledgePointId() {
        return this.$route.query.knowledgePointId as string;
    }
    get count() {
        return this.$route.query.count as string;
    }

    @Loading('加载中...')
    async GetChapterQuestionsResult() {
        const data = await this.repository.chapterPractice.GetChapterQuestionsResult(
            this.chapterQuestionsId
        );
        this.ChapterQuestionsResult = data;
    }
    @Loading('加载中...')
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.returnQuestionReply);

        const chapterQuestions = Storage.Instance.get<Array<ExaminationInfo>>(
            ChapterQuestions
        );
        chapterQuestions.forEach((x: any) => {
            x.chapterPracticeOutput.forEach((p: any) => {
                this.Index++;
                p.Index = this.Index;
                this.ChapterQuestionLists.push(p);
            });
        });
        this.TipsChapterQuestionLists = chapterQuestions;
        this.replies = Storage.Instance.get<
            Array<CourseChapterQuestionsDetail>
        >(ChapterReplies);
        await this.GetChapterQuestionsResult();
    }

    GetReplyStatus(id: string) {
        const ReplyInfo = this.replies.filter(
            x => x.questionId == id && x.stuAnswer != ''
        )[0];
        if (ReplyInfo != undefined) {
            if (ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedTrue) {
                this.filterTrueQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedTrue;
            } else if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedFlase
            ) {
                this.filterFalseQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedFlase;
            } else if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedPartTrue
            ) {
                this.filterPartTrueQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedPartTrue;
            } else {
                this.filterNoRepliedQuestionIds(id);
                return this.Enum.ReplyStatus.NoReplyed;
            }
        } else {
            this.filterNoRepliedQuestionIds(id);
            return this.Enum.ReplyStatus.NoReplyed;
        }
    }

    filterNoRepliedQuestionIds(id: string) {
        if (this.NoRepliedQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.NoRepliedQuestionIds.push(id);
        }
    }
    filterPartTrueQuestionIds(id: string) {
        if (this.partTrueQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.partTrueQuestionIds.push(id);
        }
    }
    filterFalseQuestionIds(id: string) {
        if (this.falseQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.falseQuestionIds.push(id);
        }
    }
    filterTrueQuestionIds(id: string) {
        if (this.trueQuestionIds.indexOf(id) == -1)
            this.trueQuestionIds.push(id);
    }
    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Number((((num / total) * 10000) / 100).toFixed(1));
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        this.navigator.redirect(PracticeChapterQuestionView, '', {
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
            chapterQuestionsId: this.chapterQuestionsId,
        });
    }

    timestampToTime(timestamp: string) {
        const date = new Date(); //时间戳为10位需*10011，   时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + '/';
        const M =
            (date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1) + '/';
        const D =
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        return Y + M + D;
    }
    viewAllAlyasis() {
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        this.navigator.redirect(PracticeChapterQuestionView, '', {
            relationType: this.relationType,
            knowledgePointId: this.knowledgePointId,
            chapterQuestionsId: this.chapterQuestionsId,
        });
    }
    viewErrorAlyasis() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.set(
            NoRepliedXChapterQuestionIds,
            this.ErrorQuestionIds
        );
        Storage.Instance.set(ChapterTrueQuestionIds, this.trueQuestionIds);
        if (this.ErrorQuestionIds.length <= 0) {
            this.notifier.success('恭喜您满分通过测试，没有错题记录。');
            return;
        } else {
            this.navigator.redirect(PracticeChapterQuestionView, '', {
                relationType: this.relationType,
                knowledgePointId: this.knowledgePointId,
                chapterQuestionsId: this.chapterQuestionsId,
            });
        }
    }
    returnQuestionReply() {
        this.clearStroage();
        this.navigator.redirect(PracticeChapterList, '', {
            Type: this.relationType,
        });
    }
    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(ChapterQuestionInfos);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        Storage.Instance.clear(ChapterReplies);
        Storage.Instance.clear(ChapterQuestions);
        Storage.Instance.clear(Questions);
        Storage.Instance.clear(chaptertaskType);
        Storage.Instance.clear(sourcetype);
    }
}
