












import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator } from '@/domain/providers';
import { ReviewList } from '@/domain/views';
import { Navigation } from '@/infrastructure/navigation';
@Component({
    components: {},
})
export default class ReviewWrongTopicListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;

    reviewList() {
        this.navigator.redirect(ReviewList);
    }
}
