var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height theme-bg--color",attrs:{"no-gutters":""}},[(_vm.subjectCount > 1)?_c('v-col',{staticClass:"fill-height list-navigation-bar mr-2",attrs:{"cols":"auto"}},[(this.type==2)?_c('van-sidebar',{staticClass:"left_w",model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('van-sidebar-item',{key:"3",attrs:{"title":"全真试卷"},on:{"click":function($event){return _vm.changePracticeType('5', 3)}}}),_c('van-sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSecret),expression:"isSecret"}],key:"4",attrs:{"title":"密训试卷"},on:{"click":function($event){return _vm.changePracticeType('14', 4)}}})],1):_c('van-sidebar',{staticClass:"left_w",model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_c('van-sidebar-item',{key:"1",attrs:{"title":"章节练习"},on:{"click":function($event){return _vm.changePracticeType('2', 1)}}}),_c('van-sidebar-item',{key:"2",attrs:{"title":"章节强化"},on:{"click":function($event){return _vm.changePracticeType('3', 2)}}}),_c('van-sidebar-item',{key:"3",attrs:{"title":"全真试卷"},on:{"click":function($event){return _vm.changePracticeType('5', 3)}}}),_c('van-sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSecret),expression:"isSecret"}],key:"4",attrs:{"title":"密训试卷"},on:{"click":function($event){return _vm.changePracticeType('14', 4)}}})],1)],1):_vm._e(),_c('v-col',{staticClass:"list-content"},[(_vm.currentPracticeType == 5||_vm.currentPracticeType == 14)?_c('div',_vm._l((_vm.recordList),function(record){return _c('v-card',{key:record.id,staticClass:"rounded-0",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"body-2 px-5 pt-5 pb-4 text-grey-3 card-active-bg",on:{"click":function($event){return _vm.redirectPaperReport(
                            record.id,
                            record.paperId,
                            record.containLunShu
                        )}}},[_c('div',[_c('p',{staticClass:"answerRecord-title-overflow"},[_vm._v(" "+_vm._s(record.title)+" ")]),_c('p',{staticClass:"body-4 text-grey"},[_c('span',[_vm._v(_vm._s(record.date))]),_c('span',{staticClass:"px-2"}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!record.containLunShu),expression:"!record.containLunShu"}]},[_vm._v(" 得分:"+_vm._s(record.score)+" ")])])]),_c('v-spacer'),_c('p',{staticClass:"d-inline-block"},[_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#251A17","size":"0.32rem"}},[_vm._v(" hk-return-right ")])],1)],1),_c('v-divider')],1)}),1):_vm._e(),(_vm.currentPracticeType == 2 || _vm.currentPracticeType == 3)?_c('div',_vm._l((_vm.recordChapterList),function(record){return _c('v-card',{key:record.id,staticClass:"rounded-0",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"body-2 px-5 pt-5 pb-4 text-grey-3 card-active-bg",on:{"click":function($event){return _vm.redirectChapterReport(
                            record.id,
                            record.knowLedgePointId,
                            _vm.currentPracticeType
                        )}}},[_c('p',{staticClass:"chapter-title-overflow"},[_vm._v(" "+_vm._s(record.title)+" ")]),_c('p',{staticClass:"body-4 text-grey"},[_c('span',[_vm._v(_vm._s(record.date))]),_c('span',{staticClass:"px-2"},[_vm._v("共做"+_vm._s(record.alls))]),_c('span',[_vm._v("答对"+_vm._s(record.rights))])]),_c('v-spacer'),_c('p',{staticClass:"d-inline-block mt-n4"},[_c('v-icon',{staticClass:"iconfont",attrs:{"color":"#251A17","size":"0.32rem"}},[_vm._v(" hk-return-right ")])],1)],1),_c('v-divider')],1)}),1):_vm._e(),(_vm.recordChapterList.length == 0 && _vm.recordList.length == 0)?_c('div',[(_vm.subjectCount > 1)?_c('div',{staticClass:"text-grey-3 py-7 list-noData"},[_c('p',{staticClass:"caption text-center"},[_vm._v(_vm._s(_vm.dataTipMsg))])]):(_vm.isCompleted)?_c('div',{staticClass:"text-grey-3 py-7 list-data"},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"2.64rem","src":require("@/assets/images/zanwunrong.png")}}),_c('p',{staticClass:"caption text-center"},[_vm._v("暂无数据")])],1):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }