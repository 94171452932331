


































































import navbar from '@/components/navbar.vue';
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Context, EnableProp, RouteName } from 'ea-router';
import { BatchDel } from '@/domain/views';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import Storage from '@/infrastructure/Storage';
import { DowList } from '@/domain/getters';
import {InputsModel} from '@/infrastructure/model/CourseAddOrEditQuery';
import Repositories from '@/infrastructure/repository';
import { Notification } from 'vuetify-extension';
import { StatusMap } from '@/models/ExamPractice';
@RouteName(BatchDel)
@Context('courseId')
@EnableProp()

@Component({
    components: {
        navbar
    },
})
export default class BatchDelPage extends Vue { 
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    @Ref('checkboxGroup') readonly checkboxGroup!: any;
    result:any = [];
    dowListsView  = new Array<InputsModel>();
    checkedS = true;
    ids: string = '';
    Enum = {
        statusMap: StatusMap
    }
    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
        this.dowListsView = Storage.Instance.get<Array<InputsModel>>(
           DowList
        );
        if(this.dowListsView.length>0) {
            this.$nextTick(() => {
                this.checkboxGroup.toggleAll({
                  checked: true
                });
            })
        }
       
    }
    ToUserPackageDetail() {
        this.navigator.back();
    }
    // 单个选择
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    }
    checkGroupChange() {
        if(this.result.length === this.dowListsView.length) {
            this.checkedS = true;
        }else {
            this.checkedS = false;
        }
    }
    // 全选触发
    checkAll() {
        this.checkedS = !this.checkedS;
        if(this.checkedS) {
            this.checkboxGroup.toggleAll({
              checked: true,
              skipDisabled: true,
            });
        }else {
            this.checkboxGroup.toggleAll({skipDisabled: true});
        }
    }
    // 删除
    @Loading(LoadingMsg)
    async del() {
        let str = '';
        let ids = [];
        let that = this;
        this.result.forEach((item: any) => {
            ids.push(item.id);
            if(item.status === 3) {
                // 完成状态需要删除手机中的视频
                str = item.videoUrl.split("/");
                that.delFile('_downloads/'+str[str.length-1])
            }
        })
        this.ids = ids.join();
        let set=this.result.map(item=>item.id);
        let resArr=this.dowListsView.filter(item=>!set.includes(item.id));
        this.dowListsView = resArr;
        await this.delFu();
        // Storage.Instance.set(DowList, resArr);
    }
    // 删除手机中的视频
    delFile(relativePath) {
        window.plus.io.resolveLocalFileSystemURL(relativePath, function(entry) {
          entry.remove(function(entry) {
            // Notification.success('文件删除成功');
            // alert("文件删除成功==" + relativePath);
          }, function(e) {
            // alert("文件删除失败=" + relativePath);
          });
        });
    }
    // 删除
    async delFu() {
        const data = await this.repository.courseAppraise.VideoDowListDelete(
            {ids:this.ids}
        );
        if(data.success == true) {
            await Notification.success('删除成功');
            await this.statusChange();
        }
        
    }
    // 批量删除改状态
    async statusChange() {
        await this.repository.courseAppraise.UpdateStatus(
            {id:this.ids,status:'0'}
        );
    }

}
