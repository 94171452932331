





































































































































































































import { Component, Inject, Ref, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import {
  ExaminationPaperInfo,
  PaperQuestionInfo,
  QuestionDetailInfo,
  QuestionType, SubjectRedisInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import { RouteName } from 'ea-router';
import {
    PracticeExamDetail,
    PracticeExamQuestionReply,
    PracticeExamTopicList,
    PracticeExamReport,
    PracticeExamSubjectiveItemReport,
} from '@/domain/views';

import QuestionReplyComponent from '@/components/questionviews/QuestionView.vue';
import Analysis from '@/components/questionviews/Analysis.vue';

import { Dialog } from 'vant';

import { Navigation } from '@/infrastructure/navigation';
import { LoadingTestPaper, LoadingHandPaper } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {
  NoRepliedQuestionIds,
  Replies,
  ExaminationPaperInfos,
  PaperQuestions,
  QuestionId,
  TrueQuestionIds,
  taskType, DateNow, examsubjectInfo
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
    },
})
@RouteName(PracticeExamQuestionReply)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Ref('CountDown') readonly CountDown!: any;
    PaperQuestions = new Array<PaperQuestionInfo>();
    ExaminationPaper = new ExaminationPaperInfo();
    Questions = new Array<QuestionDetailInfo>();
    IsShowAnswer = false;
    IsRepeat = false;
    IsShowAnswerBtn = true;
    IsShowCollectioned = false;
    question = new QuestionDetailInfo();
    currentQuestionIndex = 1;
    collectShow = false;
    isShowRighteAnswer = false;
    testTimeView:number = 5400000;
    countDownTimer:any = null;
    isEnable = false;
    Enum = {
        questionType: QuestionType,
    };
    TotalCount = 0;

    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get paperId() {
        return this.$route.query.paperId as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get testTime() {
        return this.$route.query.testTime as string;
    }


    get paperQuestionsRedis() {
        return Storage.Instance.get<Array<PaperQuestionInfo>>(
            PaperQuestions
        );
    }
    get ExaminationPaperRedis() {
        return Storage.Instance.get<ExaminationPaperInfo>(
            ExaminationPaperInfos
        );
    }
  get DateTimeNow() {
    return Storage.Instance.get<Date>(
        DateNow
    );
  }

    get replies() {
        return this.Questions.map(x => {
            if (x.stuAnswer != null) {
                return {
                    questionId: x.id,
                    answer: x.stuAnswer,
                    type: x.subjectType,
                    paperId: this.paperId,
                    Score: this.ExaminationPaper.questionTypes.filter(
                        p => p.questionTypeId == x.subjectType
                    )[0].questionScore,
                    PaperRecordsId: this.paperRecordsId,
                };
            }
        });
    }

    @Loading(LoadingTestPaper)
    async GetPaperQuestions() {
        clearInterval(this.countDownTimer);
        const Data = await this.repository.exampractice.GetPaperInfo(
            this.paperId,
            this.paperRecordsId
        );
        this.PaperQuestions = Data;
        this.question = this.PaperQuestions[0].question[0];
        Storage.Instance.set(PaperQuestions, this.PaperQuestions);
    }
    async GetExaminationPaper() {
        const data = await this.repository.exampractice.GetExaminationPaper(
            {
                paperId: this.paperId,
                paperRecordsId: this.paperRecordsId,
            }
        );
        this.ExaminationPaper = data;
        Storage.Instance.set(ExaminationPaperInfos, this.ExaminationPaper);
    }

    //当作答记录发生改变时 记录
    async changedReplies(question: QuestionDetailInfo) {
        //单选作答实现自动切题
        const that = this;
        console.log(question);
        console.log(QuestionType);

        if (question.subjectType == QuestionType.Single) {
            setTimeout(async function() {
                await that.nextQuestion();
            }, 1 * 100);
        }
        Storage.Instance.set(Replies, this.replies);
        Storage.Instance.set(PaperQuestions, this.PaperQuestions);
    }
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);
        if (
            this.paperQuestionsRedis != null &&
            this.ExaminationPaperRedis != null
        ) {
            await this.HandInLocalData();
            this.ExaminationPaper = this.ExaminationPaperRedis;

        } else {
          await this.GetPaperQuestions();
          await this.GetExaminationPaper();
        }

      //处理倒计时部分的逻辑
      let begindate=new Date(this.DateTimeNow);
      let enddate=new Date();
      let min=begindate.getMinutes();
      begindate.setMinutes(min+this.ExaminationPaper.testTime);
      let timediff=this.DateDifference(enddate,begindate);
      this.testTimeView = timediff*1000;
    //   await this.CountDown.pause();
    //   this.countTime(timediff);

        await this.GetAllQuestions();
        this.currentQuestionIndex = this.question.rowsIndex;
        this.TotalCount = this.Questions.length;
        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }
  DateDifference(faultDate,completeTime) {
    let stime = new Date(faultDate).getTime();
    let etime = new Date(completeTime).getTime();
    let usedTime = etime - stime;  //两个时间戳相差的毫秒数
    let minutes = (usedTime % (1000 * 60 * 60)) / (1000 * 60);
    let hours = ((usedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let seconds = (usedTime % (1000 * 60)) / 1000;
    let difftime= Math.floor(hours) * 3600+ Math.floor(minutes)*60+Math.floor(seconds);
    console.log(difftime);
    return difftime;
  }
  async HandInLocalData() {
        this.PaperQuestions = this.paperQuestionsRedis;
        if (this.questionId != undefined && this.questionId != null) {
            this.GetCurrentQuestion(this.questionId);
        } else {
            this.question = this.PaperQuestions[0].question[0];
        }
    }

    GetCurrentQuestion(questionId: string) {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if (x.id == questionId) {
                    this.GetComprehensiveType(p, x);
                    this.question = x;
                }
            });
        });
    }
    async GetAllQuestions() {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                this.GetComprehensiveType(p, x);
                this.Questions.push(x);
            });
        });
    }
    GetComprehensiveType(p: PaperQuestionInfo, x: QuestionDetailInfo) {
        if (p.questionType == this.Enum.questionType.Comprehensive) {
            x.subjectTypeName = '案例分析题';
        } else if (p.questionType == this.Enum.questionType.LunShu) {
            x.subjectTypeName = '论述题';
        }
    }

    async preQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async nextQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex >= this.TotalCount) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }
    async HandlerBtnController() {
        this.IsShowAnswer = false;
        this.IsShowAnswerBtn = true;
        this.IsRepeat = false;
        this.IsShowCollectioned = false;
        this.isShowRighteAnswer = false;
    }
    async HandlerChangeQuestion() {
        this.question = this.Questions.filter(
            x => x.rowsIndex == this.currentQuestionIndex
        )[0];
        Storage.Instance.set(QuestionId, this.question.id);
    }

    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data <= 0) {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        } else {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        }
    }

    async handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }
    async handlerReplies() {
        this.replies
            .filter(x => x != null)
            .forEach(x => {
                if (x.type == this.Enum.questionType.Muti) {
                    if (x.answer.indexOf(',') == -1)
                      x.answer = ((x.answer as unknown) as Array<
                          string
                          >).join(',');
                }
            });
    }
    @Loading(LoadingHandPaper)
    async handIn() {
        // if (
        //     this.replies.filter(x => x != null && x.answer != '').length ==
        //     0
        // ) {
        //     this.notifier.warn('您未开始做题,不能交卷');
        //     return;
        // }
        await this.handlerReplies();
        const data = await this.repository.exampractice.AnswerQuestion(
            this.replies.filter(x => x != null && x.answer != '')
        );
        this.notifier.success('交卷成功');
        //手动交卷成功后需要把定时器清除
         clearInterval(this.countDownTimer);
        console.log('确认');
        Storage.Instance.set(Replies, data.result);
        if (
            this.Questions.filter(
                x => x.subjectType == this.Enum.questionType.LunShu
            ).length >= this.Questions.length
        ) {
            this.navigator.redirect(PracticeExamSubjectiveItemReport, '', {
                count: this.Questions.length,
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            });
        } else {
            this.navigator.redirect(PracticeExamReport, '', {
                count: this.Questions.length,
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            });
        }
    }
    async viewAnswer(question: QuestionDetailInfo) {
        this.isShowRighteAnswer = true;
        this.isEnable = true;
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowCollectioned = true;
        } else {
            this.IsRepeat = true;
            this.IsShowCollectioned = false;
        }

        this.IsShowAnswer = true;
        this.IsShowAnswerBtn = false;
    }
    viewQuestionItems() {
        this.navigator.redirect(PracticeExamTopicList, '', {
            IsShowHand: true,
            count: this.Questions.length,
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }

    redirectList() {
        Dialog.confirm({
            title: '提示',
            message:
                '直接退出后，答题记录将不会被保存\n如需保存记录，请点击“交卷”按钮',
            confirmButtonText: '继续答题',
            cancelButtonText: '仍要退出',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {})
            .catch(() => {
                clearInterval(this.countDownTimer);
                this.ConfirmRedirect();
            });
    }
    clearStorage() {
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Replies);
        Storage.Instance.clear(ExaminationPaperInfos);
        Storage.Instance.clear(PaperQuestions);
        Storage.Instance.clear(taskType);
        Storage.Instance.clear(DateNow);
        clearInterval(this.countDownTimer);
    }
    ConfirmRedirect() {
        this.clearStorage();
      const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(
          examsubjectInfo
      );
        this.navigator.redirect(PracticeExamDetail, '', {
            groupId: this.groupId,
            Type:subjectinfo.Type
        });
    }
    // 标记
    signChange(type) {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if(x.id == this.Questions[this.currentQuestionIndex-1].id) {
                    this.$set(x,'signActive',type==1?true:false)
                }
            });
        });
        this.notifier.success(type==1?'已标记':'已取消标记')
        Storage.Instance.set(PaperQuestions, this.PaperQuestions);
    }
    // 倒计时
    //将秒转化为时分秒
    formateSeconds(endTime){
        if(endTime && endTime > 0) {
            let secondTime = Math.floor(endTime)//将传入的秒的值转化为Number
            let h, m, s;
            if (secondTime >= 0) {
                h = Math.floor(secondTime / 60 / 60);
                m = Math.floor(secondTime / 60 % 60 );
                s = Math.floor(secondTime % 60);
                h = h < 10 ? ("0" + h) : h;
                m = m < 10 ? ("0" + m) : m;
                s = s < 10 ? ("0" + s) : s;
            }
            // this.testTimeView =`${h.toString().padStart(2,'0')}:${m.toString().padStart(2,'0')}:${s.toString().padStart(2,'0')}`;
        }else {
            // 交卷
            clearInterval(this.countDownTimer);
            this.handIn();
        }
    }
    countTime(differTime) {
      //这里的倒计时需要考虑从纠错退回来的情况,会导致值丢失的情况引发时间为0,提前交卷的情况出现
        if (differTime  && differTime > 0) {
            this.countDownTimer = setInterval(() => {
                this.formateSeconds(differTime--);
            },1000)
        } else {
            // 交卷
           clearInterval(this.countDownTimer);
            this.handIn();
        }
    }

}
