import { PagedList } from '@/domain/PagedList';
import {
    GetAppraiseListByCourseIdQuery,
    CourseAppraiseItem,
    CourseAddOrEditQuery,
    ActionResult,
    CourseLearnProgressModel,
    MyVideoWatchInput,
    IdsModel,
    UpdateStatusExanModel, InputsModels,InputsModel
} from '@/infrastructure/model/CourseAddOrEditQuery';
import AxiosHttp from '@/infrastructure/http';

export class CourseAppraiseRepository {
    GetAppraiseListByCourseId(
        query: GetAppraiseListByCourseIdQuery
    ): Promise<PagedList<CourseAppraiseItem>> {
        return AxiosHttp.Instance.post<PagedList<CourseAppraiseItem>>(
            '/CourseAppraise/GetCourseAppraiseByCourseId',
            query
        );
    }
    AddOrEdit(query: CourseAddOrEditQuery): Promise<ActionResult> {
        return AxiosHttp.Instance.post<ActionResult>(
            '/CourseAppraise/AddCourseAppraise',
            query
        );
    }

    GetVideoProgress(videoid: string): Promise<MyVideoWatchInput> {
        return AxiosHttp.Instance.get<MyVideoWatchInput>(
            '/CourseAppraise/GetVideoProgress?videoid=' + videoid
        );
    }

    AddVideoProgress(CourseLearnProgressModelInput: CourseLearnProgressModel) {
        return AxiosHttp.Instance.post<string>(
            '/CourseAppraise/AddVideoProgress',
            CourseLearnProgressModelInput
        );
    }
    UpdateVideoProgress(
        CourseLearnProgressModelInput: CourseLearnProgressModel
    ) {
        return AxiosHttp.Instance.post<string>(
            '/CourseAppraise/AddCourseStudyRecord',
            CourseLearnProgressModelInput
        );
    }
    // 可缓存视频列表
    GetCourseVideoLists(courseid: string): Promise<Array<InputsModel>> {
        return AxiosHttp.Instance.get<Array<InputsModel>>(
            '/CourseAppraise/GetCourseVideoLists?courseid=' + courseid
        );
    }
    // 添加缓存视频
    AddOrEditCacheVideo(CourseLearnProgressModelInput: InputsModels) {
        return AxiosHttp.Instance.post<ActionResult>(
            '/CourseAppraise/AddOrEdit',
            CourseLearnProgressModelInput
        );
    }
    // 缓存列表
    GetList(courseid: string): Promise<MyVideoWatchInput> {
        return AxiosHttp.Instance.get<MyVideoWatchInput>(
            '/CourseAppraise/GetList?courseid=' + courseid
        );
    }
    // 删除缓存列表
    VideoDowListDelete(ids: IdsModel) {
        return AxiosHttp.Instance.post<ActionResult>(
            '/CourseAppraise/UpdateVideo',
            ids
        );
    }
    // 修改下载状态
    UpdateStatus(UpdateStatusExan: UpdateStatusExanModel) {
        const data = {
            id:UpdateStatusExan.id,
            status:UpdateStatusExan.status,
        }
        return AxiosHttp.Instance.post<string>(
            '/CourseAppraise/UpdateStatus',
            data
        );
    }
    // 缓存视频进度
    AddVideoProgressHc(CourseLearnProgressModelInput: CourseLearnProgressModel) {
        return AxiosHttp.Instance.post<string>(
            '/CourseAppraise/AddVideoProgressHc',
            CourseLearnProgressModelInput
        );
    }
    // 获取缓存进度
    GetVideoProgressHc(videoid: string): Promise<MyVideoWatchInput> {
        return AxiosHttp.Instance.get<MyVideoWatchInput>(
            '/CourseAppraise/GetVideoProgressHc?videoid=' + videoid
        );
    }
}
