


























import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { ReviewAdvancedList } from '@/domain/views';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import NavigationBar from '@/components/Review/answerRecord/NavigationBar.vue';
import answerRecordList from '@/components/Review/answerRecord/List.vue';
@RouteName(ReviewAdvancedList)
@Component({
    components: { NavigationBar, answerRecordList },
})
export default class ReviewAdvancedListPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    practice = [
        { practiceList: '章节练习' },
        { practiceList: '章节强化' },
        { practiceList: '全真试卷' },
    ];
}
