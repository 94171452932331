



















































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    LoginAuthenticationCode,
    LoginCompleteInfo,
    LoginPassword,
    MyInfo,
    UserLogin,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Repositories from '@/infrastructure/repository';
import { Login, MatchExam } from '@/domain/actions';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(LoginAuthenticationCode)
@Component
export default class LoginAuthenticationCodePageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    @Inject(Repository) repository: Repositories;

    showKeyboard = true;
    inputSmsValue = '';
    phoneNumberShow = '';
    smsSendBtnTxt = '获取验证码';
    isShowSendSmsBtn = true;
    isOpType = true;
    time = 60;
    smsCodeLen = 6;

    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }
    get OpType() {
        return this.$route.query.OpType as string;
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.loginPassword);

        this.phoneNumberShow =
            this.numPhone.substr(0, 3) +
            ' ' +
            this.numPhone.substr(3, 4) +
            ' ' +
            this.numPhone.substr(7, 4);

        if (this.OpType == 'reg') {
            this.isOpType = false;
        } else {
            this.isOpType = true;
        }

        await this.SendSms();
    }

    //发送短信
    @Loading(LoadingMsg)
    async SendSms() {
        const response = await this.repository.user.SendSmsInfo(
            this.numPhone
        );
        if (response.success) {
            this.isShowSendSmsBtn = false;
            this.notifier.success(response.message, 2000);
            this.DescTime();
        } else {
            this.notifier.error(response.message);
        }
    }

    //检查验证码输入
    async InputSmsCode(key) {
        this.inputSmsValue = (this.inputSmsValue + key).slice(
            0,
            this.smsCodeLen
        );
        if (this.inputSmsValue.length == this.smsCodeLen) {
            if (this.OpType == 'reg') {
                await this.RegNewUser();
            } else {
                //验证码登陆
                await this.SmsLogin();
            }
        }
    }
    DeleteSmsCode() {
        this.inputSmsValue = this.inputSmsValue.slice(
            0,
            this.inputSmsValue.length - 1
        );
    }

    //倒计时
    DescTime() {
        if (this.time <= 0) {
            this.isShowSendSmsBtn = true;
            this.smsSendBtnTxt = '重新获取验证码';
            this.time = 60;
        } else {
            setTimeout(this.DescTime, 1000);
            this.time--;
        }
    }

    //短信验证码登陆
    @Loading(LoadingMsg)
    async SmsLogin() {
        try {
            const response = await this.repository.user.SmsLogin(
                this.numPhone,
                this.inputSmsValue
            );
            await this.$store.dispatch(Login, response);
            const data = await this.repository.user.getCurrentExamInfo();
            await this.$store.dispatch(MatchExam, data);
            this.notifier.success('登录成功!');
            this.navigator.back();
            //this.navigator.redirect(MyInfo);
        } catch (e) {
            this.notifier.error(e);
        }
    }

    //注册用户，完善资料
    @Loading(LoadingMsg)
    async RegNewUser() {
        const response = await this.repository.user.CheckPhoneSmsCode(
            this.numPhone,
            this.inputSmsValue
        );
        if (response.success) {
            this.navigator.replace(LoginCompleteInfo, {
                phoneNumber: this.numPhone,
            });
        } else {
            this.notifier.error(response.message);
        }
    }

    loginPassword() {
        let url = LoginPassword;
        if (this.OpType == 'reg') {
            url = UserLogin;
        }
        this.navigator.replace(url, {
            phoneNumber: this.numPhone,
        });
    }
}
