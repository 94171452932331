















































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    ReviewAnswerRecordList,
    ReviewAnswerRecordChapterReport,
    PracticeChapterQuestionView,
    PracticeChapterList,
} from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import {
    ChapterPractice,
    ChapterQuestionInfo,
    ChapterQuestionsDetailInfo,
    ExaminationInfo,
} from '@/models/ChapterPractice';
import { CourseChapterQuestionsDetail } from '@/models/ChapterPractice';
import Storage from '@/infrastructure/Storage';
import {
    ChapterQuestions,
    ChapterReplies,
    ChapterTrueQuestionIds,
    NoRepliedXChapterQuestionIds,
    QuestionId,
    chaptertaskType,
    ChapterQuestionInfos,
    sourcetype,
} from '@/domain/getters';
import { ReplyStatus } from '@/models/ExamPractice';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@Component
@RouteName(ReviewAnswerRecordChapterReport)
export default class Report extends Vue {
    gradientColor = {
        '0%': '#fdaf95',
        '100%': '#F96E7D',
    };
    chapterQuestions = new Array<ExaminationInfo>();
    ChapterQuestionsResult = new ChapterQuestionsDetailInfo();
    Enum = {
        ReplyStatus: ReplyStatus,
    };
    chapterQuestionInfo = new ChapterQuestionInfo();
    trueQuestionIds = new Array<string>();
    falseQuestionIds = new Array<string>();
    partTrueQuestionIds = new Array<string>();
    NoRepliedQuestionIds = new Array<string>();
    ErrorQuestionIds = new Array<string>();
    Questions = new Array<ChapterPractice>();
    Index = 0;
    get Type() {
        return this.$route.query.relationType as string;
    }
    get chapterQuestionsId() {
        return this.$route.query.chapterQuestionsId as string;
    }
    get knowledgePointId() {
        return this.$route.query.knowledgePointId as string;
    }
    get sourceType() {
        return Storage.Instance.get<string>(sourcetype);
    }
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    async GetChapterQuestion() {
        const Data = await this.repository.chapterPractice.GetChapterPracticeList(
            this.knowledgePointId,
            this.Type
        );
        this.chapterQuestions = Data;
        this.chapterQuestions.forEach((p: any) => {
            p.chapterPracticeOutput.forEach((k: any) => {
                if (
                    this.ChapterQuestionsResult.chapterQuestionsDetailOutput.filter(
                        x => x.questionId == k.id
                    ).length > 0
                ) {
                    const question = this.ChapterQuestionsResult.chapterQuestionsDetailOutput.filter(
                        x => x.questionId == k.id
                    )[0];
                    k.stuAnswer = question.stuAnswer;
                    k.result = question.result;
                } else {
                    k.result = [];
                }
            });
        });
        Storage.Instance.set(ChapterQuestions, this.chapterQuestions);
    }
    async GetChapterQuestionInofs() {
        if (this.knowledgePointId != null) {
            const Data = await this.repository.chapterPractice.ChapterPractice(
                {
                    knowLedgePoint: this.knowledgePointId,
                    relationType: this.Type,
                }
            );
            this.chapterQuestionInfo = Data;
            Storage.Instance.set(
                ChapterQuestionInfos,
                this.chapterQuestionInfo
            );
        }
    }
    async GetAllQuestions() {
        this.chapterQuestions.forEach((x: any) => {
            x.chapterPracticeOutput.forEach((p: any) => {
                this.Index++;
                p.Index = this.Index;
                this.Questions.push(p);
            });
        });
    }
    @Loading('加载中...')
    async GetChapterQuestionsResult() {
        const data = await this.repository.chapterPractice.GetChapterQuestionsResult(
            this.chapterQuestionsId
        );
        this.ChapterQuestionsResult = data;
        //replies-redis本地缓存
        const inputs = new Array<CourseChapterQuestionsDetail>();
        this.ChapterQuestionsResult.chapterQuestionsDetailOutput.forEach(
            x => {
                const replyInfo = new CourseChapterQuestionsDetail();
                replyInfo.stuAnswer = x.stuAnswer;
                replyInfo.questionId = x.questionId;
                inputs.push(replyInfo);
            }
        );
        Storage.Instance.set(ChapterReplies, inputs);
    }
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.reviewAnswerRecordList);

        await this.GetChapterQuestionsResult();
        await this.GetChapterQuestion();
        await this.GetAllQuestions();
        await this.GetChapterQuestionInofs();
    }
    GetReplyStatus(id: string) {
        const ReplyInfo = this.ChapterQuestionsResult.chapterQuestionsDetailOutput.filter(
            x => x.questionId == id && x.stuAnswer != ''
        )[0];
        if (ReplyInfo != undefined) {
            if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedTrue
            ) {
                this.filterTrueQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedTrue;
            } else if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedFlase
            ) {
                this.filterFalseQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedFlase;
            } else if (
                ReplyInfo.replyStatus ==
                this.Enum.ReplyStatus.ReplyedPartTrue
            ) {
                this.filterPartTrueQuestionIds(id);
                return this.Enum.ReplyStatus.ReplyedPartTrue;
            } else {
                this.filterNoRepliedQuestionIds(id);
                return this.Enum.ReplyStatus.NoReplyed;
            }
        } else {
            this.filterNoRepliedQuestionIds(id);
            return this.Enum.ReplyStatus.NoReplyed;
        }
    }

    filterNoRepliedQuestionIds(id: string) {
        if (this.NoRepliedQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.NoRepliedQuestionIds.push(id);
        }
    }
    filterPartTrueQuestionIds(id: string) {
        if (this.partTrueQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.partTrueQuestionIds.push(id);
        }
    }
    filterFalseQuestionIds(id: string) {
        if (this.falseQuestionIds.indexOf(id) == -1) {
            this.ErrorQuestionIds.push(id);
            this.falseQuestionIds.push(id);
        }
    }
    filterTrueQuestionIds(id: string) {
        if (this.trueQuestionIds.indexOf(id) == -1)
            this.trueQuestionIds.push(id);
    }
    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Number((((num / total) * 10000) / 100).toFixed(1));
    }
    timestampToTime(timestamp: string) {
        if (timestamp == null || timestamp == '') {
            return '';
        }
        const date = new Date(timestamp); //时间戳为10位需*10011，时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + '/';
        const M =
            (date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1) + '/';
        const D =
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            ' ';
        return Y + M + D;
    }

    reviewAnswerRecordList() {
        if (this.sourceType == '1')
            this.navigator.redirect(PracticeChapterList, '', {
                Type: this.Type,
            });
        else this.navigator.redirect(ReviewAnswerRecordList);
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        Storage.Instance.set(chaptertaskType, 1);
        this.navigator.redirect(PracticeChapterQuestionView, '', {
            relationType: this.Type,
            knowledgePointId: this.knowledgePointId,
            chapterQuestionsId: this.chapterQuestionsId,
        });
    }
    reviewAnswerRecordQuestionView() {
        Storage.Instance.clear(ChapterTrueQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedXChapterQuestionIds);
        Storage.Instance.set(chaptertaskType, 1);
        this.navigator.redirect(PracticeChapterQuestionView, '', {
            relationType: this.Type,
            knowledgePointId: this.knowledgePointId,
            chapterQuestionsId: this.chapterQuestionsId,
        });
    }
    viewErrorAlyasis() {
        Storage.Instance.set(chaptertaskType, 1);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.set(
            NoRepliedXChapterQuestionIds,
            this.ErrorQuestionIds
        );
        Storage.Instance.set(ChapterTrueQuestionIds, this.trueQuestionIds);
        if (this.ErrorQuestionIds.length <= 0) {
            this.notifier.success('恭喜您满分通过测试，没有错题记录。');
            return;
        } else {
            this.navigator.redirect(PracticeChapterQuestionView, '', {
                relationType: this.Type,
                knowledgePointId: this.knowledgePointId,
                chapterQuestionsId: this.chapterQuestionsId,
            });
        }
    }
}
