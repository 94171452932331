import AxiosHttp from '@/infrastructure/http';
import { PackageDetail } from '@/models/PackageDetail';
import { ProductGroup, ProductGroupSearch } from '@/models/ProductGroup';
import { ProductCategoryGroup } from '@/models/ProductCategoryGroup';

export default class ProductRepository {
    Package(id: string) {
        const param = {
            id: id,
        };
        return AxiosHttp.Instance.get<PackageDetail>('/Product/Package', param);
    }

    ProductGroup(param: ProductGroupSearch) {
        return AxiosHttp.Instance.post<ProductGroup[]>(
            '/Product/ProductGroup',
            param
        );
    }
    ProductCategoryGroup(param: ProductGroupSearch) {
        return AxiosHttp.Instance.post<ProductCategoryGroup[]>(
            '/Product/ProductCategoryGroup',
            param
        );
    }
}
