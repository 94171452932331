var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.courseChapters),function(courseChapterInfo,index){return _c('div',{key:index,staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"px-5 py-3 body-2 text-grey-9",attrs:{"no-gutters":""},on:{"click":function($event){_vm.CurrentCourseParentChapterIds.indexOf(
                    courseChapterInfo.name
                ) >= 0
                    ? _vm.parentCoursecallpase(courseChapterInfo)
                    : _vm.parentCourseexpand(courseChapterInfo)}}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[(!_vm.isHasPreMission(courseChapterInfo))?_c('div',[(
                            !_vm.isFree(courseChapterInfo) &&
                                courseChapterInfo.pid == '0'
                        )?_c('div',[_c('v-chip',{staticClass:"px-2 orange--text",attrs:{"color":"#FFE7DE","x-small":"","label":""}},[_vm._v(" 付费 ")])],1):(
                            _vm.isFree(courseChapterInfo) &&
                                courseChapterInfo.pid == '0'
                        )?_c('div',[_c('v-chip',{staticClass:"px-2 green--text",attrs:{"color":"#DFF2E8","x-small":"","label":""}},[_vm._v(" 试听 ")])],1):_vm._e()]):_c('div',[(courseChapterInfo.pid === '0')?_c('div',[_c('v-chip',{staticClass:"px-2 primary--text",attrs:{"color":"#E4EFFD","x-small":"","label":""}},[_vm._v(" 学习 ")])],1):_vm._e()])]),_c('v-col',[(courseChapterInfo.name.indexOf('(*)') != -1)?_c('div',[_vm._v(" "+_vm._s(courseChapterInfo.name.replace('(*)', ''))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfo.name)+" ")])]),_c('v-col',{staticClass:"px-2 arrows",attrs:{"cols":"auto"}},[(
                        courseChapterInfo.children != null &&
                            _vm.CurrentCourseParentChapterIds.indexOf(
                                courseChapterInfo.name
                            ) >= 0
                    )?_c('v-img',{attrs:{"width":"0.42rem","height":"0.42rem","src":require("@/assets/images/open.png")}}):_c('v-img',{attrs:{"width":"0.42rem","height":"0.42rem","src":require("@/assets/images/close.png")}})],1)],1),(courseChapterInfo.children != null)?_c('div',_vm._l((courseChapterInfo.children),function(courseChapterInfoNode,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.CurrentCourseParentChapterIds.indexOf(
                        courseChapterInfo.name
                    ) >= 0
                ),expression:"\n                    CurrentCourseParentChapterIds.indexOf(\n                        courseChapterInfo.name\n                    ) >= 0\n                "}],key:index,staticClass:"part-title  user-card-bg"},[_c('v-row',{staticClass:"px-5 py-3 body-2 text-grey-9",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.PlayOrExpand(
                            courseChapterInfoNode,
                            courseChapterInfo.id
                        )}}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[(!_vm.isHasPreMission(courseChapterInfoNode))?_c('div',[(
                                    !_vm.isFree(courseChapterInfoNode) &&
                                        courseChapterInfoNode.pid == '0'
                                )?_c('div',[_c('v-chip',{staticClass:"px-2 orange--text",attrs:{"color":"#FFE7DE","x-small":"","label":""}},[_vm._v(" 付费 ")])],1):(
                                    _vm.isFree(courseChapterInfoNode) &&
                                        courseChapterInfoNode.pid == '0'
                                )?_c('div',[_c('v-chip',{staticClass:"px-2 green--text",attrs:{"color":"#DFF2E8","x-small":"","label":""}},[_vm._v(" 试听 ")])],1):_vm._e()]):_c('div',[(courseChapterInfoNode.pid === '0')?_c('div',[_c('v-chip',{staticClass:"px-2 primary--text",attrs:{"color":"#E4EFFD","x-small":"","label":""}},[_vm._v(" 学习 ")])],1):_vm._e()])]),(courseChapterInfoNode.children.length <= 0)?_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[(
                                courseChapterInfoNode.vip == '1' &&
                                    courseChapterInfoNode.pid != '0'
                            )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/shitinganniu.png")}})],1):(
                                courseChapterInfoNode.vip == '0' &&
                                    courseChapterInfoNode.pid != '0'
                            )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/suo.png")}})],1):_vm._e()]):_vm._e(),_c('v-col',[(
                                courseChapterInfoNode.name.indexOf('(*)') !=
                                    -1
                            )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNode.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNode.name)+" ")])]),_c('v-col',{staticClass:"px-2 arrows",attrs:{"cols":"auto"}},[(
                                _vm.CurrentParentChapterIds.indexOf(
                                    courseChapterInfoNode.id
                                ) >= 0 &&
                                    courseChapterInfoNode.children.length >
                                        0
                            )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/open.png")}}):_vm._e(),(
                                _vm.CurrentParentChapterIds.indexOf(
                                    courseChapterInfoNode.id
                                ) < 0 &&
                                    courseChapterInfoNode.children.length >
                                        0
                            )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/close.png")}}):_vm._e()],1)],1),(courseChapterInfoNode.children != null)?_c('div',_vm._l((courseChapterInfoNode.children),function(courseChapterInfoVideo,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.CurrentParentChapterIds.indexOf(
                                courseChapterInfoNode.id
                            ) >= 0
                        ),expression:"\n                            CurrentParentChapterIds.indexOf(\n                                courseChapterInfoNode.id\n                            ) >= 0\n                        "}],key:index},[(courseChapterInfoVideo.videoId != null)?_c('div',[_c('v-row',{staticClass:"px-5 py-3 body-3 text-grey-3",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.VideoDetail(
                                        courseChapterInfoVideo.videoId,
                                        courseChapterInfoVideo.courseId,
                                        courseChapterInfoVideo.vip,
                                        courseChapterInfoVideo.isTaste,
                                        courseChapterInfoNode.id,
                                        courseChapterInfoNode.id
                                    )}}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[(
                                            courseChapterInfoVideo.vip ==
                                                '1' &&
                                                courseChapterInfoVideo.pid !=
                                                    '0'
                                        )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/shitinganniu.png")}})],1):(
                                            courseChapterInfoVideo.vip ==
                                                '0' &&
                                                courseChapterInfoVideo.pid !=
                                                    '0'
                                        )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/suo.png")}})],1):_vm._e()]),_c('v-col',{staticClass:"px-2"},[(
                                            courseChapterInfoVideo.name.indexOf(
                                                '(*)'
                                            ) != -1
                                        )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoVideo.name)+" ")])])],1)],1):_c('div',[(
                                    courseChapterInfoVideo.children != null
                                )?_c('div',[_c('v-row',{staticClass:"px-5 py-3 body-2 text-grey-9",attrs:{"no-gutters":""},on:{"click":function($event){_vm.CurrentChapterIds.indexOf(
                                            courseChapterInfoVideo.id
                                        ) >= 0
                                            ? _vm.callpase(
                                                  courseChapterInfoVideo
                                              )
                                            : _vm.expand(courseChapterInfoVideo)}}},[_c('v-col',[(
                                                courseChapterInfoVideo.name.indexOf(
                                                    '(*)'
                                                ) != -1
                                            )?_c('div',[_c('span',[_vm._v("•")]),_vm._v(" "+_vm._s(courseChapterInfoVideo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_c('span',[_vm._v("•")]),_vm._v(" "+_vm._s(courseChapterInfoVideo.name)+" ")])]),_c('v-col',{staticClass:"px-2 arrows",attrs:{"cols":"auto"}},[(
                                                _vm.CurrentChapterIds.indexOf(
                                                    courseChapterInfoVideo.id
                                                ) >= 0
                                            )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/open.png")}}):_vm._e(),(
                                                _vm.CurrentChapterIds.indexOf(
                                                    courseChapterInfoVideo.id
                                                ) < 0
                                            )?_c('v-img',{attrs:{"width":"0.4rem","height":"0.4rem","src":require("@/assets/images/close.png")}}):_vm._e()],1)],1),_vm._l((courseChapterInfoVideo.children),function(courseChapterInfoNodeTwo,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.CurrentChapterIds.indexOf(
                                            courseChapterInfoVideo.id
                                        ) >= 0
                                    ),expression:"\n                                        CurrentChapterIds.indexOf(\n                                            courseChapterInfoVideo.id\n                                        ) >= 0\n                                    "}],key:index},[_vm._v(" "+_vm._s(courseChapterInfo.id)+" "),_c('v-row',{staticClass:"px-5 py-3 body-3 text-grey-3",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.VideoDetail(
                                                courseChapterInfoNodeTwo.videoId,
                                                courseChapterInfoNodeTwo.courseId,
                                                courseChapterInfoNodeTwo.vip,
                                                courseChapterInfoNodeTwo.isTaste,
                                                courseChapterInfoNode.id,
                                                courseChapterInfoNode.id
                                            )}}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"auto"}},[(
                                                    courseChapterInfoNodeTwo.vip ==
                                                        '1' &&
                                                        courseChapterInfoNodeTwo.pid !=
                                                            '0'
                                                )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/shitinganniu.png")}})],1):(
                                                    courseChapterInfoNodeTwo.vip ==
                                                        '0' &&
                                                        courseChapterInfoNodeTwo.pid !=
                                                            '0'
                                                )?_c('div',[_c('v-img',{attrs:{"width":"0.5066rem","src":require("@/assets/images/suo.png")}})],1):_vm._e()]),_c('v-col',{staticClass:"px-2"},[(
                                                    courseChapterInfoNodeTwo.name.indexOf(
                                                        '(*)'
                                                    ) != -1
                                                )?_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNodeTwo.name.replace( '(*)', '' ))+" "),_c('v-img',{staticClass:"d-inline-block",attrs:{"width":"0.6667rem","height":"0.32rem","src":require("@/assets/images/new.png")}})],1):_c('div',[_vm._v(" "+_vm._s(courseChapterInfoNodeTwo.name)+" ")])])],1)],1)})],2):_vm._e()])])}),0):_vm._e()],1)}),0):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }