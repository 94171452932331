export interface CourseAddOrEditQuery {
    CourseId: string;
    AppraiseCotent: string;
    AppraiseLevel: number;
}

export class CourseLearnProgressModel {
    CourseId: string;
    ChapterId: string;
    VideoId: string;
    Progress: number;
}

export class MyVideoWatchInput {
    id: string;
    videoId: string;
    watchTime: string;
    userId: string;
    progress: number;
    videoName: string;
    courseID: string;
    chapterId: string;
    ids: string;
}

export class CourseAppraiseItem {
    Id!: string;
    CourseName!: string;
    AppraiseContent!: string;
    AppraiseLevel!: number;
    ReplyContent!: string;
    AppraiseIp!: string;
    AppraiseTime!: string;
    AuditStatus!: number;
    StickToTop!: number;
    UserName!: string;
    HeadImage!: string;
}

export class GetAppraiseListByCourseIdQuery {
    coursetype!: number;
    courseId!: string;
    nd!: string;
    rows!: number;
    page!: number;
}
export interface ActionResult {
    id: number;
    success: boolean;
    message: string;
    modelId: string;
    modelId1: string;
    code: number;
    tlAddress: string;
    blAddress: string;
    rtmpAddress: string;
    flvAddress: string;
}

export class InputsModels {
  inputs:Array<InputsModel>
}


export class InputsModel {
    courseId: string;
    courseName: string;
    id: string;
    status: string;
    videoId: string;
    videoName: string;
    videoSize: string;
    videoUrl: string;
    success: boolean;
}

export class IdsModel {
    ids: string;
}

export class UpdateStatusExanModel {
    id: string;
    status: string;
}
