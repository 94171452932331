export class GetPaperGroupInfoQuery {
    bigType: string;
    Type: string;
    SubjectId: string;
}

export class SubjectInfo {
    id: string;
    subjectName: string;
    projectId: string;
    projectName: string;
    orderNo: number;
    projectClassName: string;
    isEconomicBase: number;
    subjectVipPrice: number;
    bbsLink: string;
    isDefaultSelected: boolean;
}


export   class IsSecretResult{
    result:boolean;

}

export  class IsSecretResultInput{


}


export class SubjectClassInfo {
    id: string;
    name: string;

}

export class SubjectsActionsInfo {
    id: string;
    subjectName: string;
}


export class SubjectRedisInfo {
    subjectId: string;
    tab: number;
    Type:string;
}
export class ExaminationPaperQuery {
    paperId: string;
    paperRecordsId: string;
}

export class ExaminationPaperInfo {
    paperName: string;
    testTime: number;
    userName: string;
    count: number;
    count1: number;
    imgUrl: string;
    practiceNo: string;
    questionScore: string;
    score: number;
    questionTypes: Array<QuestionTypeInfo>;
}
export class QuestionTypeInfo {
    questionCount: number;
    questionTypeId: number;
    questionScore: string;
}

export class PaperGroupInfo {
    Id: string;
    SubjectId: string;
    GroupId: string;
    GroupName: string;
    RelationType: number;
}

export class MyPaperRecordsInfo {
    paperId: string;
    paperRecordsId: string;
    paperGroupId: string;
    economicPaperId: string;
}

export class InsertIntoPaperRecordsPcQuery {
    paperId!: string;
}

export class PaperQuestionInfo {
    id: string;

    className: string;

    questionCount: number;

    questionTypeScoreSum: number;

    questionScore: string;
    questionType: number;
    questionClass: string;
    difficultLevel: number;
    question: Array<QuestionDetailInfo>;
}

export class QuestionInfo {
    id: string;
    subjectType: string;
    rowsIndex: number;
}

export enum QuestionType {
    Single = 1,
    Muti = 2,
    Comprehensive = 7,
    Judge = 3,
    LunShu = 8,
}
export enum StatusMap {
    等待下载 = 0,
    下载中 = 1,  
    暂停 = 2,  
    完成 = 3,
}


export enum ReplyType {
    Error = 0,
    Correct = 1,
    PartCorrect = 3,
}

export class QuestionDetailInfo {
    result: [];
    id: string;
    subjectType: number;
    rowsIndex: number;

    description: string;

    questionTitle: string;

    QuestionContent: string;

    subjectClassId: string;

    number: number;

    input_type: string;

    subjectTypeName: string;
    subjectName: string;

    option1: string;

    option2: string;

    option3: string;

    option4: string;

    option5: string;

    option6: string;

    option7: string;

    option8: string;

    option9: string;

    option: Array<Option>;

    stuAnswer: string;

    knowledgePointName: string;

    parentKnowledgePointName: string;

    subjectClassName: string;

    questionScore: number;
    bigQuestionId: string;
    questionTextAnalysis: string;
    questionVedioAnalysis: string;
    rightAnswer: string;
    corectAnswer: string;
    questionContent: string;
    questionId: string;
    type: number;
}

export class Option {
    content: string;
    id: string;
    ischeck: string;
}

export class AnswerQuestionInputInfo {
    questionId: string;
    replyStatus: number;
    answer: string;
    type: number;
    paperId: string;
    Score: number;
    PaperRecordsId: string;
    subjectType: number;
}
export interface ActionResult {
    id: number;
    success: boolean;
    message: string;
    modelId: string;
    modelId1: string;
    code: number;
    tlAddress: string;
    blAddress: string;
    rtmpAddress: string;
    flvAddress: string;


}

export class QuestionCheckerInput {
    Id: string;
    Content: string;

    QuestionId: string;

    CheckerTypeIds: string;

    CheckerTypeTexts: string;
}

export class MyPaperRecordsJgQuery {
    paperRecordsId: string;
}

export class IsShoppingQuery {
    subjectid: string;
    relationType: string;
}

export class MyPaperRecordsJgInfo {
    c1: string;

    c2: string;
    paperName: string;

    score: number;

    addTime: string;
    replies: Array<ReplyInfo>;
}
export class ReplyInfo {
    bigQuestionId: string;
    smallQuestionId: string;
    replyStatus: number;
    stuAnswer: string;
    result: [];
}

export class GetIsCollectionInfo {
    result: number;
}

export class IsShoppingInfo {
    result: boolean;
}

export class AnswerQuestionInputInfoQuery {
    inputs: Array<AnswerQuestionInputInfo>;
}

export class AnswerQuestionOutInfo {
    result: boolean;
}

export class QuestionViewInfo {
    isCollection: number;

    rightAnswer: string;

    collectionId: string;

    questionTextAnalysis: string;

    description: string;

    questionTitle: string;

    questionContent: string;

    subjectType: number;

    subjectClassId: string;

    number: number;

    input_type: string;

    subjectTypeName: string;

    stuAnswer: string;
}

export enum ReplyStatus {
    NoReplyed = 0,
    ReplyedTrue = 1,
    ReplyedFlase = 2,
    ReplyedPartTrue = 3,
}

export class GetOcrQuestionResultQuery {
    Picurl:string;
    QuestionType:string
    questionContent: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string; 
    option5: string; 
    option6: string; 
    option7: string; 
    option8: string; 
    subjectId: string; 
    subjectName: string;
    subjectType: string;
    subjectTypeName: string;
    rightAnswer: string; 
    questionTextAnalysis: string;
} 


export class QuestionResultInfo {
    questionContent: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string; 
    option5: string; 
    option6: string; 
    option7: string; 
    option8: string; 
    subjectId: string; 
    subjectName: string;
    subjectType: string;
    subjectTypeName: string;
    rightAnswer: string; 
    questionTextAnalysis: string;
}

export class QuestionResultInput {
    QuestionContent: string;
    Option1: string;
    Option2: string;
    Option3: string;
    Option4: string; 
    Option5: string; 
    Option6: string; 
    Option7: string; 
    Option8: string; 
    SubjectId: string; 
    SubjectName: string;
    SubjectType: string;
    SubjectTypeName: string;
    RightAnswer: string; 
    QuestionTextAnalysis: string;
}


