















































































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import QuestionReplyComponent from '@/components/questionviews/QuestionView.vue';
import { QuestionDetailInfo, QuestionType } from '@/models/ExamPractice';
import { WrongTopicInfo } from '@/models/ReviewQuestion';
import { Loading } from 'vuetify-extension';
import { RouteName } from 'ea-router';
import {
    ReviewWrongTopicList,
    ReviewWrongTopicTopicList,
    ReviewWrongQuestionReply,
    ReviewWrongTopicReport,
} from '@/domain/views';

import Analysis from '@/components/Review/wrongTopic/Analysis.vue';
import { Dialog } from 'vant';
import { Navigation } from '@/infrastructure/navigation';
import { LoadingTestPaper, LoadingHandPaper } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import {
    WrongTopicQuestions,
    QuestionId,
    chaptertaskType,
    WrongTopicReplies,
    WrongTopicReportReturn,
    WrongTopicViewOnlyError,
    WrongTopicViewType,
    taskType,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
    },
})
@RouteName(ReviewWrongQuestionReply)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    PaperQuestions = new Array<WrongTopicInfo>();
    Questions = new Array<QuestionDetailInfo>();
    IsShowAnswer = false;
    IsRepeat = false;
    IsShowAnswerBtn = true;
    IsShowCollectioned = false;
    question = new QuestionDetailInfo();
    currentQuestionIndex = 1;
    collectShow = false;
    isShowRighteAnswer = false;
    isEnable = false;
    Enum = {
        questionType: QuestionType,
    };
    TotalCount = 0;
    knowledgePointName = '';

    get wrongType() {
        return this.$route.query.wrongType as string;
    }

    get knowLedgePointId() {
        const knowing: string = this.$route.query
            .knowledgePointId as string;
        return knowing;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get paperQuestionsRedis() {
        return Storage.Instance.get<Array<WrongTopicInfo>>(
            WrongTopicQuestions
        );
    }

    get QuestionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    get replies() {
        return this.Questions.map(x => {
            if (x.stuAnswer != null) {
                return {
                    questionId: x.id,
                    answer: x.stuAnswer,
                    subjectType: x.subjectType,
                    type: x.type,
                    paperId: this.wrongType,
                    Score: 0,
                    PaperRecordsId: this.paperRecordsId,
                };
            }
        });
    }

    @Loading(LoadingTestPaper)
    async GetWrongTopicQuestions() {
        const Data = await this.repository.reviewRepository.GetWrongTopicInfo(
            Number(this.wrongType),
            this.knowLedgePointId
        );
        this.PaperQuestions = Data;
        this.question = this.PaperQuestions[0].question[0];
        Storage.Instance.set(WrongTopicQuestions, this.PaperQuestions);
    }

    //当作答记录发生改变时记录
    async changedReplies(question: QuestionDetailInfo) {
        //单选作答实现自动切题
        const that = this;
        if (question.subjectType == QuestionType.Single) {
            setTimeout(async function() {
                await that.nextQuestion();
            }, 1 * 100);
        }
        Storage.Instance.set(WrongTopicReplies, this.replies);
        Storage.Instance.set(WrongTopicQuestions, this.PaperQuestions);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectList);

        Storage.Instance.clear(WrongTopicViewType);
        Storage.Instance.clear(taskType);
        Storage.Instance.clear(WrongTopicViewOnlyError);
        if (this.paperQuestionsRedis != null) {
            await this.HandInLocalData();
        } else {
            await this.GetWrongTopicQuestions();
            await this.GetAllQuestions();
        }
        this.currentQuestionIndex = this.question.rowsIndex;
        this.TotalCount = this.Questions.length;

        this.knowledgePointName = this.PaperQuestions[0].knowledgePointName;

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }

    async HandInLocalData() {
        this.PaperQuestions = this.paperQuestionsRedis;
        await this.GetAllQuestions();
        if (this.QuestionId != undefined) {
            this.GetCurrentQuestion(this.QuestionId);
        } else {
            this.question = this.PaperQuestions[0].question[0];
        }
    }

    GetCurrentQuestion(questionId: string) {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                if (x.id == questionId) {
                    this.GetComprehensiveType(p, x);
                    this.question = x;
                }
            });
        });
    }

    async GetAllQuestions() {
        this.PaperQuestions.forEach((p: any) => {
            p.question.forEach((x: any) => {
                this.GetComprehensiveType(p, x);
                this.Questions.push(x);
            });
        });
    }

    GetComprehensiveType(p: WrongTopicInfo, x: QuestionDetailInfo) {
        if (p.subjectType == this.Enum.questionType.Comprehensive) {
            x.subjectTypeName = '案例分析题';
        } else if (p.subjectType == this.Enum.questionType.LunShu) {
            x.subjectTypeName = '论述题';
        }
    }

    async preQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async nextQuestion() {
        this.isEnable = false;
        if (this.currentQuestionIndex >= this.TotalCount) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async HandlerBtnController() {
        this.IsShowAnswer = false;
        this.IsShowAnswerBtn = true;
        this.IsRepeat = false;
        this.IsShowCollectioned = false;
        this.isShowRighteAnswer = false;
    }

    async HandlerChangeQuestion() {
        this.question = this.Questions.filter(
            x => x.rowsIndex == this.currentQuestionIndex
        )[0];
        Storage.Instance.set(QuestionId, this.question.id);
    }

    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.id
        );
        return data.result;
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data <= 0) {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.id,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        } else {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.id,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        }
    }

    async handInClick() {
        Dialog.confirm({
            title: '提示',
            message: '确定提交并查看结果？',
            confirmButtonText: '是',
            cancelButtonText: '否',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {
                this.handIn();
            })
            .catch(() => {});
    }

    async handlerReplies() {
        this.replies
            .filter(x => x != null)
            .forEach(x => {
                if (x.answer != '') {
                    if (x.subjectType == this.Enum.questionType.Muti) {
                        if (x.answer.indexOf(',') == -1)
                            x.answer = ((x.answer as unknown) as Array<
                                string
                            >).join(',');
                    }
                }
            });
    }

    @Loading(LoadingHandPaper)
    async handIn() {
        if (
            this.replies.filter(x => x != null && x.answer != '').length ==
            0
        ) {
            this.notifier.warn('您未开始做题,不能交卷');
            return;
        }
        await this.handlerReplies();

        const data = await this.repository.reviewRepository.WrongTopicInfo(
            this.replies.filter(x => x != null && x.answer != ''),
            this.PaperQuestions[0].knowledgePointId
        );
        this.notifier.success('交卷成功');

        Storage.Instance.set(WrongTopicReportReturn, {
            questions: this.Questions,
            wrongTopicInfoReturn: data,
        });

        await this.navigator.redirect(ReviewWrongTopicReport, '', {
            paperRecordsId: this.paperRecordsId,
        });
    }

    async viewAnswer(question: QuestionDetailInfo) {
        this.isShowRighteAnswer = true;
        this.isEnable = true;
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowAnswerBtn = false;
            this.IsShowCollectioned = true;
            this.IsShowAnswer = true;
        } else {
            this.IsShowAnswer = true;
            this.IsRepeat = true;
            this.IsShowAnswerBtn = false;
        }
    }

    viewQuestionItems() {
        this.navigator.redirect(ReviewWrongTopicTopicList, '', {
            IsShowHand: true,
            count: this.Questions.length,
            wrongType: 0,
            paperRecordsId: this.paperRecordsId,
        });
    }

    redirectList() {
        Dialog.confirm({
            title: '提示',
            message:
                '直接退出后，答题记录将不会被保存\n如需保存记录，请点击“交卷”按钮',
            confirmButtonText: '继续答题',
            cancelButtonText: '仍要退出',
            confirmButtonColor: '#3f7efd',
        })
            .then(() => {})
            .catch(() => {
                this.ConfirmRedirect();
            });
    }

    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(chaptertaskType);
    }

    ConfirmRedirect() {
        this.clearStroage();
        this.navigator.redirect(ReviewWrongTopicList);
    }
}
