import AxiosHttp from '@/infrastructure/http';
import { SlidePhoto } from '@/models/SlidePhoto';
import { SlideDetail } from '@/models/SlideDetail';

export class SlideRepository {
    Course(type: number) {
        const param = {
            type: type,
        };
        return AxiosHttp.Instance.get<SlidePhoto[]>('/Slide/Course', param);
    }

    SlideDetail(id: string) {
        const param = {
            id: id,
        };
        return AxiosHttp.Instance.get<SlideDetail>('/Slide/Detail', param);
    }
}
