







































































































































































































































































































































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import {
  UserOrders,
  MyInfo,
  UserInvoice,
  UserCourse,
  UserPerfectInformation,
  MyAgreement,
  Paymentorder,
} from '@/domain/views';
import { Dialog } from 'vant';
import Repositories from '@/infrastructure/repository';
import { Loading, Notification } from 'vuetify-extension';
import { LoadingData } from '@/domain/loadingText';
import { OrderSheetOutput } from '@/models/OrderSheetOutput';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import Storage from '@/infrastructure/Storage';
import { CurrentType, OrderNo } from '@/domain/getters';
import {
  PayResultInfo,
  ToOrderPayInput,
  OrderSumbitInput,
  UpdateOrderStatusInput,
  SheetModelInfo, SheetModel,
} from '@/models/UserCourseResponse';
@RouteName(UserOrders)
@Component({
  components: {
    navbar,
  },
})
export default class MyOrderPageComponent extends Vue {
  @Inject(Navigator) navigator: Navigation;
  @Inject(Repository) repository: Repositories;
  @Inject(Notifier) notifier: any;
  unpaidOrderSheetOutputs = new Array<OrderSheetOutput>();
  closeOrderSheetOutputs = new Array<OrderSheetOutput>();
  OrderSheetOutputs = new Array<OrderSheetOutput>();
  sheetModelInfo = new SheetModelInfo();
  currentType = 0;
  payResultInfo = new PayResultInfo();
  sheetModel = new SheetModel();
  isShow = false;
  tab = 0;
  teamArray=['知识产权进行时','知识产权进行时-2','亦飞服务专营店','吉林优学','慧众教育专营店'];
  mounted() {
    const currentType = Storage.Instance.get<number>(CurrentType);
    if (currentType != null) {
      this.tab = currentType;
    }
    BackRouteConfig.addBackEvent(this.MyInfo);
    this.getMyOrder();
  }
  IsContainIns(Name:string) {
    if (this.teamArray.indexOf(Name) >= 0) return false;
    else {
      return true;
    }
  }
  IsContainInsRe(Name:string,isshow:boolean) {
    if (this.teamArray.indexOf(Name) >= 0) return false;
    else {
      if (isshow)
        return true;
      else
        return false;
    }
  }
  setCurrentType(type: number) {
    const newType = type == 0 ? 1 : 0;
    Storage.Instance.set(CurrentType, newType);
    this.currentType = type;
  }
  MyInfo() {
    //清楚缓存
    Storage.Instance.clear(CurrentType);
    this.navigator.redirect(MyInfo, null);
  }
  redirectUserInvoice(id: string, courseName: string) {
    this.navigator.redirect(UserInvoice, '', {
      id: id,
      courseName: courseName,
    });
  }
  showApplying(id: string, courseName: string) {
    Dialog.alert({
      title: '提示',
      message:
          '<p class="body-16 line-height-24">发票预计将在45天内寄到您的手中</p>如有疑问请联系客服！',
      confirmButtonText: '确定',
      confirmButtonColor: '#3f7efd',
    }).then(r => {
      return;
    });
  }
  showExpress(express: string, expressNumber: string) {
    const mesStr =
        '您的发票已寄出\n' +
        '物流公司：' +
        express +
        '\n' +
        '物流单号：' +
        expressNumber;

    Dialog.alert({
      title: '提示',
      message: mesStr,
      confirmButtonText: '确定',
      confirmButtonColor: '#3f7efd',
    }).then(r => {
      return;
    });
  }

  @Loading(LoadingData)
  async getMyOrder() {
    this.OrderSheetOutputs = await this.repository.user.GetUserOrderList();
    this.OrderSheetOutputs.forEach((x: any) => {
      if (x.state == '1' && !x.isExpired) {
        this.unpaidOrderSheetOutputs.push(x);
      } else if (x.state == '0') {
        this.closeOrderSheetOutputs.push(x);
      }
    });
      this.unpaidOrderSheetOutputs= this.unpaidOrderSheetOutputs.filter(x=>x.institutionsName=='人事社');
    console.log(this.closeOrderSheetOutputs);
    this.isShow = true;
  }

  resultConfirm() {
    // this.getMyOrder();
  }

  async redirectPerfect(
      renewalStatus: number,
      id: string,
      courseName: string,
      validityEndDate: string,
      agreementID: string,
      refuse: string,
      agreementOrderID:string
  ) {
    if (renewalStatus == 1) {
      if (agreementOrderID == '' || agreementOrderID == null) {
        Dialog.confirm({
          title: '提示',
          message: '请签订课程协议后申请续读',
          confirmButtonText: '去签订',
          cancelButtonText: '取消',
          confirmButtonColor: '#3f7efd',
        })
            .then(() => {
              this.navigator.redirect(MyAgreement, null);
            })
            .catch(() => {
              return;
            });
      } else {
        this.redirectPerfectInformation(renewalStatus, id, courseName,agreementID);
      }
    } else if (renewalStatus == 2) {
      this.handleingAlert();
    } else if (renewalStatus == 3) {
      this.refuseConfirm(renewalStatus, id, courseName, refuse,agreementID);
    } else if (renewalStatus == 4) {
      this.successAlert(validityEndDate);
    }
  }
  timestampToTime(timestamp: string) {
    const date = new Date(Date.parse(timestamp.replace(/-/g, "/"))); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
    const Y = date.getFullYear() + '/';
    const M =
        (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '/';
    const D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    //处理日期在苹果手机上显示NAN的问题
    return Y + M + D ;
  }


  redirectPerfectInformation(number, id: string, courseName: string,agreementID:string) {
    this.navigator.redirect(UserPerfectInformation, null, {
      courseId: id,
      courseName: courseName,
      agreementID:agreementID
    });
  }
  successAlert(validityEndDate: string) {
    console.log(validityEndDate);
    Dialog.alert({
      title: '通知',
      message:
          '<p class="body-16 line-height-24">恭喜！您的续读申请已通过\n课程有效期延长至：<span class="text-blue">' +
          this.timestampToTime(validityEndDate) +
          ' </span>\n届时可以学习下一期最新课程\n祝您学习愉快，备考顺利！</p>',
      confirmButtonText: '知道了',
      confirmButtonColor: '#3f7efd',
    });
    return;
  }
  handleingAlert() {
    Dialog.alert({
      title: '提示',
      message:
          '<p class="body-16 line-height-24">您的续读申请正在审核，审核完成\n可以在本页查看结果，请耐心等待</p>',
      confirmButtonText: '知道了',
      confirmButtonColor: '#3f7efd',
    });
    return;
  }
  refuseConfirm(
      renewalStatus: number,
      id: string,
      courseName: string,
      refuse: string,
      agreementID:string
  ) {
    Dialog.confirm({
      title: '通知',
      message:
          '<p class="body-16 text-red-f pb-20">抱歉，您的续读申请未通过</p><p class="body-3 text-left text-grey-3 line-height-18">具体原因如下:<br/>' +
          refuse +
          '</p>',
      confirmButtonText: '重新申请',
      cancelButtonText: '关闭',
      confirmButtonColor: '#3f7efd',
    })
        .then(() => {
          this.redirectPerfectInformation(renewalStatus, id, courseName,agreementID);
        })
        .catch(() => {
          return;
        });
  }
  //去支付
  async toPay(orderNo: string, amount: string, courseId: string) {
    const isExist = await this.repository.CurriculumPay.GetIsExistToPayOrder(
        courseId
    );
    if (isExist) {
      Notification.warn('您已购买,请勿重复提交');
      return;
    }

    this.sheetModel = await this.repository.CurriculumPay.GetOrderInfo(
        orderNo
    );



    //因为存在客户临时修改价格的需求,所以这里需要重新生成订单,否则会因为价格不一致,导致微信那边认为是重复的订单而无法支付的情况
    //不应该去删除优惠券信息,否则就找不到优惠券信息了
    const submitinput = new OrderSumbitInput();
    submitinput.CourseId = courseId;
    submitinput.OrderType = '0';
    submitinput.isrepeat = true;
    submitinput.SubjectId = this.$store.getters.exam.profession?.id;
    submitinput.OrderAmount = Number(this.sheetModel.orderAmount);
    const submitdata = await this.repository.CurriculumPay.OrderSumbit(submitinput);
    this.sheetModelInfo = submitdata;


    //清除上一份订单
    let updateInput=new UpdateOrderStatusInput();
    updateInput.orderNo=orderNo;
    updateInput.ordernoNew= submitdata.orderNo;
    console.log(updateInput.orderNo)
    console.log(updateInput.ordernoNew)
    await this.repository.CurriculumPay.UpdateOrderStatus(updateInput);


    //重新唤起微信支付
    const input = new ToOrderPayInput();
    input.Amount = Number(this.sheetModel.orderAmount);
    input.OrderNo = submitdata.orderNo;
    const data = await this.repository.CurriculumPay.ToOrderPay(input);
    this.payResultInfo = data;
    //这里拿到支付的url,唤起微信支付框
    if (this.payResultInfo.result_code == 'SUCCESS') {
      location.href = this.payResultInfo.mweb_url;
    } else {
      this.notifier.error(this.payResultInfo.err_code_des);
    }

  }
  redirectMyCourse() {
    this.navigator.redirect(UserCourse, null);
  }
  resultCancle() {
    // this.getMyOrder();
  }
  resultShow = false;
}
