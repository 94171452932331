


































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { ReviewList, ReviewWrongTopicList } from '@/domain/views';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import NavigationBar from '@/components/Review/wrongTopic/NavigationBar.vue';
import wrongTopicRecordList from '@/components/Review/wrongTopic/List.vue';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import Storage from '@/infrastructure/Storage';
import {
    QuestionId,
    WrongTopicQuestions,
    tabStartPos,
    WrongTopicViewType,
    WrongTopicReplies,
    WrongTopicReportReturn,
    chaptertaskType,
} from '@/domain/getters';

@RouteName(ReviewWrongTopicList)
@Component({
    components: {
        NavigationBar,
        wrongTopicRecordList,
        navbar,
    },
})
export default class ReviewWrongTopicListPage extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @NeedLogin
    async reviewList() {
        this.navigator.redirect(ReviewList);
        await this.GetSubjects();
    }
    Subjects = [];
    subjectId = '';
    subjectCount = 0;
    subjectName = '';

    tabIndex = 0;
    leftTabIndex = 0;
    leftIndexAry = new Array<number>();

    get tabPos() {
        return Storage.Instance.get<number>(tabStartPos);
    }

    async mounted() {
        //设置tab标签值
        if (this.tabPos != null && this.tabPos >= 0) {
            this.tabIndex = Math.floor(this.tabPos / 10);
            this.leftTabIndex = Math.floor(this.tabPos % 10);
        }

        await this.GetSubjects();

        //清除启动位置缓存
        Storage.Instance.clear(tabStartPos);
        Storage.Instance.clear(WrongTopicQuestions);
        Storage.Instance.clear(WrongTopicReplies);
        Storage.Instance.clear(WrongTopicViewType);
        Storage.Instance.clear(WrongTopicReportReturn);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(chaptertaskType);
    }

    async GetSubjects() {
        const data = await this.repository.exampractice.GetSubjects(
            this.$store.getters.exam.profession?.id,
            '0'
        );
        this.Subjects = data;
        this.subjectCount = this.Subjects.length;

        //初始化左侧数组长度
        if (this.tabIndex >= this.subjectCount) {
            this.tabIndex = this.subjectCount - 1;
        }
        this.subjectId = data[this.tabIndex].id;
        this.subjectName = data[this.tabIndex].subjectName;
        this.leftIndexAry = [];
        for (let i = 0; i < this.subjectCount; i++) {
            this.leftIndexAry.push(0);
        }
        //更新左侧标签记录
        this.leftIndexAry[this.tabIndex] = this.leftTabIndex;
    }

    async changeSubject(id: string, index: number) {
        this.subjectId = id;
        //更新顶部标签记录
        this.tabIndex = index;
        //更新左侧标签记录
        this.leftTabIndex = this.leftIndexAry[this.tabIndex];
    }

    ChangeLeftFlag(leftIndex: number) {
        this.leftIndexAry[this.tabIndex] = leftIndex;
    }
}
