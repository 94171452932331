











































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    LoginWxBindPhoneNum,
    UserLogin,
    LoginWxVerifyCode,
} from '@/domain/views';
import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import Repositories from '@/infrastructure/repository';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Rule } from '@/infrastructure/rules';
import Storage from '@/infrastructure/Storage';
import { OpenWxUserInfo } from '@/domain/getters';
import { WxUserInfo } from '@/models/WxUserInfo';
import { Dialog } from 'vant';

@RouteName(LoginWxBindPhoneNum)
@Component
export default class WxBindPhoneNumPage extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Rules) rules: Rule;

    wxNickName = '';
    invalid = true;
    phoneNumber = '';
    get numPhone() {
        return this.$route.query.phoneNumber as string;
    }
    get wxUser() {
        return Storage.Instance.get<WxUserInfo>(OpenWxUserInfo);
    }

    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.backWxBind);

        this.wxNickName = this.wxUser.nickname;
        this.phoneNumber = this.numPhone;
        this.ValidPhone(this.phoneNumber);
    }

    //校验手机号码是否合法
    ValidPhone(phone) {
        if (/^1[3456789]\d{9}$/.test(phone)) {
            this.invalid = false;
        } else {
            this.invalid = true;
        }
    }

    //删除手机号
    deletePhoneNumber() {
        this.phoneNumber = '';
        this.ValidPhone(this.phoneNumber);
    }

    //输入控制
    checkInputPhone(value) {
        //获取输入字符的ascii值，如果非数字，则截断不输入
        const charKeyVal = value.charAt(value.length - 1).charCodeAt();
        if (charKeyVal < 48 || charKeyVal > 57) {
            value = value.substr(0, value.length - 1);
        }
        if (value.length > 11) {
            value = value.slice(0, 11);
        }

        this.$nextTick(() => {
            this.phoneNumber = value;
        });

        this.ValidPhone(value);
    }

    @Loading(LoadingMsg)
    async wxPhoneReg() {
        try {
            const response = await this.repository.user.CheckPhoneIsBindWx(
                this.phoneNumber,
                this.wxUser.unionid
            );
            if (response.success) {
                if (response.code == 1) {
                    //已经注册，弹出提示信息
                    Dialog.alert({
                        title: '提示',
                        message: '该手机号已绑定其他微信',
                        confirmButtonText: '我知道了',
                        confirmButtonColor: '#3f7efd',
                    }).then(r => {
                        return;
                    });
                } else {
                    //跳转到验证手机号码页面                        c
                    this.navigator.replace(LoginWxVerifyCode, {
                        phoneNumber: this.phoneNumber,
                    });
                }
            } else {
                this.notifier.error(response.message);
            }
        } catch (e) {
            this.notifier.error(e);
        }
    }

    backWxBind() {
        this.navigator.replace(UserLogin);
    }
}
