






































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Course, Products } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import { Loading } from 'vuetify-extension';
import config from '@/config';
import { ProductCategoryGroup } from '@/models/ProductCategoryGroup';
import { ProductGroup, ProductGroupSearch } from '@/models/ProductGroup';
import { Page } from '@/infrastructure/navigation/page';
import { LoadingMsg } from '@/domain/loadingText';
@RouteName(Course)
@Component({})
export default class CourseNavigationComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    isCompleted = false;
    searchInput = new ProductGroupSearch();
    productGroups = new Array<ProductGroup>();
    mounted() {
        this.GetProductCategoryGroups();
    }

    categoryDetail(bqCode: string) {
        const page = new Page(Products);
        page.context = { bqCode: bqCode };
        this.navigator.redirectPage(page);
    }

    productCategoryGroup = new Array<ProductCategoryGroup>();

    @Loading(LoadingMsg)
    async GetProductCategoryGroups() {
        try {
            const searchInput = new ProductGroupSearch();
            searchInput.examType = this.$store.getters.exam.type;
            searchInput.levelId = this.$store.getters.exam.level.id;
            searchInput.professionId = this.$store.getters.exam.profession?.id;
            const data = await this.repository.product.ProductCategoryGroup(
                searchInput
            );
            this.productCategoryGroup = data;

            this.isCompleted = true;
        } catch (e) {
            console.log(e);
            this.productCategoryGroup = [];
        }
    }

    GetCoverSrc(cover: string) {
        return config.Host + cover;
    }
}
