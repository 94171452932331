






































































































































































































































































































































































































































































































































































































































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Course, CourseContrast } from '@/domain/views';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { CourseContrastInfo } from '@/infrastructure/model/CourseContrastInfo';
import { Navigation } from '@/infrastructure/navigation';
import NoContent from '@/components/NoContent.vue';

@RouteName(CourseContrast)
@Component({
    components: {
        NoContent,
        navbar,
    },
})
export default class CourseContrastPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;
    listVisible = false;

    get examLevel() {
        return this.$store.getters.examLevel;
    }

    get selectedFirst(): CourseContrastInfo | null {
        const currentSelected = this.selected.first;
        if (currentSelected < 0) return null;
        return this.list[currentSelected];
    }

    get selectedOther(): CourseContrastInfo | null {
        const currentSelected = this.selected.other;
        if (currentSelected < 0) return null;
        return this.list[currentSelected];
    }

    mounted() {
        this.getData();
    }

    courseIndex() {
        this.navigator.redirect(Course);
    }

    async getData() {
        this.list = await this.repository.courseContrast.List(
            this.examLevel.id
        );
    }

    confirm() {
        this.selected[this.currentSelected] = this.listSelectedIndex;
        this.listVisible = false;
        this.noContent = false;
    }

    select(target: string) {
        this.currentSelected = target;
        this.listVisible = true;
        const currentIndex = this.selected[target];
        this.listSelectedIndex = currentIndex >= 0 ? currentIndex : 0;
    }

    selectFirst() {
        this.select('first');
    }

    selectOther() {
        this.select('other');
    }

    list: CourseContrastInfo[] = [];
    selected: any = {
        first: -1,
        other: -1,
    };
    currentSelected = 'first';
    listSelectedIndex = 0;
    noContent = true;
}
