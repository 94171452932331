































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import {
    PracticeExamList,
    PracticeExamQuestionView,
    PracticeExamReport,
} from '@/domain/views';
import {
  AnswerQuestionInputInfo,
  ExaminationPaperInfo,
  MyPaperRecordsJgInfo,
  PaperQuestionInfo,
  QuestionDetailInfo,
  ReplyStatus, SubjectRedisInfo,
} from '@/models/ExamPractice';
import { Loading } from 'vuetify-extension';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import {
  NoRepliedQuestionIds,
  PaperQuestions,
  ExaminationPaperInfos,
  Replies,
  QuestionId,
  TrueQuestionIds,
  taskType, examsubjectInfo,
} from '@/domain/getters';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@Component
@RouteName(PracticeExamReport)
export default class Report extends Vue {
    gradientColor = {
        '0%': '#FDAF95',
        '100%': '#F96E7D',
    };

    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    ExaminationPaperInfo = new ExaminationPaperInfo();
    paperQuestions = new Array<PaperQuestionInfo>();
    replies = Array<AnswerQuestionInputInfo>();
    currentRate = 0;
    Enum = {
        ReplyStatus: ReplyStatus,
    };
    myPaperRecordsJg = new MyPaperRecordsJgInfo();
    trueQuestionIds = new Array<string>();
    falseQuestionIds = new Array<string>();
    partTrueQuestionIds = new Array<string>();
    NoRepliedQuestionIds = new Array<string>();
    ErrorQuestionIds = new Array<string>();
    get paperId() {
        return this.$route.query.paperId as string;
    }
    get groupId() {
        return this.$route.query.groupId as string;
    }
    get count() {
        return this.$route.query.count as string;
    }

    get paperRecordsId() {
        return this.$route.query.paperRecordsId as string;
    }

    get getUnRepliedCount() {
        return Number(this.count) - this.replies?.length;
    }

    Percentage(num: number, total: number) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }

    @Loading('加载中...')
    async GetMyPaperRecordsJg() {
        const data = await this.repository.exampractice.MyPaperRecordsJg({
            paperRecordsId: this.paperRecordsId,
        });
        this.myPaperRecordsJg = data;
    }
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.returnQuestionReply);

        this.paperQuestions = Storage.Instance.get<
            Array<PaperQuestionInfo>
        >(PaperQuestions);
        this.ExaminationPaperInfo = Storage.Instance.get<
            ExaminationPaperInfo
        >(ExaminationPaperInfos);
        this.replies = Storage.Instance.get<Array<AnswerQuestionInputInfo>>(
            Replies
        );
        await this.GetMyPaperRecordsJg();
    }
    GetReplyStatus(childQuestion: QuestionDetailInfo) {
        const ReplyInfo = this.replies.filter(
            x => x.questionId == childQuestion.id
        )[0];

        if (ReplyInfo != undefined) {
            if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedTrue
            ) {
                this.filterTrueQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedTrue;
            } else if (
                ReplyInfo.replyStatus == this.Enum.ReplyStatus.ReplyedFlase
            ) {
                this.filterFalseQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedFlase;
            } else if (
                ReplyInfo.replyStatus ==
                this.Enum.ReplyStatus.ReplyedPartTrue
            ) {
                this.filterPartTrueQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.ReplyedPartTrue;
            } else {
                this.filterNoRepliedQuestionIds(childQuestion);
                return this.Enum.ReplyStatus.NoReplyed;
            }
        } else {
            this.filterNoRepliedQuestionIds(childQuestion);
            return this.Enum.ReplyStatus.NoReplyed;
        }
    }
    filterNoRepliedQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.NoRepliedQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.NoRepliedQuestionIds.push(childQuestion.id);
        }
    }
    filterPartTrueQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.partTrueQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.partTrueQuestionIds.push(childQuestion.id);
        }
    }
    filterFalseQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.falseQuestionIds.indexOf(childQuestion.id) == -1) {
            this.ErrorQuestionIds.push(childQuestion.id);
            this.falseQuestionIds.push(childQuestion.id);
        }
    }
    filterTrueQuestionIds(childQuestion: QuestionDetailInfo) {
        if (this.trueQuestionIds.indexOf(childQuestion.id) == -1) {
            this.trueQuestionIds.push(childQuestion.id);
        }
    }
    timestampToTime(timestamp: string) {
        const date = new Date(); //时间戳为10位10 ， vue   时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + '/';
        const M =
            (date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1) + '/';
        const D =
            (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
            ' ';
        return Y + M + D;
    }
    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }

    viewAllAlyasis() {
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(NoRepliedQuestionIds);
        this.navigator.redirect(PracticeExamQuestionView, '', {
            paperId: this.paperId,
            groupId: this.groupId,
            paperRecordsId: this.paperRecordsId,
        });
    }
    viewErrorAlyasis() {
        Storage.Instance.clear(QuestionId);
        Storage.Instance.set(TrueQuestionIds, this.trueQuestionIds);
        Storage.Instance.set(NoRepliedQuestionIds, this.ErrorQuestionIds);
        if (this.ErrorQuestionIds.length <= 0) {
            this.notifier.success('恭喜您获得满分，没有错 题记录');
            return;
        } else {
            this.navigator.redirect(PracticeExamQuestionView, '', {
                paperId: this.paperId,
                groupId: this.groupId,
                paperRecordsId: this.paperRecordsId,
            });
        }
    }
    clearStorage() {
        Storage.Instance.clear(TrueQuestionIds);
        Storage.Instance.clear(NoRepliedQuestionIds);
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Replies);
        Storage.Instance.clear(ExaminationPaperInfos);
        Storage.Instance.clear(PaperQuestions);
        Storage.Instance.clear(taskType);
    }
    returnQuestionReply() {
        this.clearStorage();
      const subjectinfo = Storage.Instance.get<SubjectRedisInfo>(
          examsubjectInfo
      );
        this.navigator.redirect(PracticeExamList, '', {Type:subjectinfo.Type});
    }
}
