




















































import navbar from '@/components/navbar.vue';
import { Vue, Component, Inject,Ref } from 'vue-property-decorator';
import { Context, EnableProp, RouteName } from 'ea-router';
import { Navigation } from '@/infrastructure/navigation';
import { Navigator,Notifier, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { QuestionResultInfo,QuestionResultInput } from '@/models/ExamPractice';
import { DistinguishImg,QuestionRecognition } from '@/domain/views';
import CropperSub from '@/components/questionviews/CropperSub.vue';
import { QuestionsRes } from '@/domain/getters';
import Storage from '@/infrastructure/Storage';


@RouteName(DistinguishImg)


@Component({
    components: {
        navbar,CropperSub
    },
})

export default class DistinguishImgView extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Notifier) notifier: any;
    @Ref('CropperSub') readonly CropperSub!: any;
    @Ref('fileInput') readonly fileInput!: any;

    analysisImg:string = '';
    isCrpoImage:boolean = false;
    imgType:string = '';
    questionsImgCur:string = '';
    
    get questionsImg() {
        return this.$route.query.url as string;
    }

    get SubjectType() {
        return this.$route.query.SubjectType as string;
    }
    get SubjectTypeName() {
        return this.$route.query.SubjectTypeName as string;
    }
    get SubjectId() {
        return this.$route.query.SubjectId as string;
    }
    get SubjectName() {
        return this.$route.query.SubjectName as string;
    }

    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.ToUserPackageDetail);
        this.questionsImgCur = this.questionsImg;
    }
    ToUserPackageDetail() {
        this.navigator.back();
    }

    // 追加解析
    async photograph(type) {
        this.imgType = type;
        this.fileInput.value = ''; // 清空图片，保证上传同一张照片能触发change事件
        this.fileInput.click();
    }
    // 截图回调
    async cropImageRes(url) {
        this.isCrpoImage = false;
        this[this.imgType] = url;
    }
    // 上传
    async chooseFile(event: any) {
       let that = this;
       const target = event.target ;
       const { files } = target;
       if (!files) return;
       const file: File = files[0];
       // 转换为base64传给裁切组件
       const reader: FileReader = new FileReader();
       reader.readAsDataURL(file);
       reader.onload = (): void => {
            if(reader.result) {
                that.CropperSub.getImgUrl(String(reader.result));
                that.isCrpoImage = true;
            }
       }
    }
    // 上传图片取消
    async crpoImageCancel() {

       this.isCrpoImage = false;
    }
    // 开始识别
    @Loading(LoadingMsg)
    async confirm() { 
        // 题干
        let data = [];
        if(this.questionsImgCur) {
            const questionData = await this.repository.exampractice.GetOcrQuestionResult(
                this.questionsImgCur,
                this.SubjectType
            )
            if(!questionData.questionContent) {
                this.notifier.warn('题干识别失败');
                return;
            }else { 
                data[0] = questionData;
            }
        }
        if(this.analysisImg) {
            // 解析
            const analysisData = await this.repository.exampractice.GetOcrQuestionResult(
                this.analysisImg,
                '0'
            )
            if(analysisData.questionTextAnalysis) {
                data[1] = analysisData;
            }
        }
        Storage.Instance.set(QuestionsRes, data);
        this.navigator.redirect(QuestionRecognition,'',
        {
            SubjectType:this.SubjectType,
            SubjectTypeName:this.SubjectTypeName,
            SubjectId:this.SubjectId,
            SubjectName:this.SubjectName,
        })
        
    }

}



