import AxiosHttp from '@/infrastructure/http';
import config from '@/config';
import AxiosHttpResponseHandler from '@/infrastructure/http/HttpResponseHandler';
import AxiosHttpRequestFilter from '@/infrastructure/http/HttpRequestFilter';
import AxiosHttpErrorHandler from '@/infrastructure/http/HttpErrorHandler';
import videojs from 'video.js';
import FullscreenPlugin from '@/infrastructure/native/videojsPlugin/fullscreen';
import SimpleNavigation from '@/infrastructure/navigation';
import router from '@/router';
import '@/assets/css/public.css';
import '@/assets/css/override.scss';
import '@/assets/css/course.css';
import '@/assets/css/questionBank.css';
import HtmlPlusListener from '@/infrastructure/native/PlusListener';
import './hook/router';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import '@/infrastructure/rules/defined';
import { Loader, Notification } from 'vuetify-extension';
import LoadingComponent from '@/components/Loading.vue';
import NotificationComponent from '@/components/Notification.vue';
import '@/assets/iconfont/iconfont.css';
import { InitSentry } from '@/infrastructure/sentry';
import { Toast } from 'vant';
import DeviceScreen from '@/infrastructure/native/Screen';



export default class Startup {
    static configurationApp(): void {
        this.configurationRouter();
        this.configurationHttp();
        this.configurationVideoJs();
        this.configurationHtmlPlus();
        this.configurationHtml();
        this.configurationComponents();
        this.configurationVuetifyExtension();
        this.SetStatusBar();
        InitSentry(Vue, config.sentry.dsn);
    }

    private static configurationHttp() {
        const responseHandler = new AxiosHttpResponseHandler();
        const requestFilter = new AxiosHttpRequestFilter();
        const errorHandler = new AxiosHttpErrorHandler();
        AxiosHttp.Instance.setConfig(config)
            .setResponseHandler(responseHandler)
            .setRequestFilter(requestFilter)
            .setSystemErrorHandler(errorHandler);
    }

    private static configurationVideoJs() {
        videojs.registerPlugin(FullscreenPlugin.NAME, FullscreenPlugin);
    }

    private static configurationRouter() {
        SimpleNavigation.Init(router);
    }

    private static configurationHtmlPlus() {
        document.addEventListener('plusready', () => {
            HtmlPlusListener.Instance.init();
        });
    }

    private static configurationHtml() {
        document.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });
        document.addEventListener('gesturestart', function(event) {
            event.preventDefault();
        });
    }

    private static configurationComponents() {
        Vue.component('ValidationProvider', ValidationProvider);
        Vue.component('ValidationObserver', ValidationObserver);
    }

    private static configurationVuetifyExtension() {
        Toast.allowMultiple(true);
        // @ts-ignore
        Loader._messageConstructor = Vue.extend(LoadingComponent);

        // @ts-ignore
        Notification._messageConstructor = Vue.extend(NotificationComponent);
    }

    //设置状态栏
    private static SetStatusBar() {
        document.addEventListener('plusready', () => {
            DeviceScreen.SetStatusBarWhite();
        });
    }
}
