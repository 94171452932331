
























































































































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { UserPerfectInformation, UserOrders } from '@/domain/views';
import Repositories from '@/infrastructure/repository';
import { OrderRenewal } from '@/models/UserCourseResponse';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(UserPerfectInformation)
@Component({
    components: {
        navbar,
    },
})
export default class UserPerfectInformationPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    selectedOther = '请选择续读原因';
    MyInfo() {
        this.navigator.redirect(UserOrders, null);
    }
    get courseId() {
        return this.$route.query.courseId as string;
    }
    get AgreementId() {
        return this.$route.query.agreementID as string;
    }
    get courseName() {
        return this.$route.query.courseName as string;
    }
    list = [
        { name: '考试未通过', value: '考试未通过' },
        { name: '本地区考试取消', value: '本地区考试取消' },
        { name: '特殊原因缺考', value: '特殊原因缺考' },
        { name: '其他原因', value: '其他原因' },
    ];
    fileUrlList = [];
    idCard = '';
    userName = '';
    telPhoneNum = '';
    imgStr = '';
    reasonDes = '';
    listVisible = false;
    fileList = [];
    resultShow = false;
    selected: any = {
        first: -1,
        other: -1,
    };
    currentSelected = 'first';
    listSelectedIndex = 0;
    selectOther() {
        this.select('other');
    }
    async mounted() {
        BackRouteConfig.addBackEvent(this.MyInfo);
        //获取用户信息
        if (this.$store.getters.isLogin) {
            const userInfo = await this.repository.user.getAgreementDetail(this.AgreementId,this.courseId);
            this.userName = userInfo.name;
            this.telPhoneNum = userInfo.contactNumber;
            this.idCard = userInfo.idCard;
        }
    }
    select(target: string) {
        this.currentSelected = target;
        this.listVisible = true;
        const currentIndex = this.selected[target];
        this.listSelectedIndex = currentIndex >= 0 ? currentIndex : 0;
    }
    confirm() {
        this.selected[this.currentSelected] = this.listSelectedIndex;
        this.listVisible = false;
        this.selectedOther = this.list[
            this.selected[this.currentSelected]
        ].value;
    }
    ShowConfirm() {
        if (this.selectedOther == '请选择续读原因') {
            this.notifier.warn('请选择续读原因');
            return;
        }
        if (
            this.fileList.length <= 0
        ) {
            this.notifier.warn('请上传至少一张截图');
            return;
        }
        if (
            this.selectedOther == '特殊原因缺考' ||
            this.selectedOther == '其他原因'
        ) {
            if (this.reasonDes == '' || this.reasonDes == null) {
                this.notifier.warn('请输入续读原因');
                return;
            }
        }
        this.resultShow = true;
    }
    async resultConfirm() {
        const orderRenewalInput = new OrderRenewal();
        orderRenewalInput.CourseID = this.courseId;
        orderRenewalInput.Description = this.reasonDes;
        orderRenewalInput.CourseName = this.courseName;
        orderRenewalInput.Status = '2';
        this.fileUrlList.forEach(url => {
            this.imgStr += url + ',';
        });
        orderRenewalInput.Image1 = this.imgStr.trimEnd();
        orderRenewalInput.Reason = this.selectedOther;
        const data = await this.repository.user.AddOrderRenewal(
            orderRenewalInput
        );
        if (data.success) {
            this.navigator.redirect(UserOrders, null);
        }
    }
    async afterRead(file) {
        //文件上传逻辑
      console.log(file);
        if(file.length!=undefined&&file.length>=1){
            for(let p=0;p<file.length;p++){
                const formData = new FormData();
                formData.append('file', file[p].file);
                const fileUrl = await this.repository.user.GetUploadFileUrl(
                    formData
                );
                if(this.fileUrlList.length<4)
                this.fileUrlList.push(fileUrl.fileUrl);
            }
            console.log( this.fileUrlList);
        }
         else {
            const formData = new FormData();
            formData.append('file', file.file);
            const fileUrl = await this.repository.user.GetUploadFileUrl(
                formData
            );
          if(this.fileUrlList.length<4)
            this.fileUrlList.push(fileUrl.fileUrl);
        }
    }
}
