












































































































































import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { Context, EnableProp, RouteName } from 'ea-router';
import {
    ReviewAnswerRecordReport,
    ReviewAnswerRecordChapterReport,
    ReviewAnswerRecordList,
    ReviewAnswerRecordSubjectiveItemReport,
} from '@/domain/views';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import {
    PaperReviewRecord,
    ChapterReviewRecord,
} from '@/models/ReviewRecord';
import { Loading } from 'vuetify-extension';
import { LoadingMsg, LoadingData } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { sourcetype, tabStartPos } from '@/domain/getters';
import {IsSecretResult, IsSecretResultInput} from "@/models/ExamPractice";

@RouteName(ReviewAnswerRecordList)
@Component({
    components: {},
})
@Context('subjectId', 'subjectCount', 'tabIndex', 'leftTabIndex','type')
@EnableProp()
export default class AnswerRecordListComponent extends Vue {
    @Prop() subjectId: string;
    @Prop() subjectCount: number;
  @Prop() type: number;
    @Prop() readonly tabIndex: number;
    @Prop() readonly leftTabIndex: number;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Notifier) notifier: any;
    recordList = new Array<PaperReviewRecord>();
    recordChapterList = new Array<ChapterReviewRecord>();
  isSecretResult=new IsSecretResult();
  isSecret=false;
    dataTipMsg = '';
    activeKey = this.leftTabIndex;
    practice = [
        { practiceList: '章节练习', type: '2' },
        { practiceList: '章节强化', type: '3' },
        { practiceList: '全真试卷', type: '5' },
      { practiceList: '密训试卷', type: '14' },
    ];
    isCompleted = false;

    currentPracticeType = '5';
    redirectPaperReport(
        id: string,
        paperId: string,
        containLunshu: boolean
    ) {
        //添加tab启动位置
        Storage.Instance.set(
            tabStartPos,
            this.tabIndex * 10 + this.activeKey
        );

        if (containLunshu) {
            this.navigator.redirect(
                ReviewAnswerRecordSubjectiveItemReport,
                '',
                {
                    paperRecordsId: id,
                    paperId: paperId,
                }
            );
        } else {
            this.navigator.redirect(ReviewAnswerRecordReport, '', {
                paperRecordsId: id,
                paperId: paperId,
            });
        }
    }
    redirectChapterReport(
        id: string,
        knowledgeId: string,
        currentPracticeType: number
    ) {
        Storage.Instance.clear(sourcetype);

        //添加tab启动位置
        Storage.Instance.set(
            tabStartPos,
            this.tabIndex * 10 + this.activeKey
        );

        this.navigator.redirect(ReviewAnswerRecordChapterReport, '', {
            chapterQuestionsId: id,
            knowledgePointId: knowledgeId,
            relationType: currentPracticeType,
        });
    }

    @Loading(LoadingMsg)
    async GetReviewRecordData() {
        const data = await this.repository.reviewRepository.ReviewRecordList(
            this.subjectId,
            this.currentPracticeType
        );

        this.recordList = data;
        this.InitReviewRcd();
    }

    //初始化全真试卷数据,如果标题包含下划线，则去掉下划线前面的文字
    InitReviewRcd() {
        this.recordList.forEach(p => {
            let pos = p.title.indexOf('_');
            while (pos >= 0) {
                p.title = p.title.substr(pos + 1, p.title.length - pos - 1);
                pos = p.title.indexOf('_');
            }
        });
    }

    @Loading(LoadingMsg)
    async GetChapterRecordData() {
        const data = await this.repository.reviewRepository.ChapterRecordList(
            this.subjectId,
            this.currentPracticeType
        );
        this.recordChapterList = data;
    }

    async mounted() {
        //await this.GetReviewRecordData();
      if(this.type==2) {
        await this.changePracticeType(
            '5',
            this.activeKey
        );
      }
      else{
        await this.changePracticeType(
            this.practice[this.activeKey].type,
            this.activeKey
        );

      }

        if (
            this.recordChapterList.length == 0 &&
            this.recordList.length == 0
        ) {
            this.dataTipMsg = '暂无数据...';
        }

        this.isCompleted = true;

        await  this.LoadSecret();
    }

  async  LoadSecret() {
    const data = await this.repository.exampractice.IsSecret(new IsSecretResultInput() );
    this.isSecretResult = data;
    this.isSecret= this.isSecretResult.result;
  }

    async changePracticeType(type: string, index: number) {
        this.recordChapterList = [];
        this.recordList = [];
        this.dataTipMsg = LoadingData;
        //更新左侧tab标签记录
        this.activeKey = index;
        this.$emit('ChangeLeftFlag', index);

        this.currentPracticeType = type;
        if (type == '5'||type == '14') {
          await this.GetReviewRecordData();
        } else {
          await this.GetChapterRecordData();
        }

        if (
            this.recordChapterList.length == 0 &&
            this.recordList.length == 0
        ) {
            this.dataTipMsg = '暂无数据...';
        }
        this.isCompleted = true;
    }
}
