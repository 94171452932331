







































































































  import { Vue, Component, Inject } from 'vue-property-decorator';
  import { RouteName } from 'ea-router';
  import {
    LoginPassword,
    UserLogin,
    LoginWxBindPhoneNum,
    LoginAuthenticationCode, RegistrationAgreement, Privacypolicy,MyInfo
  } from '@/domain/views';
  import { Navigator, Notifier, Repository, Rules } from '@/domain/providers';
  import Repositories from '@/infrastructure/repository';
  import { Navigation } from '@/infrastructure/navigation';
  import { Rule } from '@/infrastructure/rules';
  import { Loading } from 'vuetify-extension';
  import { LoadingMsg } from '@/domain/loadingText';
  import { WxUserInfo } from '@/models/WxUserInfo';
  import { OpenWxUserInfo, Checked } from '@/domain/getters';
  import { Login, MatchExam } from '@/domain/actions';
  import WxLogin from '@/infrastructure/native/Wx/wxlogin';
  import Storage from '@/infrastructure/Storage';


  @RouteName(UserLogin)
  @Component

  export default class UserLoginPageComponent extends Vue {
      @Inject(Repository) repository: Repositories;
      @Inject(Notifier) notifier: any;
      @Inject(Navigator) navigator: Navigation;
      @Inject(Rules) rules: Rule;
      invalid = true;
      checked=true;

       data() {
  return {
    checked: false,
   activeIcon: require('../assets/images/denglu_xuanzhong@2x.png'),
   inactiveIcon: require('../assets/images/denglui_weixuanzhong@2x.png'),
  };
}

      phoneNumber = '';
      get numPhone() {
          return this.$route.query.phoneNumber as string;
      }
      get sourcetype() {
          return this.$route.query.sourcetype as string;
      }

      get IsEnable() {
          return  this.invalid==true&&this.checked==true?false:true;
      }

    redirectResisgter(){
      this.navigator.redirect(RegistrationAgreement,{},{type:0,phoneNumber:this.phoneNumber});

    }

    redirectPrivacy(){
      this.navigator.redirect(Privacypolicy,{},{type:0,phoneNumber:this.phoneNumber});

    }
      redisData(){
          Storage.Instance.set(Checked, this.checked);

      }

      @Loading(LoadingMsg)
      async login() {
          try {
              const response = await this.repository.user.CheckPhoneIsReg(
                  this.phoneNumber
              );
              if (response.success) {
                  if (response.code == 1) {
                      //已经注册，跳转到密码输入页面
                      this.navigator.replace(LoginPassword, {
                          phoneNumber: this.phoneNumber,
                      });
                  } else {
                      //跳转到验证手机号码页面                        c
                      this.navigator.replace(LoginAuthenticationCode, {
                          phoneNumber: this.phoneNumber,
                          OpType: 'reg',
                      });
                  }
              } else {
                  this.notifier.error(response.message);
              }
          } catch (e) {
              this.notifier.error(e);
          }
      }

      checkInputPhone(value) {
          //获取输入字符的ascii值，如果非数字，则截断不输入
          const charKeyVal = value.charAt(value.length - 1).charCodeAt();
          if (charKeyVal < 48 || charKeyVal > 57) {
              value = value.substr(0, value.length - 1);
          }
          if (value.length > 11) {
              value = value.slice(0, 11);
          }

          this.$nextTick(() => {
              this.phoneNumber = value;
          });

          this.ValidPhone(value);
      }

      //校验手机号码是否合法
      //校验手机号码是否合法
      ValidPhone(phone) {
          if (/^1[3456789]\d{9}$/.test(phone)) {
              if(this.checked==false&&this.sourcetype!='1'&&this.sourcetype!='2'){
                  this.notifier.warn('请同意《用户协议》与《隐私政策指引》后登录');
              }
              this.invalid = true;
          } else {
              this.invalid = false;
          }
      }

      mounted() {
          this.phoneNumber = this.numPhone;

          this.ValidPhone(this.phoneNumber);
          const redisChecked = Storage.Instance.get<boolean>(Checked);
          if (redisChecked != null && redisChecked != undefined) {
              this.checked = redisChecked;
          }
      }
      deletePhoneNumber() {
          this.phoneNumber = '';
          this.ValidPhone(this.phoneNumber);
      }

      back() {
          //this.navigator.back();
          this.navigator.redirect(MyInfo);
      }

      async WxLogin() {
          if(this.checked==false){
              this.notifier.warn('请同意《用户协议》与《隐私政策指引》后登录');return;
          }
          this.notifier.success('微信登录中...');

          try {
              Storage.Instance.clear(OpenWxUserInfo);
              await WxLogin.Login(this.wxUserRegLogin);
          } catch (e) {
              this.notifier.error('微信登录失败');
          }
      }

      @Loading('微信登录中...')
      async wxUserRegLogin(wxUser: WxUserInfo) {
          try {
              const response = await this.repository.user.WxUserLoginOrBindPhone(
                  wxUser.unionid
              );
              if (response.success) {
                  if (response.code == 1) {
                      //已经绑定手机号，直接登录
                      await this.$store.dispatch(Login, {
                          token: response.message,
                      });
                      const data = await this.repository.user.getCurrentExamInfo();
                      await this.$store.dispatch(MatchExam, data);
                      this.notifier.success('登录成功!');
                      this.navigator.redirect(MyInfo);//直接跳转到我的主页去
                  } else {
                      //未绑定手机号，跳转到手机号绑定页面

                      //缓冲微信用户信息
                      Storage.Instance.set(OpenWxUserInfo, wxUser);
                      //跳转到手机号绑定页面
                      this.navigator.replace(LoginWxBindPhoneNum);
                  }
              } else {
                  this.notifier.error(response.message);
              }
          } catch (e) {
              console.log(e);
              this.notifier.error(e);
          }
      }
  }
