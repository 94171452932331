
























































import { Component, Inject, Vue } from 'vue-property-decorator';

import { RouteName } from 'ea-router';
import {
    ReviewQuestionsCollectTopicList,
    ReviewQuestionsCollectQuestionView,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import Storage from '@/infrastructure/Storage';
import { Questions, QuestionId } from '@/domain/getters';
import { QuestionDetailInfo } from '@/models/ExamPractice';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
@RouteName(ReviewQuestionsCollectTopicList)
@Component
export default class TopicList extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;

    Questions = new Array<QuestionDetailInfo>();
    get count() {
        return this.$route.query.count as string;
    }
    get subjectId() {
        return this.$route.query.subjectId as string;
    }
    get subType() {
        return this.$route.query.subType as string;
    }

    get dateType() {
        return this.$route.query.dateType as string;
    }
    GetRedisData() {
        this.Questions = Storage.Instance.get<Array<QuestionDetailInfo>>(
            Questions
        );
    }
    mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.redirectPre);

        this.GetRedisData();
    }

    redirectQuestion(questionId: string) {
        Storage.Instance.set(QuestionId, questionId);
        this.redirectPre();
    }
    redirectPre() {
        this.navigator.redirect(ReviewQuestionsCollectQuestionView, '', {
            subType: this.subType,
            subjectId: this.subjectId,
            dateType: this.dateType,
        });
    }
}
