











































































































































































































































































import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import {
    MyInfo,
    UserLogin,
    UserCourse,
    UserOrders,
    UserCards,
    PersonalInfo,
    CourseVideo,
    MyAgreement, Feedback,
} from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import { UserInfoResponse } from '@/models/UserInfoResponse';
import { NeedLogin } from '@/infrastructure/decoratoes/authorization';
import { Course, UserVersionOutput } from '@/models/UserCourseResponse';
import { VideoRedirect } from '@/infrastructure/model/CourseVideoInfo';
import Storage from '@/infrastructure/Storage';
import { SoruceType, ChapterId } from '@/domain/getters';
import { Loading } from 'vuetify-extension';
import { LoadingHandPaper1 } from '@/domain/loadingText';

@RouteName(MyInfo)
@Component
export default class UserInfoPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    lastStudyCourse: Course = new Course();
    StudyCourseLoaded = false;
    @Inject(Notifier) notifier: any;
  info=new UserInfoResponse();
    versionConfig={
        version:200002
    };
    isShow=false;
    result=new UserVersionOutput();
    ishold=false;
    cancleDisabled=false;
    confrimDisabled=false;
    UserUnSignCount=0;
ishowregister=true;
    mounted() {
        if (this.$store.getters.isLogin) {
            this.GetUserAgreementRecordCount();
            this.getUserInfo();
            this.getLastStudyCourse();

        } else {
            this.StudyCourseLoaded = true;
        }
    }


    //读取待签订的协议数量
    async GetUserAgreementRecordCount() {
        this.UserUnSignCount = await this.repository.user.GetUserAgreementRecordCount();
    }

    async getLastStudyCourse() {
        this.lastStudyCourse = await this.repository.user.getLastStudyCourse(
            this.$store.getters.exam
        );
        this.StudyCourseLoaded = true;
    }

    async getUserInfo() {
      this.info = await this.repository.user.getInfo();
      if (this.info.institutionsId == '682ebc32-8211-4886-8c65-a142dd3d4710' || this.info.institutionsId == '92b7e0e5-e115-4a7a-bb00-26f788e9c49b')
      this.ishowregister = false;
    }

    @NeedLogin
    navigateUserCourse() {
        this.navigator.redirect(UserCourse, null);
    }

    @NeedLogin
    personalInfo() {
        this.navigator.redirect(PersonalInfo, null);
    }

    navigateLogin() {
        this.navigator.redirect(UserLogin, null);
    }

    @NeedLogin
    orders() {
        this.navigator.redirect(UserOrders, null);
    }
    @NeedLogin
    feedback(){

        this.navigator.redirect(Feedback, null);
    }

    @NeedLogin
    cards() {
        this.navigator.redirect(UserCards, null);
    }
    @NeedLogin
    myAgreement() {
        this.navigator.redirect(MyAgreement, null);
    }

    VideoDetail(courseId: string, videoId: string, chapterId: string) {
        Storage.Instance.set(ChapterId, chapterId);
        Storage.Instance.set(SoruceType, 1);
        this.navigator.redirect(
            CourseVideo,
            {
                videoId: videoId,
                courseId: courseId,
            },
            {
                id: chapterId,
                type: VideoRedirect.fromMyInfo,
            }
        );
    }


    private async checkUserVersion() {
        const data = await this.repository.user.GetNewVersion();
        this.result = data;
        const lastTimeStamp = Storage.Instance.get<number>(
            "tip_version_update_time"
        );
        const versionpre = Storage.Instance.get<number>(
            "tip_version"
        );
        console.log("lastTimeStamp:" + lastTimeStamp);
        console.log("versionpre:" + versionpre);
        if (versionpre == null)
            await this.checkVersionToLoadUpdate(data.newversion, this.versionConfig.version);
        else
            await this.checkVersionToLoadUpdate(data.newversion, versionpre);
        await this.setStorageForAppVersion(data.timestmap);
    }

    setStorageForAppVersion(currTimeStamp){
        Storage.Instance.set("tip_version_update_time", currTimeStamp);
    }
    setStorageVersion(version){
        Storage.Instance.set("tip_version", version);
    }

    async   checkVersionToLoadUpdate(newVersion,version) {
        console.log("new:" + newVersion)
        console.log("old:" + version)
        if (newVersion > version) {
            this.isShow = true;
        }
        else{
            this.notifier.success('您已是最新版本,无需更新！');
            return;
        }
    }
    cancel(){
        //点击取消后进入课程页面不再弹框
        this.isShow=false;
    }
    @Loading(LoadingHandPaper1)
    async  confirm(){
        //走下载逻辑
        this.ishold=true;
        this.cancleDisabled=true;
        this.confrimDisabled=true;
        const dtask =await window.plus.downloader.createDownload("http://continueeducation.haokao123.com/img/haokao.apk", {}, async function ( d, status ) {
            if ( status == 200 ) {
                await   window.plus.runtime.install(window.plus.io.convertLocalFileSystemURL(d.filename),{},{},function(error){
                    console.log("安装失败");
                })
                this.ishold=false;
                this.cancleDisabled=false;
                this.confrimDisabled=false;
            } else {
                console.log("更新失败");
            }
        });
        if(this.ishold)
            dtask.start();
        await this.setStorageVersion(this.result.newversion);
    }
 /*   info: UserInfoResponse | {} = {};*/


  confirmtest(){
      //文件地址
      let  sd_path;
      let serverurl = "https://manager.haokao123.com/Uploads/dca28283-2cd6-4906-bf21-e27e194a0d74.jpg";
      let  dtask = window.plus.downloader.createDownload(serverurl, {
        method: "GET",
        retry: 0
      });
      //监听下载进度事件
      dtask.addEventListener("statechanged", function (task, status) {
        if (!dtask) {
          return;
        }
        switch (task.state) {
          case 1:
            // 开始
            break;
          case 2:
            // 已连接到服务器
            break;
          case 3:
            // 已接收到数据
            // eslint-disable-next-line no-case-declarations
            let nowData = Math.floor(task.downloadedSize * 100 / task.totalSize);
            if (nowData % 10 === 0) {
              if (nowData === 100) {
                let filename= dtask.filename.substring(dtask.filename.lastIndexOf("/") + 1, dtask.filename.length);
                window.plus.nativeUI.toast("正在准备环境，请稍后！");
                window.plus.io.resolveLocalFileSystemURL(dtask.filename, function (entry) {
                  sd_path = window.plus.io.convertLocalFileSystemURL("_downloads/"+filename);
                  alert(sd_path);
                });
              }
            }
            break;
          case 4:
            window.plus.nativeUI.toast("下载完成");
            break;
        }
      }, false);
      //设置跨域
      dtask.setRequestHeader('Access-Control-Allow-Origin', '*');
      dtask.stop();//开始任务
    }
    //zan


  /*删除指定文件relativePath,传的地址就是类似这中  "_downloads/"+filename*/
   readFile(relativePath) {
     //打开指定的本地缓存图片
     window.plus.runtime.openFile(relativePath, {}, function (err) {
       alert('打开失败');
     });
   }
   delFile(relativePath) {
    window.plus.io.resolveLocalFileSystemURL(relativePath, function(entry) {
      entry.remove(function(entry) {
        console.log("文件删除成功==" + relativePath);
      }, function(e) {
        console.log("文件删除失败=" + relativePath);
      });
    });
  }
}
