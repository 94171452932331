






import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Navigation } from '@/infrastructure/navigation';
import { RouteName } from 'ea-router';
import { ChooseProfession, Course } from '@/domain/views';
import ProfessionComponent from '@/components/Profession.vue';

@RouteName(ChooseProfession)
@Component({
    components: {
        ProfessionComponent,
    },
})
export default class ChooseProfessionPageComponent extends Vue {
    @Inject(Repository) repository: Repositories;
    @Inject(Navigator) navigator: Navigation;

    async selectFinish() {
        this.navigator.redirect(Course);
    }
}
