












import { Component, Vue, Prop } from 'vue-property-decorator';
import { QuestionDetailInfo, QuestionType } from '@/models/ExamPractice';
import ComprehensiveReplyComponent from '@/components/questionviews/comprehensive/Reply.vue';
import SingleReplyComponent from '@/components/questionviews/singlequestion/Reply.vue';
import MutiComponent from '@/components/questionviews/mutiquestion/Reply.vue';
import JudgeReplyComponent from '@/components/questionviews/judgequestion/Reply.vue';
import LunShuReplyComponent from '@/components/questionviews/lunshuquestion/Reply.vue';
import { Context, EnableProp } from 'ea-router';
@Component({
    components: {
        ComprehensiveReplyComponent,
        SingleReplyComponent,
        MutiComponent,
        JudgeReplyComponent,
        LunShuReplyComponent,
    },
})
@Context('question', 'isEnable', 'isShowRighteAnswer')
@EnableProp()
export default class QuestionViewComponent extends Vue {
    @Prop() question: QuestionDetailInfo;
    @Prop() isEnable: boolean;
    @Prop() isShowRighteAnswer: boolean;
    Enum = {
        questionType: QuestionType,
    };
    changedReplies() {
        this.$emit('changedReplies', this.question);
    }
    questionComponent(SubjectType: number) {
        // alert(this.isShowRighteAnswer);

        switch (SubjectType) {
            case this.Enum.questionType.Single:
                return 'SingleReplyComponent';
            case this.Enum.questionType.Muti:
                return 'MutiComponent';
            case this.Enum.questionType.Comprehensive:
                return 'ComprehensiveReplyComponent';
            case this.Enum.questionType.Judge:
                return 'JudgeReplyComponent';
            case this.Enum.questionType.LunShu:
                return 'LunShuReplyComponent';
        }
        return '';
    }
}
