






































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Context, EnableProp } from 'ea-router';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Notification } from 'vuetify-extension';
import {SimpleVideoNew} from "@/domain/views";
import {Navigation} from "@/infrastructure/navigation";

@Component
@Context('courseId','index')
@EnableProp()

export default class CourseChapterComponent extends Vue {
    @Prop() readonly courseId!: string;
    @Prop() readonly index!: string;
    @Prop() readonly lists!: any;
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) readonly repository!: Repositories;

    

    // 播放
    async playVideo(lists) {
        let str = lists.videoUrl.split("/");
        window.plus.io.resolveLocalFileSystemURL( '_downloads/'+str[str.length-1], (entry) => {
            entry.file((file) => {
                // 本地文件是否存在
                if(file) {
                    this.navigator.redirect(SimpleVideoNew, {
                        VideoUrl: window.plus.io.convertLocalFileSystemURL('_downloads/'+ str[str.length-1]),
                        videoId: lists.videoId,
                        courseId: lists.courseId,
                        videoName: lists.videoName

                    })
                }else {
                    Notification.warn('本地文件已被删除，无法播放');
                }
            })
        }, function ( e ) {
            Notification.warn('本地文件已被删除，无法播放');
	    });


      //打开指定的本地缓存图片,  这里尝试做一个调整,不用手机自带的播放器播放,使用videoH5自带的播放器进行播放
      //尝试修复ios视频可以转发出去的问题,
    //   console.log(str[str.length - 1]);
      /* window.plus.runtime.openFile('_downloads/'+str[str.length-1], {}, function (err) {
           alert('打开失败');
         });*/
//应用

    




    // window.plus.io.resolveLocalFileSystemURL( '_downloads/'+str[str.length-1], (entry) => {
	// 	// 可通过entry对象操作test.html文件 
	// 	entry.file((file) => {
	// 		let fileReader = new window.plus.io.FileReader();
	// 		alert("getFile:" + JSON.stringify(file));
	// 		fileReader.readAsText(file, 'utf-8');

	// 		fileReader.onloadend = function(evt) {
	// 			alert("11" + JSON.stringify(evt));
	// 			alert("evt.target" + evt.target);
	// 			alert(evt.target.result);
	// 		}
    //         fileReader.onerror = function(err) {
    //             alert("22" + JSON.stringify(err))
    //         }


	// 		alert(file.size + '--' + file.name);
	// 	} );
	// }, function ( e ) {
	// 	alert( "Resolve file URL failed: " + e.message );
	// } );



   // alert(window.plus.io.convertLocalFileSystemURL('_downloads/'+str[str.length-1]))
    // alert(window.plus.io.convertAbsoluteFileSystem( '_downloads/'+str[str.length-1]));
    // window.plus.io.resolveLocalFileSystemURL('_downloads/'+str[str.length-1], function(entry) {
    //   entry.file(function(file) {
    //     alert(JSON.stringify(file));
    //     let reader = new window.plus.io.FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function(res){
    //         alert(JSON.stringify(res))
    //         alert(res.target.result)
    //     }

    //   });
    //   }, function(e) {

    //   });

    }
   base64toBlob(base64, type) {
     // 将base64转为Unicode规则编码,切记需要将头部文件类型做截取
     let bstr = atob(base64.substring(base64.indexOf(',') + 1)),
         n = bstr.length,
         u8arr = new Uint8Array(n);
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n) // 转换编码后才可以使用charCodeAt 找到Unicode编码
     }
     return new Blob([u8arr], {
       type,
     })
   }
    // 调父组件lists
    getList() {
        this.$emit('getList',false)
    }
    // 删除
    // @Loading(LoadingMsg)
    async del(ids: string,videoUrl:string) {
        const data = await this.repository.courseAppraise.VideoDowListDelete(
        {ids:ids}
        );

        if(data.success == true) {
            let str = videoUrl.split("/");
            // 删除，通知父组件改变下载状态
            await this.$emit('statusChange', '0',ids,0,false)
            await this.delFile('_downloads/'+str[str.length-1]);
            await Notification.success('删除成功');
            // await this.getList();
        }
    }
    // 删除手机中的视频
    delFile(relativePath) {
        window.plus.io.resolveLocalFileSystemURL(relativePath, function(entry) {
          entry.remove(function(entry) {
            // Notification.success('文件删除成功');
            // alert("文件删除成功==" + relativePath);
          }, function(e) {
            // alert("文件删除失败=" + relativePath);
          });
        });
    }
    
}
