






























































































































import navbar from '@/components/navbar.vue'
import { Vue, Component, Inject } from 'vue-property-decorator';
import { RouteName } from 'ea-router';
import { newpassword, PersonalInfo, UserLogin } from '@/domain/views';
import { Navigator, Notifier, Repository } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import Repositories from '@/infrastructure/repository';
import { UpdatePwdInfo } from '@/models/UserCourseResponse';
import { Dialog } from 'vant';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';

@RouteName(newpassword)
@Component({
    components: {
        navbar,
    },
})
export default class LoginCompleteInfoPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;
    @Inject(Repository) repository: Repositories;
    @Inject(Notifier) notifier: any;
    show = false;
    isShowPwd = true;
    isShowOldPwd = true;
    pwdInputType = 'password';
    OldpwdInputType = 'password';
    input = new UpdatePwdInfo();
    LoginOldPwd='';
    LoginPwd='';
    mounted() {
        //大左滑
        BackRouteConfig.addBackEvent(this.back);
    }
    deleteLoginOldPwd() {
        this.LoginOldPwd = '';

    }
    deletePassword() {
        this.LoginPwd = '';
    }
    get IsDisable() {
        if(this.LoginOldPwd!=''&&this.LoginPwd!='')
            return false;
        else
            return true;
    }
    get IsShowLoginPwd() {
        if(this.LoginPwd!='')
            return true;
        else
            return false;
    }

    get IsShowLoginOldPwd() {
        if(this.LoginOldPwd!='')
            return true;
        else
            return false;
    }

    //显示隐藏密码
    ShowLoginPassword() {
        if (this.isShowPwd) {
            this.isShowPwd = false;
            this.pwdInputType = 'input';
        } else {
            this.isShowPwd = true;
            this.pwdInputType = 'password';
        }
    }
    ShowLoginOldPassword() {
        if (this.isShowOldPwd) {
            this.isShowOldPwd = false;
            this.OldpwdInputType = 'input';
        } else {
            this.isShowOldPwd = true;
            this.OldpwdInputType = 'password';
        }
    }
    async btnConfirm() {
        if (this.LoginOldPwd == null || this.LoginOldPwd == '') {
            this.notifier.warn('请输入新密码');
            return;
        }
        if (this.LoginPwd == null || this.LoginPwd == '') {
            this.notifier.warn('请再次输入新密码');
            return;
        }
        const patrn = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
        if (
            !patrn.test(this.LoginPwd) ||
            !patrn.test(this.LoginOldPwd)
        ) {
            this.notifier.error('请设置8~16位包含数字和字母的密码');
            return;
        }
        if (this.$store.getters.isLogin) {
            this.input.LoginOldPwd=this.LoginOldPwd;
            this.input.LoginPwd=this.LoginPwd;
            const data = await this.repository.user.UpdatePwd(this.input);
            if (data.success == false) {
                this.notifier.warn(data.message);
                return;
            } else {
                Dialog.alert({
                    title: '提示',
                    message: '修改密码成功，请重新登录',
                    confirmButtonText: '重新登录',
                    confirmButtonColor: '#3f7efd',
                }).then(r => {
                    this.navigator.redirect(UserLogin);
                });
            }
        }
    }
    back() {
        this.navigator.redirect(PersonalInfo);
    }
}
