function vueTouch(el, binding, type, vnode) {
    this.obj = el;
    this.binding = binding;
    this.touchType = type;
    this.vueTouches = { x: 0, y: 0 };
    this.scrollY = 0;
    this.vueMoves = true;
    this.vueLeave = true;
    this.longTouch = true;
    this.vueCallBack = binding.value;
    this.obj.addEventListener(
        'touchstart',
        e => {
            this.start(e);
        },
        false
    );
    this.obj.addEventListener(
        'touchend',
        e => {
            this.end(e);
        },
        false
    );
    this.obj.addEventListener(
        'touchmove',
        e => {
            this.move(e);
        },
        false
    );
    vnode.key = this.randomString();
}
vueTouch.prototype = {
    start: function(e) {
        this.vueMoves = true;
        this.vueLeave = true;
        this.longTouch = true;
        this.vueTouches = {
            x: e.changedTouches[0].pageX,
            y: e.changedTouches[0].pageY,
        };

        //页面开始滚动位置
        if (e.path.length > 0) {
            this.scrollY = e.path[e.path.length - 1].scrollY;
        }

        this.time = setTimeout(
            function() {
                if (this.vueLeave && this.vueMoves) {
                    this.touchType == 'longtap' && this.vueCallBack();
                    this.longTouch = false;
                }
            }.bind(this),
            1000
        );
    },
    end: function(e) {
        const disX = e.changedTouches[0].pageX - this.vueTouches.x;
        const disY = e.changedTouches[0].pageY - this.vueTouches.y;
        clearTimeout(this.time);

        if (Math.abs(disX) > 20 || Math.abs(disY) > 100) {
            //获取窗口垂直滚动条位置
            let endY = this.scrollY;
            if (e.path.length > 0) {
                endY = e.path[e.path.length - 1].scrollY;
            }
            //滚动距离
            const scrolYdis = Math.abs(endY - this.scrollY);

            //页面元素水平滚动条移动距离
            let isSwipt = true;
            for (let i = 0; i < e.path.length - 1; i++) {
                const dom = e.path[i];
                const clsName = dom.className;
                if (clsName && clsName.indexOf('scrollable') > 0) {
                    if (dom.scrollWidth > dom.clientWidth) {
                        isSwipt = false;
                    }
                    break;
                }
            }

            //垂直滚动距离少于10，水平滚动距离小于5，则触发左右滑事件
            if (scrolYdis < 10 && isSwipt) {
                if (Math.abs(disX) > Math.abs(disY)) {
                    if (disX > 20) {
                        this.touchType == 'swiperight' && this.vueCallBack(e);
                    }
                    if (disX < -20) {
                        this.touchType == 'swipeleft' && this.vueCallBack(e);
                    }
                } else {
                    //上下滑动，在水平移动距离不超过30
                    if (disY > 10) {
                        this.touchType == 'swipedown' && this.vueCallBack(e);
                    }
                    if (disY < -10) {
                        this.touchType == 'swipeup' && this.vueCallBack(e);
                    }
                }
            }
        } else {
            if (this.longTouch && this.vueMoves) {
                this.touchType == 'tap' && this.vueCallBack(e);
                this.vueLeave = false;
            }
        }
    },
    move: function(e) {
        this.vueMoves = false;
    },
    randomString: function() {
        const len = 10;
        const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        const maxPos = $chars.length;
        let rd = '';
        for (let i = 0; i < len; i++) {
            rd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return rd;
    },
};

export default {
    install: function(Vue, options) {
        Vue.directive('tap', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'tap', vnode);
            },
        });
        Vue.directive('swipe', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'swipe', vnode);
            },
        });
        Vue.directive('swipeleft', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'swipeleft', vnode);
            },
        });
        Vue.directive('swiperight', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'swiperight', vnode);
            },
        });
        Vue.directive('swipedown', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'swipedown', vnode);
            },
        });
        Vue.directive('swipeup', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'swipeup', vnode);
            },
        });
        Vue.directive('longtap', {
            bind: function(el, binding, vnode) {
                new vueTouch(el, binding, 'longtap', vnode);
            },
        });
    },
};
