






















































































































































































































































































































































































































































































































import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { Navigator, Repository } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { Loading, Notification } from 'vuetify-extension';
import SimpleNavigation, { Navigation } from '@/infrastructure/navigation';
import { CourseVideo, UserLogin } from '@/domain/views';
import { CourseChapterInfo } from '@/infrastructure/model/CourseChapterInfo';
import { Context, EnableProp } from 'ea-router';
import { VideoRedirect } from '@/infrastructure/model/CourseVideoInfo';
import store from '@/store';
import { LoadingMsg } from '@/domain/loadingText';
import Storage from '@/infrastructure/Storage';
import { ChapterId } from '@/domain/getters';

@Component
@Context('saleType', 'courseId', 'bqCode')
@EnableProp()
export default class SingleCourseChapterComponent extends Vue {
    @Inject(Repository) readonly repository!: Repositories;
    @Prop() readonly courseId: string;
    @Prop() readonly saleType: string;
    @Prop() readonly bqCode: string;
    @Inject(Navigator) navigator: Navigation;

    activeClass = 'part-active';
    open = ['public'];
    courseChapters = new Array<CourseChapterInfo>();
    Enum = {
        VideoRedirect: VideoRedirect,
    };
    CurrentChapterIds = new Array<string>();

    CurrentParentChapterIds = new Array<string>();

    CurrentCourseParentChapterIds = new Array<string>();
    courseIds = new Array<string>();
    @Loading(LoadingMsg)
    async GetCourseChapterInfo() {
        const data = await this.repository.course.GetCourseChapterInfo({
            courseId: this.courseId,
            saleType: this.saleType,
        });
        await this.BuildChaptersData(data);
        this.courseChapters = data;
        this.CurrentCourseParentChapterIds.push(
            this.courseChapters[0].name
        );
    }
    mounted() {
        this.GetCourseChapterInfo();
    }
    async created() {
        if (store.getters.isLogin) {
            const data = await this.repository.user.GetMyCourse(
                this.$store.getters.exam
            );
            for (const courseIndex in data.courses) {
                this.courseIds.push(data.courses[courseIndex].id);
            }
            for (const packIndex in data.packs) {
                for (const packCourseIndex in data.packs[packIndex]
                    .courses) {
                    this.courseIds.push(
                        data.packs[packIndex].courses[packCourseIndex].id
                    );
                }
            }
        }
    }
    isHasPreMission(chapter: any) {
        if (store.getters.isLogin) {
            if (
                chapter.children[0] != undefined &&
                this.courseIds.indexOf(chapter.children[0].courseId) > -1
            ) {
                return true;
            }
        }
        return false;
    }
    isFree(chapter: any) {
        const children = chapter.children;
        const childrenIsVideo = children.every(
            (x: any) => x.videoId !== null
        );
        if (childrenIsVideo) {
            return children.some((x: any) => x.isTaste === 1);
        }
        for (const index in children) {
            const childChapter = children[index];
            if (this.isFree(childChapter)) return true;
        }
        return false;
    }
    redirect(
        videoId: string,
        courseId: string,
        id: string,
        childid: string
    ) {
        this.navigator.redirect(
            CourseVideo,
            {
                videoId: videoId,
                courseId: courseId,
            },
            {
                id: id,
                childid: childid,
                package: this.courseId,
                saleType: this.saleType,
                type: this.Enum.VideoRedirect.FromCourseDetail,
                bqCode: this.bqCode,
            }
        );
    }
    async BuildChaptersData(data: Array<CourseChapterInfo>) {
        data.forEach(function(el: any, index: number) {
            el.id = el.id == null ? index : el.id;
            el.children.forEach(function(el1: any) {
                if (el1.children == null) el1.children = [];
                el1.children.forEach(function(el2: any) {
                    if (el2.children == null) el2.children = [];
                    el2.children.forEach(function(el3: any) {
                        if (el3.children == null) el3.children = [];
                    });
                });
            });
        });
    }
    VideoDetail(
        videoId: string,
        courseId: string,
        vip: string,
        isTaste: number,
        id: string,
        childid: string
    ) {
        Storage.Instance.set(ChapterId, childid);
        if (!store.getters.isLogin) {
            if (isTaste == 0 || isTaste == null) {
                //未登录&非 试听
                SimpleNavigation.Instance.redirect(UserLogin);
                return;
            } else if (isTaste == 1 && vip == '0') {
                //未登录&非试听&没有权限
                SimpleNavigation.Instance.redirect(UserLogin);
                return;
            } else {
                this.redirect(videoId, courseId, id, childid);
            }
        } else {
            if (vip == '1') {
                this.redirect(videoId, courseId, id, childid);
            } else {
                Notification.warn('您没有购买课程无法播放');
                return;
            }
        }
    }

    callpase(item: CourseChapterInfo) {
        const index = this.CurrentChapterIds.indexOf(item.id);
        this.CurrentChapterIds.splice(index, 1);
    }
    expand(item: CourseChapterInfo) {
        this.CurrentChapterIds.push(item.id);
    }

    parentcallpase(item: CourseChapterInfo) {
        const index = this.CurrentParentChapterIds.indexOf(item.id);
        this.CurrentParentChapterIds.splice(index, 1);
    }
    parentexpand(item: CourseChapterInfo) {
        this.CurrentParentChapterIds.push(item.id);
    }
    parentCoursecallpase(item: CourseChapterInfo) {
        const index = this.CurrentParentChapterIds.indexOf(item.name);
        this.CurrentCourseParentChapterIds.splice(index, 1);
    }
    parentCourseexpand(item: CourseChapterInfo) {
        this.CurrentCourseParentChapterIds.push(item.name);
    }

    PlayOrExpand(item: CourseChapterInfo, id: string) {
        if (item.children.length <= 0) {
            this.VideoDetail(
                item.videoId,
                item.courseId,
                item.vip,
                item.isTaste,
                id,
                id
            );
        } else {
            this.CurrentParentChapterIds.indexOf(item.id) >= 0
                ? this.parentcallpase(item)
                : this.parentexpand(item);
        }

        console.log(item);
    }
}
