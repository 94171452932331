




















import { Vue, Component, Inject } from 'vue-property-decorator';
import { Navigator } from '@/domain/providers';
import { Navigation } from '@/infrastructure/navigation';
import NetworkAnomaly from '@/components/NetworkAnomaly.vue';
import { RouteName } from 'ea-router';
import { NotNetwork } from '@/domain/views';

@RouteName(NotNetwork)
@Component({
    components: {
        NetworkAnomaly,
    },
})
export default class NotNetworkPageComponent extends Vue {
    @Inject(Navigator) navigator: Navigation;

    Back() {
        this.navigator.back();
    }
}
