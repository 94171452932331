




























































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { Navigator, Repository, Notifier } from '@/domain/providers';
import Repositories from '@/infrastructure/repository';
import { QuestionDetailInfo, QuestionType } from '@/models/ExamPractice';
import { RouteName } from 'ea-router';
import {
    ReviewQuestionsCollectQuestionView,
    ReviewQuestionsCollectTopicList,
    QuestionsCollectList,
} from '@/domain/views';
import { Questions, QuestionId,Collectiontype } from '@/domain/getters';
import QuestionReplyComponent from '@/components/questionviews/CollectQuestionView.vue';
import Analysis from '@/components/questionviews/Analysis.vue';
import { Navigation } from '@/infrastructure/navigation';
import Storage from '@/infrastructure/Storage';
import { Loading } from 'vuetify-extension';
import { LoadingMsg } from '@/domain/loadingText';
import BackRouteConfig from '@/infrastructure/native/PlusListener/backroute';
import { Dialog } from 'vant';
@Component({
    components: {
        QuestionReplyComponent,
        Analysis,
    },
})
@RouteName(ReviewQuestionsCollectQuestionView)
export default class QuestionReply extends Vue {
    @Inject(Notifier) notifier: any;
    @Inject(Repository) readonly repository!: Repositories;
    @Inject(Navigator) navigator: Navigation;

    Questions = new Array<QuestionDetailInfo>();
    IsRepeat = false;
    IsShowCollectioned = true;
    question = new QuestionDetailInfo();
    currentQuestionIndex = 1;
    collectShow = false;
    TotalCount = 0;
    Enum = {
        questionType: QuestionType,
    };

    get subjectId() {
        return this.$route.query.subjectId as string;
    }
    get subType() {
        return this.$route.query.subType as string;
    }

    get dateType() {
        return this.$route.query.dateType as string;
    }

    get collectiontype() {
        return this.$route.query.collectiontype as string || Storage.Instance.get(Collectiontype) as string;
    }

    get QuestionsRedis() {
        return Storage.Instance.get<Array<QuestionDetailInfo>>(Questions);
    }
    get questionId() {
        return Storage.Instance.get<string>(QuestionId);
    }

    @Loading(LoadingMsg)
    async mounted() {
        //添加手动物理返回键路由事件
        BackRouteConfig.addBackEvent(this.questionsCollectList);

        if (this.QuestionsRedis != undefined) {
            await this.HandInLocalData();
        } else {
            await this.GetAllQuestions();
        }
        this.InitTotalCountOrCurrentIndex();

        //滚动条自动到顶部位置
        window.scrollTo(0, 0);
    }

    async HandInLocalData() {
        this.Questions = this.QuestionsRedis;
        if (this.questionId != undefined && this.questionId != null) {
            this.question = this.Questions.filter(
                x => x.questionId == this.questionId
            )[0];
        } else {
            this.question = this.Questions[0];
        }

        await this.HandlerBtnController();
    }

    async GetAllQuestions() {
        const data = await this.repository.reviewRepository.GetMyQuestionsResult(
            this.subjectId,
            this.dateType,
            this.subType,
            this.collectiontype
        );
        this.Questions = data;
        if(this.Questions && this.Questions.length>0) {
            this.InitQuestions();
            this.question = this.Questions[0];
            Storage.Instance.set(Questions, this.Questions);
        }else {
            this.navigator.redirect(QuestionsCollectList,'',{collectiontype:'1'})
        }
    }

    InitTotalCountOrCurrentIndex() {
        this.TotalCount = this.Questions.length;
        this.currentQuestionIndex = this.question.rowsIndex;
    }

    InitQuestions() {
      this.Questions.forEach((p: any, index) => {
        p.rowsIndex = index + 1;
        p.subjectType = parseInt(this.subType);
        if (p.subjectType == this.Enum.questionType.Comprehensive) {
          p.subjectTypeName = '案例分析题';
          if (p.subjectName == '多选题') {
            p.subjectType = this.Enum.questionType.Muti;
          }
        } else if (p.subjectType == this.Enum.questionType.LunShu) {
          p.subjectTypeName = '论述题';
        } else {
          p.subjectTypeName = p.subjectName;
        }
        p.questionTitle = p.questionContent;
        p.id = p.knowLedgePointId;
        if (p.rightAnswer != null)
          p.result = this.IninOptionToNum(p.rightAnswer);
        if (p.result != null)
          p.corectAnswer = p.result.toString();
        p.stuAnswer = p.corectAnswer;
      });
    }

    //初始化选项字母转数字
    IninOptionToNum(answer) {
        const ary = [' ', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        const res = [];

        ary.forEach((val, index) => {
            if (answer.indexOf(val) >= 0) {
                res.push(index);
            }
        });
        return res;
    }

    async preQuestion() {
        if (this.currentQuestionIndex == 1) {
            this.notifier.success('已是第一题了');
            return;
        }
        this.currentQuestionIndex--;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async nextQuestion() {
        if (this.currentQuestionIndex >= this.TotalCount) {
            this.notifier.success('已是最后一题了');
            return;
        }
        this.currentQuestionIndex++;
        await this.HandlerChangeQuestion();
        await this.HandlerBtnController();
    }

    async HandlerBtnController() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            this.IsRepeat = false;
            this.IsShowCollectioned = true;
        } else {
            this.IsRepeat = true;
            this.IsShowCollectioned = false;
        }
    }
    async HandlerChangeQuestion() {
        this.question = this.Questions.filter(
            x => x.rowsIndex == this.currentQuestionIndex
        )[0];

        Storage.Instance.set(QuestionId, this.question.questionId);
    }
    async GetIsCollection() {
        const data = await this.repository.exampractice.GetIsCollection(
            this.question.questionId
        );
        return data.result;
    }

    async collectClick() {
        const data = await this.GetIsCollection();
        if (data > 0) {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.questionId,
            });
            this.collectShow = false;
            this.IsShowCollectioned = false;
            this.IsRepeat = true;
        } else {
            await this.repository.exampractice.AddCollectSubject({
                questionId: this.question.questionId,
            });
            this.collectShow = true;
            this.IsShowCollectioned = true;
            this.IsRepeat = false;
        }
    }

    viewQuestionItems() {
        this.navigator.redirect(ReviewQuestionsCollectTopicList, '', {
            IsShowHand: false,
            count: this.Questions.length,
            subjectId: this.subjectId,
            dateType: this.dateType,
            subType: this.subType,
        });
    }

    questionsCollectList() {
        //清除缓存
        this.clearStroage();
        //返回
        this.navigator.redirect(QuestionsCollectList);
    }

    clearStroage() {
        //将缓存清除
        Storage.Instance.clear(QuestionId);
        Storage.Instance.clear(Questions);
    }
    // 自定义题删除
    async del() {
        Dialog.confirm({
          title: '提示',
          message: '删除后不可恢复，确定删除？',
          confirmButtonText:"是",
          confirmButtonColor:"#4080FD",
          cancelButtonText:"否",
          cancelButtonColor:"#333",

        })
        .then(async () => {
            await this.repository.exampractice.CancelCollectSubject({
                id: this.question.questionId,
                CollectionType: 1,
            });
            await this.GetAllQuestions();
            await this.InitTotalCountOrCurrentIndex();
        })
        .catch(() => {
            // this.notifier.warn('已取消');
        });
    }
}
